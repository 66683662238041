import {
  IRectangleLayoutConstraint,
  IStandaloneConstraint,
  IWall,
  IZFormLayoutConstraint,
  MailBoxEngravingMaterial,
  WallLayout,
  WallLocation,
  WallType
} from '../schema';
import { Layout, SHELL_THICKNESS } from '../layout/Layout';
import { BoxModelPathService } from '../services/BoxModelPathService';
import {
  BoxViewModel,
  DimensionsViewModel, HDR,
  ScreenBox,
  ScreenLeftRightPlate,
  ScreenMountingFoots,
  ScreenRoof,
  ScreenRoofTopPlate,
  ScreenSocle,
  ScreenTopBottomPlate
} from './BoxViewModel';
import { ColorConversionResult, colorResultTypeGuard, ralToHex } from '../services/utils';
import { IDimensionsService } from '../services/DimensionsService';
import { rootStore } from '../stores';
import { DEFAULT_MATERIAL, RECOMMENDED_MATERIALS } from '../components/ColorSidePanel/ColorSidePanel';

const DELTA = 0.0001;
const SOCLE_OFFSET = 0.04;
const FOOT_THICKNESS = 0.04;
const FOOT_DEPTH = 120;
export const SOCLE_HEIGHT = 0.04;


class BoxViewModelMaker {
  private width: number = -Infinity;
  private height: number = -Infinity;
  private boxes: ScreenBox[];
  private topBottoms: ScreenTopBottomPlate[] = [];
  private leftRights: ScreenLeftRightPlate[] = [];
  private foots: ScreenMountingFoots[] = [];
  private roof: ScreenRoof;
  private socle?: ScreenSocle;
  private dimensions: DimensionsViewModel;

  constructor(
    private model: IWall,
    private readonly dimensionsService: IDimensionsService,
    private engravingMaterial: MailBoxEngravingMaterial | undefined
  ) {
  }

  make(): BoxViewModel {
    this.createBoxes();
    this.calculateDimensions();
    this.createFinishingPlates();
    this.createRoofPlates();
    this.createFoots();
    this.createDimensions();
    if (this.hasSocle()) {
      this.createSocle();
      this.lift();
    }

    const hexResult = ralToHex(this.model.material?.ralColor ?? DEFAULT_MATERIAL.ralColor);
    
    if (!colorResultTypeGuard(hexResult)) {
      throw new Error(`Cannot get HEX value of RAL color ${this.model.material?.ralColor}`);
    }


    return {
      dimensions: this.dimensions,
      boxes: this.boxes,
      topBottoms: this.topBottoms,
      leftRights: this.leftRights,
      mountingFoots: this.foots,
      width: this.width,
      height: this.height,
      cellWidth: this.model.cellWidth,
      cellHeight: this.model.cellHeight,
      offsetHeight: this.model.offsetHeight,
      roof: this.roof,
      socle: this.socle,
      material: {
        color: `#${hexResult.color}`
      },
      engravingMaterial: this.engravingMaterial,
      mountingType: this.model.mountingType,
      layout: this.model.layout,
      location: this.model.location,
      wallType: this.model.wallType,
      hdr: this.getHDR(this.model.location),
      interior: this.getInteriorModel(this.model.wallType, this.model.location),
      boxDepth: this.model.boxDepth
    };
  }

  private getHDR(location: WallLocation): HDR {
    if (location === WallLocation.Inside) {
      return HDR.Inside;
    } else {
      return HDR.Outside;
    }
  }

  private getInteriorModel(type: WallType, location: WallLocation): string {
    switch (type) {
      case WallType.Digital:
        return !rootStore.isDeviceLowPerformant
          ? location
          : (location + 'Mobile');
      case WallType.Mechanical:
      case WallType.Interna:
        return !rootStore.isDeviceLowPerformant ? 'Inside' : 'InsideMobile';
      case WallType.Boxis:
        return !rootStore.isDeviceLowPerformant ? 'OutsideUnsheltered' : 'OutsideUnshelteredMobile';
    }
  }

  private createBoxes() {
    this.boxes = this.model.boxes.map<ScreenBox>(box => {
      const coordinates = Layout.toWorldCoordinates(box.row, box.column, box.box.columns, box.box.rows, this.model.cellWidth, this.model.cellHeight);
      return {
        positionedBox: box,
        view: BoxModelPathService.getWallBoxPath(box.box),
        ...coordinates,
        z: Layout.boxDepth
      };
    });
  }

  private calculateDimensions() {
    this.width = -Infinity;
    this.height = -Infinity;
    this.model.boxes.forEach(box => {
      this.width = Math.max(this.width, box.column + box.box.columns);
      this.height = Math.max(this.height, box.row + box.box.rows);
    });

    this.width *= this.model.cellWidth;
    this.height *= this.model.cellHeight;
  }

  private createFinishingPlates() {
    this.topBottoms = [];
    this.leftRights = [];
    if ([WallType.Interna, WallType.Boxis].includes(this.model.wallType)) {
      return;
    }
    switch (this.model.layout) {
      case WallLayout.Standalone:
        this.createStandaloneFinishingPlates(this.model.layoutOptions as IStandaloneConstraint);
        break;
      case WallLayout.Rectangle:
        this.createRectangleFinishingPlates(this.model.layoutOptions as IRectangleLayoutConstraint);
        break;
      case WallLayout.Z:
        this.createZFormFinishingPlates(this.model.layoutOptions as IZFormLayoutConstraint);
        break;
    }
  }

  private createFoots() {
    if (!this.model.mountingFoots.length) {
      this.foots = [];
      return;
    }
    const { column, row } = this.model.layoutOptions as IRectangleLayoutConstraint;
    const left = this.model.mountingFoots[0];
    const right = this.model.mountingFoots[1];
    const middle = this.model.mountingFoots[2];
    const heightFromGround = this.model.heightFromGround ? this.model.heightFromGround / 1000 : 0;
    const boxesHeight = (row.max - row.min + 1) * this.model.cellHeight;
    const boxesWidth = (column.min + column.max + 1) * this.model.cellWidth;
    const footY = boxesHeight - heightFromGround;
    const boxDepth = this.model.boxDepth;
    const footZ = boxDepth > FOOT_DEPTH ? -0.5 * 0.001 * (boxDepth - FOOT_DEPTH) : 0;
    this.foots = [
      ...left ?
        [{
          view: left.model3D,
          pos: {
            x: -FOOT_THICKNESS,
            y: footY,
            z: footZ
          },
          height: heightFromGround
        }] : [],
      ...right ?
        [{
          view: right.model3D,
          pos: {
            x: boxesWidth,
            y: footY,
            z: footZ
          },
          height: heightFromGround
        }] : [],
      ...middle ?
        [{
          view: middle.model3D,
          pos: {
            x: 0.5 * (boxesWidth - FOOT_THICKNESS),
            y: footY,
            z: footZ
          },
          height: heightFromGround - boxesHeight
        }] : []
    ];

  }

  private createZFormFinishingPlates(layoutOptions: IZFormLayoutConstraint) {
    const { w1, w2, w3, h1, h2, h3 } = layoutOptions;
    const [topLeft, topRight] = this.model.finishingPlates.top;
    const [bottomLeft, bottomRight] = this.model.finishingPlates.bottom;
    const [leftTop, leftBottom] = this.model.finishingPlates.left;
    const [rightTop, rightBottom] = this.model.finishingPlates.right;
    BoxViewModelMaker.assertFinishingPlatesExistence([
      topLeft,
      topRight,
      bottomLeft,
      bottomRight,
      leftTop,
      leftBottom,
      rightTop,
      rightBottom
    ], [
      'top-left',
      'top-right',
      'bottom-left',
      'bottom-right',
      'left-top',
      'left-bottom',
      'right-top',
      'right-bottom'
    ]);
    this.topBottoms = [{
      view: bottomRight.model3D,
      pos: {
        x: w1 * this.model.cellWidth - SHELL_THICKNESS,
        y: this.height - (h1 + h2 + h3) * this.model.cellHeight - SHELL_THICKNESS,
        z: 0
      },
      width: (w2 + w3) * this.model.cellWidth + 2 * SHELL_THICKNESS
    }, {
      view: topRight.model3D,
      pos: {
        x: (w1 + w2) * this.model.cellWidth - SHELL_THICKNESS,
        y: this.height - (h1 + h2) * this.model.cellHeight + DELTA,
        z: 0
      },
      width: w3 * this.model.cellWidth + 2 * SHELL_THICKNESS
    }, {
      view: bottomLeft.model3D,
      pos: {
        x: -SHELL_THICKNESS,
        y: this.height - h1 * this.model.cellHeight - DELTA,
        z: 0
      },
      width: w1 * this.model.cellWidth + 2 * SHELL_THICKNESS
    }, {
      view: topLeft.model3D,
      pos: {
        x: -SHELL_THICKNESS - DELTA,
        y: this.height,
        z: 0
      },
      width: (w1 + w2) * this.model.cellWidth + 2 * SHELL_THICKNESS
    }];
    this.leftRights = [{
      view: leftTop.model3D,
      pos: {
        x: -SHELL_THICKNESS + DELTA,
        y: this.height - h1 * this.model.cellHeight,
        z: 0
      },
      height: h1 * this.model.cellHeight
    }, {
      view: leftBottom.model3D,
      pos: {
        x: w1 * this.model.cellWidth - SHELL_THICKNESS - DELTA,
        y: this.height - (h1 + h2 + h3) * this.model.cellHeight,
        z: 0
      },
      height: (h2 + h3) * this.model.cellHeight
    }, {
      view: rightTop.model3D,
      pos: {
        x: (w1 + w2) * this.model.cellWidth + DELTA,
        y: this.height - (h1 + h2) * this.model.cellHeight,
        z: 0
      },
      height: (h1 + h2) * this.model.cellHeight
    }, {
      view: rightBottom.model3D,
      pos: {
        x: (w1 + w2 + w3) * this.model.cellWidth + DELTA,
        y: this.height - (h1 + h2 + h3) * this.model.cellHeight,
        z: 0
      },
      height: h3 * this.model.cellHeight
    }];
  }

  private createRectangleFinishingPlates(layoutOptions: IRectangleLayoutConstraint) {
    const { column, row } = layoutOptions;
    const left = this.model.finishingPlates.left[0];
    const right = this.model.finishingPlates.right[0];
    const top = this.model.finishingPlates.top[0];
    const bottom = this.model.finishingPlates.bottom[0];
    BoxViewModelMaker.assertFinishingPlatesExistence([
      left,
      right,
      top,
      bottom
    ], [
      'left',
      'right',
      'top',
      'bottom'
    ]);

    this.topBottoms = [
      // bottom
      {
        view: bottom.model3D,
        pos: {
          x: column.min * this.model.cellWidth - SHELL_THICKNESS,
          y: row.min * this.model.cellHeight - SHELL_THICKNESS,
          z: 0
        },
        width: (column.max - column.min + 1) * this.model.cellWidth + 2 * SHELL_THICKNESS
      },
      // top
      {
        view: top.model3D,
        pos: {
          x: column.min * this.model.cellWidth - SHELL_THICKNESS,
          y: (row.max + 1) * this.model.cellHeight - SHELL_THICKNESS + DELTA,
          z: 0
        },
        width: (column.max - column.min + 1) * this.model.cellWidth + 2 * SHELL_THICKNESS
      }
    ];
    this.leftRights = [
      // left
      {
        view: left.model3D,
        pos: {
          x: column.min * this.model.cellWidth - SHELL_THICKNESS - DELTA,
          y: row.min * this.model.cellHeight,
          z: 0
        },
        height: (row.max - row.min + 1) * this.model.cellHeight
      },
      // // right
      {
        view: right.model3D,
        pos: {
          x: (column.min + column.max + 1) * this.model.cellWidth + DELTA,
          y: row.min * this.model.cellHeight,
          z: 0
        },
        height: (row.max - row.min + 1) * this.model.cellHeight
      }
    ];
  }

  private createStandaloneFinishingPlates(layoutOptions: IStandaloneConstraint) {
    const { columns, rows } = layoutOptions;
    const bottom = this.model.finishingPlates.bottom[0];
    BoxViewModelMaker.assertFinishingPlatesExistence([bottom], ['bottom']);
    this.topBottoms = [
      // bottom
      {
        view: bottom.model3D,
        pos: {
          x: -SHELL_THICKNESS,
          y: -SHELL_THICKNESS,
          z: 0
        },
        width: columns * this.model.cellWidth + 2 * SHELL_THICKNESS
      }];

    const hasRoof = this.model.roof.top.length > 0;
    if (!hasRoof) {
      // top
      const top = this.model.finishingPlates.top[0];
      BoxViewModelMaker.assertFinishingPlatesExistence([top], ['top']);
      this.topBottoms.push({
        view: top.model3D,
        pos: {
          x: -SHELL_THICKNESS,
          y: rows * this.model.cellHeight + DELTA,
          z: 0
        },
        width: columns * this.model.cellWidth + 2 * SHELL_THICKNESS
      });
    }

    if (hasRoof) {
      this.leftRights = [];
    } else {
      const left = this.model.finishingPlates.left[0];
      const right = this.model.finishingPlates.right[0];
      BoxViewModelMaker.assertFinishingPlatesExistence([left, right], ['left', 'right']);

      this.leftRights = [
        //left
        {
          view: left.model3D,
          pos: {
            x: -SHELL_THICKNESS - DELTA,
            y: 0,
            z: 0
          },
          height: rows * this.model.cellHeight
        },
        // right
        {
          view: right.model3D,
          pos: {
            x: columns * this.model.cellWidth + DELTA,
            y: 0,
            z: 0
          },
          height: rows * this.model.cellHeight
        }];
    }
  }

  private createRoofPlates(): void {
    const topPlates: ScreenRoofTopPlate[] = [];
    const maxRow = Math.max(...this.model.boxes.map(box => box.row + box.box.rows));
    const height = maxRow * this.model.cellHeight;
    let xShift = 0;
    const zShift = 0.24;
    for (const topPlate of this.model.roof.top) {
      const width = topPlate.columns * this.model.cellWidth;
      topPlates.push({
        pos: {
          x: xShift,
          y: height,
          z: zShift
        },
        view: topPlate.model3D,
        text: topPlate.text,
        textAlignment: topPlate.textAlignment
      });
      xShift += width;
    }
    const leftPanels = this.model.roof.left;
    const leftRights: ScreenLeftRightPlate[] = [];
    if (leftPanels.length) {
      leftRights.push({
        pos: {
          x: 0,
          y: 0,
          z: zShift
        },
        height: this.height,
        view: leftPanels[0].model3D
      });
    }
    const rightPlates = this.model.roof.right;
    if (rightPlates.length) {
      leftRights.push({
        pos: {
          x: this.width,
          y: 0,
          z: zShift
        },
        height: this.height,
        view: rightPlates[0].model3D
      });
    }
    this.roof = {
      topPlates,
      leftRights
    };
  }

  private createSocle(): void {
    if (this.model.socleRules.length < 1) {

      throw new Error('Cannot find socle rules');
    }
    this.socle = {
      view: this.model.socleRules[0].socle.model3D,
      pos: {
        x: -SHELL_THICKNESS + SOCLE_OFFSET,
        y: 0,
        z: -SOCLE_OFFSET
      },
      width: this.width + 2 * SHELL_THICKNESS - 2 * SOCLE_OFFSET
    };
  }

  private hasSocle(): boolean {
    return this.model.layout === WallLayout.Standalone;
  }

  private lift(): void {
    this.boxes.forEach(obj => {
      obj.y += SOCLE_HEIGHT;
    });
    this.topBottoms.forEach(obj => {
      obj.pos.y += SOCLE_HEIGHT;
    });
    this.leftRights.forEach(obj => {
      obj.pos.y += SOCLE_HEIGHT;
    });
    this.roof.leftRights.forEach(obj => {
      obj.pos.y += SOCLE_HEIGHT;
    });
    this.roof.topPlates.forEach(obj => {
      obj.pos.y += SOCLE_HEIGHT;
    });
  }

  private static assertFinishingPlatesExistence<T>(items: (T | undefined)[], labels: string[]) {
    for (let i = 0; i < items.length; i++) {
      if (!items[i]) {
        throw new Error(`Cannot find ${labels[i]} finishing plate`);
      }
    }
  }

  private createDimensions() {
    this.dimensions = {
      width: this.dimensionsService.width,
      height: this.dimensionsService.height
    };
  }
}

export default BoxViewModelMaker;
