import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import Guide, { GuideFrame } from "../Guide/Guide";

import Illustration from './assets/Esafe-wizzard-animation.svg';
import styles from './RemoveGuide.module.scss';

interface IProps {
  onChangeStep: (step: number) => void;
  onFinish: () => void;
}

const FirstStep: React.VFC = () => (
  <div>
    <FormattedMessage id="boxes.remove.guide.1" />
  </div>
);

const SecondStep: React.VFC = () => (
  <div>
    <FormattedMessage id="boxes.remove.guide.2" />
  </div>
);

const ThirdStep: React.VFC = () => (
  <GuideFrame>
    <div className={styles.image}>
      <img src={Illustration} alt="" />
    </div>
    <FormattedMessage id="boxes.remove.guide.3" />
  </GuideFrame>
);

const RemoveGuide: React.VFC<IProps> = ({ onChangeStep, onFinish }) => {
  const steps = React.useMemo(() => [<FirstStep />, <SecondStep />, <ThirdStep />], []);

  return <Guide steps={steps} onChangeStep={onChangeStep} onFinish={onFinish} />;
};

export default RemoveGuide;
