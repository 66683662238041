import { SelectionZone } from "../types";
import { Layout } from "../../layout/Layout";
import { v4 } from "uuid";
import { canBoxBeRemovedFromLayout } from "../../services/utils";

const ZONE_PADDING = 0.02;

export type RemoveColumnsCoordinates = {
  column: number;
  count: number;
}

export type RemoveColumnZone = SelectionZone & RemoveColumnsCoordinates;

export function removeColumnZoneTypeGuard(zone: SelectionZone): zone is RemoveColumnZone {
  return 'column' in zone && 'count' in zone;
}

export function createColumnRemoveZones(layout: Layout, columnsToRemove: RemoveColumnsCoordinates[], disabled: boolean): RemoveColumnZone[] {
  const result: RemoveColumnZone[] = [];

  for (const { column, count } of columnsToRemove) {
    let canDeleteColumn = true;

    let firstRow: number | undefined = undefined;
    let lastRow: number | undefined = undefined;

    const rows = [...layout.getRows(column)];

    for (const row of rows) {
      const box = layout.safeFindBoxAt({ row, column });

      if (!box) {
        if (lastRow !== undefined) {
          firstRow = row;
        }
        continue;
      }

      if (lastRow === undefined) {
        lastRow = row;
      }
    }

    firstRow = firstRow ?? rows[rows.length - 1];
    lastRow = lastRow ?? rows[0];

    if (canDeleteColumn) {
      let { centerX, centerY } = layout.toWorldCoordinates(firstRow, column, count, lastRow - firstRow + 1);

      result.push({
        id: v4(),
        column,
        count,
        centerX,
        centerY,
        height: (lastRow - firstRow + 1) * layout.cellHeight - ZONE_PADDING * 2,
        width: layout.cellWidth * count - ZONE_PADDING * 2,
        selected: false,
        valid: true,
        disabled: disabled
      })
    }
  }

  return result;
}

export function createNonRemovableZones(layout: Layout): SelectionZone[] {
  const result: SelectionZone[] = [];

  for (const box of layout.boxes) {
    let columnOfTheSameSize = true;

    if (box.box.columns > 1) {
      for (const row of layout.getRows(box.column)) {
          const neighbourBox = layout.safeFindBoxAt({ row, column: box.column });
          if (!neighbourBox) {
            continue;
          }

          if (neighbourBox.column === box.column && neighbourBox.box.columns === box.box.columns) {
            continue;
          }
          columnOfTheSameSize = false;
      }
    }

    if ((box.box.columns > 1 && !columnOfTheSameSize) || !canBoxBeRemovedFromLayout(box.box)) {
      const { centerX, centerY } = layout.toWorldCoordinates(box.row, box.column, box.box.columns, box.box.rows);

      result.push({
        id: v4(),
        centerX,
        centerY,
        height: box.box.rows * layout.cellHeight - ZONE_PADDING * 2,
        width: box.box.columns * layout.cellWidth - ZONE_PADDING * 2,
        selected: false,
        valid: false,
        disabled: true
      });
    }
  }

  return result;
}
