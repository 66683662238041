import { Box3, Camera, Scene, Vector3 } from 'three';
import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import s from '../components/Viewer/Viewer.module.scss';
import { BoxScene } from './BoxScene';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { MountingType, WallLayout } from '../schema';
import { rootStore } from "../stores";
import { DimensionsService, FOOT_WIDTH } from '../services/DimensionsService';

const TEXT_SIZE = '18px';
const TEXT_COLOR = 'rgb(255,255,255)';
const BACKGROUND_COLOR = 'rgb(69,85,96)';
const BORDER_RADIUS = '7px';
const PADDING = '3px 10px';
const MAIN_LINE_OFFSET = 0.08;
const LABEL_OFFSET = 0.08;
const SOCLE_OFFSET = 0.025;

export class DimensionsRenderer {
  private dimensionsRenderer2D!: CSS2DRenderer;
  private showRuler = false;

  constructor(width: number, height: number) {
    this.dimensionsRenderer2D = new CSS2DRenderer();
    this.dimensionsRenderer2D.setSize(width, height);
    this.dimensionsRenderer2D.domElement.classList.add('dimensions');

    if (document.getElementsByClassName(s.viewer)[0]) {
      document.getElementsByClassName(s.viewer)[0].appendChild(this.dimensionsRenderer2D.domElement);
    }
  }

  render(dimensionsScene: Scene, camera: Camera) {
    if (this.showRuler) {
      this.dimensionsRenderer2D.render(dimensionsScene, camera);
    }
  }

  setSize(width: number, height: number) {
    this.dimensionsRenderer2D.setSize(width, height);
  }

  clearScene(scene: BoxScene) {
    scene.getDimensionsScene().remove(...scene.getDimensionsScene().children);
    scene.getRulerNode().remove(...scene.getRulerNode().children);
  }

  buildRuler(scene: BoxScene, wallLayout: WallLayout, mountingType: MountingType, showRuler: boolean, boxWidth: number, boxHeight: number) {
    this.clearScene(scene);
    this.showRuler = showRuler;
    if (showRuler) {
      const sceneBox = new Box3();
      const sceneBoxSize = new Vector3();
      const sceneBoxCenter = new Vector3();
      const socleOffset = wallLayout === WallLayout.Standalone ? SOCLE_OFFSET : 0;
      sceneBox.setFromObject(scene.getRootNode());
      sceneBox.getSize(sceneBoxSize);
      sceneBox.getCenter(sceneBoxCenter);

      if (DimensionsService.isFeetExist(mountingType)) {
        const footWidth = DimensionsService.mmToM(FOOT_WIDTH);
        sceneBox.min.setX(sceneBox.min.x - footWidth);
        sceneBox.max.setX(sceneBox.max.x + footWidth);
      }

      this.add2DLabel(new Vector3(sceneBoxCenter.x, sceneBox.max.y + LABEL_OFFSET, sceneBox.max.z), boxWidth.toFixed(0), scene);

      this.add3DLine([sceneBox.min.x, sceneBox.max.y + MAIN_LINE_OFFSET, sceneBox.max.z], [sceneBox.max.x, sceneBox.max.y + MAIN_LINE_OFFSET, sceneBox.max.z], 0.0035, scene);
      this.add3DLine([sceneBox.min.x - 0.004, sceneBox.max.y + 0.11, sceneBox.max.z], [sceneBox.min.x - 0.004, sceneBox.max.y + 0.05, sceneBox.max.z], 0.0015, scene);
      this.add3DLine([sceneBox.max.x + 0.004, sceneBox.max.y + 0.11, sceneBox.max.z], [sceneBox.max.x + 0.004, sceneBox.max.y + 0.05, sceneBox.max.z], 0.0015, scene);

      this.add2DLabel(new Vector3(sceneBox.min.x - LABEL_OFFSET, sceneBoxCenter.y, sceneBox.max.z), boxHeight.toFixed(0), scene, -90);

      this.add3DLine([sceneBox.min.x - MAIN_LINE_OFFSET, sceneBox.min.y - socleOffset, sceneBox.max.z], [sceneBox.min.x - MAIN_LINE_OFFSET, sceneBox.max.y, sceneBox.max.z], 0.0015, scene);
      this.add3DLine([sceneBox.min.x - 0.1, sceneBox.min.y - socleOffset, sceneBox.max.z], [sceneBox.min.x - 0.06, sceneBox.min.y - socleOffset, sceneBox.max.z], 0.0035, scene);
      this.add3DLine([sceneBox.min.x - 0.1, sceneBox.max.y, sceneBox.max.z], [sceneBox.min.x - 0.06, sceneBox.max.y, sceneBox.max.z], 0.0035, scene);
    }
  }

  private add2DLabel(position: Vector3, content: string, scene: BoxScene, rotation = 0) {
    const text = document.createElement('div');
    text.textContent = content + ` ${rootStore.localization.formatMessage('units.mm')}`;
    text.style.fontSize = TEXT_SIZE;
    text.style.color = TEXT_COLOR;
    text.style.transform = `rotate(${rotation}deg)`;
    text.style.backgroundColor = BACKGROUND_COLOR;
    text.style.borderRadius = BORDER_RADIUS;
    text.style.padding = PADDING;
    const wrap = document.createElement('div');
    wrap.appendChild(text);
    const label = new CSS2DObject(wrap);
    label.position.copy(position);
    scene.getDimensionsScene().add(label);
  }

  private add3DLine(from: number[], to: number[], width: number, scene: BoxScene, opacity = 0.85) {
    const geometry = new LineGeometry().setPositions([...from, ...to]);
    const material = new LineMaterial({ color: 0x455560, linewidth: width, transparent: true });
    material.uniforms.opacity.value = opacity;
    const line = new Line2(geometry, material);
    scene.getRulerNode().add(line);
  }

}
