import { Layout } from '../../layout/Layout';


export class RemoveRowBehaviour {
    public constructor(private readonly layout: Layout) {}

    public removeRow(start: number, count: number): void {
        this.layout.removeRow(start, count);
    }
}
