import {Mesh, Object3D} from "three";
import {IMorph} from "./types";

export const removeNodeChildren = (node: Object3D) => {
    node.children.forEach(obj => {
        obj.traverse((child) => {
            if(child instanceof Mesh) {
                child.material.dispose();
                child.geometry.dispose();
            }
        })
    })
    node.remove(...node.children);
}

export const morph = (mesh: Mesh, morph: IMorph, val: number) => {
    const ind = mesh.morphTargetDictionary?.[morph.name];

    if(undefined === ind) {
        console.log(`ERROR: Can't morph ${morph.name}. Name not found.`);

        return;
    }

    if(undefined === mesh.morphTargetInfluences?.[ind]) {
        console.log(`ERROR: Can't morph ${morph.name}. Index not found.`)

        return;
    }

    return mesh.morphTargetInfluences[ind] = (val - morph.min) / (morph.max - morph.min);
}
