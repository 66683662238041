import React from 'react';
import { FormattedMessage } from 'react-intl';
import { WallLayout } from '../../schema';
import { rootStore } from '../../stores';
import { WizardStore } from '../../stores/WizardStore';
import { IconName } from '../Icon/Icon';
import Selector, { ImageType } from '../Selector/Selector';
import Typography from '../Typography/Typography';
import styles from './layoutForm.module.scss';

interface IProps {
  className?: string;
  wallLayout: WallLayout | undefined;
  availableWallLayouts: WallLayout[];
  wizardStore: WizardStore;
  onSelect?: () => void;
}

export const LayoutForm: React.FC<IProps> = ({
                                               className,
                                               wallLayout,
                                               availableWallLayouts,
                                               wizardStore,
                                               onSelect
                                             }) => {
  const options = React.useMemo(() => {
    return availableWallLayouts.map(layout => ({
      value: layout,
      title: <FormattedMessage id={`layout.${layout}`} />,
      image: layout as IconName,
      imageType: ImageType.Icon
    }));
  }, [availableWallLayouts]);

  const handleOnSelect = React.useCallback((layout: string) => {
    onSelect?.();
    wizardStore.setWallLayout(layout as WallLayout);
  }, [onSelect, wizardStore]);

  return (
    <section className={className}>
      <Typography variant="h2" align="center">
        <FormattedMessage id="menu.options.layout" />
      </Typography>
      <Selector
        name={rootStore.localization.formatMessage('menu.options.layout')}
        options={options}
        selected={wallLayout}
        onSelect={handleOnSelect}
        className={styles.layoutForm__selector}
        classes={{ list: styles.selectorList }}
      />
    </section>
  );
};

export default LayoutForm;
