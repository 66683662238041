import { IPostProcessor } from './IPostProcessor';
import { ShelteringPostProcessor } from './ShelteringPostProcessor';
import { WallPostProcessor } from './WallPostProcessor';
import {
  CentralBoxExternalFields,
  IAccessory,
  IBackPlate,
  ICentralBox,
  ICuttingService,
  IMountingFoot,
  IRFIDBadge,
  IRoofModuleConnection,
  IRoofTopPlate,
  ISidePlate,
  ITopBottomPlate,
  IWall, MailBoxExternalFields,
  WallBox
} from '../schema';
import { Engine, FlatStructuralTransformer, PModel, PropertyPath } from '@canvas-logic/engine';
import { SoclePostProcessor } from './SoclePostProcessor';
import { PackagingPostProcessor } from './PackagingPostProcessor';
import { HangingBracketPostProcessor } from './HangingBracketsPostProcessor';
import { BackplatesPostProcessor } from './BackplatesPostProcessor';
import { CentralBoxPostProcessor } from './CentralBoxPostProcessor';
import { centralBoxTypeGuard, mailBoxTypeGuard } from '../guards';
import { CentralBoxAccessoriesPostProcessor } from './CentralBoxAccessoriesPostProcessor';
import { MailBoxPostProcessor } from './MailBoxPostProcessor';
import { MountingFootPostProcessor } from './MountingFootsPostProcessor';
import { getInitialSettings } from '../services/utils';
import { RFIDBadgesPostProcessor } from './RFIDBadgesPostProcessor';

export class WallPostProcessorFactory {
  static create(engine: Engine, referenceModel: IWall,
                centralFields: CentralBoxExternalFields,
                mailboxFields: MailBoxExternalFields): IPostProcessor {
    const getModels = getModelList(engine, referenceModel);
    const roofTopPlates = getModels<IRoofTopPlate>('roof.top');
    const roofLeftPlates = getModels<ISidePlate>('roof.left');
    const roofRightPlates = getModels<ISidePlate>('roof.right');
    const finishingTopPlates = getModels<ITopBottomPlate>('finishingPlates.top');
    const finishingBottomPlates = getModels<ITopBottomPlate>('finishingPlates.bottom');
    const finishingLeftPlates = getModels<ISidePlate>('finishingPlates.left');
    const finishingRightPlates = getModels<ISidePlate>('finishingPlates.right');
    const connections = getModels<IRoofModuleConnection>('roof.connections');
    const initialSettings = getInitialSettings(referenceModel);

    const shelteringCuttingServices =
      engine.optionValuesByPath(
        referenceModel,
        'shelteringCuttingServices',
        new FlatStructuralTransformer<ICuttingService>()
      ) ?? [];
    if (shelteringCuttingServices.length !== 1) {
      throw new Error('You must provide 1 sheltering cutting service');
    }

    const soclePostProcessor = new SoclePostProcessor();

    const packagingPostProcessor = new PackagingPostProcessor();

    const hangingBracketsPostProcessor = new HangingBracketPostProcessor();

    const backPlatesOptions =
      engine.optionValuesByPath(referenceModel, 'backPlates', new FlatStructuralTransformer<IBackPlate>()) ?? [];
    const backPlates = backPlatesOptions.map(o => o.model);
    const backplatesCuttingServices =
      engine.optionValuesByPath(
        referenceModel,
        'backplatesCuttingServices',
        new FlatStructuralTransformer<ICuttingService>()
      ) ?? [];
    if (backplatesCuttingServices.length !== 1) {
      throw new Error('You must provide 1 backplates cutting service');
    }

    const accessories = (
      engine.optionValuesByPath(referenceModel, 'accessories', new FlatStructuralTransformer<IAccessory>()) ?? []
    ).map(o => o.model);

    const backplatesPostProcessor = new BackplatesPostProcessor(backPlates, backplatesCuttingServices[0].model, accessories);

    const boxesOptions =
      engine.optionValuesByPath(referenceModel, 'boxes.box', new FlatStructuralTransformer<WallBox>()) ?? [];

    const centralBoxes: ICentralBox[] = [];
    for (const option of boxesOptions) {
      const box = option.model;
      if (centralBoxTypeGuard(box)) {
        centralBoxes.push(box);
      }
    }
    const centralBoxPostProcessor = new CentralBoxPostProcessor(centralBoxes, centralFields, initialSettings);

    const centralBoxAccessoriesProcessor = new CentralBoxAccessoriesPostProcessor(accessories);
    const mailBoxAccessoriesPostProcessor = new MailBoxPostProcessor(accessories, mailboxFields);

    const mountingFoots = (
      engine.optionValuesByPath(referenceModel, 'mountingFoots', new FlatStructuralTransformer<IMountingFoot>()) ?? []
    ).map(o => o.model);

    const mountingFootPostProcessor = new MountingFootPostProcessor(mountingFoots, accessories);

    const mailBox = boxesOptions.find(box => mailBoxTypeGuard(box.model));

    let rfidBadges: IRFIDBadge[] = [];

    if (mailBox) {
      rfidBadges = (
        engine.optionValuesByPath(mailBox.model, 'rfidBadges', new FlatStructuralTransformer<IRFIDBadge>()) ?? []
      ).map(o => o.model);
    }

    const rfidBadgesPostProcessor = new RFIDBadgesPostProcessor(rfidBadges);

    return new WallPostProcessor(
      new ShelteringPostProcessor(
        {
          roofTopPlates,
          roofLeftPlates,
          roofRightPlates,
          finishingTopPlates,
          finishingBottomPlates,
          finishingLeftPlates,
          finishingRightPlates
        },
        connections,
        shelteringCuttingServices[0].model
      ),
      soclePostProcessor,
      packagingPostProcessor,
      hangingBracketsPostProcessor,
      backplatesPostProcessor,
      centralBoxPostProcessor,
      centralBoxAccessoriesProcessor,
      mailBoxAccessoriesPostProcessor,
      mountingFootPostProcessor,
      rfidBadgesPostProcessor
    );
  }
}

function getModelList(engine: Engine, referenceModel: PModel): <T>(path: PropertyPath) => T[] {
  return <T>(path: PropertyPath) => {
    const optionList = engine.optionValuesByPath(referenceModel, path, new FlatStructuralTransformer<T>()) ?? [];
    return optionList.map(option => option.model);
  };
}
