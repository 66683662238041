import { IPostProcessor } from "./IPostProcessor";
import { AccessoryType, IAccessory, IMountingFoot, IWall, MountingType } from "../schema";
import { ILayoutData, LayoutFactory } from "../layout/LayoutFactory";
import { getOptionId } from "@canvas-logic/engine";

export type IMountingFootWall = ILayoutData & {
  mountingType: IWall['mountingType'];
  heightFromGround?: IWall['heightFromGround'];
  mountingFoots: IWall['mountingFoots'];
  accessories: IWall['accessories'];
};

export class MountingFootPostProcessor implements IPostProcessor {
  private bySize: Map<number, IMountingFoot>;
  private byType: Map<AccessoryType, IAccessory>;
  private amount: Map<AccessoryType, number>;

  private wall: IMountingFootWall;
  private static mountingFootAccessories: AccessoryType[] = [
    AccessoryType.Traverse,
    AccessoryType.MountingFootFixationLock,
    AccessoryType.MountingFootWeldingPlate
  ];

  constructor(private readonly mountingFoots: IMountingFoot[], private readonly accessories: IAccessory[]) {
    this.normalizeFoots();
  }

  normalizeFoots() {
    this.bySize = new Map();
    for (const foot of this.mountingFoots) {
      if (this.bySize.has(foot.height)) {
        console.warn(`Encountered mounting foot (${getOptionId(foot)}) with duplicated height`);
      }

      this.bySize.set(foot.height, foot);
    }
  }

  process(wall: IMountingFootWall): void {
    this.wall = wall;
    const layout = LayoutFactory.create(wall);

    const layoutWidth = layout.width * layout.cellWidth;
    const extraFootNeeded = layoutWidth > 2.5;

    switch (wall.mountingType) {
      case MountingType.FixOnTheGround:
        if (wall.heightFromGround === 1500) {
          const foot = this.bySize.get(1500);
          if (foot) {
            wall.mountingFoots = extraFootNeeded ? [foot, foot, foot] : [foot, foot];
          }
        } else {
          const foot = this.bySize.get(2000);
          if (foot) {
            wall.mountingFoots = extraFootNeeded ? [foot, foot, foot] : [foot, foot];
          }
        }
        break;
      case MountingType.FixOnConcrete:
        if (wall.heightFromGround === 1200) {
          const foot = this.bySize.get(1500);
          if (foot) {
            wall.mountingFoots = extraFootNeeded ? [foot, foot, foot] : [foot, foot];
          }
        } else if (wall.heightFromGround === 1600) {
          const foot = this.bySize.get(2000);
          if (foot) {
            wall.mountingFoots = extraFootNeeded ? [foot, foot, foot] : [foot, foot];
          }
        } else if (wall.heightFromGround === 1800) {
          const foot = this.bySize.get(2250);
          if (foot) {
            wall.mountingFoots = extraFootNeeded ? [foot, foot, foot] : [foot, foot];
          }
        }
        break;
    }

    this.parseAccessories();
    this.clearOldAccessories();

    this.processFootAccessories(layoutWidth);
    this.addAccessories();
  }

  private clearOldAccessories() {
    this.wall.accessories = this.wall.accessories
      .filter(accessory => !MountingFootPostProcessor.mountingFootAccessories.includes(accessory.type));
  }

  private parseAccessories() {
    this.byType = new Map<AccessoryType, IAccessory>();
    this.amount = new Map<AccessoryType, number>();

    MountingFootPostProcessor.mountingFootAccessories.forEach(accessoryType => {
      const accessory = this.accessories.find(item => item.type === accessoryType);
      if (!accessory) {
        throw new Error(`Cannot find accessory ${accessoryType}`);
      }
      this.byType.set(accessory.type, accessory);
      this.amount.set(accessory.type, 0);
    });
  }

  private setAccessoryAmount(type: AccessoryType, number: number): void {
    this.amount.set(type, number);
  }

  private processFootAccessories(layoutWidth: number) {
    if ([MountingType.FixOnTheGround, MountingType.FixOnConcrete].includes(this.wall.mountingType)) {
      if (layoutWidth > 2.5) {
        this.setAccessoryAmount(AccessoryType.Traverse, 2);
      } else if (layoutWidth > 1.5) {
        this.setAccessoryAmount(AccessoryType.Traverse, 1);
      }
    }

    this.setAccessoryAmount(AccessoryType.MountingFootFixationLock, this.wall.mountingFoots.length);
    if (this.wall.mountingType === MountingType.FixOnTheGround) {
      this.setAccessoryAmount(AccessoryType.MountingFootWeldingPlate, this.wall.mountingFoots.length);
    }
  }

  private addAccessories() {
    for (let [type, amount] of this.amount.entries()) {
      while (amount-- > 0) {
        const accessory = this.getAccessory(type);
        this.wall.accessories.push(accessory);
      }
    }
  }

  private getAccessory(type: AccessoryType) {
    const candidate = this.byType.get(type);
    if (!candidate) {
      throw new Error(`Cannot find ${type}`);
    }
    return candidate;
  }
}
