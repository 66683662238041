import classNames from "classnames";
import Icon from "../Icon/Icon";

import styles from './TopMenuLoader.module.scss';

interface IProps {
    className?: string;
}

export const TopMenuLoader: React.FC<IProps> = ({ className }) => {
    return (
        <div className={classNames(styles.loaderWrapper, className)}>
            <Icon type="loader24" size="small" />
        </div>
    );
}