export interface Position {
  row: number;
  column: number;
}

export function addPosition(a: Position, b: Position): Position {
  return {
    row: a.row + b.row,
    column: a.column + b.column
  };
}
export function subtractPosition(a: Position, b: Position): Position {
  return {
    row: a.row - b.row,
    column: a.column - b.column
  };
}
