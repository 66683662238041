import React, { useCallback, useEffect } from 'react';
import styles from './WizardCountrySelector.module.scss';
import Typography from '../../../../components/Typography/Typography';
import { FormattedMessage } from 'react-intl';
import TextField from '../../../../components/TextField/TextField';
import CountrySelector from '../../../../components/CountrySelector/CountrySelector';
import { observer } from 'mobx-react';
import { IWizardStepProps } from '../../WizardStepper';
import { rootStore } from '../../../../stores';

const WizardCountrySelector: React.VFC<IWizardStepProps> = observer(({ store, onChange, index }) => {
  const [customCountry, setCustomCountry] = React.useState(store.model.country.name ?? '');

  const handleOnCompleted = useCallback(() => {
    onChange(index);
  }, [index, onChange]);

  useEffect(() => {
    handleOnCompleted();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const country = e.target.value;
    setCustomCountry(country);
  };

  const handleOnBlur = () => {
    store.setCountry(customCountry);
  };

  const handleSetCountryName = (name: string) => {
    setCustomCountry('');
    store.setCountry(name);
  };

  const selectedCountry = () => {
    return customCountry || store.model.country.name;
  };

  const inputValue = React.useMemo(() => {
    const value = store.availableCountries
      .map(country => {
        return country.model.name;
      })
      .includes(customCountry);

    if (value) {
      return '';
    }

    return customCountry;
  }, [store.availableCountries, customCountry]);

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <Typography variant="h2" className={styles.header}>
          <FormattedMessage id={'menu.options.country'} />
        </Typography>
        <div>
          <CountrySelector
            name="country"
            countries={store.availableCountries}
            selectedCountry={selectedCountry()}
            onSelect={handleSetCountryName}
            isMobile={rootStore.isMobile}
          />
        </div>
        <TextField
          maxLength={30}
          value={inputValue}
          onChange={handleChange}
          onBlur={handleOnBlur}
          className={styles.countryInput}
          placeholder={rootStore.localization.formatMessage('wizardStepper.countrySelector.placeholder')}
        />
      </div>
    </div>
  );
});

export default WizardCountrySelector;
