import { useCallback, useEffect, useState } from "react";

export type Setter<T> = (v: T) => void;

export function useDebounce<T>(action: Setter<T>, initialValue: T, delay: number = 300) {
  const [handle, setHandle] = useState<number>(0);
  const [displayValue, setDisplayValue] = useState<T>(initialValue);

  useEffect(() => {
    setDisplayValue(initialValue);
  }, [initialValue]);

  const setter: Setter<T> = useCallback((value) => {
    setDisplayValue(value);
    if (handle) {
      clearTimeout(handle);
    }
    setHandle(window.setTimeout(() => {
      action(value);
    }, delay));
  }, [handle, action, delay]);
  return { displayValue, setter };
}