import * as React from 'react';
import classNames from 'classnames';

import TextField from '../TextField/TextField';
import SelectItem from '../SelectItem/SelectItem';
import { dataCy, DataCyAttribute } from '../../cy';
import Typography from '../Typography/Typography';
import Icon from '../Icon/Icon';
import styles from './Autocomplete.module.scss';


interface IOption {
  label?: string | JSX.Element;
  value: string;
}

interface IProps {
  label?: string | JSX.Element;
  value: string;
  placeholder?: string;
  maxLength?: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement> | null, value: string) => void;
  suggestions: IOption[];
  className?: string;
  hasEndAdornment?: boolean;
  textFieldDataCy?: DataCyAttribute;
  select?: boolean;
  disabled?: boolean;
  upwardOpening?: boolean;
}

interface IState {
  popupVisible: boolean;
}

class Autocomplete extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      popupVisible: false
    };
  }

  static defaultProps = {
    hasEndAdornment: false
  };

  handleOnTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props;

    onChange(event, event.target.value);
  };

  closePopup = () => {
    this.setState({
      popupVisible: false
    });
  };

  handleBlur = (event: React.FocusEvent) => {
    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
      this.closePopup();
    }
  };

  getOptionByValue(value: string): IOption | undefined {
    const { suggestions } = this.props;

    return suggestions.find(option => option.value === value)
  }

  private togglePopUp(): void {
    this.setState(previousState => ({ popupVisible: !previousState.popupVisible }));
  }

  renderControl = () => {
    const { label, maxLength, placeholder, value, textFieldDataCy, select } = this.props;
    const { popupVisible } = this.state;
    const title = this.getOptionByValue(value)?.label ?? value;

    if (select) {
      return (
        <div className={styles.autocomplete_inputContainer}>
          {label && <Typography variant="body2">{label}</Typography>}
          <button 
            disabled={this.props.disabled}
            onClick={this.togglePopUp.bind(this)}
            className={styles.autocomplete__input}
            >
            <Typography className={classNames({ [styles.input_placeholder]: !value })}>{value ? title : placeholder}</Typography>
            <span className={styles.iconContainer}>
              <Icon size="xsmall" type={popupVisible ? 'dropdownUp' : 'dropdownDown'} />
            </span>
          </button>
        </div>
      );
    } else {
      return (
        <TextField
          label={label}
          maxLength={maxLength}
          placeholder={placeholder}
          value={value}
          onChange={this.handleOnTextChange}
          componentDataCy={textFieldDataCy}
          // onClick={() => this.setState({ popupVisible: false })}
          onFocus={() => this.setState({ popupVisible: true })}
          disabled={this.props.disabled}
        />
      );
    }
  };

  render() {
    const {
      suggestions,
      hasEndAdornment,
      className,
      label,
      placeholder,
      value,
      onChange,
      maxLength,
      textFieldDataCy,
      select,
      upwardOpening,
      ...rest
    } = this.props;

    return (
      <div {...rest} className={classNames(styles.autocomplete, className)} onBlur={this.handleBlur}>
        {!upwardOpening && this.renderControl()}
        {suggestions.length > 0 && (
          <ul
            className={classNames(styles.autocomplete__suggestions, {
              [styles.autocomplete__suggestionsHidden]: !this.state.popupVisible,
              [styles.upwardOpening]: upwardOpening
            })}
          >
            {suggestions.map(suggestion => (
              <SelectItem
                key={suggestion.value}
                className={styles.autocomplete__suggestion}
                bare
                onClick={() => {
                  this.setState({ popupVisible: false });
                  onChange(null, suggestion.value);
                }}
                selected={suggestion.value === value}
                classes={{ selected: styles.selectedItem }}
              >
                <span {...dataCy('countryNameItem')}>{suggestion.label ?? suggestion.value}</span>
              </SelectItem>
            ))}
          </ul>
        )}
        {upwardOpening && this.renderControl()}
      </div>
    );
  }
}

export default Autocomplete;
