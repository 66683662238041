import { Camera } from 'three';
import { Transition } from '../viewer/Transition';

export interface ValueBoundary {
  min: number;
  max: number;
}

export interface CameraMoveControlBoundary {
  x: ValueBoundary;
  y: ValueBoundary;
}
export const CAMERA_MOVE_TRIGGER_CLASS = 'cmc-trigger';

export class CameraMoveControl {
  private triggerSize = {
    left: 80,
    right: 80,
    top: 100,
    bottom: 120
  };
  private activated = false;
  private cameraShift = 0.4;
  private transition: Transition;
  private loopHandler: number;
  private pending: boolean;
  private boundary: CameraMoveControlBoundary;

  constructor(
    private readonly camera: Camera
  ) {
  }

  deactivate() {
    this.removeHandlers();
    this.activated = false;
  }

  activate() {
    if (!this.activated) {
      this.initHandlers();
      this.activated = true;
    }
  }

  private initHandlers() {
    document.body.addEventListener('pointerdown', this.onMouseMove);
    document.body.addEventListener('pointermove', this.onMouseMove);
    document.body.addEventListener('pointerup', this.onCancel);
  }

  private removeHandlers() {
    document.body.removeEventListener('pointerdown', this.onMouseMove);
    document.body.removeEventListener('pointermove', this.onMouseMove);
    document.body.removeEventListener('pointerup', this.onCancel);
  }

  onMouseMove = (event: PointerEvent) => {
    const target: Element | null = event.target as Element;

    if (!target || !target.classList.contains(CAMERA_MOVE_TRIGGER_CLASS)) {
      return;
    }
    const { clientX, clientY } = event;
    const width = document.body.clientWidth;
    const height = document.body.clientHeight;
    let xShift = 0;
    let yShift = 0;

    if (clientX < this.triggerSize.left) {
      xShift = -this.cameraShift;
    }
    if (clientX > width - this.triggerSize.right) {
      xShift = this.cameraShift;
    }
    if (clientY < this.triggerSize.top) {
      yShift = +this.cameraShift;
    }
    if (clientY > height - this.triggerSize.bottom) {
      yShift = -this.cameraShift;
    }
    if (xShift || yShift) {
      this.enterLoop(xShift, yShift);
    } else {
      this.leaveLoop();
    }
  };
  onCancel = () => {
    this.loopHandler = 0;
  };

  setTransition(transition: Transition) {
    this.transition = transition;
  }

  setBoundary(boundary: CameraMoveControlBoundary): void {
    this.boundary = boundary;
  }

  private async enterLoop(xShift: number, yShift: number) {
    this.loopHandler = 1;
    while (this.loopHandler) {
      const x = this.camera.position.x + xShift;
      const y = this.camera.position.y + yShift;
      if (this.exceedBoundary(x, y)) {
        break;
      }
      this.pending = true;
      await this.transition.transitionTo([
        x,
        y,
        this.camera.position.z,

        x,
        y,
        0
      ], 350);
      this.pending = false;
    }
  }

  private leaveLoop() {
    this.loopHandler = 0;
  }

  private exceedBoundary(x: number, y: number): boolean {
    return this.boundary && (
      this.outsideBoundary(x, this.boundary.x) ||
      this.outsideBoundary(y, this.boundary.y)
    );
  }

  private outsideBoundary(value: number, boundary: ValueBoundary): boolean {
    return value < boundary.min || value > boundary.max;
  }
}