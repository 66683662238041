import { Layout } from '../../layout/Layout';
import { WallBox } from '../../schema';
import { ValueSplitService } from '../../layout/ValueSplitService';
import { uniq } from "../../services/utils";

export type NewColumnType = 'First' | 'Last';

export class NewColumnBehaviour {
  constructor(private readonly layout: Layout) {}

  private getNewColumnIndex(columnType: NewColumnType) {
    switch (columnType) {
      case 'First':
        return 0;
      case 'Last':
        return this.layout.columns;
    }
  }

  private getColumnHeight(column: number) {
    return [...this.layout.getRows(Math.max(0, column - 1))].length;
  }

  newColumn(columnType: NewColumnType, box: WallBox, availableBoxes: WallBox[]) {
    const newColumnIndex = this.getNewColumnIndex(columnType);
    const height = this.getColumnHeight(newColumnIndex);

    /**
     * Get suitable boxes, put lightboxes at the end
     */
    const filteredBoxes = availableBoxes
      .filter(item => {
        if (box.type !== 'lightbox' && item.type === 'lightbox') {
          return false;
        }

        if (box.type === 'lightbox') {
          return item.columns <= box.columns;
        }

        return item.rows <= box.rows && item.columns <= box.columns;
      })
      .sort((a, b) => {
        if (a.type === 'lightbox' && b.type !== 'lightbox') {
          return 1;
        }
        if (b.type === 'lightbox' && a.type !== 'lightbox') {
          return -1;
        }

        return b.rows - a.rows;
      });

    /**
     * Leave only one box of each type with the same dimensions
     */
    const allCandidatesToAdd = uniq(filteredBoxes, box => `${box.rows}x${box.columns}x${box.type}`);

    /**
     * Split lightboxes from the other
     */
    const { lightBoxes, candidates } = allCandidatesToAdd.reduce<{ lightBoxes: WallBox[], candidates: WallBox[]}>((result, box) => {
      if (box.type === 'lightbox') {
        result.lightBoxes.push(box);
      } else {
        result.candidates.push(box);
      }
      return result;
    }, { lightBoxes: [], candidates: [] });


    /**
     * Look for ways to split available space between items
     */
    let splitResult = ValueSplitService.split(
      height - box.rows,
      candidates.map(box => box.rows)
    );

    /**
     * We add lightboxes to the selection pool only when it is impossible
     * without them.
     */
    while (splitResult.length === 0 && lightBoxes.length > 0) {
      candidates.push(lightBoxes.pop()!);
      splitResult = ValueSplitService.split(
        height - box.rows,
        candidates.map(box => box.rows)
      );
    }

    if (splitResult.length === 0) {
      throw new Error('Not possible to combine items into column');
    }

    /**
     * Get the variant with majority of bigger boxes
     */
    splitResult = splitResult.sort((one, two) => {
      for (let index = 0; index < Math.min(one.length, two.length); index++) {
        if (one[index] !== two[index]) {
          return two[index] - one[index];
        }
      }

      return two.length - one.length;
    });

    /**
     * We still have to add initial box first!
     */
    const boxesToAdd = [box].concat(
      splitResult[0].flatMap((count, index) => new Array(count).fill(candidates[index]))
    );
    this.layout.addColumns(newColumnIndex, box.columns);

    /**
     * If we add lightbox, we should add it only once at the beginning
     */
    let addedAtLeastOneLightbox = false;

    for (let boxColumn = 0; boxColumn < box.columns; boxColumn++) {
      for (let boxIndex = 0; boxIndex < boxesToAdd.length; boxIndex++) {
        const boxToAdd = boxesToAdd[boxIndex];
        const column = newColumnIndex + boxColumn;
        const rows = [...this.layout.getRows(column)];

        /**
         * Adding lightboxes to the top, rows are top-to-bottom
         */
        if (boxToAdd.type === 'lightbox') {
          rows.reverse();
        }

        for (let rowIndex = rows.length - 1; rowIndex >= 0; rowIndex--) {
          if (boxToAdd.type === 'lightbox' && addedAtLeastOneLightbox && boxIndex === 0) {
            continue;
          }
          const row = rows[rowIndex];
          if (this.layout.canInsertBoxAt(boxToAdd, { row, column })) {
            this.layout.insertBox({
              row,
              column,
              box: boxToAdd
            });
            if (boxToAdd.type === 'lightbox') {
              addedAtLeastOneLightbox = true;
            }
            break;
          }
        }
      }
    }

    return {
      index: newColumnIndex,
      count: box.columns
    };
  }
}
