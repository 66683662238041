import { IPostProcessor } from './IPostProcessor';
import {
  CentralBoxExternalFields,
  CentralBoxLocation,
  ICentralBox,
  Intercom,
  IntercomSetting,
  IWall,
  WallLocation,
  WallType
} from '../schema';
import { centralBoxTypeGuard } from '../guards';
import { ILayoutData, LayoutFactory } from '../layout/LayoutFactory';
import { getOptionId } from '@canvas-logic/engine';
import { PositionedLayoutBox } from '../layout/Box';
import { InitialSettings } from '../services/utils';

export type CentralBoxWall = ILayoutData & {
  wallType: IWall['wallType'];
  boxes: IWall['boxes'];
  location: IWall['location'];
};

export type ICentralBoxExternalFields = Pick<CentralBoxExternalFields, keyof CentralBoxExternalFields>;

export class CentralBoxPostProcessor implements IPostProcessor {
  private centralBox: ICentralBox | undefined;
  private wall: CentralBoxWall;
  private centralBoxIndex = -1;

  constructor(
    private readonly centralBoxes: ICentralBox[],
    private readonly fields: ICentralBoxExternalFields,
    private readonly initialSettings: InitialSettings
  ) {
  }

  process(wall: CentralBoxWall): void {
    this.wall = wall;
    this.findCentralBox();
    if (this.centralBoxConfigurationRequired()) {
      this.configureCentralBox();
      this.updatePriceAndArticle();
    }
    this.resetState();
  }

  private findCentralBox(): void {
    for (let i = 0; i < this.wall.boxes.length; i++) {
      const box = this.wall.boxes[i].box;
      if (centralBoxTypeGuard(box)) {
        this.centralBox = box;
        this.centralBoxIndex = i;
      }
    }
  }

  private configureCentralBox() {
    if (this.centralBox?.custom) {
      return;
    }

    const layout = LayoutFactory.create(this.wall);
    const isBoxOnTheLeft = this.wall.boxes[this.centralBoxIndex].column < 0.5 * (layout.columns - 1);
    const expectedLocation: CentralBoxLocation =
      this.wall.location === WallLocation.Inside
        ? CentralBoxLocation.Inside
        : this.wall.location === WallLocation.OutsideUnsheltered
          ? CentralBoxLocation.OutsideRight
          : isBoxOnTheLeft
            ? CentralBoxLocation.OutsideLeft
            : CentralBoxLocation.OutsideRight;

    if (this.centralBox?.location !== expectedLocation) {
      this.replaceCentralBox(expectedLocation);
    }
    // this.updateIntercomSettings();
  }

  private replaceCentralBox(expectedLocation: CentralBoxLocation) {
    const candidate = this.centralBoxes.find(box => {
      return (
        box.location === expectedLocation &&
        box.intercom === this.centralBox?.intercom &&
        box.rows === this.centralBox?.rows &&
        box.columns === this.centralBox?.columns
      );
    });
    if (!candidate) {
      throw new Error('Cannot find suitable central box');
    }
    // restore user defined property
    candidate.bellsAmount = this.centralBox?.bellsAmount ?? 0;
    candidate.intercomSetting = this.centralBox?.intercomSetting ?? candidate.intercomSetting;
    const cBox: PositionedLayoutBox = this.wall.boxes[this.centralBoxIndex];
    cBox.box = candidate;
    this.centralBox = candidate;
    console.log(`Replaced ${getOptionId(this.centralBox)} with ${getOptionId(candidate)}`);
  }

  private centralBoxConfigurationRequired(): boolean {
    if ([WallType.Mechanical, WallType.Boxis, WallType.Interna].includes(this.wall.wallType)) {
      return false;
    }
    if (!this.centralBox) {
      throw new Error('You must provide central box');
    }
    return true;
  }

  private resetState() {
    this.centralBox = undefined;
    this.centralBoxIndex = -1;
  }

  private updatePriceAndArticle(): void {
    if (this.centralBox) {
      const overridePrice = this.fields.overridePrice(this.centralBox);
      const overrideArticle = this.fields.overrideArticle(this.centralBox);
      if (overridePrice.isPositive() && overrideArticle !== '') {
        const box = this.wall.boxes[this.centralBoxIndex].box as ICentralBox;
        box.article = overrideArticle;
        box.price = overridePrice;
      }
    }
  }


}
