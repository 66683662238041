import { SummaryPart } from './SummaryPartsMaker';
import React from 'react';
import { Form } from '../Form/Form';
import { rootStore } from '../../stores';
import classNames from 'classnames';
import s from './SummarySidePanel.module.scss';
import { Price } from './Price';
import { IWall } from '../../schema';
import { observer } from 'mobx-react';

export interface PartProps {
  model: IWall;
  part: SummaryPart;
}

export const Part: React.FC<PartProps> = observer(({ part, model }) => {
  return (
    <Form.Section>
      <Form.Section title={rootStore.localization.formatMessage('menu.summary.' + part.header, model)}>
        {part.items.map((item, i: number) => {
          return (
            <div key={i} className={classNames(s.line, s.threeColumnsLine)}>
              <span>{rootStore.localization.formatMessage('summary.subgroup.' + item.title, model)}</span>
              {<span className={s.count}>{item.count !== null && item.count}</span>}
              <Price price={item.price} />
            </div>
          );
        })}
      </Form.Section>
    </Form.Section>
  );
});
