import {
  CentralBoxExternalFields,
  ICentralBox,
  IMailBox,
  IWall,
  IWizard, MailBoxExternalFields,
  MountingType,
  WallBox,
  WallLayout
} from '../schema';
import { deepCopy, Engine, FlatStructuralTransformer, Option, PModel } from '@canvas-logic/engine';
import { centralBoxTypeGuard, mailBoxTypeGuard } from '../guards';
import { WallPostProcessorFactory } from '../postprocessors/WallPostProcessorFactory';
import { RulesManagerFactory } from '../rules/RulesManagerFactory';
import { BaseMutator, emptyAction } from '../mutators/BaseMutator';
import { LayoutFactory } from '../layout/LayoutFactory';
import { AlboLayoutResult } from './alboLayout';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from 'worker-loader!../workers/worker';
import { callWorker } from '../workers/utils';
import { rootStore } from '../stores';


const MAX_ROW = 1800;
const MIN_ROW = 400;
const AVG_ROW = 1500;

export class BoxisGenerator {
  private readonly boxes: Option<WallBox>[];
  public readonly mailBoxes: IMailBox[];
  public readonly centralBoxes: ICentralBox[];
  private readonly mailboxesByRows = new Map<number, IMailBox>();
  private readonly centralboxesByRows = new Map<number, ICentralBox>();

  constructor(
    private readonly engine: Engine,
    private readonly referenceProduct: PModel<IWall>
  ) {
    this.boxes = this.engine.optionValuesByPath(
      this.referenceProduct,
      'boxes.box',
      new FlatStructuralTransformer<WallBox>()
    ) ?? [];

    this.mailBoxes = [];
    this.centralBoxes = [];

    for (const box of this.boxes) {
      const model = box.model;
      if (mailBoxTypeGuard(model)) {
        this.mailBoxes.push(model);
      } else if (centralBoxTypeGuard(model)) {
        this.centralBoxes.push(model);
      } else {
        throw new Error('Invalid box type');
      }
    }
  }

  async generate(wizard: IWizard): Promise<IWall[]> {
    this.indexBoxes(wizard);

    let allBoxes: number[] = [];

    let cu: boolean[] = [];

    if (wizard.hasCentralBox) {
      let size = BoxisGenerator.getCUSize(wizard.customIntercom?.height);
      if (size === undefined) {
        allBoxes.push(2, 3, 4);
        cu.push(true, true, true);
      } else {
        allBoxes.push(size);
        cu.push(true);
      }
    }

    this.addMailboxes(wizard.mailBox, allBoxes, cu);
    const maxRows = BoxisGenerator.calculateMaxRows(wizard, this.referenceProduct.cellHeight);
    const worker = new Worker();
    const configurations = await callWorker<AlboLayoutResult>(worker, {
      kind: 'albo',
      mailboxes: wizard.mailBoxesAmount,
      allBoxes, cu, minRows: 1, maxRows
    });
    worker.terminate();
    // const configurations = albaLayout(wizard.mailBoxesAmount, allBoxes, cu, 1, maxRows);
    return configurations.map(configuration => this.createModel(wizard, configuration.layout, allBoxes, cu));
  }

  public static getCUSize(intercomHeight: number | undefined): number | undefined {
    if (!intercomHeight) {
      return undefined;
    }
    if (intercomHeight <= 200) {
      return 2;
    }
    if (intercomHeight > 200 && intercomHeight <= 535) {
      return 3;
    }
    if (intercomHeight > 535 && intercomHeight <= 735) {
      return 4;
    }
    throw new Error('Invalid size of CU');
  }

  private createModel(wizard: IWizard, columnsLayout: number[][], allBoxSizes: number[], cu: boolean[]): IWall {
    const model = deepCopy(this.referenceProduct);
    let totalRows = 0;
    const rowBase = 0;
    // first column (with central box)
    const firstColumn = columnsLayout[0];
    let row = rowBase;
    for (let i = firstColumn.length - 1; i >= 0; i--) {
      const boxSize = allBoxSizes[i];
      const box = cu[i]
        ? this.centralboxesByRows.get(boxSize)
        : this.mailboxesByRows.get(boxSize);

      if (!box) {
        throw new Error(`Cannot find box with rows = ${boxSize}`);
      }
      for (let n = 0; n < firstColumn[i]; n++) {
        totalRows += boxSize;
        model.boxes.push({
          row,
          column: 0,
          box
        });
        row += boxSize;
      }
    }

    // rest columns (no central box)
    const mailBoxSizes = allBoxSizes.filter((v, i) => !cu[i]);
    for (let column = 1; column < columnsLayout.length; column++) {
      const maxIndex = columnsLayout[column].length - 1;
      let row = rowBase;
      for (let i = maxIndex; i >= 0; i--) {
        // const boxSize =
        const boxSize = mailBoxSizes[i];
        const box = this.mailboxesByRows.get(boxSize);
        if (!box) {
          throw new Error(`Cannot find box with rows = ${boxSize}`);
        }
        for (let n = 0; n < columnsLayout[column][i]; n++) {
          model.boxes.push({
            row,
            column,
            box
          });
          row += boxSize;
        }
      }
    }

    this.calculateOffset(model, totalRows, wizard);

    model.layout = WallLayout.Rectangle;
    model.layoutOptions = {
      row: {
        min: rowBase,
        max: rowBase + totalRows - 1
      },
      column: {
        min: 0,
        max: columnsLayout.length - 1
      }
    };
    model.mountingType = wizard.mountingType;
    model.hasOwnKeyPlan = wizard.hasOwnKeyPlan;
    model.customIntercom = wizard.customIntercom;
    model.heightFromGround = wizard.heightFromGround;
    const centralBoxFields = new CentralBoxExternalFields(rootStore.datasetService);
    const mailBoxFields = new MailBoxExternalFields(rootStore.datasetService);
    const wallPostProcessor = WallPostProcessorFactory.create(this.engine, this.referenceProduct, centralBoxFields, mailBoxFields);
    const layout = LayoutFactory.create(model);
    const layoutRulesManager = RulesManagerFactory.createAlbaRulesManager(model, layout);
    const domainRulesManager = RulesManagerFactory.createDomainRulesManager();
    const baseMutator = new BaseMutator(wallPostProcessor, layoutRulesManager, domainRulesManager, emptyAction, model);
    const [newModel, validationResult] = this.engine.mutate(model, baseMutator);
    if (validationResult.isInvalid) {
      throw new Error(validationResult.errorMessage);
    }

    return newModel as IWall;
  }

  private calculateOffset(model: IWall, totalRows: number, wizard: IWizard): void {
    const maxTotalHeight = MAX_ROW - MIN_ROW;
    const totalHeight = model.cellHeight * 1000 * totalRows;
    if (totalHeight > maxTotalHeight) {
      throw new Error(`Exceeded maxTotalHeight value with value ${totalHeight}`);
    }
    // check if avg can be 1500 mm
    const offset = AVG_ROW - 0.5 * totalHeight;
    const top = totalHeight + offset;
    if (wizard.mailBoxesAmount > 3 && top <= MAX_ROW && [MountingType.Niche, MountingType.Hanging].includes(wizard.mountingType)) {
      // place at average
      model.offsetHeight = offset / 1000;
    } else {
      // place at top
      const height = wizard.heightFromGround ?? MAX_ROW;
      model.offsetHeight = (height - totalHeight) / 1000;
    }
  }

  private indexBoxes(wizard: IWizard): void {
    const depth = wizard.mailBox.depth;
    for (const mailbox of this.mailBoxes) {
      if (mailbox.depth === depth) {
        if (this.mailboxesByRows.has(mailbox.rows)) {
          throw new Error(`Only one mailboxes with rows = ${mailbox.rows} expected`);
        }
        this.mailboxesByRows.set(mailbox.rows, mailbox);
      }
    }

    if (wizard.hasCentralBox) {
      for (const centralbox of this.centralBoxes) {
        if (centralbox.depth === depth) {
          if (this.centralboxesByRows.has(centralbox.rows)) {
            throw new Error(`Only one centralbox with rows = ${centralbox.rows} expected`);
          }
          this.centralboxesByRows.set(centralbox.rows, centralbox);
        }
      }
    }
  }

  private addMailboxes(mailbox: IMailBox, allBoxes: number[], cu: boolean[]) {
    const mailboxes: number[] = [];
    this.mailboxesByRows.forEach(box => {
      const isMainMailbox = box.rows === mailbox.rows;
      if (isMainMailbox) {
        // main mailbox goes first
        mailboxes.unshift(box.rows);
      } else {
        mailboxes.push(box.rows);
      }
      cu.push(false);
    });
    allBoxes.push(...mailboxes);
  }

  public static calculateMaxRows(wizard: IWizard, cellHeight: number): number {
    if ([MountingType.Niche, MountingType.Hanging].includes(wizard.mountingType)) {
      return 7;
    }
    const maxHeight = wizard.heightFromGround ?? MAX_ROW;
    const maxSize = maxHeight - MIN_ROW;
    return Math.floor(maxSize / (cellHeight * 1000));
  }
}
