import s from './Viewer.module.scss';
import * as React from 'react';
import { BoxViewer } from '../../viewer/BoxViewer';
import { DraggingBox, Handler, VisualMode, wallBoxTypeGuard } from '../../stores/ConfiguratorStore';
import { autorun, reaction, toJS } from 'mobx';
import { BoxViewModel } from '../../viewer/BoxViewModel';
import { DropZone } from '../../behaviour/zones';
import { MainMenu, menu, SIDE_PANEL_WIDTH, SidePanelId } from '../../stores/ConfiguratorMenuStore';
import { IConcreteBox } from '../../schema';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { PositionedLayoutBox } from '../../layout/Box';
import { SelectionZone } from '../../behaviour/types';
import { rootStore } from '../../stores';
import { Point } from '../../layout/Point';
import { dndService } from '../../services/DnDService';
import { CAMERA_MOVE_TRIGGER_CLASS } from '../../behaviour/CameraMoveControl';

const MOBILE_DRAG_POINT_WIDTH = 40;
const MOBILE_DRAG_POINT_HEIGHT = 40;


export interface IWallStore {
  showMobileDragPoint: boolean;
  isMobile: boolean;
  dragPoint: Point | null;
  draggingBox: DraggingBox | null;
  screenDropZones: DropZone[];
  selectionZones: SelectionZone[];
  screenDraggingBox: { x: number, y: number, width: number, height: number } | null;

  getScreenPosition(x: number, y: number): { x: number, y: number };

  onDragStart(positionedBox: PositionedLayoutBox, centerX: number, centerY: number): void;

  onMove(centerX: number, centerY: number): void;

  onDrop(): void;

  onRotate(): void;

  onZoomIn(): void;

  onToggleSelection(zone: SelectionZone): void;

  subscribe(eventName: string, handler: Handler): Handler;

  setSelectedBox(concreteBox: IConcreteBox | undefined): void;

  viewModel: BoxViewModel;
  nearestDropZones: DropZone[];
  mode: VisualMode;
  canRemoveColumns?: boolean;
  showRuler: boolean;

  onMobileMove(clientX: number, clientY: number): void;
}

interface IProps {
  store: IWallStore;
  onBoxViewerCreated: (boxViewer: BoxViewer) => void;
  onBoxViewerRendered: (boxViewer: BoxViewer) => void;
}

export const Viewer: React.FC<IProps> = observer(({ store, onBoxViewerCreated, onBoxViewerRendered }) => {
  const canvasRef = React.createRef<HTMLCanvasElement>();
  const disposeZoomOut = React.useRef<Handler>();
  React.useEffect(() => {
    let viewer: BoxViewer | undefined;
    (async () => {
      if (canvasRef.current) {
        viewer = new BoxViewer(canvasRef.current, rootStore.resourceLoader, rootStore.textRenderer, true);
        disposeZoomOut.current = store.subscribe('zoomOut', () => viewer?.zoomOut());
        viewer.onDragStart = store.onDragStart;
        viewer.onMove = store.onMove;
        viewer.onDrop = store.onDrop;
        viewer.onRotate = store.onRotate;
        viewer.onToggleSelection = store.onToggleSelection;
        store.getScreenPosition = viewer.getScreenPosition;
        viewer.onSelectedBoxChanged = concreteBox => {
          store.setSelectedBox(concreteBox);
        };
        viewer.onZoomIn = () => {
          menu.enterZoomedMainMenu();
          store.onZoomIn();
        };
        viewer.onZoomOut = () => {
          menu.enterOverviewMainMenu();
          // store.onZoomIn();
        };
        menu.onSidePanelChanged((isOpened, sidePanelId) => {
          if (menu.mainMenu === MainMenu.Zoomed) {
            return;
          }
          if (isOpened && sidePanelId === SidePanelId.Summary) {
            return;
          }
          // put it in the queue after mobx autoruns
          setTimeout(() => {
            viewer?.setFrontalView();
          }, 20);
        });

        // await viewer.init(store.viewModel.mountingType);
        const isBackgroundRequired = rootStore.isDeviceLowPerformant ? false : true;
        await viewer.init(toJS(store.viewModel), isBackgroundRequired);
        // viewer.setDimensions(store.viewModel.dimensions.width, store.viewModel.dimensions.height);

        onBoxViewerCreated(viewer);

        await viewer.render(toJS(store.viewModel));
        onBoxViewerRendered(viewer);
        onBoxViewerCreated(viewer);

        autorun(() => {
          // ignore screen boxes for inserting mode
          const boxes = (rootStore.isMobile && store.mode === VisualMode.Insert)
            ? []
            : toJS(menu.screenBoxes.map(x => toJS(x)));
          viewer?.changeScreenBoxes(boxes);
        }, { delay: 1 });

        autorun(() => {
          switch (store.mode) {
            case VisualMode.View:
              viewer?.setViewMode();
              break;
            case VisualMode.FrontalView:
              viewer?.setFrontalViewMode();
              break;
            case VisualMode.Edit:
              viewer?.setEditMode();
              break;
            case VisualMode.Delete:
              viewer?.setDeleteMode();
              break;
            case VisualMode.Insert:
              viewer?.setInsertMode();
          }
        });
        autorun(async () => {
          await viewer?.render(toJS(store.viewModel));
        });
        reaction(() => store.viewModel.width, async () => {
          await viewer?.changeSceneWidth(store.viewModel.width);
        });
        reaction(() => store.viewModel.height, async () => {
          // wait till rendering
          setTimeout(() => {
            viewer?.changeSceneHeight(store.viewModel.height);
          }, 20)
        });
        autorun(() => {
          viewer?.renderDropZones(toJS(store.nearestDropZones));
        });

        autorun(() => {
          viewer?.renderSelectionZones(toJS(store.selectionZones));
        });

        autorun(() => {
          if (store.showRuler) {
            viewer?.showDimensions(store.viewModel.dimensions.width, store.viewModel.dimensions.height);
          } else {
            viewer?.hideDimensions();
          }
        });
      }
    })();

    return () => {
      viewer?.dispose();
      disposeZoomOut.current && disposeZoomOut.current();
    };
    // eslint-disable-next-line
  }, []);

  menu.setCanvasLeftOffset(menu.sidePanels.length > 0 ? -SIDE_PANEL_WIDTH : -0.5 * SIDE_PANEL_WIDTH);
  const mobileDragPointStyle = store.dragPoint ? {
    left: store.dragPoint.centerX - 0.5 * MOBILE_DRAG_POINT_WIDTH,
    top: store.dragPoint.centerY - 0.5 * MOBILE_DRAG_POINT_HEIGHT
  } : {};
  return (
    <div className={classNames(s.viewer, { [s.viewerExpanded]: menu.sidePanels.length > 0 })}>
      <canvas className={classNames(s.canvas, CAMERA_MOVE_TRIGGER_CLASS)}
              ref={canvasRef}>
      </canvas>
      {
        store.showMobileDragPoint &&
        <div className={classNames(s.mobileDragPoint, CAMERA_MOVE_TRIGGER_CLASS)}
             onPointerDown={e => {
               if (store.draggingBox && wallBoxTypeGuard(store.draggingBox)) {
                 dndService.pointerDown(store.draggingBox);
               }
             }}
             style={mobileDragPointStyle} />
      }
      {
        !rootStore.isMobile && store.screenDraggingBox && (
          <div className={s.draggingBox} style={{
            left: store.screenDraggingBox.x,
            top: store.screenDraggingBox.y,
            width: store.screenDraggingBox.width,
            height: store.screenDraggingBox.height
          }}></div>
        )
      }
    </div>
  );
});
