import * as React from "react";
import clsx from "clsx";

import SelectorItem from "../SelectorItem/SelectorItem";
import { IconName } from "../Icon/Icon";

import styles from "./Selector.module.scss";

interface IClasses {
  root: string;
  list: string;
}

export enum ImageType {
  Image,
  Icon
}

interface ISelectorOption {
  image: IconName | string;
  imageType: ImageType;
  title: string | JSX.Element;
  subtitle?: string;
  value: string;
  disabled?: boolean;
}

interface IProps {
  name: string;
  className?: string;
  classes?: Partial<IClasses>;
  options: ISelectorOption[];
  selected?: string;
  onSelect?: (value: string) => void;
  autoFocus?: boolean;
}

const Selector: React.VFC<IProps> = ({
                                      name,
                                      className,
                                      classes,
                                      options,
                                      selected,
                                      onSelect,
                                      autoFocus
                                    }) => {

  return (
    <div className={clsx(styles.root, classes?.root, className)}>
      <ul className={clsx(styles.list, classes?.list)}>
        {options.map(option => {
          return (
            <li key={option.value}>
              <SelectorItem
                id={`${name}-${option.value}`}
                name={name}
                title={option.title}
                subtitle={option.subtitle}
                value={option.value}
                selected={option.value === selected}
                onSelect={onSelect}
                image={option.image}
                imageType={option.imageType}
                disabled={option.disabled}
                autoFocus={autoFocus}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Selector;
