import * as React from 'react';
import classNames from 'classnames';

import Typography from '../Typography/Typography';

import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import { useSwipable } from './hooks/useSwipable';

import styles from './BottomSheet.module.scss';


interface IProps {
  title: string | JSX.Element;
  onClose: () => void;
  onClick?: () => void;
  renderActions?: () => JSX.Element;
}

export interface BottomSheetContentProps {
  height?: 'content' | '*';
  className?: string;
}

export type IBottomSheet = React.FC<IProps> & {
  Container: React.FC;
  Content: React.FC<BottomSheetContentProps>;
  Footer: React.FC;
};

const BottomSheet: IBottomSheet = ({ title, onClose, onClick = () => {}, renderActions, children }) => {
  const { sheetRef, handleRef, sheetStyle, isCollapsed } = useSwipable({
    onCollapse: onClose,
    swipeDownBoundaryCoefficient: 0.7
  });

  const handleClose = React.useCallback(
    (event: React.MouseEvent) => {
      onClose();
      event.stopPropagation();
    },
    [onClose]
  );

  return (
    <section
      className={classNames(styles.root, {
        [styles.collapsed]: isCollapsed
      })}
      ref={sheetRef}
      style={sheetStyle}
    >
      <div className={styles.headerContainer} ref={handleRef}>
        <header className={styles.header} onClick={onClick}>
          {typeof title === 'string' ? <Typography variant="h4">{title}</Typography> : title}
          <div>
            {renderActions && renderActions()}
            <Button variant="secondary" onClick={handleClose}>
              <Icon type="close" size="small" />
            </Button>
          </div>
        </header>
      </div>
      <main className={styles.main}>{children}</main>
    </section>
  );
};

BottomSheet.Container = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};

BottomSheet.Content = ({ children, className = '', height = 'content' }) => {
  return (
    <div
      className={classNames(
        styles.content,
        {
          [styles.contentHeight]: height === 'content',
          [styles.restHeight]: height === '*'
        },
        className
      )}
    >
      {children}
    </div>
  );
};

BottomSheet.Footer = ({ children }) => {
  return (
    <footer className={styles.footer}>
      <BottomSheet.Container>{children}</BottomSheet.Container>
    </footer>
  );
};

export default BottomSheet;
