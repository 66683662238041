import * as React from 'react';
import classNames from 'classnames';

import styles from './SelectItem.module.scss';
import Typography from '../Typography/Typography';
import Icon from '../Icon/Icon';
import { dataCy } from '../../cy';

interface IClasses {
  root?: string;
  disabled?: string;
  selected?: string;
}

interface IProps {
  disabled?: boolean;
  selected?: boolean;
  className?: string;
  classes?: IClasses;
  bare?: boolean;
  onClick?: (event: React.MouseEvent) => void;
}

class SelectItem extends React.Component<IProps> {
  render() {
    const { children, bare, disabled, selected, className, classes, onClick, ...rest } = this.props;

    return (
      <li
        {...rest}
        className={classNames(
          styles.root,
          {
            [styles.disabled]: disabled,
            [classes?.disabled ?? '']: disabled,
            [classes?.selected ?? '']: selected
          },
          classes?.root,
          className
        )}
        tabIndex={0}
        onClick={!disabled ? onClick : undefined}
        role="menuitem"
        aria-disabled={disabled}
        {...dataCy('autocompleteListItem')}
      >
        {selected && (
          <span className={styles.icon}>
            <Icon type="countryCheck" size="auto" iconDataCy="selectItemChecked" />
          </span>
        )}
        {!bare ? (
          <Typography component="span" size="14">
            {children}
          </Typography>
        ) : (
          <>{children}</>
        )}
      </li>
    );
  }
}

export default SelectItem;
