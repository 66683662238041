import ResourceLoader from '../viewer/ResourceLoader';
import { BoxScene } from '../viewer/BoxScene';
import { TextRenderer } from '../viewer/TextRenderer';
import { BoxViewModel } from '../viewer/BoxViewModel';
import { MountingType } from '../schema';
import { Color, PerspectiveCamera, Vector3 } from 'three';
import { BoxRenderer } from '../viewer/BoxRenderer';
import { BinarySearchCameraOptimizer } from '../camera/BinarySearchCameraOptimizer';

export class ImageService {
  private boxScene: BoxScene;
  private camera: PerspectiveCamera;
  private renderer: BoxRenderer;
  private element: HTMLCanvasElement;
  private lookAt: Vector3;
  private position: Vector3;

  constructor(
    private readonly width: number,
    private readonly height: number,
    private readonly resourceLoader: ResourceLoader,
    private readonly textRenderer: TextRenderer
  ) {
    this.boxScene = new BoxScene(resourceLoader, textRenderer);
  }

  async render(viewModel: BoxViewModel): Promise<string> {
    this.element = document.createElement('canvas');
    // this.element = rootStore.canvas!;
    this.element.width = this.width;
    this.element.height = this.height;

    this.initScene(viewModel.mountingType, viewModel.offsetHeight);
    //this.boxScene.hideBackground();
    this.initCamera(viewModel);
    await this.initRenderer(viewModel);
    await this.boxScene.buildScene(viewModel, false);
    this.renderer.render();
    this.updateCamera();
    this.renderer.render();
    return this.element.toDataURL();
  }

  private initScene(mountingType: MountingType, offsetHeight: number) {
    this.boxScene.init(mountingType, offsetHeight);
  }

  private initCamera(viewModel: BoxViewModel) {
    const { width, height } = this;
    this.camera = new PerspectiveCamera(30, width / height, 0.1, 180);

    this.lookAt = new Vector3(viewModel.width / 2, 1, 0);
    this.position = new Vector3(viewModel.width / 2, 1, 5);
  }

  private async initRenderer(viewModel: BoxViewModel) {
    const { width, height, element } = this;
    this.renderer = new BoxRenderer();
    const envTexture = await this.renderer.init(width, height, element, this.boxScene.getScene(), this.camera, viewModel.hdr);
    this.boxScene.getScene().environment = envTexture;
    this.renderer.setClearColor(new Color(0xffffff));
  }

  private updateCamera() {
    const cameraOptimizer = BinarySearchCameraOptimizer.create({ width: this.width, height: this.height }, 4, 100);
    const desiredState = [...this.position.toArray(), ...this.lookAt.toArray()];
    const targetState = cameraOptimizer.optimizeCameraPosition(
      this.camera,
      desiredState,
      this.boxScene.getRootNode(),
      [
        { x: 0, y: 0, width: 20, height: this.height }, // left
        { x: this.width, y: 0, width: 20, height: this.height }, // right
        { x: 0, y: 0, width: this.width, height: 20 }, // top
        { x: 0, y: this.height, width: this.width, height: 20 } // bottom
      ]
    );

    this.camera.position.set(targetState[0], targetState[1], targetState[2]);
    this.camera.lookAt(new Vector3(targetState[3], targetState[4], targetState[5]));
  }
}
