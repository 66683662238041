import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { MediaPathResolver } from '../services/MediaPathResolver';

export type ProgressEventHandler = (event: ProgressEvent) => void;

export class ModelLoader {
  cache = new Map<string, THREE.Object3D>();
  loader = new GLTFLoader();
  constructor(public readonly mediaPathResolver: MediaPathResolver) {
  }

  async load(name: string, format = 'glb', onProgress: ProgressEventHandler = noop) {
    const path = this.mediaPathResolver.getPath('assets/models/' + name + '.' + format);
    if (this.cache.has(path)) {
      return Promise.resolve(this.cache.get(path)!.clone());
    }
    return new Promise<THREE.Object3D>((resolve, reject) => {
      this.loader.load(
        path,
        (gltf: GLTF) => {
          this.cache.set(path, gltf.scene);
          resolve(gltf.scene.clone());
        },
        onProgress,
        reject
      );
    });
  }
}

//eslint-disable-next-line @typescript-eslint/no-empty-function
function noop() {}
