import { PositionedLayoutBox } from '../../layout/Box';
import { Layout } from '../../layout/Layout';
import { DropZoneBase } from '../types';

export type NeighbourBoxPosition = 'left' | 'right' | 'top' | 'bottom';

export interface NeighbourData {
  type: 'neighbour';
  position: NeighbourBoxPosition;
}

export interface NeighbourZone extends DropZoneBase {
  data: NeighbourData;
}

function addHorizontalNeighbourZone(leftNeighbour: PositionedLayoutBox, {
  box,
  row
}: PositionedLayoutBox, zones: NeighbourZone[], layout: Layout, position: 'left' | 'right') {
  let canSwapWithNeighbour = leftNeighbour.row === row && leftNeighbour.box.rows === box.rows;
  if (canSwapWithNeighbour) {
    zones.push(createNeighbourZone(layout, leftNeighbour, position));
  }
}

function addVerticalNeighbourZone(topNeighbour: PositionedLayoutBox, {
  box,
  column
}: PositionedLayoutBox, zones: NeighbourZone[], layout: Layout, position: 'top' | 'bottom') {
  let canSwapWithNeighbour = topNeighbour.column === column && topNeighbour.box.columns === box.columns;
  if (canSwapWithNeighbour) {
    zones.push(createNeighbourZone(layout, topNeighbour, position));
  }
}

export function getNeighboursDropZones(layout: Layout, box: PositionedLayoutBox): NeighbourZone[] {
  const zones: NeighbourZone[] = [];

  const leftNeighbour = layout.leftNeighbour(box);
  if (leftNeighbour) {
    addHorizontalNeighbourZone(leftNeighbour, box, zones, layout, 'left');
  }
  const rightNeighbour = layout.rightNeighbour(box);
  if (rightNeighbour) {
    addHorizontalNeighbourZone(rightNeighbour, box, zones, layout, 'right');
  }
  const topNeighbour = layout.topNeighbour(box);
  if (topNeighbour) {
    addVerticalNeighbourZone(topNeighbour, box, zones, layout, 'top');
  }
  const bottomNeighbour = layout.bottomNeighbour(box);
  if (bottomNeighbour) {
    addVerticalNeighbourZone(bottomNeighbour, box, zones, layout, 'bottom');
  }
  return zones;
}

function createNeighbourZone(layout: Layout, {
  box,
  row,
  column
}: PositionedLayoutBox, position: NeighbourBoxPosition): NeighbourZone {
  let { centerX, centerY } = layout.toWorldCoordinates(row, column, box.columns, box.rows);
  return {
    width: box.columns * layout.cellWidth,
    height: box.rows * layout.cellHeight,
    centerX,
    centerY,
    data: {
      type: 'neighbour',
      position
    }
  };
}
