import * as React from 'react';
import { Link } from "react-router-dom";

import classNames from 'classnames';

import Typography from '../Typography/Typography';

import './Button.scss';

interface IBaseButtonProps {
  href?: string;
  download?: boolean;
  bareLink?: boolean;
  type?: 'button' | 'reset' | 'submit';
  variant?: 'primary' | 'secondary' | 'icon' | 'accent';
  inverted?: boolean;
  toggle?: boolean;
  active?: boolean;
  checked?: boolean;
  value?: string;
  className?: string;
  disabled?: boolean;
  fake?: boolean;
  onClick?: (event: React.MouseEvent, active?: boolean) => void;
  onMouseDown?: (event: React.MouseEvent) => void;
}

const Button: React.FC<IBaseButtonProps> = ({
                                    href,
                                    download,
                                    bareLink,
                                    fake,
                                    type = "button",
                                    variant= "primary",
                                    inverted,
                                    toggle,
                                    active,
                                    checked,
                                    className,
                                    disabled,
                                    onClick,
                                    onMouseDown,
                                    children
                                  }) => {

  const LinkComponent = bareLink ? 'a' as React.ElementType : Link;

  const extraClasses = {
    'button--primary': !variant || variant === 'primary',
    'button--secondary': variant === 'secondary',
    'button--inverted': inverted,
    'button--icon': variant === 'icon',
    'button--active': toggle && active,
    'button--checked': !toggle && checked,
    'button--disabled': disabled,
  };

  const handleClick = (event: React.MouseEvent) => {
    if (!onClick) {
      return;
    }

    if (toggle) {
      onClick(event, !active);
    } else {
      onClick(event);
    }
  }

  if (fake) {
    return (
      <span className={classNames('button', 'button--fake', extraClasses, className)} onClick={onClick}>
        <Typography variant="button">{children}</Typography>
      </span>
    );
  }

  return (
    <>
      {href ? (
        <LinkComponent
          to={!bareLink ? href : undefined}
          href={bareLink ? href : undefined}
          download={bareLink ? download : undefined}
          className={classNames('button', 'button--link', extraClasses, className)}
          onClick={handleClick}
          onMouseDown={onMouseDown}
          aria-disabled={disabled}
        >
          <Typography variant="button">{children}</Typography>
        </LinkComponent>
      ) : (
        <button
          type={type}
          className={classNames('button', extraClasses, className)}
          onClick={handleClick}
          onMouseDown={onMouseDown}
          disabled={disabled}
        >
          <Typography variant="button">{children}</Typography>
        </button>
      )}
    </>
  );
};

export default Button;
