import { ContactService, IComponent, IInfoComponent, IPdfTitle, Item, PdfDto } from './ContactService';
import { DimensionsService } from './DimensionsService';
import { WeightService } from './WeightService';
import { CostSummaryService, CostSummarySubgroup } from './CostSummaryService';
import { ImageService } from './ImageService';
import Localization, { TranslatedMessage } from '../stores/Localization';
import { ConfiguratorStore } from '../stores/ConfiguratorStore';
import FileSaver from 'file-saver';
import { Formatter } from './Formatter';
import { BigNumber } from '@canvas-logic/engine';
import { centralBoxTypeGuard, lightBoxTypeGuard, mailBoxTypeGuard } from '../guards';
import {
  ICentralBox,
  ILightBox,
  IMailBox,
  Intercom,
  IntercomSetting,
  MailBoxEngravingMaterial,
  MountingType,
  WallLayout,
  WallLocation,
  WallType
} from '../schema';
import { NotificationsStore } from '../stores/NotificationsStore';
import { rootStore } from '../stores';
import { createUrl, isAlbo, isDigital, isMechanical } from './utils';
import { PDFDocument } from 'pdf-lib';
import { SOURCE_PARAMETER } from './LinkService';

export class PdfDownloader {
  private isAlbo: boolean;
  private isDigital: boolean;
  private isMechanical: boolean;
  private centralBox: ICentralBox | undefined;
  private isAuth = rootStore.authorized;

  constructor(
    private readonly contactService: ContactService,
    private readonly dimensionService: DimensionsService,
    private readonly weightService: WeightService,
    private readonly costSummaryService: CostSummaryService,
    private readonly imageService: ImageService,
    private localizationService: Localization,
    private notificationStore: NotificationsStore,
    private readonly configuratorStore: ConfiguratorStore
  ) {
  }

  async downloadPdf() {
    const dto = await this.createPdfDto();
    const pdfBlob = await this.contactService.generatePdf(dto, this.configuratorStore.model.wallType);    
    let closeInfo = () => {
    };

    try {
      closeInfo = this.notificationStore.info(new TranslatedMessage('message.pdf.download.process'));

      if (this.isDigital) {
        const donorPdfBlob = await this.loadPdfStaticPart();
        const mergedPdfBlob = await this.mergePdfs(pdfBlob, donorPdfBlob);
        closeInfo();
        await this.savePdfFile(mergedPdfBlob);
      } else {
        closeInfo();
        await this.savePdfFile(pdfBlob);
      }
    } catch (e) {
      console.error(e);
      closeInfo();
      this.notificationStore.error(new TranslatedMessage('message.pdf.download.error'), 'cross');
    }
  }

  private async mergePdfs(mainPdfBlob: ArrayBuffer, donorPdfBlobs: ArrayBuffer[]): Promise<Blob> {
    const mainPdfDoc = await PDFDocument.load(mainPdfBlob);
    const donorPdfDoc = await Promise.all(donorPdfBlobs.map(async (pdfBlob) => {
      return await PDFDocument.load(pdfBlob);
    }));
    const donorPages = await Promise.all(donorPdfDoc.map(async (pdfDoc) => {
      return await mainPdfDoc.copyPages(pdfDoc, pdfDoc.getPageIndices());
    }));

    donorPages.forEach(pages => {
      pages.forEach(page => {
        mainPdfDoc.addPage(page);
      });
    });

    const pdfBytes = await mainPdfDoc.save();
    return new Blob([pdfBytes]);
  }

  private async loadPdfStaticPart(): Promise<ArrayBuffer[]> {
    const BASE_PATH = 'assets/templates/pdf/digital_wall/static';
    const lang = {
      en: 'EN',
      de: 'DE',
      nl: 'NL',
      fr: 'FR'
    }[this.localizationService.locale];

    const donorPdfFiles = this.getMatchingPages().map((index => createUrl(`${BASE_PATH}/${lang}/${index}.pdf`)));
    const donorPdfBlobs = await Promise.all(donorPdfFiles.map(async (donorUrl) => {
      const response = await fetch(donorUrl);

      if (!response.ok) {
        throw new Error(`Failed to download PDF from URL: ${donorUrl}`);
      }

      return await response.arrayBuffer();
    }));

    return donorPdfBlobs;
  }

  private getMatchingPages(): string[] {
    const matchTable = {
      commonInfo: ['03'],
      installationReq: ['04'],
      locationSheltered: ['05'],
      locationUnsheltered: ['06'],
      wallMounted: ['07'],
      niche: ['08'],
      salesInfo: ['09', '10']
    };

    const { location, mountingType, layout } = this.configuratorStore.model;
    const matchingPages = [...matchTable.commonInfo];

    if (this.isAuth) {
      matchingPages.push(...matchTable.installationReq);
      if (
        ((location === WallLocation.Inside || location === WallLocation.OutsideSheltered) && layout === WallLayout.Standalone)
        || (location === WallLocation.OutsideUnsheltered)
      ) {
        matchingPages.push(...matchTable.locationSheltered);
        location === WallLocation.OutsideUnsheltered && matchingPages.push(...matchTable.locationUnsheltered);
      }
      if (mountingType === MountingType.Niche) {
        matchingPages.push(...matchTable.niche);
      }
      if (mountingType === MountingType.Hanging) {
        matchingPages.push(...matchTable.wallMounted);
      }
      matchingPages.push(...matchTable.salesInfo);
    }

    return matchingPages;
  }

  private async savePdfFile(pdfBlob: Blob): Promise<void> {
    const fileName = this.generateFileName();
    FileSaver.saveAs(pdfBlob, fileName);
    this.notificationStore.info(new TranslatedMessage('message.pdf.download.success'), 'ok');
  }

  private async createPdfDto(): Promise<PdfDto> {
    this.isAlbo = isAlbo(this.configuratorStore.model.wallType);
    this.isDigital = isDigital(this.configuratorStore.model.wallType);
    this.isMechanical = isMechanical(this.configuratorStore.model.wallType);
    this.centralBox = this.configuratorStore.model.boxes
      .find(cbox => centralBoxTypeGuard(cbox.box))?.box as ICentralBox;
    let color: string;
    const components: IComponent[] = [];
    const boxesInfo: IInfoComponent[] = [];
    const sceneImg = await this.imageService.render(this.configuratorStore.viewModel);

    function getBox(width: number, height: number) {
      return {
        string: '+',
        style: 'font-size: 1px; padding: ' + Math.floor(height / 2) + 'px ' + Math.floor(width / 2) + 'px; line-height: ' + height + 'px;'
      };
    }

    // @ts-ignore
    console.image = function(url, scale) {
      scale = scale || 1;
      const img = new Image();

      img.onload = function() {
        // @ts-ignore
        var dim = getBox(this.width * scale, this.height * scale);
        // @ts-ignore
        console.log('%c' + dim.string, dim.style + 'background: url(' + url + '); background-size: ' + (this.width * scale) + 'px ' + (this.height * scale) + 'px; color: transparent;');
      };

      img.src = url;
    };

    // @ts-ignore
    console.image(sceneImg, 0.5);
    const link = this.configuratorStore.shareLink() + `&${SOURCE_PARAMETER}=pdf`;
    this.createCentralGroup(components, boxesInfo);
    this.createMailboxesGroup(components, boxesInfo);
    this.createParcelboxesGroup(components);
    this.createLightboxesGroup(components);
    this.createRoofGroup(components);
    this.createOthersGroup(components);
    if (this.isAlbo) {
      this.createOptionalGroup(components, this.configuratorStore.hasEngravingColor, this.configuratorStore.hasNameEngraving);
    }

    if (this.configuratorStore.model.material) {
      const ral = this.configuratorStore.model.material.ralColor;
      const finishingType = this.translate(`color.finishType.${this.configuratorStore.model.material.finish}`);
      color = `RAL ${ral} ${finishingType}`;
    } else {
      color = this.translate('color.colorToBeConfirmed');
    }

    const generalInfo = {
      design:
        [{
          name: this.translate('dimensions.width'),
          value: Formatter.formatSize(this.dimensionService.width)
        },
          {
            name: this.translate('dimensions.height'),
            value: Formatter.formatSize(this.dimensionService.height)
          },
          {
            name: this.translate('dimensions.depth'),
            value: Formatter.formatSize(this.dimensionService.depth)
          }],
      structure: [{
        name: this.translate('pdfDigital.parameters.location'),
        value: this.translate(`location.${this.configuratorStore.model.location}`)
      },
        {
          name: this.translate('pdfDigital.parameters.layout'),
          value: this.translate(`layout.${this.configuratorStore.model.layout}`)
        }]
    };

    if (this.configuratorStore.model.mountingType === MountingType.Niche || this.configuratorStore.model.mountingType === MountingType.Hanging) {
      generalInfo.structure.push(
        {
          name: this.translate('pdfDigital.parameters.mountingType'),
          value: this.translate(`mountingType.${this.configuratorStore.model.mountingType}`)
        }
      );
    }

    if (this.hasIntercom()) {
      generalInfo.structure.push(
        {
          name: this.translate('pdfDigital.parameters.videophone'),
          value: this.translate('pdfDigital.parameters.yes')
        }, {
          name: this.translate('pdfDigital.parameters.typeVideophone'),
          value: this.translate(`pdfDigital.parameters.videophones.${this.centralBox?.intercom}`)
        }
      );
    } else {
      generalInfo.structure.push(
        {
          name: this.translate('pdfDigital.parameters.videophone'),
          value: this.translate('pdfDigital.parameters.no')
        }
      );
    }

    if (!this.isAlbo) {
      generalInfo.design.push(
        {
          name: this.translate('dimensions.weight'),
          value: Formatter.formatWeight(this.weightService.weight)
        },
        {
          name: this.translate('menu.options.country'),
          value: this.translate(`${this.configuratorStore.model.country.name}`)
        }
      );
    }

    generalInfo.design.push({
      name: this.translate('pdf.color'),
      value: color
    });

    if (this.isAlbo && this.configuratorStore.hasEngravingColor) {
      let mailbox: IMailBox | undefined = undefined;
      for (let wbox of this.configuratorStore.model.boxes) {
        const box = wbox.box;
        if (mailBoxTypeGuard(box)) {
          mailbox = box;
        }
      }
      if (mailbox) {
        const color = mailbox.engraving[0].color ?? MailBoxEngravingMaterial.Aluminum;
        generalInfo.design.push({
          name: this.translate('pdf.engraving-color'),
          value: this.translate(`content.options.engravings.${color}`)
        });
      }
    }

    const agreementList = ['first', 'second'];
    const wiringList = ['first', 'second', 'third', 'fourth'];
    const title: IPdfTitle = {
      configurationSpec: this.translate(`pdfDigital.title.configurationSpec.${this.configuratorStore.model.wallType}`),
      viewConfig: this.translate('pdfDigital.title.viewConfig'),
      components: this.translate('pdfDigital.title.components'),
      info: this.translate('pdf.title.info'),
      agreement: {
        name: this.translate('pdfDigital.title.agreement.name'),
        list: agreementList.map((el) => this.translate(`pdfDigital.title.agreement.list.${el}`))
      },
      wiring: {
        name: this.translate('pdfDigital.title.wiring.name'),
        list: wiringList.map((el) => this.translate(`pdfDigital.title.wiring.list.${el}`))
      }
    };
    const hasPrice = rootStore.authorized && this.costSummaryService.totalPrice;
    const totalPrice = hasPrice
      ? this.translate('pdfDigital.priceText', {
          price: this.formatPrice(this.costSummaryService.totalPrice, !this.isAlbo)
        })
      : '';
    const priceAddInfo = hasPrice ? this.translate('pdfDigital.priceAddInfo', { value: this.isAlbo ? 2 : 6 }) : '';
    const vatText = this.translate('pdfDigital.vatText');

    return {
      isDigital: this.isDigital,
      isAuth: this.isAuth,
      date: Formatter.formatDate(new Date()),
      sceneImg,
      link,
      title,
      generalInfo,
      components,
      boxesInfo,
      totalPrice,
      priceAddInfo,
      vatText
    };
  }

  private translate(key: string, context: any = undefined): string {
    if (typeof context === 'undefined') {
      return this.localizationService.formatMessage(key, this.configuratorStore.model);
    } else {
      return this.localizationService.formatMessage(key, context);
    }
  }
  
  private formatPrice(price: BigNumber | null, round: boolean = true): string {
    if (price === null) {
      return this.translate('menu.summary.onRequest');
    }
    return Formatter.formatPrice(price, round, 2);
  }

  private isAlboSubgroup(subgroup: string) {
    return subgroup.includes(WallType.Interna.toLowerCase()) || subgroup.includes(WallType.Boxis.toLowerCase());
  }

  private createCentralGroup(components: IComponent[], boxesInfo: IInfoComponent[]) {
    const central = this.costSummaryService.parts.find(part => part.group === 'centralbox');
    if (central) {
      const title = this.translate(`menu.summary.${central.group}`);
      let items: Item[] = central.subgroups.map(subgroup => this.createComponentSubgroup(subgroup));
      if (this.isAlbo) {
        const alboSubgroups = central.subgroups.filter(s => this.isAlboSubgroup(s.subgroup));
        const info = alboSubgroups.map(subgroup => this.createInfoComponentItem(subgroup));
        for (const i of info) {
          boxesInfo.push(i);
        }
      }
      const centralBox = this.configuratorStore.model.boxes.find(cbox => centralBoxTypeGuard(cbox.box));
      if (!centralBox) {
        throw new Error(`Cannot find centralbox`);
      }
      const box = centralBox.box as ICentralBox;

      // custom intercom
      if (box.custom) {
        items = [
          {
            type: 'remark',
            value: this.translate('menu.summary.custom_intercom')
          }
        ];
      }
      this.addCustomIntercomSettings(box, items);
      // additional settings
      if (box.intercomSetting !== IntercomSetting.NA) {
        items.push({
          type: 'component',
          name: this.translate('menu.summary.additional-settings'),
          amount: '',
          value: this.translate(`intercom.setting.${box.intercomSetting}`)
        });
      }
      // internet connection
      const internetConnection = this.costSummaryService.toggleParts
        .flatMap(part => part.subgroups)
        .find(subgroup => subgroup.included);

      if (internetConnection) {
        items.push({
          name: this.translate('menu.summary.internet-connection'),
          type: 'component',
          amount: '',
          value: this.translate(`summary.subgroup.${internetConnection.subgroup}`)
        });
      }

      // doorbells
      if (!this.isAlbo) {
        let bellsAmount = box.bellsAmount.toString();
        if (this.isMechanical && !box.custom && this.configuratorStore.initialSettings.isCustom) {
          bellsAmount = this.translate('TBD');
        }
        items.push({
          type: 'component',
          name: this.translate('menu.summary.doorbells'),
          amount: '',
          value: bellsAmount
        });
      }

      components.push({
        title,
        items
      });
    }
  }

  private createMailboxesGroup(components: IComponent[], boxesInfo: IInfoComponent[]) {
    const mailbox = this.costSummaryService.parts.find(part => part.group === 'mailbox');
    if (mailbox) {
      const title = this.translate(`menu.summary.${mailbox.group}`);
      const items: Item[] = mailbox.subgroups.map(subgroup => this.createComponentSubgroup(subgroup));
      if (this.isAlbo) {
        const alboSubgroups = mailbox.subgroups.filter(s => this.isAlboSubgroup(s.subgroup));
        const info = alboSubgroups.map(subgroup => this.createInfoComponentItem(subgroup));
        for (const i of info) {
          boxesInfo.push(i);
        }
      }
      if (this.configuratorStore.model.hasOwnKeyPlan) {
        items.push({
          type: 'remark',
          value: this.translate('boxes.ownKeyPlan')
        });
      }
      components.push({
        title,
        items
      });
    }
  }

  private createParcelboxesGroup(components: IComponent[]) {
    const parcelbox = this.costSummaryService.parts.find(part => part.group === 'parcelbox');
    if (parcelbox) {
      const title = this.translate(`menu.summary.${parcelbox.group}`);
      const items: Item[] = parcelbox.subgroups.map(subgroup => this.createComponentSubgroup(subgroup));
      components.push({
        title,
        items
      });
    }
  }

  private createLightboxesGroup(components: IComponent[]) {
    const lightbox = this.costSummaryService.parts.find(part => part.group === 'lightbox');
    if (lightbox) {
      const lightboxes: ILightBox[] = [];
      this.configuratorStore.model.boxes.forEach(cbox => {
        if (lightBoxTypeGuard(cbox.box)) {
          lightboxes.push(cbox.box);
        }
      });
      const title = this.translate(`menu.summary.lightbox`);
      let items: Item[] = []; //lightbox.subgroups.map(subgroup => this.createComponentSubgroup(subgroup));
      lightbox.subgroups.forEach(item => {
        items.push({
          type: 'component',
          name: this.translate(`summary.subgroup.${item.subgroup}`),
          amount: item.count?.toString() ?? '',
          value: rootStore.authorized && this.isAlbo ? this.formatPrice(item.price, false) : ''
        });
        const currentLightboxes = lightboxes.filter(box => box.subgroup === item.subgroup);
        if (!currentLightboxes.length) {
          throw new Error(`Cannot find lightbox ${item.subgroup}`);
        }
        for (const lightbox of currentLightboxes) {
          items.push({
            type: 'component',
            name: this.translate(`pdf.text`),
            amount: '',
            value: lightbox.text
          });
        }
      });
      components.push({
        title,
        items
      });
    }
  }

  private createOthersGroup(components: IComponent[]) {
    const other = this.costSummaryService.parts.find(part => part.group === 'others');
    if (other) {
      const title = this.translate(`menu.summary.${other.group}`);
      const items: Item[] = other.subgroups
        .filter(subgroup => subgroup.subgroup !== 'roof')
        .map(subgroup => this.createComponentSubgroup(subgroup));
      components.push({
        title,
        items
      });
    }
  }

  private createOptionalGroup(components: IComponent[], hasEngravingColor: boolean, hasNameEngraving: boolean) {
    const optionalItems = this.configuratorStore.optionalEngravings;
    if (optionalItems.length) {
      let items: Item[] = [];
      if (!hasNameEngraving) {
        items = items.concat(optionalItems.filter(i => i.subgroup === 'name-pvc').map(i => {
          return {
            type: 'component',
            name: this.translate('summary.subgroup.name-pvc'),
            amount: '',
            value: rootStore.authorized && this.isAlbo ? 
              this.translate('pdf.optional.price', {value: this.formatPrice(i.price, false)}) : 
              ''
          };
        }));
      }
      if (!hasEngravingColor) {
        items = items.concat(optionalItems.filter(i => i.subgroup === 'apartment-engraving').map(i => {
          return {
            type: 'component',
            name: this.translate('summary.subgroup.apartment-engraving'),
            amount: '',
            value: rootStore.authorized && this.isAlbo ? 
            this.translate('pdf.optional.from.price', {value: this.formatPrice(i.price, false)}) : 
              ''
          };
        }));
      }
      const title = this.translate('menu.summary.optional');
      components.push({
        title,
        items,
        optional: true
      });
    }
  }

  private createComponentSubgroup(subgroup: CostSummarySubgroup) {
    return {
      type: 'component',
      name: this.translate(`summary.subgroup.${subgroup.subgroup}`),
      amount: subgroup.count ?? '1',
      value: rootStore.authorized && this.isAlbo ? this.formatPrice(subgroup.price, false) : ''
    };
  }

  private createInfoComponentItem(subgroup: CostSummarySubgroup): IInfoComponent {
    const url = createUrl(`assets/images/boxes/${subgroup.subgroup}.svg`);
    return {
      title: this.translate(`summary.subgroup.${subgroup.subgroup}`),
      image: url,
    };
  }

  generateFileName() {
    const fileNamePrefix = this.translate('menu.summary.pdfPrefix');
    const now = new Date();
    const dateString = now.toLocaleDateString('ru-RU');
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const timeString = `${hours}_${minutes}_${seconds}`;
    return `${fileNamePrefix}_${dateString}_${timeString}.pdf`;
  }

  private createRoofGroup(components: IComponent[]) {
    const roof = this.findRoof();
    if (roof) {
      const title = this.translate(`menu.summary.roof`);
      let items: Item[] = [this.createComponentSubgroup(roof)];
      const lightboxTexts: string[] = [];
      this.configuratorStore.model.roof.top.forEach(top => {
        if (top.hasText) {
          lightboxTexts.push(top.text);
        }
      });
      lightboxTexts.forEach(text => {
        items.push({
          type: 'component',
          name: this.translate('pdf.text'),
          amount: '',
          value: text
        });
      });
      components.push({
        title,
        items
      });
    }
  }

  private findRoof(): CostSummarySubgroup | undefined {
    for (const part of this.costSummaryService.parts) {
      for (const subgroup of part.subgroups) {
        if (subgroup.subgroup === 'roof') {
          return subgroup;
        }
      }
    }
  }

  private hasIntercom(): boolean {
    return this.centralBox ? (this.centralBox.custom || this.centralBox.intercom !== Intercom.No) : false;
  }

  private addCustomIntercomSettings(box: ICentralBox, items: Item[]): void {
    if (this.isDigital) {
      this.addDigitalCustomIntercomSettings(box, items);
    } else if (this.isMechanical) {
      this.addMechanicalCustomIntercomSettings(box, items);
    } else {
      this.addAlboCustomIntercomSettings(items);
    }
  }

  private addDigitalCustomIntercomSettings(box: ICentralBox, items: Item[]) {
    if (!box.custom) {
      return;
    }

    const brand = this.configuratorStore.model.customIntercom?.name ?? this.translate('TBD');
    items.push({
      type: 'component',
      name: this.translate('menu.summary.brand'),
      amount: '',
      value: brand
    });
  }

  private addMechanicalCustomIntercomSettings(box: ICentralBox, items: Item[]): void {
    if (!box.custom) {
      return;
    }

    const width = this.configuratorStore.model.customIntercom?.width;
    const height = this.configuratorStore.model.customIntercom?.height;

    let size = this.translate('intercom.noDimensions');
    if (width && height) {
      size = `${width}x${height}${this.translate('units.mm')}`;
    }

    items.push({
      type: 'component',
      name: this.translate('menu.summary.cut-out'),
      amount: '',
      value: size
    });
  }

  private addAlboCustomIntercomSettings(items: Item[]): void {
    const width = this.configuratorStore.model.customIntercom?.width;
    const height = this.configuratorStore.model.customIntercom?.height;
    const cutoutManufacturer = this.configuratorStore.model.customIntercom?.cutoutManufacturer;
    let size = this.translate('intercom.noDimensions');

    if (cutoutManufacturer === 'client') {
      size = this.translate('intercom.onClient');
    }

    if (width && height) {
      size = `${width}x${height}${this.translate('units.mm')}`;
    }

    items.push({
      type: 'component',
      name: this.translate('menu.summary.cut-out'),
      amount: '',
      value: size
    });
  }
}
