import { Layout } from '../../layout/Layout';
import { Action } from '../../rules/LayoutRulesManager';
import { RemoveRowBehaviour } from './behaviour';


export const removeRowAction = (row: number, count: number): Action => {
    return (layout: Layout): void => {
        const behaviour = new RemoveRowBehaviour(layout);
        behaviour.removeRow(row, count);
    };
};
