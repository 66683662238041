export class DomainValidationResult {
  public get isValid() {
    return this.valid;
  }

  public get isInvalid() {
    return !this.valid;
  }

  public get errorMessage() {
    return this.message;
  }

  public static Error(message: string) {
    return new DomainValidationResult(false, message);
  }

  public static Valid() {
    return new DomainValidationResult(true, '');
  }

  private constructor(private valid: boolean, private message: string) {

  }

  and(next: () => DomainValidationResult): DomainValidationResult {
    return this.isInvalid ? this : next();
  }
}
