import { Camera, Intersection, Object3D, Raycaster, Vector2 } from 'three';

export interface INodeClickEvent {
  stopPropagation(): void;
}

class NodeClickEvent implements INodeClickEvent {
  stopped = false;

  stopPropagation() {
    this.stopped = true;
  }
}

export type NodeClickEventHandler = (node: Object3D | null, event: INodeClickEvent) => void;

interface NodeClickListener {
  nodeName: string;
  handler: NodeClickEventHandler;
}

const EPS = 0.00001;
const TOUCH_CLICK_EPS = 5;

export class SelectObject3D {
  private mouse!: Vector2;
  private raycaster!: Raycaster;
  private nodeClickListeners: NodeClickListener[] = [];
  private readonly intersections: Array<Intersection> = [];
  private hovered: Object3D | null = null;
  private touches: Touch[] = [];

  constructor(
    private readonly element: HTMLElement,
    private readonly camera: Camera,
    private readonly sceneNode: Object3D,
    private canHover: boolean = false
  ) {
    this.raycaster = new Raycaster();
    this.mouse = new Vector2();
    this.activate();
  }

  addNodeClickEventHandler(nodeName: string, handler: NodeClickEventHandler) {
    this.nodeClickListeners.push({ nodeName, handler });
  }

  deactivate() {
    this.element.removeEventListener('touchstart', this.onTouchStart);
    this.element.removeEventListener('touchend', this.onTouchEnd);
    this.element.removeEventListener('touchcancel', this.onTouchCancel);
    this.element.removeEventListener('mousedown', this.onMouseDown);
    this.element.removeEventListener('mouseup', this.onMouseUp);
    this.element.removeEventListener('pointermove', this.onPointerMove);

    this.element.style.cursor = '';
    this.touches = [];
  }

  activate() {
    this.element.addEventListener('touchstart', this.onTouchStart);
    this.element.addEventListener('touchend', this.onTouchEnd);
    this.element.addEventListener('touchcancel', this.onTouchCancel);
    this.element.addEventListener('mousedown', this.onMouseDown);
    this.element.addEventListener('mouseup', this.onMouseUp);
    this.element.addEventListener('pointermove', this.onPointerMove);
  }

  private onPointerMove = (event: PointerEvent) => {
    if (!this.canHover) {
      return;
    }

    this.updatePointer(event);

    if (event.pointerType === 'mouse' || event.pointerType === 'pen') {
      this.intersections.length = 0;

      this.raycaster.setFromCamera(this.mouse, this.camera);
      this.raycaster.intersectObjects(this.sceneNode.children, true, this.intersections);

      if (this.intersections.length > 0) {
        const object = this.getClickableNode(this.intersections);

        if (this.hovered !== object && this.hovered !== null) {
          this.element.style.cursor = 'auto';
          this.hovered = null;
        }

        if (this.hovered !== object) {
          this.element.style.cursor = 'pointer';
          this.hovered = object;
        }
      } else {
        if (this.hovered !== null) {
          this.element.style.cursor = 'auto';
          this.hovered = null;
        }
      }
    }
  };

  private onTouchStart = (event: TouchEvent) => {
    this.touches = [...event.targetTouches];
  };

  private onTouchEnd = (event: TouchEvent) => {
    const initialTouch = this.touches[0];
    const changedTouch = event.changedTouches[0];
    if (!initialTouch || !changedTouch) {
      return;
    }

    if (Math.sqrt((changedTouch.pageX - initialTouch.pageX) ** 2 + (changedTouch.pageY - initialTouch.pageY) ** 2) > TOUCH_CLICK_EPS) {
      return;
    }

    event.preventDefault();

    const rect = this.element.getBoundingClientRect();
    const offsetX = event.changedTouches[0].pageX - rect.left;
    const x = (offsetX / this.element.clientWidth) * 2 - 1;
    const offsetY = event.changedTouches[0].pageY - rect.top;
    const y = -(offsetY / this.element.clientHeight) * 2 + 1;
    this.clickHandler({ x, y });
    this.touches = [];
  };

  private onTouchCancel = () => {
    this.touches = [];
  }

  private onMouseDown = (event: MouseEvent) => {
    const x = (event.offsetX / this.element.clientWidth) * 2 - 1;
    const y = -(event.offsetY / this.element.clientHeight) * 2 + 1;
    this.mouse.x = x;
    this.mouse.y = y;
  };

  private onMouseUp = (event: MouseEvent) => {
    const x = (event.offsetX / this.element.clientWidth) * 2 - 1;
    const y = -(event.offsetY / this.element.clientHeight) * 2 + 1;
    if (Math.abs(this.mouse.x - x) < EPS && Math.abs(this.mouse.y - y) < EPS) {
      this.mouse.x = NaN;
      this.mouse.y = NaN;
      this.clickHandler({ x, y });
    }
  };

  private clickHandler = (mouse: { x: number; y: number }) => {
    /**
     * Making sure we reset our focus state when user touches scene
     */
    if (document?.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }

    try {
      // this.mouse.x = x;
      // this.mouse.y = y;

      this.raycaster.setFromCamera(mouse, this.camera);
      const intersects = this.raycaster.intersectObjects(this.sceneNode.children, true);
      const clickEvent = new NodeClickEvent();
      for (let { nodeName, handler } of this.nodeClickListeners) {
        if (nodeName === '') {
          handler(null, clickEvent);
        }
        let node: THREE.Object3D | null = null;
        for (let i = 0; i < intersects.length; i++) {
          let obj: THREE.Object3D | null = intersects[i].object;
          while (obj && obj.name !== nodeName) {
            obj = obj.parent;
          }
          if (obj && obj.name === nodeName && obj.visible) {
            node = obj;
            break;
          }
        }
        if (node) {
          handler(node, clickEvent);
          if (clickEvent.stopped) {
            return;
          }
        }
      }
    } catch (e: any) {
      console.error(e.message);
    }
  };

  private updatePointer(event: PointerEvent) {
    const rect = this.element.getBoundingClientRect();

    this.mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    this.mouse.y = (-(event.clientY - rect.top) / rect.height) * 2 + 1;
  }

  private getClickableNode(nodes: Intersection[]): Object3D | null {
    for (let { nodeName } of this.nodeClickListeners) {
      if (nodeName === '') {
        return null;
      }
      let node: THREE.Object3D | null = null;
      for (let i = 0; i < nodes.length; i++) {
        let obj: THREE.Object3D | null = nodes[i].object;
        while (obj && obj.name !== nodeName) {
          obj = obj.parent;
        }
        if (obj && obj.name === nodeName && obj.visible) {
          node = obj;
          break;
        }
      }

      if (node) {
        return node;
      }
    }

    return null;
  }
}
