import React, { Fragment } from "react";
import styles from "./CountrySelector.module.scss";
import { ICountry } from "../../schema";
import { Option } from "@canvas-logic/engine/dist/option";
import { OTHER_COUNTRY } from "../../stores/WizardStore";
import Typography from "../Typography/Typography";
import { FormattedMessage } from "react-intl";

interface IProps {
  name: string;
  countries: Option<ICountry>[];
  selectedCountry?: string;
  onSelect: (name: string) => void;
  isMobile: boolean;
}

const CountrySelector: React.VFC<IProps> = ({ name, countries, selectedCountry, onSelect, isMobile }) => {
  const sortedCountries = React.useMemo(() => {
    return countries
      .sort((x, y) => {
        return x.model.name.localeCompare(y.model.name);
      })
      .filter((country) => country.model.name !== OTHER_COUNTRY);
  }, [countries]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const country = event.target.value;
    onSelect(country);
  };

  return (
    <div className={styles.countryList}>
      {sortedCountries.flatMap((country, index) => {
        const component = <div key={country._id} className={styles.countryButton}>
          <input
            id={country._id}
            type="radio"
            name={name}
            value={country.model.name}
            checked={selectedCountry === country.model.name}
            onChange={handleOnChange}
          />
          <label htmlFor={country._id}>
            <Typography component="span" weight="bold" variant="body2">
              <FormattedMessage id={country.model.name}/>
            </Typography>
          </label>
        </div>;

        return [component, (index + 1) % (isMobile ? 2 : 3) === 0 ? <Fragment key={`${country._id}-s`}>{"\n"}</Fragment> : null];
      })}
    </div>
  );
};

export default CountrySelector;
