import React, {useMemo } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { NavigateProps } from 'react-router/dist/lib/components';

const NavigateWithQuery: React.VFC<NavigateProps> = ({ to, ...rest }) => {
  const [searchParams] = useSearchParams();

  const updatedTo = useMemo(() => {
    if (typeof to === 'string') {
      return {
        pathname: to,
        search: searchParams.toString()
      };
    }

    return {
      ...to,
      search: searchParams.toString()
    };
  }, [to, searchParams]);

  return <Navigate to={updatedTo} {...rest} />;
};

export default NavigateWithQuery;
