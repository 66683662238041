import {Mesh, Object3D} from "three";
import {SHELL_THICKNESS} from "../../layout/Layout";
import {IMorph, IPosition3D} from "../types";
import {morph} from "../utils";
import { MeshUtils } from '../MeshUtils';

interface ITopBottomPlateData {
    widthMorph: IMorph,
    thickness: number,
}

const defaultData: ITopBottomPlateData = {
    widthMorph: {
        name: 'width',
        min: 0.3,
        max: 1.04,
        // min: 0.38,
        // max: 1.12
    },
    thickness: SHELL_THICKNESS
}

export class WallSocleObject {
    readonly node: Object3D;
    readonly obj: Object3D;
    readonly widthMorph: IMorph;
    readonly thickness: number;

    constructor(obj: Object3D, data: ITopBottomPlateData = defaultData) {
        this.node  = new Object3D();
        this.obj = obj.clone();
        this.node.add(this.obj);
        this.widthMorph = data.widthMorph;
        this.thickness = data.thickness;
    }

    setPosition(pos: IPosition3D) {
        this.node.position.set(pos.x, pos.y, pos.z);
    }

    setWidth(width: number) {
        console.log('socle', width);
        const mesh = this.obj.children[0] as Mesh;
        mesh.geometry = mesh.geometry.clone();
        morph(mesh, this.widthMorph, width);
        MeshUtils.applyMorphing(mesh);
    }
}
