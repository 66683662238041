/*** 
  Autogenerated by ts-generator 6.5.0.
  Schema: public/assets/datasets/schema.yml
  Date: 2024-07-16T16:33:33.692Z
***/
import { MLString, BigNumber, WithOptionId, DatasetService, ExternalFieldRegistry } from '@canvas-logic/engine';
export interface IWeight {
  weight: BigNumber;
}
export interface ISizeWeight {
  size: number;
  weightPerSize: BigNumber;
}
export interface ICost {
  article: string;
  price: BigNumber;
  amount: number;
  marginName: string;
}
export interface ISummary {
  group?: string;
  subgroup?: string;
  key?: string;
  parentKey?: string;
  count: boolean;
  amount: number;
  toggle: boolean;
  included?: boolean;
}
export interface IAccessory {
  group?: string;
  subgroup?: string;
  key?: string;
  parentKey?: string;
  count: boolean;
  amount: number;
  toggle: boolean;
  included?: boolean;
  article: string;
  price: BigNumber;
  marginName: string;
  type: AccessoryType;
}
export type AccessoryList = IAccessory[];
export interface IEmpty {}
export interface IMountingFoot {
  group?: string;
  subgroup?: string;
  key?: string;
  parentKey?: string;
  count: boolean;
  amount: number;
  toggle: boolean;
  included?: boolean;
  article: string;
  price: BigNumber;
  marginName: string;
  height: number;
  model3D: string;
}
export type MountingFootList = IMountingFoot[];
export enum ParcelLockType {
  Electric = 'Electric',
  Number = 'Number'
}
export enum MailboxLockType {
  Electric = 'Electric',
  Mechanical = 'Mechanical'
}
export enum TextAlignment {
  Central = 'Central',
  Left = 'Left',
  Right = 'Right'
}
export enum CentralBoxLocation {
  Inside = 'Inside',
  OutsideLeft = 'OutsideLeft',
  OutsideRight = 'OutsideRight'
}
export enum WallLocation {
  Inside = 'Inside',
  OutsideSheltered = 'OutsideSheltered',
  OutsideUnsheltered = 'OutsideUnsheltered'
}
export enum WallType {
  Digital = 'Digital',
  Mechanical = 'Mechanical',
  Boxis = 'Boxis',
  Interna = 'Interna'
}
export enum WallLayout {
  Rectangle = 'Rectangle',
  Z = 'Z',
  Standalone = 'Standalone'
}
export enum FinishType {
  Structure = 'Structure',
  StructureMatt = 'StructureMatt'
}
export enum PersonalizationTechnic {
  Engraving = 'Engraving',
  PVC = 'PVC'
}
export interface ICuttingService {
  article: string;
  price: BigNumber;
  amount: number;
  marginName: string;
  group?: string;
  subgroup?: string;
  key?: string;
  parentKey?: string;
  count: boolean;
  toggle: boolean;
  included?: boolean;
}
export enum AccessoryType {
  G3104877 = 'G3104877',
  G3104653 = 'G3104653',
  G3104673 = 'G3104673',
  G3104671 = 'G3104671',
  G0002041 = 'G0002041',
  G3104875 = 'G3104875',
  G3103010 = 'G3103010',
  G3104878 = 'G3104878',
  G3104729 = 'G3104729',
  G8010108 = 'G8010108',
  S0000597 = 'S0000597',
  G3104896 = 'G3104896',
  S0000559 = 'S0000559',
  G3104897 = 'G3104897',
  G3100010 = 'G3100010',
  G3104226 = 'G3104226',
  G3104715 = 'G3104715',
  G3104717 = 'G3104717',
  G3104725 = 'G3104725',
  CylinderCutout = 'CylinderCutout',
  LockCylinder = 'LockCylinder',
  Traverse = 'Traverse',
  MountingFootFixationLock = 'MountingFootFixationLock',
  MountingFootWeldingPlate = 'MountingFootWeldingPlate',
  Mount = 'Mount',
  eSafeCutout = 'eSafeCutout',
  other = 'other'
}
export enum Intercom {
  Bticino = 'Bticino',
  Comelit = 'Comelit',
  Fermax = 'Fermax',
  Niko = 'Niko',
  No = 'No'
}
export type CuttingServiceList = ICuttingService[];
export type SummaryGroupsOrder = string[];
export interface IMaterial {
  ralColor: number;
  finish: FinishType;
}
export interface IValueConstraint {
  min: number;
  max: number;
}
export interface IRectangleLayoutConstraint {
  column: IValueConstraint;
  row: IValueConstraint;
}
export interface IZFormLayoutConstraint {
  w1: number;
  w2: number;
  w3: number;
  h1: number;
  h2: number;
  h3: number;
}
export interface IStandaloneConstraint {
  rows: number;
  columns: number;
}
export type LayoutOptions = IRectangleLayoutConstraint | IZFormLayoutConstraint | IStandaloneConstraint;
export enum MountingType {
  OnFloor = 'OnFloor',
  FixOnTheGround = 'FixOnTheGround',
  FixOnConcrete = 'FixOnConcrete',
  Hanging = 'Hanging',
  Niche = 'Niche'
}
export type HeightFromGround = 1200 | 1500 | 1600 | 1700 | 1800;
export enum IntercomSetting {
  NA = 'NA',
  IP = 'IP',
  TwoWire = 'TwoWire',
  TBD = 'TBD'
}
export enum IntercomCutoutManufacturer {
  esafe = 'esafe',
  client = 'client'
}
export enum MailBoxEngravingMaterial {
  White = 'White',
  Black = 'Black',
  Aluminum = 'Aluminum'
}
export interface IMargin {
  name: string;
  margin: BigNumber;
}
export type MarginList = IMargin[];
export interface IRFIDBadge {
  article: string;
  price: BigNumber;
  amount: number;
  marginName: string;
  group?: string;
  subgroup?: string;
  key?: string;
  parentKey?: string;
  count: boolean;
  toggle: boolean;
  included?: boolean;
}
export interface ICommonBox {
  rows: number;
  columns: number;
  width: number;
  height: number;
  model3D: string;
  article: string;
  name: string;
  depth: number;
  token: string;
  type: BoxType;
}
export type RFIDBadgeList = IRFIDBadge[];
export type AdditionalArticleList = string[];
export interface IParcelBox {
  rows: number;
  columns: number;
  width: number;
  height: number;
  model3D: string;
  article: string;
  name: string;
  depth: number;
  token: string;
  type: BoxType;
  price: BigNumber;
  amount: number;
  marginName: string;
  group?: string;
  subgroup?: string;
  key?: string;
  parentKey?: string;
  count: boolean;
  toggle: boolean;
  included?: boolean;
  weight: BigNumber;
  lockType: ParcelLockType;
  image: string;
  rfidBadges: WithOptionId<RFIDBadgeList>;
}
export type MailBoxEngravingList = IMailBoxEngraving[];
export interface IMailBoxEngraving {
  article: string;
  price: BigNumber;
  amount: number;
  marginName: string;
  group?: string;
  subgroup?: string;
  key?: string;
  parentKey?: string;
  count: boolean;
  toggle: boolean;
  included?: boolean;
  apartment: boolean;
  name: boolean;
  pvc: boolean;
  color: MailBoxEngravingMaterial;
}
export interface IMailBox {
  rows: number;
  columns: number;
  width: number;
  height: number;
  model3D: string;
  article: string;
  name: string;
  depth: number;
  token: string;
  type: BoxType;
  price: BigNumber;
  amount: number;
  marginName: string;
  group?: string;
  subgroup?: string;
  key?: string;
  parentKey?: string;
  count: boolean;
  toggle: boolean;
  included?: boolean;
  weight: BigNumber;
  lockType: MailboxLockType;
  requiresLockCylinder: boolean;
  engraving: WithOptionId<MailBoxEngravingList>;
  rfidBadges: WithOptionId<RFIDBadgeList>;
  image: string;
  hasPVC: boolean;
}
export interface ILightBox {
  rows: number;
  columns: number;
  width: number;
  height: number;
  model3D: string;
  article: string;
  name: string;
  depth: number;
  token: string;
  type: BoxType;
  price: BigNumber;
  amount: number;
  marginName: string;
  group?: string;
  subgroup?: string;
  key?: string;
  parentKey?: string;
  count: boolean;
  toggle: boolean;
  included?: boolean;
  weight: BigNumber;
  text: string;
  textAlignment: TextAlignment;
  image: string;
}
export interface ICentralBox {
  rows: number;
  columns: number;
  width: number;
  height: number;
  model3D: string;
  article: string;
  name: string;
  depth: number;
  token: string;
  type: BoxType;
  price: BigNumber;
  amount: number;
  marginName: string;
  group?: string;
  subgroup?: string;
  key?: string;
  parentKey?: string;
  count: boolean;
  toggle: boolean;
  included?: boolean;
  weight: BigNumber;
  intercomSetting: IntercomSetting;
  intercomCutoutManufacturer: IntercomCutoutManufacturer;
  custom: boolean;
  intercom: Intercom;
  display: boolean;
  bellsAmount: number;
  maxBellsAmount: number;
  location: CentralBoxLocation;
  physicalBells: boolean;
  image: string;
  additionalArticles: AdditionalArticleList;
}
export type WallBox = IParcelBox | IMailBox | ILightBox | ICentralBox;
export interface IConcreteBox {
  row: number;
  column: number;
  box: WallBox;
}
export type ConcreteBoxList = IConcreteBox[];
export interface ITopBottomPlateBase {
  article: string;
  columns: number;
  model3D: string;
}
export interface ITopBottomPlate {
  article: string;
  columns: number;
  model3D: string;
  price: BigNumber;
  amount: number;
  marginName: string;
  group?: string;
  subgroup?: string;
  key?: string;
  parentKey?: string;
  count: boolean;
  toggle: boolean;
  included?: boolean;
  size: number;
  weightPerSize: BigNumber;
}
export interface IBackPlate {
  article: string;
  price: BigNumber;
  amount: number;
  marginName: string;
  group?: string;
  subgroup?: string;
  key?: string;
  parentKey?: string;
  count: boolean;
  toggle: boolean;
  included?: boolean;
  weight: BigNumber;
  columns: number;
  rows: number;
  model3D: string;
}
export type TopBottomPlateList = ITopBottomPlate[];
export type RoofTopPlateList = IRoofTopPlate[];
export interface IRoofTopPlate {
  article: string;
  columns: number;
  model3D: string;
  price: BigNumber;
  amount: number;
  marginName: string;
  group?: string;
  subgroup?: string;
  key?: string;
  parentKey?: string;
  count: boolean;
  toggle: boolean;
  included?: boolean;
  weight: BigNumber;
  hasText: boolean;
  text: string;
  textAlignment: TextAlignment;
}
export interface ISidePlate {
  size: number;
  weightPerSize: BigNumber;
  article: string;
  price: BigNumber;
  amount: number;
  marginName: string;
  group?: string;
  subgroup?: string;
  key?: string;
  parentKey?: string;
  count: boolean;
  toggle: boolean;
  included?: boolean;
  model3D: string;
  rows: number;
}
export interface IRoofModuleConnection {
  article: string;
  price: BigNumber;
  amount: number;
  marginName: string;
  group?: string;
  subgroup?: string;
  key?: string;
  parentKey?: string;
  count: boolean;
  toggle: boolean;
  included?: boolean;
}
export type RoofModuleConnectionList = IRoofModuleConnection[];
export type SidePlateList = ISidePlate[];
export interface IRoof {
  left: WithOptionId<SidePlateList>;
  right: WithOptionId<SidePlateList>;
  top: WithOptionId<RoofTopPlateList>;
  connections: WithOptionId<RoofModuleConnectionList>;
}
export interface ISocle {
  article: string;
  price: BigNumber;
  amount: number;
  marginName: string;
  group?: string;
  subgroup?: string;
  key?: string;
  parentKey?: string;
  count: boolean;
  toggle: boolean;
  included?: boolean;
  weight: BigNumber;
  model3D: string;
}
export interface IPackaging {
  article: string;
  price: BigNumber;
  amount: number;
  marginName: string;
  group?: string;
  subgroup?: string;
  key?: string;
  parentKey?: string;
  count: boolean;
  toggle: boolean;
  included?: boolean;
}
export type SocleList = ISocle[];
export type HangingBracketsList = IHangingBracket[];
export type PackagingList = IPackaging[];
export interface ISocleRule {
  columns: number;
  socle: ISocle;
  amount: number;
}
export interface IPackagingRule {
  columns: number;
  packaging: IPackaging;
}
export interface IFinishingPlates {
  top: WithOptionId<TopBottomPlateList>;
  bottom: WithOptionId<TopBottomPlateList>;
  left: WithOptionId<SidePlateList>;
  right: WithOptionId<SidePlateList>;
}
export type SocleRuleList = ISocleRule[];
export type PackagingRuleList = IPackagingRule[];
export interface IHangingBracket {
  article: string;
  price: BigNumber;
  amount: number;
  marginName: string;
  group?: string;
  subgroup?: string;
  key?: string;
  parentKey?: string;
  count: boolean;
  toggle: boolean;
  included?: boolean;
}
export interface IHangingBracketRule {
  columns: number;
  hangingBracket: IHangingBracket;
  amount: number;
}
export interface IInternetConnection {
  article: string;
  price: BigNumber;
  amount: number;
  marginName: string;
  group?: string;
  subgroup?: string;
  key?: string;
  parentKey?: string;
  count: boolean;
  toggle: boolean;
  included?: boolean;
  name: string;
}
export type BackPlateList = IBackPlate[];
export type HangingBracketRuleList = IHangingBracketRule[];
export interface IWall {
  boxes: ConcreteBoxList;
  boxDepth: number;
  cellWidth: number;
  cellHeight: number;
  offsetHeight: number;
  roof: IRoof;
  finishingPlates: IFinishingPlates;
  backPlates: WithOptionId<BackPlateList>;
  hangingBracketRules: HangingBracketRuleList;
  wallType: WallType;
  location: WallLocation;
  layout: WallLayout;
  layoutOptions: LayoutOptions;
  internetConnection?: IInternetConnection;
  mountingType: MountingType;
  heightFromGround?: HeightFromGround;
  socleRules: SocleRuleList;
  material?: IMaterial;
  socles: SocleList;
  hangingBrackets: HangingBracketsList;
  packagings: PackagingList;
  margins: MarginList;
  packagingRules: PackagingRuleList;
  shelteringCuttingServices: WithOptionId<CuttingServiceList>;
  backplatesCuttingServices: WithOptionId<CuttingServiceList>;
  accessories: WithOptionId<AccessoryList>;
  mountingFoots: WithOptionId<MountingFootList>;
  summaryGroupsOrder: SummaryGroupsOrder;
  country: WithOptionId<ICountry>;
  customIntercom?: ICustomIntercom;
  hasOwnKeyPlan: boolean;
  cylinderCutout: CylinderCutout;
}
export type MinMailboxHeight = 100 | 150;
export type MailboxHeight = 200;
export enum BoxType {
  mailbox = 'mailbox',
  parcelbox = 'parcelbox',
  lightbox = 'lightbox',
  centralbox = 'centralbox'
}
export enum CylinderCutout {
  None = 'None',
  CylinderOnly = 'CylinderOnly',
  BuildInLock = 'BuildInLock'
}
export interface ICountry {
  name: string;
  minMailboxHeight: MinMailboxHeight;
}
export interface ICustomIntercom {
  name: string;
  width: number;
  height: number;
  cutoutManufacturer: IntercomCutoutManufacturer;
}
export interface IWizard {
  wallType: WallType;
  country: WithOptionId<ICountry>;
  wallLocation?: WallLocation;
  layout: WallLayout;
  mountingType: MountingType;
  heightFromGround?: HeightFromGround;
  doorBellsAmount: number;
  centralBox: WithOptionId<ICentralBox>;
  mailBox: WithOptionId<IMailBox>;
  mailBoxesAmount: number;
  hasOwnKeyPlan: boolean;
  cylinderCutout: CylinderCutout;
  parcelBoxesAmount: number;
  hasLightBox: boolean;
  hasIntercom: boolean;
  hasCustomIntercom: boolean;
  customIntercom?: ICustomIntercom;
  intercomDimensionsUnknown: boolean;
  hasCentralBox: boolean;
  parcelLockType: ParcelLockType;
  cellWidth: number;
  cellHeight: number;
}
export class MailBoxExternalFields {
  private registry: ExternalFieldRegistry;

  constructor(datasetService: DatasetService) {
    this.registry = datasetService.getExternalFieldRegistry('MailBox');
  }

  overrideArticle(model: IMailBox): string {
    return this.registry.getExternalFieldValue<string>(model, 'overrideArticle');
  }
}

export class CentralBoxExternalFields {
  private registry: ExternalFieldRegistry;

  constructor(datasetService: DatasetService) {
    this.registry = datasetService.getExternalFieldRegistry('CentralBox');
  }

  overridePrice(model: ICentralBox): BigNumber {
    return this.registry.getExternalFieldValue<BigNumber>(model, 'overridePrice');
  }
  overrideArticle(model: ICentralBox): string {
    return this.registry.getExternalFieldValue<string>(model, 'overrideArticle');
  }
}
