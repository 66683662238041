import IntercomForm from "../../../../components/IntercomForm/IntercomForm";
import * as React from "react";
import { IWizardStepProps } from "../../WizardStepper";
import { observer } from "mobx-react";

const WizardIntercomSelector: React.VFC<IWizardStepProps> = observer(({ store, onChange, initialIsTouched, index }) => {
  const [touched, setTouched] = React.useState(initialIsTouched);

  const handleOnCompleted = React.useCallback(
    (complete?: boolean) => {
      setTouched(true);
      onChange(index, complete);
    }, [index, onChange]);

  return <IntercomForm touched={touched} wizardStore={store} onSelect={handleOnCompleted} />;
});

export default WizardIntercomSelector;
