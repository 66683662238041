import { EntityFinder } from './EntityFinder';
import { ICost, ISummary, IWall, SummaryGroupsOrder } from '../schema';
import { BigNumber, getOptionId } from '@canvas-logic/engine';
import { CostService } from './CostService';

export interface CostSummarySubgroup {
  subgroup: string;
  count: number | null;
  price: BigNumber | null;
}

export interface CostSummaryToggleSubgroup {
  subgroup: string;
  included: boolean;
  price: BigNumber | null;
}

export interface CostSummaryGroup {
  group: string,
  subgroups: CostSummarySubgroup[]
}

export interface CostSummaryToggleGroup {
  group: string,
  subgroups: CostSummaryToggleSubgroup[]
}

type MainItem = ICost & {
  toggle: false;
  group: string;
  subgroup: string;
  key?: string;
  count: boolean;
}

type ChildItem = ICost & {
  toggle: false;
  parentKey: string;
  count: boolean;
}

type ToggleItem = ICost & {
  count: false;
  toggle: true;
  group: string;
  subgroup: string;
  included: boolean;
}

type CostSummaryItem = ICost & ISummary;

export class CostSummaryService {
  parts: CostSummaryGroup[];
  toggleParts: CostSummaryToggleGroup[];
  totalPrice: BigNumber | null;
  private items: Array<CostSummaryItem>;
  private mainItems: MainItem[] = [];
  private childItems: ChildItem[] = [];
  private toggleItems: ToggleItem[] = [];

  constructor(
    private readonly finder: EntityFinder,
    private readonly model: IWall,
    private readonly order: SummaryGroupsOrder = []
  ) {
    this.items = this.finder.byComponents(model, ['Summary', 'Cost']).map(e => e.entity);
    this.createParts();
  }

  private createParts(): void {
    this.splitItems();
    this.buildParts();
    this.orderAllParts();
  }

  private splitItems(): void {
    for (let item of this.items) {
      if (isChildItem(item)) {
        this.childItems.push(item);
      } else {
        if (isMainItem(item)) {
          this.mainItems.push(item);
        } else if (isToggleItem(item)) {
          this.toggleItems.push(item);
        } else {
          console.error(`Cannot identify item ${getOptionId(item)} summary type`);
        }

      }
    }
    this.validateChildItems();
    this.validateToggleItems();
  }

  private buildParts(): void {
    let groups = new Map<string, MainItem[]>();
    for (let mainItem of this.mainItems) {
      const group = groups.get(mainItem.group) ?? [];
      group.push(mainItem);
      groups.set(mainItem.group, group);
    }
    for (let childItem of this.childItems) {
      const parent = this.findParent(childItem);
      if (!parent) {
        throw new Error(`Cannot find parent for key ${childItem.parentKey}`);
      }
      const group = groups.get(parent.group) ?? [];
      group.push({ ...childItem, group: parent.group, subgroup: parent.subgroup });
      groups.set(parent.group, group);
    }

    this.parts = [];
    this.totalPrice = BigNumber.zero();
    for (let [groupName, items] of groups) {
      this.parts.push(this.createSummaryPart(groupName, items));
    }
    this.buildToggleGroups();
  }

  private validateChildItems() {

  }

  private findParent(childItem: ChildItem): MainItem | undefined {
    return this.mainItems.find(item => !!item.key && item.key === childItem.parentKey);
  }

  private createSummaryPart(groupName: string, items: MainItem[]): CostSummaryGroup {
    const map = new Map<string, CostSummarySubgroup>();

    for (let item of items) {
      let subgroup = map.get(item.subgroup);
      if (!subgroup) {
        subgroup = { subgroup: item.subgroup, count: null, price: new BigNumber(0) };
        map.set(item.subgroup, subgroup);
      }
      if (item.count) {
        if (subgroup.count === null) {
          subgroup.count = item.amount;
        } else {
          subgroup.count += item.amount;
        }
      }

      const itemPrice = CostService.itemPrice(item, this.model);
      this.increaseTotalPriceBy(itemPrice);
      if (subgroup.price !== null) {
        if (itemPrice) {
          subgroup.price = subgroup.price.plus(itemPrice);
        } else {
          subgroup.price = null;
        }
      }
    }
    return {
      group: groupName,
      subgroups: Array.from(map.values())
    };
  }

  private increaseTotalPriceBy(itemPrice: BigNumber | null) {
    if (this.totalPrice !== null) {
      if (itemPrice) {
        this.totalPrice = this.totalPrice.plus(itemPrice);
      } else {
        this.totalPrice = null;
      }
    }
  }


  private validateToggleItems() {
    const mainGroups = new Set(this.mainItems.map(item => item.group));
    for (let toggleItem of this.toggleItems) {
      if (toggleItem.count) {
        throw new Error('Cannot count toggle items');
      }
      if (mainGroups.has(toggleItem.group)) {
        throw new Error('Cannot put toggle item in a main group');
      }
    }
  }

  private buildToggleGroups() {
    const map = new Map<string, CostSummaryToggleSubgroup[]>();
    for (let toggleItem of this.toggleItems) {
      const groupName = toggleItem.group;
      const subgroups = map.get(groupName) ?? [];
      map.set(groupName, subgroups);
      const price = CostService.itemPrice(toggleItem, this.model);
      if (toggleItem.included) {
        this.increaseTotalPriceBy(price);
      }
      subgroups.push({
        subgroup: toggleItem.subgroup,
        included: toggleItem.included,
        price
      });
    }

    this.toggleParts = [];
    for (const [groupName, subgroups] of map.entries()) {
      this.toggleParts.push({
        group: groupName,
        subgroups
      });
    }
  }

  private orderAllParts() {
    if (this.order.length) {
      this.orderParts(this.parts);
      this.orderParts(this.toggleParts);
    }
  }

  private orderParts<T extends { group: string }>(xs: T[]) {
    const orderedToggleParts: T[] = [];
    this.order.forEach(groupName => {
      const index = xs.findIndex(part => part.group === groupName);
      if (index >= 0) {
        orderedToggleParts.push(xs[index]);
        xs.splice(index, 1);
      }
    });
    while (xs.length) {
      orderedToggleParts.push(xs.pop()!);
    }

    while (orderedToggleParts.length) {
      xs.push(orderedToggleParts.shift()!);
    }
  }
}

function isMainItem(item: ISummary): item is MainItem {
  return ['group', 'subgroup', 'count'].every(field => (item as any)[field] !== undefined) && (item as any).toggle === false;
}

function isChildItem(item: ISummary): item is ChildItem {
  return (item as any)['parentKey'] !== undefined;
}

function isToggleItem(item: ISummary): item is ToggleItem {
  return (item as any)['toggle'] === true;
}