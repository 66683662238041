import { toJS } from 'mobx';
import { parse, RowSchema } from './parser';
import { getBoxCells, LayoutBox, PositionedLayoutBox } from './Box';
import { Position } from './Position';
import { IWall, WallLayout } from '../schema';
import { LayoutBoxRegistry } from './LayoutBoxRegistry';
import { ILayoutData } from './LayoutFactory';
import { isRowSolid } from '../services/utils';


// todo: remove depth, define it in schema
export const CELL_DEPTH = 0.4;
export const SHELL_THICKNESS = 0.003; //0.03 * 0.3;
const eps = 0.00001;

export type Index = string;

export type RowsShiftDirection = 'up' | 'down';
export interface RowArea {
  top: number;
  bottom: number;
  rows: number;
}

interface PendingArea {
  type: 'PendingArea';
}

interface InArea {
  type: 'InArea';
  top: number;
  bottom: number;
}

type AreaState = PendingArea | InArea;
export interface ILayoutDto {
  type: string;
  boxes: PositionedLayoutBox[];
  columns: number;
  rows: number;
}

export class Layout {
  offsetHeight = 0;

  boxes: PositionedLayoutBox[] = [];
  static boxDepth = 0;
  private EMPTY_SPACE_TOKEN = '*';
  protected boxesByIndex: Map<Index, PositionedLayoutBox>;
  static empty = new Layout(0, 0, [], 0.38, 0.05);

  getLayoutData(): ILayoutData {
    return {
      layout: WallLayout.Standalone,
      layoutOptions: {
        rows: this.rows,
        columns: this.columns
      },
      offsetHeight: this.offsetHeight,
      cellHeight: this.cellHeight,
      cellWidth: this.cellWidth,
      boxes: this.getBoxes()
    }
  }
  get maxFreeCells() {
    return this.rows * this.columns;
  }

  get width(): number {
    return this.columns;
  }

  get height(): number {
    return this.rows;
  }

  get columns(): number {
    return this._columns;
  }

  get rows(): number {
    return this._rows;
  }

  static create(rows: number, cols: number, schema: RowSchema, cellWidth = 0.38, cellHeight = 0.05): Layout {
    const boxes = parse(rows, cols, schema);
    const layout = new Layout(rows, cols, boxes, cellWidth, cellHeight);
    return layout;
  }

  static createFromWall(wall: IWall): Layout {
    let rows = -Infinity;
    let columns = -Infinity;
    for (const box of wall.boxes) {
      rows = Math.max(rows, box.row + box.box.rows);
      columns = Math.max(columns, box.column + box.box.columns);
    }
    return new Layout(
      rows,
      columns,
      wall.boxes.map(LayoutBoxRegistry.createFromConcreteBox),
      wall.cellWidth,
      wall.cellHeight
    );
  }

  constructor(
    private readonly _rows: number,
    private readonly _columns: number,
    boxes: PositionedLayoutBox[],
    readonly cellWidth: number,
    readonly cellHeight: number
  ) {
    this.boxes = boxes;
    this.updateLayout();
  }

  updateLayout() {
    this.boxesByIndex = new Map<Index, PositionedLayoutBox>();

    for (const box of this.boxes) {
      this.forEachBoxCell(box, cellIndex => {
        this.boxesByIndex.set(cellIndex, box);
      });
    }

  }

  forEachBoxCell({ row, column, box }: PositionedLayoutBox, callback: (cellIndex: Index) => void) {
    for (let dx = 0; dx < box.columns; dx++) {
      for (let dy = 0; dy < box.rows; dy++) {
        const cellIndex = this.calculateIndex(row + dy, column + dx);
        callback(cellIndex);
      }
    }
  }

  static toWorldCoordinates(
    row: number,
    col: number,
    width: number,
    height: number,
    cellWidth: number,
    cellHeight: number
  ): {
    x: number;
    y: number;
    centerX: number;
    centerY: number;
    bottomLeftX: number;
    bottomLeftY: number;
  } {
    const x = col * cellWidth;
    const y = row * cellHeight;
    const centerX = x + 0.5 * width * cellWidth;
    const centerY = y + 0.5 * height * cellHeight;
    return {
      x,
      y,
      bottomLeftX: x,
      bottomLeftY: y,
      centerX,
      centerY
    };
  }

  toWorldCoordinates(
    row: number,
    col: number,
    width: number,
    height: number
  ): {
    x: number;
    y: number;
    centerX: number;
    centerY: number;
    bottomLeftX: number;
    bottomLeftY: number;
  } {
    return Layout.toWorldCoordinates(row, col, width, height, this.cellWidth, this.cellHeight);
  }

  toSchema(padding: number = 3): RowSchema {
    const schema: RowSchema = [];
    const cells = this.boxes.flatMap(box => getBoxCells(box));
    for (let row = this.rows - 1; row >= 0; row--) {
      let rowTokens = [];
      for (let col = 0; col < this.columns; col++) {
        const cell = cells.find(cell => cell.row === row && cell.column === col);
        const cellToken = cell ? cell.token : this.EMPTY_SPACE_TOKEN;
        const formattedToken = col !== this.columns - 1 ? cellToken.padEnd(padding) : cellToken;
        rowTokens.push(formattedToken);
      }
      schema.push(rowTokens.join(''));
    }

    return schema;
  }

  leftNeighbour(box: PositionedLayoutBox): PositionedLayoutBox | null {
    return this.safeFindBoxAt({ row: box.row, column: box.column - 1 }) ?? null;
  }

  rightNeighbour({ box, row, column }: PositionedLayoutBox): PositionedLayoutBox | null {
    return this.safeFindBoxAt({ row: row, column: column + box.columns }) ?? null;
  }

  topNeighbour({ box, row, column }: PositionedLayoutBox): PositionedLayoutBox | null {
    return this.safeFindBoxAt({ row: row + box.rows, column: column }) ?? null;
  }

  bottomNeighbour(box: PositionedLayoutBox): PositionedLayoutBox | null {
    return this.safeFindBoxAt({ row: box.row - 1, column: box.column }) ?? null;
  }

  canInsertBoxAt(box: LayoutBox, position: Position): boolean {
    if (
      position.row + box.rows > this.rows ||
      position.column + box.columns > this.columns ||
      position.row < 0 ||
      position.column < 0
    ) {
      return false;
    }
    for (let dx = 0; dx < box.columns; dx++) {
      for (let dy = 0; dy < box.rows; dy++) {
        const cellIndex = this.calculateIndex(position.row + dy, position.column + dx);
        if (this.boxesByIndex.has(cellIndex)) {
          return false;
        }
      }
    }
    return true;
  }

  findBoxAt(position: Position): PositionedLayoutBox {
    // const box = this.boxes.find(box => {
    //   return (box.row <= position.row && position.row < box.row + box.info.height)
    //     && (box.column <= position.column && position.column < box.column + box.info.width)
    // })
    const index = this.calculateIndex(position.row, position.column);
    const box = this.boxesByIndex.get(index);
    if (!box) {
      throw new Error(`Cannot find box at row = ${position.row}, column = ${position.column}`);
    }
    return box;
  }

  safeFindBoxAt(position: Position): PositionedLayoutBox | undefined {
    const index = this.calculateIndex(position.row, position.column);
    return this.boxesByIndex.get(index);
  }

  removeBox(position: Position) {
    const index = this.boxes.findIndex(
      layoutBox => layoutBox.row === position.row && layoutBox.column === position.column
    );
    if (index >= 0) {
      const box = this.boxes[index];
      this.boxes.splice(index, 1);

      this.forEachBoxCell(box, cellIndex => {
        this.boxesByIndex.delete(cellIndex);
      });
    }
  }

  addColumns(index: number, count: number) {
    for (const box of this.boxes) {
      if (box.column >= index) {
        box.column += count;
      }
    }

    this.updateLayout();
  }

  removeColumn(columnIndex: number, count: number) {
    for (const row of this.getRows(columnIndex)) {
      const box = this.safeFindBoxAt({ row, column: columnIndex });
      if (!box) {
        continue;
      }

      if (box.box.columns !== count || box.column !== columnIndex) {
        throw new Error(`Cannot remove columns (#${columnIndex}, count ${count}). There are boxes that occupy more or less columns.`);
      }
    }

    for (let boxIndex = this.boxes.length - 1; boxIndex >= 0; boxIndex--) {
      const box = this.boxes[boxIndex];
      if (box.column === columnIndex) {
        this.boxes.splice(boxIndex, 1);
        continue;
      }

      if (box.column > columnIndex) {
        box.column -= count;
      }
    }

    this.updateLayout();
  }

  get firstRowIndex(): number {
    return 0;
  }

  get centralBox(): PositionedLayoutBox | undefined {
    return this.findBox(box => box.box.type === 'centralbox');
  }

  appendRowsToTop(count: number): void {
    this.updateLayout();
  }

  insertRowsToBottom(count: number): void {
    this.updateLayout();
  }

  removeRow(rowIndex: number, count: number): void {
    const isRow: boolean = isRowSolid(this, rowIndex, count);

    if (!isRow) {
      throw new Error(`Cannot remove rows (#${rowIndex}, count ${count}). There are boxes that occupy more or less rows.`);
    }


    for (let boxIndex = this.boxes.length - 1; boxIndex >= 0; --boxIndex) {
      const box = this.boxes[boxIndex];
      if (box.row === rowIndex) {
        this.boxes.splice(boxIndex, 1);
      }
    }

    this.shiftRowsAfterDeletion(rowIndex, count);
    this.updateLayout();
  }


  protected shiftRowsAfterDeletion(rowIndex: number, count: number): void {}

  protected static shiftBox(box: PositionedLayoutBox, direction: RowsShiftDirection, shiftLength: number): void {
    if (direction === 'up') {
      box.row += shiftLength;
    } else if (direction === 'down') {
      box.row -= shiftLength;
    }
  }


  insertBox(box: PositionedLayoutBox) {
    //TODO: check if space is free
    this.boxes.push(box);

    this.forEachBoxCell(box, cellIndex => {
      this.boxesByIndex.set(cellIndex, box);
    });
  }

  bottomDistanceToFloor(box: PositionedLayoutBox): number {
    return box.row * this.cellHeight;
  }

  topDistanceToFloor(box: PositionedLayoutBox): number {
    return (box.row + box.box.rows) * this.cellHeight;
  }

  boxInstalledInRange(
    box: PositionedLayoutBox,
    minBottomDistanceToFloor: number,
    maxTopDistanceToFloor: number
  ): boolean {
    const bottomDistance = +this.bottomDistanceToFloor(box).toFixed(2);
    const topDistance = +this.topDistanceToFloor(box).toFixed(2);
    return bottomDistance >= minBottomDistanceToFloor && topDistance <= maxTopDistanceToFloor;
  }

  boxInstalledAt(box: PositionedLayoutBox, height: number): boolean {
    return Math.abs(this.topDistanceToFloor(box) - height) <= eps;
  }

  clone() {
    const boxes = this.boxes.map(box => {
      return Object.assign({}, toJS(box));
    });
    return new Layout(this.rows, this.columns, boxes, this.cellWidth, this.cellHeight);
  }

  totalFreeCells(): number {
    let takenCells = 0;
    for (const { box } of this.boxes) {
      takenCells += box.columns * box.rows;
    }
    return this.maxFreeCells - takenCells;
  }

  findBox(predicate: (box: PositionedLayoutBox) => boolean): PositionedLayoutBox | undefined {
    return this.boxes.find(predicate);
  }

  protected calculateIndex(row: number, column: number): Index {
    return `${row}x${column}`;
  }

  findBoxes(predicate: (box: PositionedLayoutBox) => boolean) {
    return this.boxes.filter(predicate);
  }

  getAllColumns() {
    const columns: number[] = [];
    for (let column = 0; column < this.columns; column++) {
      columns.push(column);
    }
    return columns;
  }

  *getRows(column: number): Iterable<number> {
    for (let row = this.rows - 1; row >= 0; row--) {
      yield row;
    }
  }

  getColumnFreeRowsAreas(column: number): RowArea[] {
    const rows = Array.from(this.getRows(column));
    if (!rows.length) {
      return [];
    }
    const areas: RowArea[] = [];

    let state: AreaState = { type: 'PendingArea' };

    for (const row of rows) {
      const cellIndex = this.calculateIndex(row, column);
      const isRowTaken = this.boxesByIndex.has(cellIndex);
      switch (state.type) {
        case 'PendingArea': {
          if (!isRowTaken) {
            state = {
              type: 'InArea',
              top: row,
              bottom: row
            };
          }
          break;
        }
        case 'InArea': {
          if (isRowTaken) {
            areas.push({
              top: state.top,
              bottom: state.bottom,
              rows: state.top - state.bottom + 1
            });
            state = { type: 'PendingArea' };
          } else {
            if (row < state.bottom) {
              state.bottom = row;
            }
          }
          break;
        }
      }
    }
    if (state.type === 'InArea') {
      areas.push({
        top: state.top,
        bottom: state.bottom,
        rows: state.top - state.bottom + 1
      });
    }
    return areas;
  }

  static bottomAt(bottomRow: number, boxRows: number): number {
    return bottomRow;
  }
  static topAt(topRow: number, boxRows: number): number {
    return topRow + 1 - boxRows;
  }

  protected getBoxes(): PositionedLayoutBox[] {
    return this.boxes.map(wbox => ({
      row: wbox.row,
      column: wbox.column,
      box: {
        rows: wbox.box.rows,
        columns: wbox.box.columns,
        type: wbox.box.type,
        token: wbox.box.token
      }
    }))
  }
}
