import { rootStore } from '../../stores';
import s from './SummarySidePanel.module.scss';
import { FormattedMessage } from 'react-intl';
import React from 'react';

export interface PriceProps {
  price: string;
}

export function Price({ price }: PriceProps) {
  if (!rootStore.authorized) {
    return null;
  }
  return (
    <span className={s.price}>
      {price === '' ? <FormattedMessage id="menu.summary.onRequest" /> : price === '0' ? '' : price}
    </span>
  );
}
