import { IPostProcessor } from './IPostProcessor';
import { AccessoryType, BackPlateList, IAccessory, IBackPlate, ICuttingService, IWall, WallLayout } from '../schema';
import { ILayoutData, LayoutFactory } from '../layout/LayoutFactory';
import { ValueSplitService } from '../layout/ValueSplitService';
import { BigNumber, WithOptionId } from '@canvas-logic/engine';
import { CentralBoxAccessoriesWall } from './CentralBoxAccessoriesPostProcessor';


type AvailablePlates = IBackPlate[];

export type IBackplatesWall = ILayoutData & {
  backPlates: IWall['backPlates'];
  backplatesCuttingServices: IWall['backplatesCuttingServices'];
  accessories: IWall['accessories'];
}

export class BackplatesPostProcessor implements IPostProcessor {
  private byType: Map<AccessoryType, IAccessory>;
  private amount: Map<AccessoryType, number>;
  private wall: IBackplatesWall;
  private static backplatesAccessories: AccessoryType[] = [
    AccessoryType.G3104877,
    AccessoryType.G3104653,
    AccessoryType.G3104673,
    AccessoryType.G3104671,
    AccessoryType.G0002041,
    AccessoryType.G3104875,
    AccessoryType.G3103010,
    AccessoryType.G3104878
  ];
  constructor(
    private readonly availablePlates: AvailablePlates,
    private readonly cuttingService: ICuttingService,
    private readonly accessories: IAccessory[]
  ) {
  }

  process(wall: IBackplatesWall): void {
    this.wall = wall;
    this.byType = new Map<AccessoryType, IAccessory>();
    this.amount = new Map<AccessoryType, number>();
    if (wall.layout === WallLayout.Standalone) {
      const layout = LayoutFactory.create(wall);
      const rows = layout.rows;
      this.availablePlates.sort((a, b) => a.rows - b.rows);
      const candidatesRows = this.findPlatesRows(rows);
      if (!candidatesRows) {
        throw new Error(`Cannot find suitable backplate for ${rows} rows`);
      }
      this.fillBackPlates(wall, candidatesRows, rows, layout.columns);
      this.parseAccessories(BackplatesPostProcessor.backplatesAccessories);
      this.processAccessories(wall.backPlates);
      this.updateAccessories();
    } else {
      wall.backPlates = [];
    }
  }

  private processAccessories(backplates: WithOptionId<BackPlateList>) {
    this.processG3104877(backplates);
    this.processG3104653(backplates);
    this.processG3104673(backplates);
    this.processG3104671(backplates);
    this.processG0002041(backplates);
    this.processG3104875(backplates);
    this.processG3103010(backplates);
    this.processG3104878(backplates);
  }

  private updateAccessories() {
    const updatedAccessories = new Map<AccessoryType, IAccessory>();

    for (let [type, amount] of this.amount.entries()) {
      const accessory = this.getAccessory(type);
      updatedAccessories.set(type, { ...accessory, amount });
    }

    this.wall.accessories = this.wall.accessories.filter(acc => !BackplatesPostProcessor.backplatesAccessories.includes(acc.type));
    updatedAccessories.forEach(accessory => {
      this.wall.accessories.push(accessory);
    });
  }

  private parseAccessories(accessories: AccessoryType[]) {
    accessories.forEach(accessoryType => {
      const accessory = this.accessories.find(item => item.type === accessoryType);
      if (!accessory) {
        throw new Error(`Cannot find accessory ${accessoryType}`);
      }
      this.byType.set(accessory.type, accessory);
      this.amount.set(accessory.type, 0);
    });
  }

  private getAccessory(type: AccessoryType) {
    const candidate = this.byType.get(type);
    if (!candidate) {
      throw new Error(`Cannot find ${type}`);
    }
    return candidate;
  }

  private processG3104878(backplates: WithOptionId<BackPlateList>) {
    let totalAmount = 0;
    if (backplates.some(el => ['S3104677', 'S3104676'].includes(el.article))) {
      let backplateAmount = this.getBackplatesAmount(['S3104677', 'S3104676'], backplates);
      totalAmount += 1 / 11.2 * backplateAmount;
    }
    if (backplates.some(el => ['S3104680', 'S3104681'].includes(el.article))) {
      let backplateAmount = this.getBackplatesAmount(['S3104680', 'S3104681'], backplates);
      totalAmount += 1 / 12.5 * backplateAmount;
    }
    if (backplates.some(el => ['S3104679', 'S3104678'].includes(el.article))) {
      let backplateAmount = this.getBackplatesAmount(['S3104679', 'S3104678'], backplates);
      totalAmount += 1 / 11.8 * backplateAmount;
    }

    this.setAccessoryAmount(AccessoryType.G3104878, Number(totalAmount.toFixed(4)));
  }

  private processG3103010(backplates: WithOptionId<BackPlateList>) {
    let totalAmount = 0;
    if (backplates.some(el => ['S3104679', 'S3104681', 'S3104677'].includes(el.article))) {
      let backplateAmount = this.getBackplatesAmount(['S3104679', 'S3104681', 'S3104677'], backplates);
      totalAmount += 16 * backplateAmount;
    }
    if (backplates.some(el => ['S3104680', 'S3104678', 'S3104676'].includes(el.article))) {
      let backplateAmount = this.getBackplatesAmount(['S3104680', 'S3104678', 'S3104676'], backplates);
      totalAmount += 24 * backplateAmount;
    }
    this.setAccessoryAmount(AccessoryType.G3103010, totalAmount);
  }

  private processG3104875(backplates: WithOptionId<BackPlateList>) {
    if (backplates.some(el => ['S3104680', 'S3104678', 'S3104676'].includes(el.article))) {
      let backplateAmount = this.getBackplatesAmount(['S3104680', 'S3104678', 'S3104676'], backplates);
      this.setAccessoryAmount(AccessoryType.G3104875, 2 * backplateAmount);
    }
  }

  private processG0002041(backplates: WithOptionId<BackPlateList>) {
    if (backplates.some(el => ['S3104679', 'S3104680', 'S3104681', 'S3104678', 'S3104677', 'S3104676'].includes(el.article))) {
      let backplateAmount = this.getBackplatesAmount(['S3104679', 'S3104680', 'S3104681', 'S3104678', 'S3104677', 'S3104676'], backplates);
      this.setAccessoryAmount(AccessoryType.G0002041, 8 * backplateAmount);
    }
  }

  private processG3104671(backplates: WithOptionId<BackPlateList>) {
    if (backplates.some(el => ['S3104679', 'S3104680', 'S3104681', 'S3104678', 'S3104677', 'S3104676'].includes(el.article))) {
      let backplateAmount = this.getBackplatesAmount(['S3104679', 'S3104680', 'S3104681', 'S3104678', 'S3104677', 'S3104676'], backplates);
      this.setAccessoryAmount(AccessoryType.G3104671, 8 * backplateAmount);
    }
  }

  private processG3104673(backplates: WithOptionId<BackPlateList>) {
    if (backplates.some(el => ['S3104679', 'S3104680', 'S3104681', 'S3104678', 'S3104677', 'S3104676'].includes(el.article))) {
      let backplateAmount = this.getBackplatesAmount(['S3104679', 'S3104680', 'S3104681', 'S3104678', 'S3104677', 'S3104676'], backplates);
      this.setAccessoryAmount(AccessoryType.G3104673, 16 * backplateAmount);
    }
  }

  private processG3104653(backplates: WithOptionId<BackPlateList>) {
    if (backplates.some(el => ['S3104679', 'S3104680', 'S3104681', 'S3104678', 'S3104677', 'S3104676'].includes(el.article))) {
      let backplateAmount = this.getBackplatesAmount(['S3104679', 'S3104680', 'S3104681', 'S3104678', 'S3104677', 'S3104676'], backplates);
      this.setAccessoryAmount(AccessoryType.G3104653, 4 * backplateAmount);
    }
  }

  private processG3104877(backplates: WithOptionId<BackPlateList>) {
    if (backplates.some(el => ['S3104679', 'S3104680', 'S3104681', 'S3104678', 'S3104677', 'S3104676'].includes(el.article))) {
      let backplateAmount = this.getBackplatesAmount(['S3104679', 'S3104680', 'S3104681', 'S3104678', 'S3104677', 'S3104676'], backplates);
      this.setAccessoryAmount(AccessoryType.G3104877, 4 * backplateAmount);
    }
  }

  private getBackplatesAmount(articles: string[], backplates: WithOptionId<BackPlateList>): number {
    let amount = 0;
    articles.forEach((article) => {
      for (let backplate of backplates) {
        if (backplate.article === article) {
          amount += 1;
        }
      }
    })

    return amount;
  }

  private setAccessoryAmount(type: AccessoryType, number: number): void {
    this.amount.set(type, number);
  }


  private findPlatesRows(rows: number): number | undefined {
    for (let availablePlate of this.availablePlates) {
      if (availablePlate.rows >= rows) {
        return availablePlate.rows;
      }
    }
    return undefined;
  }

  private fillBackPlates(wall: IBackplatesWall, candidatesRows: number, rows: number, columns: number): void {
    const candidates = this.availablePlates.filter(plate => plate.rows === candidatesRows);
    const pickedPerColumn = new Map<number, IBackPlate>();
    for (const candidate of candidates) {
      const plate = pickedPerColumn.get(candidate.columns) ?? candidate;
      if (candidate.price.is('<=', plate.price)) {
        pickedPerColumn.set(candidate.columns, candidate);
      }
    }
    const bases = Array.from(pickedPerColumn.keys());
    const splits = ValueSplitService.split(columns, bases);

    if (!splits.length) {
      throw new Error('Cannot configure backplates');
    }
    let cheapestSplit = splits[0];
    let cheapestPrice = new BigNumber('9999999999999999999999999999');

    for (let split of splits) {
      let price = new BigNumber(0);
      for (let i = 0; i < split.length; i++) {
        const columns = bases[i];
        const amount = split[i];
        const pickedPlate = pickedPerColumn.get(columns);
        if (!pickedPlate) {
          throw new Error(`Cannot find plate for ${columns} columns`);
        }
        price = price.plus(pickedPlate.price.mulBy(amount));
      }
      if (price.is('<', cheapestPrice)) {
        cheapestSplit = split;
        cheapestPrice = price;
      }
    }

    wall.backPlates = [];
    wall.backplatesCuttingServices = []
    let cuttingServiceAdded = false;
    for (let i = 0; i < cheapestSplit.length; i++) {
      const columns = bases[i];
      let amount = cheapestSplit[i];
      const plate = pickedPerColumn.get(columns);
      if (plate) {
        while (amount-- > 0) {
          if (!cuttingServiceAdded && candidatesRows !== rows) {
            wall.backplatesCuttingServices.push(this.cuttingService);
            cuttingServiceAdded = true;
          }
          wall.backPlates.push(plate)
        }
      }
    }
  }
}