import { IWall, MountingType, WallLayout, WallType } from '../schema';
import { Layout } from '../layout/Layout';
import { SOCLE_HEIGHT } from '../viewer/BoxViewModelMaker';

const FINISHING_PLATES_THICKNESS = 6;
export const FOOT_WIDTH = 40;
export const ROOF_SIDE_PLATES_HEIGHT = 1950;

export interface IDimensionsService {
  readonly width: number;
  readonly height: number;
  readonly depth: number;
}

export class DimensionsService {
  constructor(
    private readonly wall: IWall,
    private readonly layout: Layout
  ) {
  }

  get width() {
    const n = this.layout.width;
    const width = DimensionsService.mToMm(this.layout.cellWidth) * n;
    const { wallType, mountingType } = this.wall;
    return DimensionsService.isBoxisOrInterna(wallType) ?
      (DimensionsService.isFeetExist(mountingType) ?
        width + 2 * FOOT_WIDTH
        : width)
      : width + FINISHING_PLATES_THICKNESS + n - 1;
  }

  get height() {
    const n = this.layout.height;
    const boxesHeight = DimensionsService.mToMm(this.layout.cellHeight * n);
    const socleHeight = DimensionsService.mToMm(SOCLE_HEIGHT);
    const boxesHeightWithPlates = boxesHeight + FINISHING_PLATES_THICKNESS;
    if (this.wall.layout === WallLayout.Standalone) {
      if (this.wall.roof.top.length !== 0) {
        return ROOF_SIDE_PLATES_HEIGHT + socleHeight;
      } else {
        return boxesHeightWithPlates + socleHeight;
      }
    } else {
      return DimensionsService.isBoxisOrInterna(this.wall.wallType) ? boxesHeight : boxesHeightWithPlates;
    }
  }

  get depth() {
    const hasRoof = this.wall.roof.top.length > 0;
    const depth = this.wall.boxes[0].box.depth;
    return DimensionsService.isBoxisOrInterna(this.wall.wallType) ? depth : (hasRoof ? 645 : 445);
  }

  static mToMm(value: number): number {
    return Math.floor(value * 1000);
  }

  static mmToM(mm: number): number {
    return mm / 1000;
  }

  static isBoxisOrInterna(wallType: WallType): boolean {
    return [WallType.Boxis, WallType.Interna].includes(wallType);
  }

  static isFeetExist(mountingType: MountingType): boolean {
    return [MountingType.FixOnTheGround, MountingType.FixOnConcrete].includes(mountingType);
  }
}


export class FakeDimensionsService implements IDimensionsService {
  readonly depth = 0;
  readonly height = 0;
  readonly width = 0;
}
