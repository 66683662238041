import * as React from 'react';
import classNames from 'classnames';

import s from './Icon.module.scss';

import { ReactComponent as hide } from './icons/icon-hide.svg';
import { ReactComponent as show } from './icons/icon-show.svg';
import { ReactComponent as logout } from './icons/icon-logout.svg';
import { ReactComponent as cross } from './icons/icon-cross.svg';
import { ReactComponent as close } from './icons/icon-close.svg';
import { ReactComponent as check } from './icons/icon-check.svg';
import { ReactComponent as ok } from './icons/icon-ok.svg';
import { ReactComponent as countryCheck } from './icons/icon-country-check.svg';
import { ReactComponent as dropdownDown } from './icons/icon-dropdown-down.svg';
import { ReactComponent as dropdownUp } from './icons/icon-dropdown-up.svg';
import { ReactComponent as plus } from './icons/icon-plus.svg';
import { ReactComponent as loader } from './icons/icon-loader.svg';
import { ReactComponent as loaderCheck } from './icons/icon-loader-check.svg';
import { ReactComponent as exclamation } from './icons/icon-exclamation.svg';
import { ReactComponent as help } from './icons/icon-help.svg';
import { ReactComponent as ar } from './icons/icon-ar.svg';
import { ReactComponent as share } from './icons/icon-share.svg';
import { ReactComponent as cart } from './icons/icon-cart.svg';
import { ReactComponent as edit } from './icons/icon-edit.svg';
import { ReactComponent as up } from './icons/icon-up.svg';
import { ReactComponent as down } from './icons/icon-down.svg';
import { ReactComponent as left } from './icons/icon-chevron-left.svg';
import { ReactComponent as right } from './icons/icon-chevron-right.svg';
import { ReactComponent as hint } from './icons/icon-hint.svg';

import { ReactComponent as mail } from './icons/icon-mail.svg';
import { ReactComponent as bell } from './icons/icon-bell.svg';
import { ReactComponent as box } from './icons/icon-box.svg';
import { ReactComponent as light } from './icons/icon-light.svg';

import { ReactComponent as Standalone } from './icons/stand-alone.svg';
import { ReactComponent as Rectangle } from './icons/rectangle.svg';
import { ReactComponent as Z } from './icons/z-form.svg';

import { ReactComponent as Hanging } from './icons/hanging.svg';
import { ReactComponent as Niche } from './icons/niche.svg';

import { ReactComponent as HangingZ } from './icons/hanging-z.svg';
import { ReactComponent as NicheZ } from './icons/niche-z.svg';

import { ReactComponent as HangingBoxis } from './icons/il-hanging-boxis.svg';
import { ReactComponent as NicheBoxis } from './icons/il-niche-boxis.svg';

import { ReactComponent as FixOnTheGround } from './icons/il-fix-on-ground.svg';
import { ReactComponent as FixOnConcrete } from './icons/il-fix-on-concrete.svg';

import { ReactComponent as Inside } from './icons/inside.svg';
import { ReactComponent as OutsideSheltered } from './icons/outside-sheltered.svg';
import { ReactComponent as OutsideUnsheltered } from './icons/outside-unsheltered.svg';

import { ReactComponent as ArrowLeft } from './icons/icon-arrow-left.svg';
import { ReactComponent as ArrowRight } from './icons/icon-arrow-right.svg';
import { ReactComponent as arrowRightGoForward } from './icons/icon-arrow-right-go-forward.svg';
import { ReactComponent as arrowLeftGoBack } from './icons/icon-arrow-left-go-back.svg';
import { ReactComponent as color } from './icons/icon-color.svg';
import { ReactComponent as view } from './icons/icon-view.svg';
import { ReactComponent as text } from './icons/icon-text.svg';
import { ReactComponent as editMode } from './icons/icon-edit-mode.svg';
import { ReactComponent as add } from './icons/icon-add.svg';
import { ReactComponent as remove } from './icons/icon-delete.svg';
import { ReactComponent as gear } from './icons/icon-gear.svg';
import { ReactComponent as loop } from './icons/icon-loop.svg';
import { ReactComponent as zoomOut } from './icons/icon-zoom-out.svg';
import { ReactComponent as clear } from './icons/icon-clear.svg';
import { ReactComponent as loader24 } from './icons/icon-loader-24.svg';

import { ReactComponent as alignLeft } from './icons/icon-align-left.svg';
import { ReactComponent as alignCentral } from './icons/icon-align-center.svg';
import { ReactComponent as alignRight } from './icons/icon-align-right.svg';

import { ReactComponent as home } from './icons/icon-home.svg';
import { ReactComponent as rulerOn } from './icons/icon-ruler-on.svg';
import { ReactComponent as rulerOff } from './icons/icon-ruler-off.svg';
import { ReactComponent as moreActions } from './icons/icon-more-actions.svg';

import { ReactComponent as lockMechanical } from './icons/il-lock-mechanical.svg';
import { ReactComponent as lockElectrical } from './icons/il-lock-electrical.svg';
import { ReactComponent as lockNumber } from './icons/parcel-lock-num.svg';

import { ReactComponent as xsinterna } from './icons/il-interna-560.svg';
import { ReactComponent as sinterna } from './icons/il-interna-560-d.svg';
import { ReactComponent as xsboxis } from './icons/il-boxis-584-d-505.svg';
import { ReactComponent as sboxis } from './icons/il-boxis-540-505.svg';
import { ReactComponent as sboxisd } from './icons/il-boxis-540-d-505.svg';

import { dataCy, DataCyAttribute } from '../../cy';

import { ReactComponent as sendMail } from './icons/icon-sendMail.svg';
import { ReactComponent as pdfFile } from './icons/icon-pdf-file.svg';

import { ReactComponent as selectColumns} from './icons/icon-select-columns.svg';
import { ReactComponent as selectRows} from './icons/icon-select-rows.svg';

const icons = {
  cross,
  plus,
  check,
  ok,
  countryCheck,
  hide,
  show,
  logout,
  loaderCheck,
  loader,
  exclamation,
  help,
  ar,
  share,
  cart,
  edit,
  view,
  up,
  down,
  left,
  right,
  ArrowLeft,
  ArrowRight,
  color,
  text,
  editMode,
  add,
  remove,
  gear,
  loop,
  zoomOut,
  mail,
  bell,
  box,
  light,
  Standalone,
  Rectangle,
  Z,
  Hanging,
  Niche,
  HangingZ,
  NicheZ,
  NicheBoxis,
  HangingBoxis,
  FixOnTheGround,
  FixOnConcrete,
  Inside,
  OutsideSheltered,
  OutsideUnsheltered,
  clear,
  alignLeft,
  alignCentral,
  alignRight,
  home,
  rulerOn,
  rulerOff,
  moreActions,
  lockMechanical,
  lockElectrical,
  loader24,
  lockNumber,
  hint,
  close,
  sendMail,
  pdfFile,
  dropdownDown,
  dropdownUp,
  xsinterna,
  sinterna,
  xsboxis,
  sboxis,
  sboxisd,
  selectColumns,
  selectRows,
  arrowRightGoForward,
  arrowLeftGoBack,
};

export type IconName = keyof typeof icons;

export type IconSize = 'auto' | 'xsmall' | 'small' | 'medium';

interface IProps {
  type: IconName;
  className?: string;
  size?: IconSize;
  iconDataCy?: DataCyAttribute;
}

interface IFigureProps {
  type: IconName;
  className?: string;
  iconDataCy?: DataCyAttribute;
}

const Icon: React.FC<IProps> = ({ type, size = 'small', className, iconDataCy }) => {
  const SVGIcon = icons[type];

  return (
    <i className={classNames(s.icon, size, className)} {...dataCy(iconDataCy)}>
      <SVGIcon
        className={classNames(s.svg, {
          [s.auto]: size === 'auto',
          [s.small]: size === 'small',
          [s.xsmall]: size === 'xsmall',
          [s.medium]: size === 'medium'
        })}
      />
    </i>
  );
};

export const Figure: React.FC<IFigureProps> = props => {
  return <Icon {...props} size="auto" />;
};
export default Icon;
