import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import { getOptionId } from '@canvas-logic/engine';

import BottomSheet from '../BottomSheet/BottomSheet';
import { ConfiguratorStore, VisualMode } from '../../stores/ConfiguratorStore';
import { ConfiguratorMenuStore, SidePanelId } from '../../stores/ConfiguratorMenuStore';
import ElementItem from '../ElementItem/ElementItem';
import { groupBy, uniq } from '../../services/utils';
import Typography from '../Typography/Typography';

import styles from './AdditionalBoxesSidePanel.module.scss';
import { rootStore } from '../../stores';
import { dndService } from '../../services/DnDService';
import Icon from "../Icon/Icon";
import Button from "../Button/Button";
import { useGuideContext } from "../../contexts/GuideContext";

interface IProps {
  store: ConfiguratorStore;
  menu: ConfiguratorMenuStore;
}

export const AddBoxSidePanelSidePanel: React.FC<IProps> = observer(({ store, menu }) => {
  const { showInsertBoxGuide } = useGuideContext();
  const boxesToAdd = React.useMemo(() => {
    return groupBy(uniq(store.additionalBoxes, 'name'), 'type');
  }, [store.additionalBoxes]);

  const handleHintClick = () => {
    showInsertBoxGuide();
  }

  return (
    <BottomSheet
      title={rootStore.localization.formatMessage('menu.summary.additionalBoxes')}
      onClick={() => {
        menu.openSidePanel(SidePanelId.AdditionalBoxes);
        store.continueBoxInsertion();
      }}
      onClose={() => {
        menu.closeSidePanels();
        store.cancelBoxInsertion();
      }}
      renderActions={() => (
        <span className={styles.mobileOnly}>
          <Button variant="secondary" onClick={handleHintClick}>
            <Icon type="hint" size="small" />
          </Button>
        </span>
      )}
    >
      {store.mode !== VisualMode.Insert && <BottomSheet.Content>
        {Object.entries(boxesToAdd).flatMap(([groupType, boxes], i) => {
          return [
            <div key={groupType} className={styles.subHeader}>
              <Typography variant="subtitle1">
                <FormattedMessage id={`menu.summary.${groupType}`} />
              </Typography>
            </div>,
            boxes.map(box => (
              <ElementItem
                key={getOptionId(box)}
                id={getOptionId(box)}
                name={box.type}
                subtitle={<FormattedMessage id="boxes.dimensions" values={{ width: box.width, height: box.height }} />}
                value={String(i)}
                onSelect={() => {
                  if (rootStore.isMobile) {
                    store.onMobileInsertStart(box);
                  }
                }}
                onPointerDown={(e) => {
                  if (!rootStore.isMobile) {
                    dndService.pointerDown(box)
                    dndService.pointerMove(e.clientX, e.clientY);
                  }
                }}
                title={rootStore.localization.formatMessage(`${box.name}`)}
                image={(box as any)?.image ?? ''}
              />
            ))];
        })}
      </BottomSheet.Content>
      }
    </BottomSheet>
  );
});
