import { WallBox } from '../../schema';
import { Layout } from '../../layout/Layout';
import { Action } from '../../rules/LayoutRulesManager';
import { NewRowType, NewRowBehaviour } from './behaviour';


export const newRowAction = (box: WallBox, rowType: NewRowType, availableBoxes: WallBox[]): Action => {
    return (layout: Layout) => {
        const behaviour = new NewRowBehaviour(layout);
        behaviour.newRow(rowType, box, availableBoxes);
    };
}
