import classNames from 'classnames';
import * as React from 'react';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import styles from './ZoomBottomMenu.module.scss';

interface IProps {
  className?: string;
  onGearClick?: () => void;
  onZoomClick: () => void;
}

const ZoomBottomMenu: React.FC<IProps> = ({ className, onGearClick, onZoomClick }) => {
  return (
    <div className={classNames(styles.root, className)}>
      {
        onGearClick &&
        <Button className={styles.button} variant="secondary" onClick={onGearClick} disabled={!onGearClick}>
          <Icon type="gear" size="small" />
        </Button>
      }
      <Button className={styles.button} variant="secondary" onClick={onZoomClick}>
        <Icon type="zoomOut" size="small" />
      </Button>
    </div>
  );
};

export default ZoomBottomMenu;
