import React from 'react';
import { rootStore } from '../../stores';
import BottomSheet from '../BottomSheet/BottomSheet';
import { Form } from '../Form/Form';
import classNames from 'classnames';
import s from './SummarySidePanel.module.scss';
import Button from '../Button/Button';
import Typography from '../Typography/Typography';
import { Dimension, SummaryPart, SummaryTogglePart } from './SummaryPartsMaker';
import { OnToggleHandler, TogglePart } from './TogglePart';
import { Part } from './Part';
import { DownloadPart } from './DownloadPart';
import { IWall } from '../../schema';

export interface ComponentProps {
  model: IWall;
  addToCartDisabled: boolean;
  onClose: () => void;
  onToggle: OnToggleHandler;
  dimensionsParts: Dimension;
  parts: SummaryPart[];
  toggleParts: SummaryTogglePart[];
  totalPrice: string;
  onContactMe: () => void;
  onAddToCart: () => void;
  onDownload: () => void;
}

export const SummarySidePanelComponent: React.FC<ComponentProps> = ({
                                                                      onClose,
                                                                      onToggle,
                                                                      parts,
                                                                      toggleParts,
                                                                      totalPrice,
                                                                      addToCartDisabled,
                                                                      dimensionsParts,
                                                                      onContactMe,
                                                                      onAddToCart,
                                                                      onDownload,
                                                                      model
                                                                    }) => {
  const showTotalPrice = rootStore.authorized;
  const canAddToCart = rootStore.authorized && totalPrice;
  const isPdfSource = rootStore.isPdfSource;
  const withoutFooter = isPdfSource && !canAddToCart;
  const showContactMe = !canAddToCart && !isPdfSource;
  return (
    <BottomSheet title={rootStore.localization.formatMessage('menu.summary.title')} onClose={onClose}>
      <BottomSheet.Content>
        {/* dimensions */}
        {/*<div className={s.body}>*/}
        <Form.Control labelDisplay={'block'}>
          <Form.Section
            title={rootStore.localization.formatMessage('menu.summary.' + dimensionsParts.header)}
          >
            {dimensionsParts.items.map((item: any, i: number) => {
              return (
                <div key={i} className={classNames(s.line, s.twoColumnsLine)}>
                  <span>
                    {rootStore.localization.formatMessage('dimensions.' + item.title)}
                  </span>
                  <span className={s.dimensionValue}>{item.value}</span>
                </div>
              );
            })}
          </Form.Section>
        </Form.Control>

        {
          /* boxes & other elements */
          parts.map((part, i) => (
            <Part part={part} key={i} model={model} />
          ))
        }
        {
          /* boxes & other elements */
          toggleParts.map((part, i) => (
            <TogglePart onChange={onToggle} part={part} key={i} />
          ))
        }

        {!isPdfSource && <DownloadPart onClick={onDownload} />}
      </BottomSheet.Content>
      {!withoutFooter && <BottomSheet.Footer>
        <div className={s.footer}>
          <div className={s.priceWrapper}>
            {showTotalPrice && (<>
              <Typography variant="caption">
                {rootStore.localization.formatMessage('menu.summary.footer.price.sum.label')}
              </Typography>
              <Typography variant={'body2'} weight={'bold'}>
                {totalPrice || rootStore.localization.formatMessage('menu.summary.onRequest')}
              </Typography>
              {
                totalPrice && (
                  <Typography variant="caption" size="10">
                    {rootStore.localization.formatMessage('menu.summary.footer.price.sum.VAT')}
                  </Typography>
                )}
            </>)}
          </div>
          {showContactMe && <Button onClick={onContactMe}>
            {rootStore.localization.formatMessage('menu.summary.button.label.contact')}
          </Button>
          }
          {canAddToCart && <Button disabled={addToCartDisabled} onClick={onAddToCart}>
            {rootStore.localization.formatMessage('menu.summary.button.label.addToCart')}
          </Button>
          }
        </div>
      </BottomSheet.Footer>}
    </BottomSheet>
  );
};