import { IConcreteBox, IWall, LayoutOptions, WallBox } from '../schema';
import { centralBoxTypeGuard, mailBoxTypeGuard, parcelBoxTypeGuard, rectangleLayoutOptionsTypeGuard } from '../guards';

export class CloneService {
  static cloneWallLayout(wall: IWall): IWall {
    return {
      ...wall,
      layoutOptions: CloneService.cloneLayoutOptions(wall.layoutOptions),
      boxes: wall.boxes.map(CloneService.cloneConcreteBox)
    };
  }

  static cloneConcreteBox(wallBox: IConcreteBox): IConcreteBox {
    return {
      row: wallBox.row,
      column: wallBox.column,
      box: CloneService.cloneWallBox(wallBox.box)
    };
  }

  static cloneWallBox(box: WallBox): WallBox {
    let copy = { ...box };
    if (mailBoxTypeGuard(copy)) {
      copy.engraving = [...copy.engraving];
      copy.rfidBadges = [...copy.rfidBadges];
    } else if (centralBoxTypeGuard(copy)) {
      copy.additionalArticles = [...copy.additionalArticles];
    } else if (parcelBoxTypeGuard(copy)) {
      copy.rfidBadges = [...copy.rfidBadges];
    }
    return copy;
  }

  static cloneLayoutOptions(layoutOptions: LayoutOptions): LayoutOptions {
    const copy: LayoutOptions = {
      ...layoutOptions
    };
    if (rectangleLayoutOptionsTypeGuard(copy)) {
      copy.row = { ...copy.row };
      copy.column = { ...copy.column };
    }
    return copy;
  }
}