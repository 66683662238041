import React from "react";
import classNames from "classnames";
import { v4 } from "uuid";

import "./Toggle.scss";

interface IProps {
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
}

const Toggle: React.FC<IProps> = ({
                                          className,
                                          checked,
                                          disabled,
                                          onChange
                                        }) => {
  const id = React.useMemo(() => v4(), []);

  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.currentTarget.checked);
    }
  };

  return (
    <div className={classNames("inline", className, {
      "toggle--disabled": disabled
    })}>
      <input
        id={id}
        type="checkbox"
        className="toggle__input"
        checked={checked}
        onChange={handleChecked}
        disabled={disabled}
      />
      <label htmlFor={id} className="toggle__label" />
    </div>
  );
};

export default Toggle;
