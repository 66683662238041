import { IPostProcessor } from './IPostProcessor';
import { IWall, MountingType } from '../schema';
import { ILayoutData, LayoutFactory } from '../layout/LayoutFactory';

export type IHangingBracketsWall = ILayoutData & {
  mountingType: IWall['mountingType'];
  hangingBracketRules: IWall['hangingBracketRules'];
  hangingBrackets: IWall['hangingBrackets'];
}

export class HangingBracketPostProcessor implements IPostProcessor {
  process(wall: IHangingBracketsWall): void {
    const isMountingTypeRequired = wall.mountingType === MountingType.Hanging;

    if (isMountingTypeRequired) {
      const columnsCount = LayoutFactory.create(wall).columns;
      const hangingBracketRules = wall.hangingBracketRules;

      wall.hangingBrackets = [];

      hangingBracketRules.forEach((rule) => {
        if (rule.columns === columnsCount) {
          let collectingArray = new Array(rule.amount).fill(rule.hangingBracket);
          wall.hangingBrackets.push(...collectingArray);
        }
      });
      if (!wall.hangingBrackets.length) {
        throw new Error('No rules found for hanging brackets');
      }
    } else {
      wall.hangingBrackets = [];
    }
  }
}