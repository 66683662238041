import * as React from 'react';
import classNames from 'classnames';

import './Typography.scss';
import { dataCy, DataCyAttribute } from '../../cy';

export type TypographyVariantName = 'h1' | 'h2' | 'h3' | 'h4' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'caption' | 'button';

interface IProps {
  id?: string;
  component?: React.ElementType;
  weight?: 'light' | 'semi-bold' | 'bold';
  size?: '10' | '12' | '14' | '16' | '21' | '28';
  lineHeight?: '';
  variant?: TypographyVariantName;
  color?: 'default' | 'white' | 'inherit' | 'primary' | 'error';
  align?: 'center';
  textTransform?: 'uppercase' | 'capitalize';
  underline?: boolean;
  className?: string;
  typographyDataCy?: DataCyAttribute;
}

class Typography extends React.Component<IProps> {

  render() {
    const {
            id,
            children,
            component: Component = "p",
            variant = "body1",
            color,
            align,
            weight,
            textTransform,
            underline,
            className,
            typographyDataCy
          } = this.props;

    return (
      <Component
        id={id}
        className={classNames(
          'typography',
          {
            [`typography--variant-${variant}`]: variant,
            [`typography--weight-${weight}`]: weight,
            // [`typography_line-height-${lineHeight}`]: lineHeight,
            [`typography_color-${color}`]: color,
            [`typography_align-${align}`]: align,
            [`typography_text-transform-${textTransform}`]: textTransform,
            [`typography--underline`]: underline,
          },
          className
        )}
        {...dataCy(typographyDataCy)}
      >
        {children}
      </Component>
    );
  }
}

export default Typography;
