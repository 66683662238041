import * as THREE from 'three';
import { Camera, Color, Scene, Texture } from 'three';
import { BloomEffect, EffectComposer, EffectPass, RenderPass } from 'postprocessing';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';

const BLOOM_INTENSITY = 0.35;
const SAMPLING_VALUE = 4;
const BACKGROUND_COLOR = 0xeeeeee;

export class BoxRenderer {
  private renderer!: THREE.WebGLRenderer;
  private composer!: EffectComposer;

  get domElement() {
    return this.renderer.domElement;
  }

  async init(width: number, height: number, element: HTMLCanvasElement, scene: Scene, camera: Camera, hdrURL: string): Promise<Texture> {
    this.renderer = new THREE.WebGLRenderer({
      canvas: element,
      powerPreference: "high-performance",
      antialias: false,
      stencil: false,
      depth: false,
      preserveDrawingBuffer: true
    });
    this.renderer.setClearColor(BACKGROUND_COLOR);
    this.renderer.debug.checkShaderErrors = (window.location.hostname === "localhost");
    this.renderer.physicallyCorrectLights = true;
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer.shadowMap.type = THREE.VSMShadowMap;
    this.renderer.shadowMap.autoUpdate = false;
    this.renderer.shadowMap.needsUpdate = true;
    this.renderer.shadowMap.enabled = true;
    //this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
    this.renderer.toneMapping = THREE.LinearToneMapping;
    this.renderer.setSize(width, height);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    const pMREMGenerator = new THREE.PMREMGenerator(this.renderer);
    pMREMGenerator.compileEquirectangularShader();

    this.composer = new EffectComposer(this.renderer, { multisampling: SAMPLING_VALUE });
    this.composer.addPass(new RenderPass(scene, camera));
    const effect = new BloomEffect({intensity: BLOOM_INTENSITY});
    this.composer.addPass(new EffectPass(camera, effect));
    //return pMREMGenerator.fromScene(new RoomEnvironment()).texture;

    return new Promise((resolve, reject) => {
      new RGBELoader()
        .setDataType(THREE.UnsignedByteType)
        .setPath('assets/models/environment/')
        .load(
          hdrURL,
          texture => {
            // this.doorScene.scene.environment = pMREMGenerator.fromEquirectangular(texture).texture;
            texture.dispose();
            pMREMGenerator.dispose();
            resolve(pMREMGenerator.fromEquirectangular(texture).texture);
          },
          //eslint-disable-next-line @typescript-eslint/no-empty-function
          () => {},
          reject
        );
    });
  }

  setClearColor(color: Color) {
    this.renderer.setClearColor(color);
  }
  render() {
    this.composer.render();
  }

  setSize(width: number, height: number) {
    this.renderer.setSize(width, height);
    this.composer.setSize(width, height);
  }

  dispose() {
    this.renderer.dispose();
  }
}
