import { ConcreteBoxList, IWall, WallLayout } from '../schema';
import { Layout } from './Layout';
import { RectangleLayout } from './RectangleLayout';
import { ZFormLayout } from './ZFormLayout';
import { StandaloneLayout } from "./StandaloneLayout";
import {
  rectangleLayoutOptionsTypeGuard,
  standaloneLayoutOptionsTypeGuard,
  zFormLayoutOptionsTypeGuard
} from '../guards';
import { PositionedLayoutBox } from './Box';

export interface ILayoutData {
  layout: IWall['layout'];
  offsetHeight: number;
  cellWidth: IWall['cellWidth'];
  cellHeight: IWall['cellHeight'];
  layoutOptions: IWall['layoutOptions'];
  boxes: PositionedLayoutBox[]
}

export class LayoutFactory {
  static create(model: ILayoutData): Layout {
    switch (model.layout) {
      case WallLayout.Rectangle:
        return LayoutFactory.createRectangleLayout(model);
      case WallLayout.Z:
        return LayoutFactory.createZFormLayout(model);
      case WallLayout.Standalone:
        return LayoutFactory.createStandaloneLayout(model);
    }
  }

  static createRectangleLayout(model: ILayoutData): RectangleLayout {
    if (rectangleLayoutOptionsTypeGuard(model.layoutOptions)) {
      const layout =  new RectangleLayout(model.layoutOptions, model.boxes, model.cellWidth, model.cellHeight);
      layout.offsetHeight = model.offsetHeight;
      return layout;
    } else {
      throw new Error(`Invalid wall configuration. Check [layoutOptions] property`);
    }
  }

  private static createZFormLayout(model: ILayoutData): ZFormLayout {
    if (zFormLayoutOptionsTypeGuard(model.layoutOptions)) {
      return new ZFormLayout(model.layoutOptions, model.boxes, model.cellWidth, model.cellHeight);
    } else {
      throw new Error(`Invalid wall configuration. Check [layoutOptions] property`);
    }
  }

  private static createStandaloneLayout(model: ILayoutData): Layout {
    if (standaloneLayoutOptionsTypeGuard(model.layoutOptions)) {
      return new StandaloneLayout(model.layoutOptions, model.boxes, model.cellWidth, model.cellHeight);
    } else {
      throw new Error('Invalid wall configuration. Check [layoutOptions] property');
    }
  }
}