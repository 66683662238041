import { action, computed, makeObservable, observable } from 'mobx';
import { SelectedOption } from '../components/CustomizeBottomMenu/CustomizeBottomMenu';
import { ScreenBox2D } from '../camera/types';
import { DeviceType } from './RootStore';
import { MOBILE_LEFT_BORDER_SCREEN_BOX } from '../viewer/BoxViewer';


export const SIDE_PANEL_WIDTH = 400;
const SIDE_PANEL_HORIZONTAL_GAP = 20;

export enum MainMenu {
  Overview = 'Overview',
  Zoomed = 'Zoomed',
  BoxContextMenu = 'BoxContextMenu',
  DeleteColumn = 'DeleteColumn',
  DeleteRow = 'DeleteRow',
  SwapElements = 'SwapElements'
}

export type RemoveMode = MainMenu.DeleteRow | MainMenu.DeleteColumn;

export const removeModeTypeGuard = (menuMode: MainMenu | undefined): menuMode is RemoveMode => {
  return menuMode === MainMenu.DeleteRow || menuMode === MainMenu.DeleteColumn;
};

export enum SidePanelId {
  Color,
  Text,
  AdditionalBoxes,
  Summary
}

export class ConfiguratorMenuStore {
  mainMenu: MainMenu = MainMenu.Overview;
  sidePanels: SidePanelId[] = [];
  sidePanelScreenBox: ScreenBox2D;
  topMenuScreenBox: ScreenBox2D;
  mainMenuScreenBox: ScreenBox2D;
  horizontalOffset = 0;
  private sidePanelsHandlers: SidePanelChangedHandler[] = [];
  deviceType: DeviceType;

  get leftBorderScreenBox(): ScreenBox2D {
    if (this.deviceType === DeviceType.HANDLET) {
      return MOBILE_LEFT_BORDER_SCREEN_BOX;
    }
    const width = Math.abs(this.horizontalOffset) + 10;
    return {
      x: width + this.horizontalOffset,
      y: 0,
      height: Infinity,
      width
    };
  }

  get screenBoxes(): ScreenBox2D[] {
    // ignore all screen boxes
    if (this.mainMenu === MainMenu.Zoomed || this.mainMenu === MainMenu.BoxContextMenu) {
      return [];
    }

    const result: ScreenBox2D[] = [this.leftBorderScreenBox];

    if (this.topMenuScreenBox && this.topMenuScreenBox.width > 0 && this.topMenuScreenBox.height > 0) {
      result.push(this.topMenuScreenBox);
    }
    if (this.deviceType === DeviceType.DESKTOP && this.sidePanelScreenBox && this.sidePanelScreenBox.width > 0 && this.sidePanelScreenBox.height > 0) {
      result.push(this.sidePanelScreenBox);
    }
    if (this.mainMenuScreenBox && this.mainMenuScreenBox.width > 0 && this.mainMenuScreenBox.height > 0) {
      result.push(this.mainMenuScreenBox);
    }
    return result;
  }

  get selectedOverviewOption(): SelectedOption {
    if (this.sidePanels.includes(SidePanelId.Text)) {
      return SelectedOption.Text;
    }
    if (this.sidePanels.includes(SidePanelId.Color)) {
      return SelectedOption.Color;
    }
    if (this.sidePanels.includes(SidePanelId.AdditionalBoxes)) {
      return SelectedOption.Add;
    }
    return SelectedOption.None;
  }

  constructor() {
    makeObservable(this, {
      mainMenu: observable,
      sidePanels: observable,
      mainMenuScreenBox: observable,
      sidePanelScreenBox: observable,
      horizontalOffset: observable,
      deviceType: observable,

      leftBorderScreenBox: computed.struct,


      screenBoxes: computed.struct,

      toggleTextSidePanel: action.bound,
      closeSidePanels: action.bound,
      toggleColorSidePanel: action.bound,
      enterZoomedMainMenu: action.bound,
      enterOverviewMainMenu: action.bound,
      enterBoxContextMainMenu: action.bound,
      enterSummarySidePanel: action.bound,
      enterAdditionalBoxesSidePanel: action.bound,
      enterDeleteColumnMainMenu: action.bound,
      setMenuScreenBoxes: action,
      setCanvasLeftOffset: action
    });
  }

  enterOverviewMainMenu() {
    this.mainMenu = MainMenu.Overview;
    this.closeSidePanels();
  }

  enterZoomedMainMenu() {
    this.mainMenu = MainMenu.Zoomed;
    this.closeSidePanels();
  }

  enterBoxContextMainMenu() {
    this.mainMenu = MainMenu.BoxContextMenu;
  }

  enterDeleteColumnMainMenu() {
    this.mainMenu = MainMenu.DeleteColumn;
    this.closeSidePanels();
  }

  enterDeleteRowMainMenu() {
    this.mainMenu = MainMenu.DeleteRow;
    this.closeSidePanels();
  }

  enterSwapMainMenu() {
    this.mainMenu = MainMenu.SwapElements;
    this.closeSidePanels();
  }

  // Side panels
  closeSidePanels() {
    this.sidePanels.forEach(sidePanel => this.fireSidePanelClosed(sidePanel));
    this.sidePanels = [];
  }

  openSidePanel(sidePanelId: SidePanelId) {
    this.sidePanels = [sidePanelId];
    this.fireSidePanelOpened(sidePanelId);
  }

  toggleTextSidePanel() {
    if (this.sidePanels.includes(SidePanelId.Text)) {
      this.closeSidePanels();
    } else {
      this.openSidePanel(SidePanelId.Text);
    }
  }

  toggleColorSidePanel() {
    if (this.sidePanels.includes(SidePanelId.Color)) {
      this.closeSidePanels();
    } else {
      this.openSidePanel(SidePanelId.Color);
    }
  }

  enterSummarySidePanel() {
    this.openSidePanel(SidePanelId.Summary);
  }

  toggleAdditionalBoxesSidePanel() {
    if (this.sidePanels.includes(SidePanelId.AdditionalBoxes)) {
      this.closeSidePanels();
    } else {
      this.enterAdditionalBoxesSidePanel();
    }
  }

  enterAdditionalBoxesSidePanel() {
    this.openSidePanel(SidePanelId.AdditionalBoxes);
  }

  setMenuScreenBoxes(main?: ScreenBox2D, sidePanel?: ScreenBox2D, top?: ScreenBox2D): void {
    if (main) {
      this.mainMenuScreenBox = this.createHorizontalScreenBox(main);
    }
    if (sidePanel) {
      this.sidePanelScreenBox = this.createSidePanelScreenBox(sidePanel);
    }
    if (top) {
      this.topMenuScreenBox = this.createHorizontalScreenBox(top);
    }
  }

  setCanvasLeftOffset(horizontalOffset: number) {
    console.log('offset 2', horizontalOffset);
    this.horizontalOffset = horizontalOffset;
  }

  fireSidePanelClosed(sidePanelId: SidePanelId): void {
    this.sidePanelsHandlers.forEach(handler => handler(false, sidePanelId));
  }

  fireSidePanelOpened(sidePanelId: SidePanelId): void {
    this.sidePanelsHandlers.forEach(handler => handler(true, sidePanelId));
  }

  onSidePanelChanged(handler: SidePanelChangedHandler): () => void {
    this.sidePanelsHandlers.push(handler);
    const index = this.sidePanelsHandlers.length - 1;
    return () => {
      const idx = this.sidePanelsHandlers.findIndex(h => h === handler);
      if (idx >= 0) {
        this.sidePanelsHandlers.splice(index, 1);
      }
    };
  }

  private createSidePanelScreenBox(screenBox: ScreenBox2D): ScreenBox2D {
    return {
      x: screenBox.x - this.horizontalOffset - SIDE_PANEL_HORIZONTAL_GAP,
      width: screenBox.width > 0 ? screenBox.width + SIDE_PANEL_HORIZONTAL_GAP : 0,
      y: screenBox.y,
      height: screenBox.height
    };
  }

  private createHorizontalScreenBox(screenBox: ScreenBox2D): ScreenBox2D {
    return {
      x: screenBox.x - this.horizontalOffset,
      width: screenBox.width,
      y: screenBox.y,
      height: screenBox.height
    };
  }

  setDeviceType(deviceType: DeviceType) {
    this.deviceType = deviceType;
  }
}

export type SidePanelChangedHandler = (isOpened: boolean, sidePanelId: SidePanelId) => void;
export const menu = new ConfiguratorMenuStore();