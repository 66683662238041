import * as React from 'react';
import Button from '../Button/Button';
import Icon, { IconName } from '../Icon/Icon';
import { TopMenuLoader } from '../TopMenuLoader/TopMenuLoader';
import Typography from '../Typography/Typography';

import s from './TopMenuButton.module.scss';

interface IProps {
  onClick: () => void;
  icon: IconName;
  title?: string | JSX.Element;
  className?: string;
  toggle?: boolean;
  active?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

export const TopMenuButton: React.FC<IProps> = ({
  title,
  className,
  icon,
  toggle,
  active,
  disabled,
  loading,
  onClick
}) => {
  if (loading) {
    return <TopMenuLoader />;
  }
  return (
    <Button
      variant="secondary"
      onClick={onClick}
      toggle={toggle}
      className={className}
      active={active}
      disabled={disabled}
    >
      <span className={s.withTitle}>
        <Icon type={icon} size="small" />
        {title && (
          <Typography variant="subtitle2" component="span" className={s.title}>
            {title}
          </Typography>
        )}
      </span>
    </Button>
  );
};
