import { BOX_NAME, BoxScene, DEBUG, SELECTION_ZONE } from './BoxScene';
import ResourceLoader from './ResourceLoader';
import {
  Box3,
  BoxGeometry,
  BufferGeometry,
  Color,
  Line,
  LineBasicMaterial,
  Material,
  Mesh,
  MeshStandardMaterial,
  Object3D,
  PerspectiveCamera,
  ShapeBufferGeometry,
  Vector3
} from 'three';
import { BoxRenderer } from './BoxRenderer';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { DragControls } from '../behaviour/DragControls';

import { BoxViewModel, ScreenBox } from './BoxViewModel';
import { INodeClickEvent, SelectObject3D } from '../behaviour/SelectObject3D';
import { CELL_DEPTH, SHELL_THICKNESS } from '../layout/Layout';
import { DropZone } from '../behaviour/zones';
import { Transition } from './Transition';
import * as utils from './utils';
import { TextRenderer } from './TextRenderer';
import { IConcreteBox, MailBoxEngravingMaterial, MountingType, WallLayout, WallLocation } from '../schema';
import { PositionedLayoutBox } from '../layout/Box';
import { DimensionsRenderer } from './DimensionsRenderer';
import { CameraState, ICameraOptimizer, ScreenBox2D } from '../camera/types';
import { Size } from '../camera/Size';
import { BinarySearchCameraOptimizer } from '../camera/BinarySearchCameraOptimizer';
import { threeUtils } from '../services/ThreeUtils';
import { SelectionZone, ZoneStatus } from '../behaviour/types';
import { RoundedShape } from './RoundedShape';
import { CameraMoveControl } from '../behaviour/CameraMoveControl';
import { rootStore } from '../stores';

export enum Mode {
  View,
  FrontalView,
  Edit,
  Delete,
  Preview,
  Insert
}

function getBoxParams(object: Object3D, cellWidth: number, cellHeight: number) {
  const box: ScreenBox = object.userData as ScreenBox;
  const w = box.positionedBox.box.columns * cellWidth;
  const h = box.positionedBox.box.rows * cellHeight;
  const centerX = object.position.x + 0.5 * w;
  const centerY = object.position.y + 0.5 * h;
  return { box, w, h, centerX, centerY };
}

type DragEvent = (box: PositionedLayoutBox, x: number, y: number) => void;
type DragMoveEvent = (x: number, y: number) => void;

function noop() {
}

const DESKTOP_ZOOM_DISTANCE = 0.8;
const MOBILE_ZOOM_DISTANCE = 1.3;
const MECHANICAL_LOCK_OFFSET = 0.007;
const CHECK_ICON_OFFSET = 0.005;
const SELECTION_ZONE_OFFSET = 0.003;
export const SIDE_PANEL_WIDTH = 400;
export const MOBILE_LEFT_BORDER_SCREEN_BOX = {
  x: -0.5 * SIDE_PANEL_WIDTH,
  width: SIDE_PANEL_WIDTH + 10,
  y: 0,
  height: Infinity
};

export class BoxViewer {
  private boxScene!: BoxScene;
  private width: number;
  private height: number;
  private camera!: PerspectiveCamera;
  private renderer!: BoxRenderer;
  private dimensionsRenderer!: DimensionsRenderer;
  private controls!: OrbitControls;
  private dragControls!: DragControls;
  private selector!: SelectObject3D;
  private selectionBox!: Mesh;
  private transition!: Transition;
  private looped: Boolean = true;
  private showRuler = false;
  private wallLayout: WallLayout;
  private wallLocation: WallLocation;
  private mountingType: MountingType;
  private cellWidth: number;
  private cellHeight: number;
  private offsetHeight: number;

  onBoxSelect: DragEvent = noop;
  onMove: DragMoveEvent = noop;
  onDragStart: DragEvent = noop;
  onDrop: DragEvent = noop;
  onRotate: () => void = noop;
  onZoomIn: () => void = noop;
  onSelectedBoxChanged: (concreteBox: IConcreteBox | undefined) => void = noop;
  onZoomOut: () => void = noop;
  onToggleSelection: (zone: SelectionZone) => void = noop;

  private size: Size;
  private draggableObjects: Object3D[] = [];
  private dropZoneMaterial: MeshStandardMaterial;
  private lookAt = new Vector3(1.5, 1.3, 0);
  private frontalCameraState: number[] = [];
  private previewCameraState: number[] = [1.5, 1, 5.0, 1, 1.3, 0.0];
  private mode: Mode = Mode.Edit;
  private zoomedAtUUID = '';
  private cameraOptimizer: ICameraOptimizer;
  private screenBoxes: ScreenBox2D[] = [];
  getScreenPosition = (x: number, y: number): { x: number; y: number } => {
    return threeUtils.get2DPosition(new Vector3(x, y, 0), this.camera, this.width, this.height);
  };
  private cameraMoveControl: CameraMoveControl;
  private wallWidth = 0;

  get isZoomed(): boolean {
    return this.zoomedAtUUID !== '';
  }

  get cameraCurrentState(): CameraState {
    return this.camera.position.toArray().concat(this.controls.target.toArray());
  }

  constructor(
    private readonly element: HTMLCanvasElement,
    private resourceLoader: ResourceLoader,
    private textRenderer: TextRenderer,
    private includesSidePanel: boolean = false,
    private readonly controlsDisabled: boolean = false
  ) {
    this.width = element.parentElement!.offsetWidth + (this.includesSidePanel ? SIDE_PANEL_WIDTH : 0);
    this.height = element.offsetHeight;
    this.size = {
      width: this.width,
      height: this.height
    };
    this.cameraOptimizer = BinarySearchCameraOptimizer.create(this.size, 4, 100);
    this.dropZoneMaterial = new MeshStandardMaterial({ color: 'yellow', opacity: 0.3, transparent: true });

    this.boxScene = new BoxScene(resourceLoader, textRenderer);
  }

  async init(viewModel: BoxViewModel, isBackgroundRequired = false) {
    this.setLayout(viewModel.layout);
    this.setCellDimensions(viewModel.cellWidth, viewModel.cellHeight);
    this.offsetHeight = viewModel.offsetHeight;
    this.setLocation(viewModel.location);
    this.setMountingType(viewModel.mountingType);
    this.initScene(viewModel.mountingType, this.offsetHeight);
    this.initCamera(viewModel);
    await this.initRenderer(viewModel, isBackgroundRequired);
    this.initControls(viewModel.location);
    this.initTransition();
    this.cameraMoveControl.setTransition(this.transition);
    this.initHandlers();
    this.initBehaviours();
    this.looped && this.startRendering();
  }

  get scene(): BoxScene {
    return this.boxScene;
  }

  dispose() {
    this.renderer.dispose();
    this.looped = false;
  }

  private initScene(mountingType: MountingType, offsetHeight: number) {
    this.boxScene.init(mountingType, offsetHeight);

    this.selectionBox = new Mesh(new BoxGeometry(1, 1, 1), new MeshStandardMaterial({ color: 'red' }));
    this.selectionBox.visible = false;
    this.boxScene.addPermanentObject(this.selectionBox);
  }

  private initCamera(viewModel: BoxViewModel) {
    const { width, height } = this;
    this.camera = new PerspectiveCamera(30, width / height, 0.1, 180);
    (window as any).camera = this.camera;
    this.updateCameraPosition(viewModel.width);
  }

  private updateCameraPosition(width: number) {
    const position = new Vector3(width / 2, 1, 5);
    this.lookAt = new Vector3(width / 2, 1, 0);
    this.frontalCameraState = [...position.toArray(), ...this.lookAt.toArray()];
    this.previewCameraState[0] = width / 2;
    this.previewCameraState[3] = width / 2;
    this.camera.position.copy(position);
    this.camera.lookAt(this.lookAt.clone());
  }

  async changeSceneWidth(width: number) {
    const position = new Vector3(width / 2, 1, 5);
    this.lookAt = new Vector3(width / 2, 1, 0);
    this.frontalCameraState = [...position.toArray(), ...this.lookAt.toArray()];
    const screenBoxes: ScreenBox2D[] = this.mode === Mode.Insert
      ? [MOBILE_LEFT_BORDER_SCREEN_BOX]
      : [];
    // for mobile devices, when adding new column the full configuration should be shown
    await this.transitionTo(this.frontalCameraState, screenBoxes);
  }

  async changeSceneHeight(height: number) {
    const screenBoxes: ScreenBox2D[] = this.mode === Mode.Insert
      ? [MOBILE_LEFT_BORDER_SCREEN_BOX]
      : [];
    // for mobile devices, when adding new row the full configuration should be shown
    await this.transitionTo(this.frontalCameraState, screenBoxes);
  }

  private async initRenderer(viewModel: BoxViewModel, isBackgroundRequired: boolean) {
    const { width, height, element } = this;
    this.renderer = new BoxRenderer();
    const envTexture = await this.renderer.init(width, height, element, this.boxScene.getScene(), this.camera, viewModel.hdr);
    this.boxScene.getScene().environment = envTexture;
    if (isBackgroundRequired) {
      this.boxScene.getScene().background = envTexture;
    }
    this.dimensionsRenderer = new DimensionsRenderer(width, height);
  }

  private initControls(location: WallLocation) {
    this.controls = new OrbitControls(this.camera, this.element);
    this.controls.enabled = false;
    this.controls.enableZoom = false;
    this.controls.enablePan = false;
    this.controls.target = this.lookAt.clone();
    if ([WallLocation.OutsideSheltered].includes(location)) {
      this.controls.minAzimuthAngle = -Math.PI / 2 + Math.PI / 6;
      this.controls.maxAzimuthAngle = Math.PI / 2 - Math.PI / 6;
    } else {
      this.controls.minAzimuthAngle = -Math.PI / 2 + Math.PI / 8;
      this.controls.maxAzimuthAngle = Math.PI / 2 - Math.PI / 8;
    }
    this.controls.addEventListener('start', () => {
      this.onRotate();
    });

    this.dragControls = new DragControls(this.draggableObjects, this.camera, this.element);
    this.cameraMoveControl = new CameraMoveControl(this.camera);
    this.dragControls.deactivate();
    const restorePosition = new Vector3();
    let restoreMaterials = noop;

    this.dragControls.addEventListener('dragstart', (e: any) => {
      const object = e.object;
      restorePosition.copy(object.position);
      pullBox(object);
      const { box, w, h, centerX, centerY } = getBoxParams(object, this.cellWidth, this.cellHeight);

      this.selectionBox.scale.fromArray([w, h, SHELL_THICKNESS]);
      restoreMaterials = makeTransparent(object);
      this.onDragStart(box.positionedBox, centerX, centerY);
    });
    this.dragControls.addEventListener('drag', (e: any) => {
      const object: Object3D = e.object;
      pullBox(object);
      const { centerX, centerY } = getBoxParams(object, this.cellWidth, this.cellHeight);
      if (DEBUG) {
        this.selectionBox.visible = true;
      }
      this.selectionBox.position.fromArray([centerX, centerY, 0.0]);
      this.onMove(centerX, centerY);
    });
    this.dragControls.addEventListener('dragend', (e: any) => {
      const object: Object3D = e.object;
      this.selectionBox.visible = false;
      object.position.copy(restorePosition);
      restoreMaterials();
      const { box, centerX, centerY } = getBoxParams(object, this.cellWidth, this.cellHeight);
      this.onDrop(box.positionedBox, centerX, centerY);
    });

    this.controls.zoomSpeed = 1;
    this.controls.update();
  }

  private initHandlers() {
    window.addEventListener('resize', this.resizeHandler);
  }

  private resizeHandler = () => {
    const parent = this.element.parentElement;
    if (parent) {
      this.height = parent.clientHeight;

      this.width = parent.clientWidth + (this.includesSidePanel ? SIDE_PANEL_WIDTH : 0);
      this.size.width = this.width;
      this.size.height = this.height;

      this.renderer.setSize(this.width, this.height);
      this.dimensionsRenderer.setSize(this.width, this.height);
      this.camera.aspect = this.width / this.height;
      this.camera.updateProjectionMatrix();
      !this.looped && this.startRendering();
    }
  };

  async render(viewModel: BoxViewModel, isInteriorRequired = true) {
    console.log('rendering');
    const { material, dimensions, engravingMaterial } = viewModel;
    this.wallWidth = viewModel.dimensions.width / 1000;
    await this.boxScene.buildScene(viewModel, isInteriorRequired);
    this.updateRuler(dimensions.width, dimensions.height);
    while (this.draggableObjects.length) {
      this.draggableObjects.pop();
    }
    const draggableObjects = this.boxScene.getRootNode().children.filter(obj => obj.name === BOX_NAME);
    this.draggableObjects.push(...draggableObjects);
    this.colorBoxes(material.color, material.color, engravingMaterial);
    !this.looped && this.startRendering();
  }

  colorBoxes(mainColor: string, shellColor: string, engravingColor: MailBoxEngravingMaterial | undefined) {
    this.boxScene.changeMainColor(new Color(mainColor));
    this.boxScene.changeShellColor(new Color(shellColor));
    if (engravingColor) {
      this.boxScene.changeEngravingColor(engravingColor);
    }
  }

  startRendering() {
    requestAnimationFrame(() => {
      this.controls?.update();
      this.transition.syncValue(this.camera.position.toArray().concat(this.controls.target.toArray()));
      this.renderer.render();
      this.dimensionsRenderer.render(this.boxScene.getDimensionsScene(), this.camera);
      this.looped && this.startRendering();
    });
  }

  private initBehaviours() {
    this.selector = new SelectObject3D(this.element, this.camera, this.boxScene.getSceneRoot(), true);
    if (this.controlsDisabled) {
      //remove EventListeners to allow onClick actions on touch devices.
      this.selector.deactivate();
    }
    this.selector.addNodeClickEventHandler(SELECTION_ZONE, (node, event) => {
      if (!node) {
        return;
      }
      event.stopPropagation();

      const zone = node.userData as SelectionZone;
      if (zone.disabled) {
        return;
      }

      this.toggleSelection(zone);
    });
    this.selector.addNodeClickEventHandler(BOX_NAME, (node, event) => this.handleBoxClick(node, event));
    this.selector.addNodeClickEventHandler('', async (node, event) => {
      event.stopPropagation();
      if (this.isZoomed) {
        await this.zoomOut();
      }
    });
  }

  private async handleBoxClick(node: Object3D | null, event: INodeClickEvent) {
    if (!node || this.mode !== Mode.View) {
      return;
    }
    const bbox = new Box3();
    bbox.setFromObject(node);
    const center = new Vector3();
    const size = new Vector3();
    bbox.getSize(size);
    bbox.getCenter(center);
    center.z += 0.5 * size.z;
    const isZoomIn = this.zoomedAtUUID !== node.uuid;
    event.stopPropagation();

    let cameraState: number[] = [];

    if (isZoomIn) {
      const data = node.userData as ScreenBox;
      this.onSelectedBoxChanged(data.positionedBox);
      this.onZoomIn();
      this.zoomedAtUUID = node.uuid;
      const zoomPoint = center.clone();
      zoomPoint.z += rootStore.isDesktop ? DESKTOP_ZOOM_DISTANCE : MOBILE_ZOOM_DISTANCE;
      cameraState = zoomPoint.toArray().concat(center.toArray());
      this.controls.enabled = false;
      await this.transition.transitionTo(cameraState);
      this.enableControls();
    } else {
      await this.zoomOut();
    }
  }

  async doZoomOut() {
    this.zoomedAtUUID = '';
    this.onSelectedBoxChanged(undefined);
    this.controls.enabled = false;
    await this.transitionTo(this.frontalCameraState);
    this.enableControls();
  }

  async zoomOut() {
    this.onZoomOut();
    await delay(1); // wait until onZoomOut updates screenBoxes
    if (this.isZoomed) {
      await this.doZoomOut();
    }
  }

  private async transitionTo(desiredState: number[], screenBoxes: ScreenBox2D[] = []) {
    const targetState = this.cameraOptimizer.optimizeCameraPosition(
      this.camera,
      desiredState,
      this.scene.getRootNode(),
      this.screenBoxes.concat(screenBoxes)
    );
    await this.transition.transitionTo(targetState);
  }

  toggleSelection(zone: SelectionZone) {
    this.onToggleSelection(zone);
  }

  renderDropZones(dropZones: DropZone[]) {
    utils.removeNodeChildren(this.boxScene.getDropZoneNode());
    const z = this.boxScene.getDropZoneNode().position.z + MECHANICAL_LOCK_OFFSET;
    const boxes = dropZones.map(zone => this.createZoneBox(zone, z));
    this.boxScene.getDropZoneNode().children = boxes;
  }

  renderSelectionZones(zones: SelectionZone[]) {
    utils.removeNodeChildren(this.boxScene.getSelectionZonesNode());
    const z = this.boxScene.getSelectionZonesNode().position.z;
    const boxes = zones.map(zone => this.createSelectionZoneBox(zone, z));
    this.boxScene.getSelectionZonesNode().children = boxes;
  }

  private createSelectionZoneBox(zone: SelectionZone, zOffset: number): Mesh | Line {
    const HIGHLIGHT_PADDING = 0.01;
    const material = new MeshStandardMaterial({
      color: !zone.valid ? 'red' : zone.selected ? '#EE8665' : 'yellow',
      opacity: 0.3,
      transparent: true
    });

    const shape = new RoundedShape(zone.width, zone.height);
    const geometry = new ShapeBufferGeometry(shape);
    const mesh = new Mesh(geometry, material);

    if (zone.selected) {
      const highlightShape = new RoundedShape(zone.width + HIGHLIGHT_PADDING * 2, zone.height + HIGHLIGHT_PADDING * 2);
      const lineMaterial = new LineBasicMaterial({ color: '#EE8665', linewidth: 0.1 });
      const lineGeometry = new BufferGeometry().setFromPoints(highlightShape.getPoints());
      const highlight = new Line(lineGeometry, lineMaterial);

      highlight.position.x = zone.centerX;
      highlight.position.y = zone.centerY;
      highlight.position.z = zOffset;

      // todo: that's a hack, but linewidth is not working for me
      const highlight2 = highlight.clone();
      highlight2.scale.set(1.001, 1.001, 1);

      const highlight3 = highlight.clone();
      highlight3.scale.set(1.002, 1.002, 1);

      const checkIcon = this.boxScene.getIconSelected().clone();
      const bbox = new Box3();
      const center = new Vector3();

      bbox.setFromObject(checkIcon);
      bbox.getCenter(center);
      checkIcon.position.x += zone.centerX;
      checkIcon.position.y += zone.centerY;
      checkIcon.position.setZ(zOffset + CHECK_ICON_OFFSET);
      checkIcon.updateMatrix();

      mesh.children = [highlight, highlight2, highlight3, checkIcon];
    }

    mesh.position.x = zone.centerX;
    mesh.position.y = zone.centerY;
    mesh.position.z = zOffset + SELECTION_ZONE_OFFSET;
    mesh.userData = zone;
    mesh.name = SELECTION_ZONE;

    return mesh;
  }

  private createZoneBox(zone: DropZone, zOffset: number): Mesh {
    const boxGeometry = new BoxGeometry(zone.width, zone.height, 0.001);
    const mesh = new Mesh(boxGeometry, this.dropZoneMaterial);
    this.dropZoneMaterial.color = new Color(zone.status === ZoneStatus.Invalid ? 'red' : 'yellow');
    mesh.position.x = zone.centerX;
    mesh.position.y = zone.centerY;
    mesh.position.z = zOffset;
    return mesh;
  }

  async changeScreenBoxes(screenBoxes: ScreenBox2D[]) {
    this.screenBoxes = screenBoxes;
    if (this.screenBoxes.length) {
      if (this.mode === Mode.View) {
        await this.transitionTo(this.camera.position.toArray().concat(this.lookAt.toArray()));
      } else {
        await this.transitionTo(this.frontalCameraState);
      }
    }
  }

  async setFrontalView() {
    if (this.frontalCameraState.length && this.mode !== Mode.Insert) {
      this.looped = true;
      await this.transitionTo(this.frontalCameraState);
    }
  }

  async setViewMode() {
    if (this.frontalCameraState.length) {
      this.mode = Mode.View;
      this.looped = true;
      this.dragControls.deactivate();
      this.cameraMoveControl.deactivate();
    }
    await this.transitionTo([...this.camera.position.toArray(), ...this.lookAt.toArray()]);
    if (this.mode === Mode.View) {
      this.enableControls();
    }
  }

  async setFrontalViewMode() {
    if (this.frontalCameraState.length) {
      this.mode = Mode.FrontalView;
      this.looped = true;
      this.dragControls.deactivate();
      this.cameraMoveControl.deactivate();
      this.controls.enabled = false;
      await this.transitionTo(this.frontalCameraState);
    }
  }

  async setEditMode() {
    if (this.frontalCameraState.length) {
      this.mode = Mode.Edit;
      this.looped = true;
      this.controls.enabled = false;
      this.cameraMoveControl.deactivate();
      await this.transitionTo(this.frontalCameraState);
      if (this.mode === Mode.Edit) {
        this.dragControls.activate();
      }
    }
  }

  async setInsertMode() {
    if (this.frontalCameraState.length) {
      this.mode = Mode.Insert;
      this.looped = true;
      this.dragControls.deactivate();
      this.controls.enabled = false;
      // TODO: Change zoom

      const state = this.frontalCameraState.slice();
      state[2] = 4.5;
      await this.transition.transitionTo(state);
      if (this.mode === Mode.Insert) {
        const width = this.wallWidth * 0.8;
        this.cameraMoveControl.setBoundary(
          {
            x: {
              min: -this.cellWidth * 1.6,
              max: width + this.cellWidth * 1.6
            },
            y: {
              min: 0.5,
              max: 1.5
            }
          }
        );
        this.dragControls.deactivate();
        this.controls.enabled = false;
        this.looped = true;
        this.cameraMoveControl.activate();
      }
    }
  }

  async setDeleteMode() {
    if (this.frontalCameraState.length) {
      this.mode = Mode.Delete;
      this.looped = true;
      this.controls.enabled = false;
      this.cameraMoveControl.deactivate();

      await this.transitionTo(this.frontalCameraState);
      if (this.mode === Mode.Delete) {
        this.dragControls.deactivate();
      }
    }
  }

  async renderPreview(viewModel: BoxViewModel) {
    this.mode = Mode.Preview;
    this.looped = false;
    this.controls.enabled = false;
    this.camera.position.fromArray(this.previewCameraState.slice(0, 3));
    const lookAt = new Vector3().fromArray(this.previewCameraState.slice(3, 6));
    this.camera.lookAt(lookAt);
/*    if (!rootStore.isDesktop) {
      this.boxScene.hideBackground();
    }*/
    await this.render(viewModel, false);
  }

  private setLayout(layout: WallLayout) {
    this.wallLayout = layout;
  }

  private setCellDimensions(cellWidth: number, cellHeight: number) {
    this.cellWidth = cellWidth;
    this.cellHeight = cellHeight;
  }

  private setLocation(location: WallLocation) {
    this.wallLocation = location;
  }

  private setMountingType(mountingType: MountingType) {
    this.mountingType = mountingType;
  }

  getMountingType(): MountingType {
    return this.mountingType;
  }

  showDimensions(width: number, height: number) {
    this.showRuler = true;
    this.dimensionsRenderer.buildRuler(this.boxScene, this.wallLayout, this.mountingType, this.showRuler, width, height);
  }

  hideDimensions() {
    this.showRuler = false;
    this.dimensionsRenderer.clearScene(this.boxScene);
  }

  private initTransition() {
    this.transition = new Transition(this.camera.position.toArray().concat(this.controls.target.toArray()), 700);
    this.transition.onChange(([x, y, z, lookX, lookY, lookZ]) => {
      this.camera.position.x = x;
      this.camera.position.y = y;
      this.camera.position.z = z;
      this.camera.lookAt(lookX, lookY, lookZ);
      this.controls.target.set(lookX, lookY, lookZ);
      this.controls.update();
    });
  }

  private enableControls() {
    this.controls.enabled = true;
    if (this.isZoomed) {
      this.controls.minPolarAngle = Math.PI / 8;
      this.controls.maxPolarAngle = Math.PI - Math.PI / 8;
    } else {
      this.controls.minPolarAngle = Math.PI / 2 - Math.PI / 8;
      this.controls.maxPolarAngle = Math.PI / 2 + Math.PI / (this.wallLocation === WallLocation.OutsideUnsheltered ? 24 : 16);
    }
  }

  private updateRuler(width: number, height: number) {
    if (this.showRuler) {
      this.showDimensions(width, height);
    }
  }
}

function makeTransparent(object: Object3D): () => void {
  const restoreMaterials = new Map<string, Material>();
  object.traverse((obj: Object3D) => {
    if (obj instanceof Mesh) {
      if (obj.material instanceof MeshStandardMaterial) {
        restoreMaterials.set(obj.uuid, obj.material);
        const material = new MeshStandardMaterial({ opacity: 0.8, transparent: true });
        material.color = obj.material.color;
        obj.material = material;
      }
    }
  });

  return () => {
    object.traverse((obj: Object3D) => {
      if (obj instanceof Mesh) {
        if (obj.material instanceof MeshStandardMaterial) {
          const material = restoreMaterials.get(obj.uuid);
          if (material) {
            obj.material = material;
          }
        }
      }
    });
  };
}

async function delay(time = 0) {
  return new Promise(resolve => setTimeout(resolve, time));
}

function pullBox(object: Object3D) {
  object.position.z = CELL_DEPTH * 1.2;
}
