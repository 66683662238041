import MountingTypeForm from '../../../../components/MountingTypeForm/MountingTypeForm';
import { MountingType } from '../../../../schema';
import * as React from 'react';
import { IWizardStepProps } from '../../WizardStepper';
import { observer } from 'mobx-react';

const WizardMountingTypeSelector: React.VFC<IWizardStepProps> = observer(({ store, onChange, initialIsTouched, index }) => {
  const [touched, setTouched] = React.useState(initialIsTouched);

  const handleOnCompleted = React.useCallback(() => {
    onChange(index);
  }, [index, onChange]);

  const handleOnChange = React.useCallback(() => {
    setTouched(true);
    handleOnCompleted();
  }, [setTouched, handleOnCompleted]);

  return (
    <div>
      {store.availableMountingTypes.length &&
        <MountingTypeForm
          selectedMountingType={touched ? store.model.mountingType : undefined}
          availableMountingTypes={store.availableMountingTypes as MountingType[]}
          wizardStore={store}
          onSelect={handleOnChange}
        />
      }
    </div>
  );
});

export default WizardMountingTypeSelector;
