import { ModelMutator, MutatorContext, ValidationResult } from '@canvas-logic/engine/dist/engine';
import { PModel, TypeReference } from '@canvas-logic/engine';
import { IWall } from '../schema';
import { IPostProcessor } from '../postprocessors/IPostProcessor';
import { LayoutRulesManager } from '../rules/LayoutRulesManager';
import { LayoutFactory } from '../layout/LayoutFactory';
import { Layout } from '../layout/Layout';
import { DomainRulesManager } from '../rules/DomainRulesManager';
import { StandaloneLayout } from "../layout/StandaloneLayout";

export type WallAction = (wall: IWall, layout: Layout) => void;
export const emptyAction = () => {
};

export class BaseMutator implements ModelMutator {
  public layout: Layout;

  constructor(
    private readonly postProcessor: IPostProcessor,
    private readonly layoutRulesManager: LayoutRulesManager,
    private readonly domainRulesManager: DomainRulesManager,
    private readonly action: WallAction = emptyAction,
    private readonly previousModel: IWall
  ) {
  }

  mutateWall(context: MutatorContext, typeRef: TypeReference, model: IWall): void {
    this.layout = LayoutFactory.create(model);
    this.action(model, this.layout);
  }

  mutate(context: MutatorContext, typeRef: TypeReference, model: PModel<IWall>): [PModel, ValidationResult] {
    try {
      this.mutateWall(context, typeRef, model);

      // todo: move to rules somehow
      if (this.layout.columns < 1) {
        return [model, ValidationResult.Error('Configurations should have at least 1 column')];
      }

      if (this.layout instanceof StandaloneLayout && this.layout.columns < 2) {
        return [model, ValidationResult.Error('Standalone configurations should have at least 2 columns')];
      }

      this.postProcessor.process(model);
      this.layoutRulesManager.updateLayout(this.layout);
      let rulesError = this.layoutRulesManager.firstError();
      if (rulesError) {
        return [model, ValidationResult.Error(rulesError)];
      }

      const domainRulesResult = this.domainRulesManager.validate(model, this.previousModel);
      if (domainRulesResult.isInvalid) {
        return [model, ValidationResult.Error(domainRulesResult.errorMessage)];
      }
    } catch (e: any) {
      const message = e.message ?? e;
      return [model, ValidationResult.Error(message)];
    }
    return [model, ValidationResult.Valid()];
  }
}