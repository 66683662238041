import { SwapZone } from './swap/zones';
import { InstallationZone } from './installation/zones';
import { NewColumnZone } from './newColumn/zones';
import { NeighbourZone } from './neighbour/zones';
import { Zone, ZoneDistance } from './types';
import { NewRowZone } from './newRow/zones';
import { Point } from '../layout/Point';
import { belongsTo } from '../services/utils';


export type DropZone = NeighbourZone | SwapZone | InstallationZone | NewColumnZone | NewRowZone;

export type DragZone = Zone;

export function distanceToZone(zone: Zone, point: Point): ZoneDistance {
  const { width, height } = zone;
  const left = zone.centerX - 0.5 * width;
  const right = zone.centerX + 0.5 * width;
  const top = zone.centerY - 0.5 * height;
  const bottom = zone.centerY + 0.5 * height;
  const isPointInside = belongsTo(point.centerX, left, right) && belongsTo(point.centerY, top, bottom);
  if (isPointInside) {
    return { outer: 0, inner: innerDistance(zone, point)};
  }

  return { outer: outerDistance(left, top, bottom, right, point), inner: Infinity};
}
export function distanceLess(a: ZoneDistance, b: ZoneDistance): boolean {
  if (a.outer < b.outer) {
    return true;
  }
  if (a.outer > b.outer) {
    return false;
  }
  return a.inner < b.inner;
}


function outerDistance(left: number, top: number, bottom: number, right: number, point: Point) {
  const anglePoints = [
    [left, top],
    [left, bottom],
    [right, top],
    [right, bottom]
  ];
  let distance = Math.min(...anglePoints
    .map(([x, y]) => Math.sqrt((point.centerX - x) ** 2 + (point.centerY - y) ** 2))
  );

  if (belongsTo(point.centerX, left, right)) {
    distance = Math.min(
      distance,
      Math.abs(point.centerY - top),
      Math.abs(point.centerY - bottom)
    );
  }
  if (belongsTo(point.centerY, top, bottom)) {
    distance = Math.min(
      distance,
      Math.abs(point.centerX - left),
      Math.abs(point.centerX - right)
    );
  }
  return distance;
}

function innerDistance(zone: Zone, point: Point): number {
  const dx = zone.centerX - point.centerX;
  const dy = zone.centerY - point.centerY;
  return Math.sqrt(dx * dx + dy * dy);
}
