import classNames from 'classnames';
import * as React from 'react';
import Icon, { IconName } from '../Icon/Icon';
import Typography, { TypographyVariantName } from '../Typography/Typography';
import styles from './TextFieldLabel.module.scss';

interface IProps {
  labelVariant?: TypographyVariantName;
  icon?: IconName;
  iconAlign?: 'center' | 'start';
  label: string | JSX.Element;
  description?: string | JSX.Element;
}

export const TextFieldLabel: React.FC<IProps> = ({ icon, iconAlign = 'center', label, description, labelVariant }) => {
  return (
    <div className={styles.textFieldLabel}>
      {icon && <Icon type={icon} className={classNames(styles.icon, { [styles.iconStart]: iconAlign === 'start' })} size="small" />}
      <div className={classNames(styles.labels, { [styles.labelsIconPlaceholder]: !icon })}>
        <Typography component="p" variant={labelVariant ? labelVariant : 'subtitle1'}>
          {label}
        </Typography>
        {description && (
          <p className={styles.description}>
            <Typography variant="caption" component="span">{description}</Typography>
          </p>
        )}
      </div>
    </div>
  );
};
