import { WallBox } from '../../schema';
import { Layout } from '../../layout/Layout';
import { DropZoneBase } from '../types';
import { NewColumnType } from "./behaviour";
import { ZFormLayout } from "../../layout/ZFormLayout";


export interface NewColumnData {
  type: 'newColumn';
  column: NewColumnType;
}

export interface NewColumnZone extends DropZoneBase {
  data: NewColumnData;
}

export function getNewColumnsDropZones(box: WallBox, layout: Layout): NewColumnZone[] {
  const width = box.columns * layout.cellWidth;

  if (layout instanceof ZFormLayout) {
    let {
          centerX: leftX,
          centerY: leftY,
        } = layout.toWorldCoordinates(layout.MAX_ROWS - layout.height, layout.columns, box.columns, layout.constraint.h3);
    let {
          centerX: rightX,
          centerY: rightY
        } = layout.toWorldCoordinates(layout.MAX_ROWS - layout.constraint.h1, -box.columns, box.columns, layout.constraint.h1);

    return [
      {
        width,
        height: layout.constraint.h3 * layout.cellHeight,
        centerX: rightX,
        centerY: rightY,
        data: {
          type: 'newColumn',
          column: 'First'
        }
      },
      {
        width,
        height: layout.constraint.h3 * layout.cellHeight,
        centerX: leftX,
        centerY: leftY,
        data: {
          type: 'newColumn',
          column: 'Last'
        }
      }
    ];
  } else {
    let {
          centerX: leftX,
          centerY
        } = layout.toWorldCoordinates(layout.rows - layout.height, layout.columns, box.columns, layout.height);
    let {
          centerX: rightX
        } = layout.toWorldCoordinates(layout.rows - layout.height, -box.columns, box.columns, layout.height);

    const height = layout.height * layout.cellHeight;

    return [
      {
        width,
        height,
        centerX: rightX,
        centerY: centerY,
        data: {
          type: 'newColumn',
          column: 'First'
        }
      },
      {
        width,
        height,
        centerX: leftX,
        centerY: centerY,
        data: {
          type: 'newColumn',
          column: 'Last'
        }
      }
    ];
  }
}

