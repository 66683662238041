import { BigNumber } from '@canvas-logic/engine';
import { toJS } from 'mobx';
import { EntityFinder } from './EntityFinder';
import { IWall } from '../schema';
import { CostService } from './CostService';
import { isToggleableTypeGuard } from '../guards';
import { isDigital, isMechanical } from './utils';


export interface IProductionBOMLine {
  type: 'Item';
  no: string;
  quantityPer: number;
  unitOfMeasureCode: 'STUK';
}

export class BomService {
  totalPrice: BigNumber | null;
  lines: IProductionBOMLine[] = [];

  constructor(
    private readonly finder: EntityFinder,
    private readonly model: IWall
  ) {
    const items = this.finder.byComponents(toJS(model), ['Cost']).map(e => e.entity);
    this.totalPrice = BigNumber.zero();
    let byArticle = new Map<string, IProductionBOMLine>();
    for (let item of items) {
      if (isToggleableTypeGuard(item) && item.toggle && !item.included) {
          continue;
      }
      let itemPrice = CostService.itemPrice(item, this.model);
      if (itemPrice && this.totalPrice) {
        this.totalPrice = this.totalPrice.plus(itemPrice);
      } else {
        this.totalPrice = null;
      }
      if (!item.article) {
        continue;
      }
      let line = byArticle.get(item.article);
      if (!line) {
        line = {
          no: item.article,
          quantityPer: item.amount,
          unitOfMeasureCode: 'STUK',
          type: 'Item'
        };
        byArticle.set(item.article, line);
      } else {
        line.quantityPer += item.amount;
      }
    }

    this.lines = Array.from(byArticle.values());
    this.addLakbalk();
  }

  private addLakbalk(): void {
    if (isDigital(this.model.wallType) || isMechanical(this.model.wallType)) {
      this.lines.push({
        no: 'LAKBALK',
        quantityPer: 2,
        unitOfMeasureCode: 'STUK',
        type: 'Item'
      });
    }
  }
}