import { BoxType, IWall } from '../schema';
import { DomainValidationResult } from './DomainValidationResult';
import { count } from '../services/utils';

export const cantAddCentralUnits = (wall: IWall, prev: IWall): DomainValidationResult => {
  const originalCUCount = count(prev.boxes, box => box.box.type === BoxType.centralbox);
  const newCUCount = count(wall.boxes, box => box.box.type === BoxType.centralbox);

  if (originalCUCount < newCUCount) {
    return DomainValidationResult.Error('Adding of central units is not allowed');
  }

  return DomainValidationResult.Valid();
};

export const cantDeleteCentralUnits = (wall: IWall, prev: IWall): DomainValidationResult => {
  const originalCUCount = count(prev.boxes, box => box.box.type === BoxType.centralbox);
  const newCUCount = count(wall.boxes, box => box.box.type === BoxType.centralbox);

  if (originalCUCount > newCUCount) {
    return DomainValidationResult.Error('Removal of central units is not allowed');
  }

  return DomainValidationResult.Valid();
};

export const cantDeleteMailboxUnits = (wall: IWall, prev: IWall): DomainValidationResult => {
  const originalMailboxCount = count(prev.boxes, box => box.box.type === BoxType.mailbox);
  const newMailboxCount = count(wall.boxes, box => box.box.type === BoxType.mailbox);

  if (originalMailboxCount > newMailboxCount) {
    return DomainValidationResult.Error('Removal of mailbox units is not allowed');
  }

  return DomainValidationResult.Valid();
}
