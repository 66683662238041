import { Form } from '../Form/Form';
import { rootStore } from '../../stores';
import classNames from 'classnames';
import s from './SummarySidePanel.module.scss';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import React from 'react';
import { FormattedMessage } from 'react-intl';


interface IProps {
  onClick(): void;
}

export const DownloadPart: React.FC<IProps> = ({onClick}) => {
  return (<Form.Control labelDisplay={'block'}>
    <Form.Section title={rootStore.localization.formatMessage('menu.summary.downloads')}>
          <div className={classNames(s.linkLine)}>
            <Button
              className={s.downloadLink}
              disabled={true}
              href="#"
              onClick={e => {
                e.preventDefault();
                onClick();
              }}
              variant={'secondary'}
            >
              <span className={classNames(s.downloadItem)}>
                <FormattedMessage id="menu.summary.specificationPdf"/>
                <Icon type="pdfFile" size="small" />
              </span>
            </Button>
          </div>
    </Form.Section>
  </Form.Control>)
}