import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

import BottomSheet from '../BottomSheet/BottomSheet';
import Toggle from '../Toggle/Toggle';
import { ConfiguratorStore, TEXT_PLACEHOLDER } from '../../stores/ConfiguratorStore';
import { ConfiguratorMenuStore } from '../../stores/ConfiguratorMenuStore';
import { Form } from '../Form/Form';
import { ILightBox, MailBoxEngravingMaterial, TextAlignment, WallType } from '../../schema';
import Switch from '../Switch/Switch';
import Typography from '../Typography/Typography';
import { DebouncedTextField } from '../TextField/DebouncedTextField';

import { WallTypeGuard } from '../WallTypeGuard/WallTypeGuard';
import ColourSelector from '../ColourSelector/ColourSelector';
import { rootStore } from '../../stores';

import styles from './TextSidePanel.module.scss';


interface IProps {
  store: ConfiguratorStore;
  menu: ConfiguratorMenuStore;
}


export const TextSidePanel: React.FC<IProps> = observer(({ store, menu }) => {
  // const [personalizationTechnic, setPersonalizationTechnic] = useState(
  //   store.hasNamePVCPlate ? PersonalizationTechnic.PVC : PersonalizationTechnic.Engraving
  // );

  const hasAnyNameEngraving = store.hasNameEngraving || store.hasNamePVCPlate;
  const hasLightboxSection = store.lightBoxes.length > 0 || store.hasRoof;

  const handleChangeNamePersonalization = (enabled: boolean) => {
    if ([WallType.Interna, WallType.Boxis].includes(store.model.wallType)) {
      store.changeNameEngraving(enabled);
      // store.changeNamePersonalizationTechnic(enabled ? personalizationTechnic : undefined);
      // setPersonalizationTechnic(PersonalizationTechnic.Engraving);
    } else {
      store.changeNameEngraving(enabled);
    }
  };

  return (
    <BottomSheet title={rootStore.localization.formatMessage('personalization.title')} onClose={menu.closeSidePanels}>
      <BottomSheet.Content>
        <Form.Container>
          {hasLightboxSection && (
            <Form.Section title={store.hasRoof ? '' : <FormattedMessage id="menu.summary.lightbox" />}>
              {store.model.roof.top.map((roofModule, index) => (
                <>
                  <Form.Control
                    label={<FormattedMessage id="boxes.roofModule.number" values={{ number: index + 1 }} />}
                    labelComponent="h2"
                  >
                    <Toggle checked={roofModule.hasText} onChange={() => store.toggleRoofText(index)} />
                  </Form.Control>

                  <div className={styles.textControl}>
                    <Form.Control labelDisplay="block">
                      <DebouncedTextField
                        initialValue={roofModule.text}
                        onChange={value => store.changeRoofText(index, value)}
                        placeholder={TEXT_PLACEHOLDER}
                        disabled={!roofModule.hasText}
                      />
                    </Form.Control>

                    <Form.Control label={rootStore.localization.formatMessage('personalization.align')}>
                      <Switch
                        name={`rooftop-${index}`}
                        options={[
                          { value: TextAlignment.Left, icon: 'alignLeft' },
                          { value: TextAlignment.Central, icon: 'alignCentral' },
                          { value: TextAlignment.Right, icon: 'alignRight' }
                        ]}
                        selected={roofModule.hasText ? roofModule.textAlignment : undefined}
                        onSelect={alignment => store.changeRoofTextAlignment(index, alignment as TextAlignment)}
                        disabled={!roofModule.hasText}
                      />
                    </Form.Control>
                  </div>
                </>
              ))}
              {store.lightBoxes.map((lightbox, index) => (
                <>
                  <div className={styles.textControl}>
                    <Form.Control
                      label={<FormattedMessage id="boxes.lightbox.number" values={{ number: index + 1 }} />}
                      labelDisplay="block"
                    >
                      <DebouncedTextField
                        initialValue={(lightbox.box as ILightBox).text}
                        onChange={value => store.changeLightboxText(lightbox, value)}
                        placeholder={TEXT_PLACEHOLDER}
                      />
                    </Form.Control>

                    <Form.Control label={rootStore.localization.formatMessage('personalization.align')}>
                      <Switch
                        name={`lightbox-${index}`}
                        options={[
                          { value: TextAlignment.Left, icon: 'alignLeft' },
                          { value: TextAlignment.Central, icon: 'alignCentral' },
                          { value: TextAlignment.Right, icon: 'alignRight' }
                        ]}
                        selected={(lightbox.box as ILightBox).textAlignment}
                        onSelect={alignment => store.changeLightBoxAlignment(lightbox, alignment as TextAlignment)}
                      />
                    </Form.Control>
                  </div>
                </>
              ))}
            </Form.Section>
          )}

          <Form.Section title={rootStore.localization.formatMessage('menu.options.mailboxes')}>
            <Form.Control label={rootStore.localization.formatMessage('personalization.apartNumber')}>
              <Toggle checked={store.hasApartmentEngraving} onChange={store.changeApartmentEngraving} />
            </Form.Control>

            <Form.Control label={rootStore.localization.formatMessage('personalization.namePlate')}>
              <Toggle checked={hasAnyNameEngraving} onChange={handleChangeNamePersonalization} />
            </Form.Control>


            <Typography variant="subtitle2" className={styles.caption}>
              *<FormattedMessage id="personalization.notice" />
            </Typography>
          </Form.Section>

          <WallTypeGuard wallType={store.model.wallType} acceptedTypes={[WallType.Boxis, WallType.Interna]}>
            {store.hasEngravingColor && (
              <Form.Section title={rootStore.localization.formatMessage('personalization.colorEngravings')}>
                <ColourSelector
                  name="engraving-color"
                  items={[
                    {
                      value: MailBoxEngravingMaterial.White,
                      label: rootStore.localization.formatMessage('content.options.engravings.White'),
                      textColor: 'black',
                      background: 'white',
                      border: 'rgba(69, 85, 96, 0.24)'
                    },
                    {
                      value: MailBoxEngravingMaterial.Black,
                      label: rootStore.localization.formatMessage('content.options.engravings.Black'),
                      textColor: 'white',
                      background: 'black'
                    },
                    {
                      value: MailBoxEngravingMaterial.Aluminum,
                      label: rootStore.localization.formatMessage('content.options.engravings.Aluminum'),
                      textColor: 'black',
                      background:'linear-gradient(50deg, hsl(0,0%,78%) 0%, hsl(0,0%,90%) 47%, hsl(0,0%,78%) 53%, hsl(0,0%,70%)100%)'
                    }
                  ]}
                  selected={store.mailboxEngravingMaterial}
                  onSelect={colour => store.changeMailboxEngravingMaterial(colour as MailBoxEngravingMaterial)}
                  orientation="vertical"
                />
              </Form.Section>
            )}
          </WallTypeGuard>
          {/*)}*/}
        </Form.Container>
      </BottomSheet.Content>
    </BottomSheet>
  );
});
