import { FormattedMessage } from 'react-intl';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import { MainMenu, RemoveMode } from '../../stores/ConfiguratorMenuStore';
import Switch, { ISwitchOption } from '../Switch/Switch';
import styles from './DeleteRowOrColumnMenu.module.scss';


interface IProps {
  disabled?: boolean;
  onHintClick?: () => void;
  onDeleteClick: () => void;
  onClose: () => void;
  activeRemovalMode: RemoveMode;
  onChangeActiveRemovalMode: (newRemoveMode: RemoveMode) => void;
}

const removeModeSwitchOptions: ISwitchOption<RemoveMode>[] = [{
  icon: 'selectRows',
  value: MainMenu.DeleteRow
}, {
  icon: 'selectColumns',
  value: MainMenu.DeleteColumn
}];

export const DeleteRowOrColumnMenu: React.FC<IProps> = (props: IProps) => {
  const onRemoveModeSelect = (newRemoveMode: RemoveMode): void => {
    props.onChangeActiveRemovalMode(newRemoveMode);
  };

  return (
    <div className={styles.root}>
      <Switch
        name="removeMenu-removalMode"
        options={removeModeSwitchOptions}
        selected={props.activeRemovalMode}
        onSelect={onRemoveModeSelect}
      />

      <Button variant="secondary" disabled={props.disabled} onClick={props.onDeleteClick}>
        <span className={styles.buttonLabel}>
          <Icon type="remove" size="small" />
          <span className={styles.mobileLabelText}>
            <FormattedMessage id="boxes.remove.button.1" />
          </span>
          <span className={styles.screenLabelText}>
            <FormattedMessage id="boxes.remove.button.2" />
          </span>
        </span>
      </Button>
      
      <span className={styles.separator} />
      <Button  className={styles.hideOnMobile} variant="secondary" onClick={props.onHintClick}>
        <Icon type="hint" size="small" />
      </Button>
      <Button variant="secondary" onClick={props.onClose}>
        <Icon type="close" size="small" />
      </Button>
    </div>
  );
};