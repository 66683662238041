import {
  BoxType,
  ICentralBox,
  ILightBox,
  IMailBox,
  IParcelBox,
  IRectangleLayoutConstraint,
  IStandaloneConstraint,
  IZFormLayoutConstraint,
  LayoutOptions,
  WallBox
} from './schema';

export function mailBoxTypeGuard(box: WallBox): box is IMailBox {
  return box.type === BoxType.mailbox;
}

export function parcelBoxTypeGuard(box: WallBox): box is IParcelBox {
  return box.type === BoxType.parcelbox;
}

export function lightBoxTypeGuard(box: WallBox): box is ILightBox {
  return box.type === BoxType.lightbox;
}

export function centralBoxTypeGuard(box: WallBox): box is ICentralBox {
  return box.type === BoxType.centralbox;
}

export function zFormLayoutOptionsTypeGuard(layoutOptions: LayoutOptions): layoutOptions is IZFormLayoutConstraint {
  return 'w1' in layoutOptions;
}

export function standaloneLayoutOptionsTypeGuard(layoutOptions: LayoutOptions): layoutOptions is IStandaloneConstraint {
  return 'rows' in layoutOptions;
}

export function rectangleLayoutOptionsTypeGuard(layoutOptions: LayoutOptions): layoutOptions is IRectangleLayoutConstraint {
  return 'row' in layoutOptions;
}

export function isToggleableTypeGuard(obj: Object): obj is {included: boolean, toggle: boolean} {
  return 'included' in obj && 'toggle' in obj;
}