import styles from './Form.module.scss';
import Typography from '../Typography/Typography';
import * as React from 'react';
import classNames from 'classnames';

const Container: React.FC = ({ children }) => {
  return <main className={styles.container}>
    {children}
  </main>;
};

interface IControlProps {
  labelDisplay?: 'inline' | 'block';
  label?: string | JSX.Element;
  labelComponent?: React.ElementType;
}

const Control: React.FC<IControlProps> = ({ labelDisplay = 'inline', labelComponent = 'p', label, children }) => {
  const className = classNames({
    [styles.inline]: labelDisplay === 'inline',
    [styles.block]: labelDisplay === 'block'
  });

  return (
    <div className={className}>
      {label && (
        <Typography
          variant="body2"
          className={labelDisplay === 'block' ? styles.blockLabel : ''}
          component={labelComponent}
        >
          {label}
        </Typography>
      )}
      {children}
    </div>
  );
};

interface ISectionProps {
  title?: string | JSX.Element;
}

const Section: React.FC<ISectionProps> = ({ title, children }) => {
  return (
    <div className={styles.section}>
      {title && <Typography className={styles.sectionTitle} variant="subtitle1" component="h2">{title}</Typography>}
      {children}
    </div>
  );
};
export const Form = {
  Container,
  Control,
  Section
};