import React from "react";
// InertPolyfill
import "wicg-inert";

interface IProps {
  inert: boolean;
  className?: string;
}

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/inert
 */
export default class Inert extends React.Component<IProps> {

  private inertRef = React.createRef<HTMLDivElement>();

  componentDidMount() {
    this.updateAttribute();
  }

  componentDidUpdate() {
    this.updateAttribute();
  }

  private updateAttribute() {
    if (this.inertRef.current) {
      if (this.props.inert) {
        this.inertRef.current.setAttribute("inert", "");
      } else {
        this.inertRef.current.removeAttribute("inert");
      }
    }
  }

  render() {
    return (
      <div className={this.props.className} ref={this.inertRef} >
        {this.props.children}
      </div>
    );
  }
}
