const KEY_SHOULD_SHOW_BOX_INSERT_GUIDE = 'SHOULD_SHOW_BOX_INSERT_GUIDE';
const KEY_SHOULD_SHOW_BOX_SWAP_GUIDE = 'SHOULD_SHOW_BOX_SWAP_GUIDE';
const KEY_SHOULD_SHOW_REMOVE_GUIDE = 'SHOULD_SHOW_REMOVE_GUIDE';


class PersistenceService {
  get shouldShowBoxInsertGuide(): boolean {
    const item = sessionStorage.getItem(KEY_SHOULD_SHOW_BOX_INSERT_GUIDE);

    return item === null || item !== 'false';
  }

  setShouldShowBoxInsertGuide(value: boolean) {
    sessionStorage.setItem(KEY_SHOULD_SHOW_BOX_INSERT_GUIDE, String(value));
  }

  get shouldShowBoxSwapGuide(): boolean {
    const item = sessionStorage.getItem(KEY_SHOULD_SHOW_BOX_SWAP_GUIDE);

    return item === null || item !== 'false';
  }

  setShouldShowBoxSwapGuide(value: boolean) {
    sessionStorage.setItem(KEY_SHOULD_SHOW_BOX_SWAP_GUIDE, String(value));
  }

  get shouldShowRemoveGuide(): boolean {
    const item = sessionStorage.getItem(KEY_SHOULD_SHOW_REMOVE_GUIDE);
    return item === null || item !== 'false';
  }

  setShouldShowRemoveGuide(value: boolean) {
    sessionStorage.setItem(KEY_SHOULD_SHOW_REMOVE_GUIDE, String(value));
  }
}

const persistenceService = new PersistenceService();

export default persistenceService;
