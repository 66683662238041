export class Transition {
  private startedAt!: number;
  private finishedAt!: number;
  private changeHandler!: (value: number[], t: number) => void;
  private currentValue: number[];
  private finishValue!: number[];
  private running = false;
  private finishHandler = noop;
  //eslint-disable-next-line @typescript-eslint/no-empty-function
  private startHandler = (v: number[]) => {};
  private easeFunction = (t: number) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);
  private pendingPromises: ((value?: unknown) => void)[] = [];
  private inProgress = false;

  constructor(private startedValue: number[], private time: number = 1000) {
    this.currentValue = startedValue;
  }

  syncValue(value: number[]) {
    // Transition currentValue takes precedence over external value, so
    // its not possible to update it during transition
    if (!this.inProgress) {
      this.currentValue = value;
    }
  }

  transitionTo(finishValue: number[], time: number = this.time) {
    this.inProgress = true;
    return new Promise(resolve => {
      this.pendingPromises.push(resolve);
      requestAnimationFrame(t => {
        this.finishValue = finishValue.slice();
        this.startedValue = this.currentValue.slice();
        if (this.startedValue.length !== this.finishValue.length) {
          throw new Error('Incorrect number of elements');
        }
        this.startedAt = t;
        this.finishedAt = t + time;
        this.startHandler(this.startedValue);
        if (!this.running) {
          this.running = true;
          this.handleRAF(t);
        }
      });
    });
  }

  onStart(fn: (v: number[]) => void) {
    this.startHandler = fn;
  }

  onChange(fn: (value: number[], t: number) => void) {
    this.changeHandler = fn;
  }

  onFinish(fn: () => void) {
    this.finishHandler = fn;
  }

  private handleRAF(time: number) {
    if (time <= this.finishedAt) {
      const percent = (time - this.startedAt) / (this.finishedAt - this.startedAt);
      this.currentValue = this.startedValue.map(
        (_, i) => this.startedValue[i] + this.easeFunction(percent) * (this.finishValue[i] - this.startedValue[i])
      );
      this.fireChange(percent);
      if (!this.reachedFinishedValues()) {
        requestAnimationFrame(t => this.handleRAF(t));
      }
    } else {
      // Fires exact last value
      if (!this.reachedFinishedValues()) {
        this.currentValue = this.finishValue.slice();
        this.fireChange(1);
      }
    }
  }

  private fireChange(percent: number) {
    this.changeHandler(this.currentValue, percent);
    if (this.reachedFinishedValues()) {
      this.running = false;
      this.pendingPromises.forEach(f => f());
      this.pendingPromises = [];
      this.finishHandler();
      this.inProgress = false;
    }
  }

  private reachedFinishedValues() {
    return this.currentValue.every((_, i) => this.currentValue[i] === this.finishValue[i]);
  }
}

//eslint-disable-next-line @typescript-eslint/no-empty-function
function noop() {}
