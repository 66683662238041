import * as React from 'react';
import classNames from 'classnames';
import s from './DropDownMenu.module.scss';

interface IProps {
  items: JSX.Element[];
  className?: string;
}

export const DropDownMenu: React.FC<IProps> = ({ items, className }) => {
  return (
    <ul className={classNames(s.list, className)}>
      {items.map(item => (
        <li>{item}</li>
      ))}
    </ul>
  );
};
