import { action, computed, makeObservable, observable, toJS } from 'mobx';
import { Engine, FlatStructuralTransformer, Option } from '@canvas-logic/engine';
import { ValidationResult } from '@canvas-logic/engine/dist/engine';
import { Layout } from '../layout/Layout';
import { BoxViewModel, createViewModel } from '../viewer/BoxViewModel';
import { shiftNeighbourAction } from '../behaviour/neighbour/action';
import { Point } from '../layout/Point';
import { distanceLess, distanceToZone, DragZone, DropZone } from '../behaviour/zones';
import { LayoutRulesManager } from '../rules/LayoutRulesManager';
import { RootStore } from './RootStore';
import {
  CentralBoxExternalFields,
  FinishType,
  ICentralBox,
  IConcreteBox,
  ICountry,
  IMailBox,
  IMailBoxEngraving,
  IMaterial,
  IParcelBox,
  IWall,
  MailBoxEngravingMaterial,
  MailBoxExternalFields,
  MailboxLockType,
  ParcelLockType,
  PersonalizationTechnic,
  SummaryGroupsOrder,
  TextAlignment,
  WallBox,
  WallLocation,
  WallType
} from '../schema';
import { WallSerializer } from '../serialization';
import { LayoutFactory } from '../layout/LayoutFactory';
import { WallPostProcessorFactory } from '../postprocessors/WallPostProcessorFactory';
import { IPostProcessor } from '../postprocessors/IPostProcessor';
import { centralBoxTypeGuard, lightBoxTypeGuard, mailBoxTypeGuard, parcelBoxTypeGuard } from '../guards';
import { BaseMutator } from '../mutators/BaseMutator';
import { IWallStore } from '../components/Viewer/Viewer';
import { Position } from '../layout/Position';
import { PositionedLayoutBox } from '../layout/Box';
import { RulesManagerFactory } from '../rules/RulesManagerFactory';
import { LinkService } from '../services/LinkService';
import { SOCLE_HEIGHT } from '../viewer/BoxViewModelMaker';
import { DimensionsService } from '../services/DimensionsService';
import { WeightService } from '../services/WeightService';
import { EntityFinder } from '../services/EntityFinder';
import { MainMenu, removeModeTypeGuard, SIDE_PANEL_WIDTH } from './ConfiguratorMenuStore';
import { newColumnAction } from '../behaviour/newColumn/action';
import { getSwapDropZones } from '../behaviour/swap/zones';
import { getNeighboursDropZones } from '../behaviour/neighbour/zones';
import { getInstallationZones } from '../behaviour/installation/zones';
import { getNewColumnsDropZones } from '../behaviour/newColumn/zones';
import { getNewRowsDropZones } from '../behaviour/newRow/zones';
import { installBoxAction } from '../behaviour/installation/action';
import { swapSingleAction } from '../behaviour/swap/action';
import { CostSummaryService } from '../services/CostSummaryService';
import { SelectionZone, Zone, ZoneDistance, ZoneStatus } from '../behaviour/types';
import {
  createColumnRemoveZones,
  createNonRemovableZones,
  RemoveColumnsCoordinates,
  RemoveColumnZone,
  removeColumnZoneTypeGuard
} from '../behaviour/removeColumn/zones';
import { removeColumnAction } from '../behaviour/removeColumn/action';
import { getInitialSettings, InitialSettings, isAlbo, uniq } from '../services/utils';
import { DomainRulesManager } from '../rules/DomainRulesManager';
import { CloneService } from '../services/CloneService';
import { rootStore } from './index';
import { IntegrationService } from '../services/IntegrationService';
import { BomService } from '../services/BomService';
import { TranslatedMessage } from './Localization';
import { newRowAction } from '../behaviour/newRow/action';
import {
  createRowRemoveZones,
  groupAndSortSelectedRowsToRemove,
  RemoveRowZone,
  removeRowZoneTypeGuard
} from '../behaviour/removeRow/zones';
import { removeRowAction } from '../behaviour/removeRow/action';
import { PdfDownloader } from '../services/PdfDownloader';
import { contactService } from '../services/ContactService';
import { DEFAULT_MATERIAL } from '../components/ColorSidePanel/ColorSidePanel';

export const TEXT_PLACEHOLDER = 'ABC';
export const MAX_ELECTRIC_LOCK_PARCEL_BOXES = 1;
const MAX_COLUMNS_TO_REMOVE_AT_ONCE = 2;

export enum VisualMode {
  View,
  FrontalView,
  Edit,
  Delete,
  Insert
}

export enum ColumnRemoveGuideStep {
  CanBeDeleted,
  ReasonNotToDelete,
  Explanation
}

// const APARTMENT_ENGRAVING_OPTION_ID = 'apartment_engraving';
// const NAME_ENGRAVING_OPTION_ID = 'name_engraving';
// const NAME_PVC_OPTION_ID = 'name_pvc';

export type Handler = () => void;

export type DraggingBox = PositionedLayoutBox | WallBox;

interface EngravingSettings {
  apartment: boolean,
  name: boolean,
  pvc: boolean,
  color: MailBoxEngravingMaterial
}

export function positionedDragBoxTypeGuard(dragBox: DraggingBox): dragBox is PositionedLayoutBox {
  return 'box' in dragBox;
}

export function wallBoxTypeGuard(dragBox: DraggingBox): dragBox is WallBox {
  return 'rows' in dragBox && 'columns' in dragBox;
}

export class ConfiguratorStore implements IWallStore {
  dragPoint: Point | null = null;
  draggingBox: DraggingBox | null;
  private readonly layoutRulesManager: LayoutRulesManager;
  private readonly domainRulesManager: DomainRulesManager;
  private readonly columnRemovalPossibilityRulesManager: LayoutRulesManager;
  private readonly rowRemovalPossibilityRulesManager: LayoutRulesManager;
  private readonly engine: Engine;

  private readonly postProcessor: IPostProcessor;
  private handlers: Map<string, Handler[]> = new Map<string, Handler[]>();
  public dimensionService: DimensionsService;
  public weightService: WeightService;
  private readonly entityFinder: EntityFinder;
  private readonly MIN_DRAG_ZONE_TRIGGERED_AT = 100;

  selectedBoxPosition: Position | undefined = undefined;

  dropZones: DropZone[] = [];
  selectionZones: SelectionZone[] = [];
  mode: VisualMode = VisualMode.View;
  columnRemoveGuideStep: ColumnRemoveGuideStep | null = null;
  showRuler: boolean = true;

  model: IWall;
  rawModel: IWall;
  serializer: WallSerializer;
  layout: Layout;

  getScreenPosition = (x: number, y: number) => ({ x, y });
  costSummaryService: CostSummaryService;
  addToCartDisabled = false;

  private previousEngraving: EngravingSettings = {
    apartment: true,
    name: true,
    pvc: true,
    color: MailBoxEngravingMaterial.White
  };
  optionalEngravings: IMailBoxEngraving[] = [];
  initialSettings: InitialSettings;

  get hasEngravingColor(): boolean {
    return (
      this.mailboxEngravingMaterial && (this.hasApartmentEngraving || (this.hasNameEngraving && !this.hasNamePVCPlate))
    );
  }

  get screenDraggingBox(): { x: number; y: number; width: number; height: number } | null {
    if (!this.dragPoint || !this.draggingBox) {
      return null;
    }
    if (wallBoxTypeGuard(this.draggingBox)) {
      const { x: xmax, y: ymax } = this.getScreenPosition(this.layout.cellWidth, this.layout.cellHeight);
      const { x: xmin, y: ymin } = this.getScreenPosition(0, 0);
      const width = Math.abs(xmax - xmin) * this.draggingBox.columns;
      const height = Math.abs(ymax - ymin) * this.draggingBox.rows;
      return {
        x: this.dragPoint.centerX - 0.5 * width,
        y: this.dragPoint.centerY - 0.5 * height,
        width,
        height
      };
    } else {
      return null;
    }
  }

  get summaryGroupsOrder(): SummaryGroupsOrder {
    return this.model.summaryGroupsOrder;
  }

  get additionalBoxes(): WallBox[] {
    const options =
      this.engine.optionValuesByPath(this.model, 'boxes.box', new FlatStructuralTransformer<WallBox>()) ?? [];
    const models = options.map(o => o.model);

    const installedCentralBox: ICentralBox | undefined = this.model.boxes.find(concreteBox =>
      centralBoxTypeGuard(concreteBox.box)
    )?.box as ICentralBox | undefined;
    let availableBoxes = models.filter(box => {
      if (mailBoxTypeGuard(box)) {
        return false;
      }

      if (parcelBoxTypeGuard(box)) {
        return (
          (box.lockType === ParcelLockType.Electric && this.model.wallType === WallType.Digital) ||
          (box.lockType === ParcelLockType.Number && this.model.wallType === WallType.Mechanical)
        );
      }

      if (lightBoxTypeGuard(box)) {
        return this.model.location !== WallLocation.OutsideUnsheltered;
      }

      if (centralBoxTypeGuard(box)) {
        if (box.display) {
          return false;
        }
        return !!installedCentralBox && !installedCentralBox.display;
      }

      return true;
    });

    return uniq(availableBoxes, 'name');
  }

  get hasRoof(): boolean {
    return this.model.roof.top.length > 0;
  }

  get lightBoxes(): IConcreteBox[] {
    return this.model.boxes
      .filter(concreteBox => lightBoxTypeGuard(concreteBox.box))
      .sort((first, second) => {
        if (first.row === second.row) {
          return first.column - second.column;
        }

        return second.row - first.row;
      });
  }

  /** selected box */
  get selectedBox(): IConcreteBox | undefined {
    return this.model.boxes.find(
      box => box.row === this.selectedBoxPosition?.row && box.column === this.selectedBoxPosition?.column
    );
  }

  get selectedMailBox(): IMailBox | undefined {
    const box = this.selectedBox?.box;
    if (box && mailBoxTypeGuard(box)) {
      return box;
    }
    return undefined;
  }

  get selectedParcelBox(): IParcelBox | undefined {
    const box = this.selectedBox?.box;
    if (box && parcelBoxTypeGuard(box)) {
      return box;
    }
    return undefined;
  }

  get viewModel(): BoxViewModel {
    return createViewModel(this.model, this.dimensionService, this.mailboxEngravingMaterial);
  }

  /** d&d */
  get newDraggingBox(): WallBox | null {
    if (this.draggingBox && wallBoxTypeGuard(this.draggingBox)) {
      return this.draggingBox;
    }
    return null;
  }

  get existingDraggingBox(): PositionedLayoutBox | null {
    if (this.draggingBox && positionedDragBoxTypeGuard(this.draggingBox)) {
      return this.draggingBox;
    }
    return null;
  }

  get screenDropZones(): DropZone[] {
    return this.dropZones.map(zone => this.toScreenZone(zone, -SIDE_PANEL_WIDTH));
  }

  get dragZone(): DragZone | null {
    if (!this.draggingBox || !this.dragPoint) {
      return null;
    }
    const box = positionedDragBoxTypeGuard(this.draggingBox) ? this.draggingBox.box : this.draggingBox;
    return {
      width: box.columns * this.layout.cellWidth,
      height: box.rows * this.layout.cellHeight,
      centerX: this.dragPoint.centerX,
      centerY: this.dragPoint.centerY + this.model.offsetHeight
    };
  }

  get nearestDropZones(): DropZone[] {
    if (!this.dropZones.length || !this.dragZone || !this.draggingBox) {
      return [];
    }
    let candidate: DropZone | null = null;
    let minDistance: ZoneDistance = { outer: Infinity, inner: Infinity };
    const shift = this.isDesktop ? -SIDE_PANEL_WIDTH : -0.5 * SIDE_PANEL_WIDTH;
    if (wallBoxTypeGuard(this.draggingBox)) {
      minDistance = { outer: this.MIN_DRAG_ZONE_TRIGGERED_AT, inner: Infinity };
      for (let zone of this.dropZones) {
        const screenZone = this.toScreenZone(zone, shift);
        const distance = distanceToZone(screenZone, this.dragZone);
        if (distanceLess(distance, minDistance)) {
          minDistance = distance;
          candidate = zone;
        }
      }
    } else {
      for (let zone of this.dropZones) {
        const distance = distanceToZone(zone, this.dragZone);
        if (distanceLess(distance, minDistance)) {
          minDistance = distance;
          candidate = zone;
        }
      }
    }
    return candidate ? [candidate] : [];
  }

  get hasApartmentEngraving(): boolean {
    return this.hasEngraving(engraving => engraving.apartment);
  }

  get hasNameEngraving(): boolean {
    return this.hasEngraving(engraving => engraving.name);
  }

  get hasNamePVCPlate(): boolean {
    return this.hasEngraving(engraving => engraving.pvc);
  }

  get defaultEngravingMaterial(): MailBoxEngravingMaterial {
    return MailBoxEngravingMaterial.Aluminum;
  }

  get isMaterialSpecified(): boolean {
    return !!this.model.material;
  }

  get mailboxEngravingMaterial(): MailBoxEngravingMaterial {
    for (let index = 0; index < this.model.boxes.length; index++) {
      const box = this.model.boxes[index].box;

      if (mailBoxTypeGuard(box)) {
        // first none Aluminum color otherwise default
        const coloredEngraving = box.engraving.find(engraving => !engraving.pvc);
        if (coloredEngraving) {
          return coloredEngraving.color;
        }
        return this.defaultEngravingMaterial;
      }
    }
    return this.defaultEngravingMaterial;
  }

  get availableCountries() {
    const options =
      this.engine.optionValuesByPath(this.model, 'country', new FlatStructuralTransformer<ICountry>()) ?? [];

    return options.filter(option => option._id !== 'custom');
  }

  get isMobile(): boolean {
    return rootStore.isMobile;
  }

  get isDesktop(): boolean {
    return rootStore.isDesktop;
  }

  get showMobileDragPoint(): boolean {
    return rootStore.isMobile && this.mode === VisualMode.Insert && this.dragPoint !== null;
  }

  constructor(rootStore: RootStore, id: string, link: string, version: string, private pdfAutoDownload = false) {
    this.serializer = new WallSerializer(rootStore.datasetService);
    const result = link
      ? this.serializer.fromLink(link, version)
      : id
      ? this.serializer.findById(id)
      : this.serializer.getDefault(WallType.Digital);

    this.initialSettings = getInitialSettings(result.wall);
    this.setModel(result.wall);
    this.engine = result.engine;
    const centralBoxExternalFields = new CentralBoxExternalFields(rootStore.datasetService);
    const mailBoxExternalFields = new MailBoxExternalFields(rootStore.datasetService);
    this.postProcessor = WallPostProcessorFactory.create(
      this.engine,
      this.model,
      centralBoxExternalFields,
      mailBoxExternalFields
    );
    this.layout = LayoutFactory.create(this.model);
    if ([WallType.Interna, WallType.Boxis].includes(this.model.wallType)) {
      this.layoutRulesManager = RulesManagerFactory.createAlbaRulesManager(this.model, this.layout);
    } else {
      this.layoutRulesManager = RulesManagerFactory.createWallRulesManager(this.model, this.layout);
    }
    this.domainRulesManager = RulesManagerFactory.createDomainRulesManager();

    this.columnRemovalPossibilityRulesManager = RulesManagerFactory.createColumnRemovalPossibilityRulesManager(
      this.model,
      this.layout
    );
    this.rowRemovalPossibilityRulesManager = RulesManagerFactory.createRowRemovalPossibilityRulesManager(
      this.model,
      this.layout
    );

    this.entityFinder = new EntityFinder(this.engine);
    this.simpleMutation(() => {
      // restore computed fields and validate rules
    });
    this.dimensionService = new DimensionsService(this.model, this.layout);
    this.weightService = new WeightService(this.entityFinder, this.model);
    this.costSummaryService = new CostSummaryService(this.entityFinder, this.model, this.summaryGroupsOrder);

    makeObservable<ConfiguratorStore, 'dragPoint' | 'draggingBox' | 'changeEngraving'>(this, {
      selectedBoxPosition: observable,
      model: observable,
      mode: observable,
      columnRemoveGuideStep: observable,
      dragPoint: observable,
      draggingBox: observable,
      dropZones: observable,
      showRuler: observable,
      selectionZones: observable,
      addToCartDisabled: observable,

      screenDraggingBox: computed,
      selectedBox: computed,
      selectedMailBox: computed,
      selectedParcelBox: computed,
      viewModel: computed.struct,
      nearestDropZones: computed,
      hasApartmentEngraving: computed,
      hasNameEngraving: computed,
      isMaterialSpecified: computed,
      mailboxEngravingMaterial: computed,
      hasEngravingColor: computed,
      hasRoof: computed,
      lightBoxes: computed,
      additionalBoxes: computed,
      screenDropZones: computed,
      newDraggingBox: computed,
      existingDraggingBox: computed,
      selectedZones: computed,
      summaryGroupsOrder: computed,
      availableCountries: computed,

      setSelectedBox: action.bound,
      changeNameEngraving: action.bound,
      changeApartmentEngraving: action.bound,
      changeEngraving: action.bound,
      toggleMaterialSpecified: action.bound,
      changeMailboxEngravingMaterial: action.bound,
      changeLock: action.bound,
      changeRoofTextAlignment: action.bound,
      changeLightboxText: action,
      changeLightBoxAlignment: action,
      onMove: action.bound,
      onDrop: action.bound,
      onDragStart: action.bound,
      changeColor: action.bound,
      changeFinishType: action.bound,
      changeMode: action.bound,
      onRotate: action.bound,
      onZoomIn: action.bound,
      onToggleSelection: action.bound,
      removeSelectedColumns: action.bound,
      removeSelectedRows: action.bound,
      updateColumnsToRemove: action.bound,
      changeRemoveGuideStep: action.bound,
      toggleSummaryItem: action.bound,
      onMobileInsertStart: action.bound,
      onMobileMove: action.bound,
      cancelBoxInsertion: action.bound,
      continueBoxInsertion: action.bound,
      addToCart: action.bound,
      startAutoDownloadPdf: action.bound
      // onInstallationStart: action.bound
    });

    this.findOptionalApartmentEngravings();
  }

  async startAutoDownloadPdf() {
    if (this.pdfAutoDownload) {
      this.downloadPdf();
    }
  }

  private async downloadPdf(): Promise<void> {
    const downloader = new PdfDownloader(
      contactService,
      this.dimensionService,
      this.weightService,
      this.costSummaryService,
      rootStore.imageService,
      rootStore.localization,
      rootStore.notificationsStore,
      this
    );

    await downloader.downloadPdf();
  }

  changeApartmentEngraving(enabled: boolean): void {
    this.changeEngraving(enabled, this.hasNameEngraving, this.hasNamePVCPlate, this.mailboxEngravingMaterial);
  }

  private findOptionalApartmentEngravings() {
    let allOptionalOptions: Option<IMailBoxEngraving>[] = [];
    for (let index = 0; index < this.model.boxes.length; index++) {
      const options =
        this.engine.optionValuesByPath(
          this.model,
          `boxes.${index}.box.engraving`,
          new FlatStructuralTransformer<IMailBoxEngraving>()
        ) ?? [];
      const engravingOptions = options.filter(option => ['AB333904', 'AB333907'].includes(option.model.article));
      const nameEngravingOptions = options.filter(option =>
        ['AB333940', 'AB333941', 'AB333942'].includes(option.model.article)
      );
      if (engravingOptions.length) {
        allOptionalOptions = allOptionalOptions.concat(engravingOptions);
      }
      if (nameEngravingOptions.length) {
        const withMinPrice = nameEngravingOptions.reduce((opt1, opt2) =>
          opt1.model.price < opt2.model.price ? opt1 : opt2
        );
        allOptionalOptions.push(withMinPrice);
      }
    }
    this.optionalEngravings = allOptionalOptions
      .filter((value, index, self) => index === self.findIndex(t => t.model.article === value.model.article))
      .map(i => i.model);
  }

  changeNameEngraving(enabled: boolean) {
    this.changeEngraving(this.hasApartmentEngraving, enabled, this.hasNamePVCPlate, this.mailboxEngravingMaterial);
  }

  toggleMaterialSpecified(): void {
    if (this.model.material) {
      this.model.material = undefined;
    } else {
      this.model.material = { ...DEFAULT_MATERIAL };
    }
  }

  private changeEngraving(apartment: boolean, name: boolean, pvc: boolean, color: MailBoxEngravingMaterial) {
    this.simpleMutation(model => {
      let engravingOptions: IMailBoxEngraving[] | undefined = undefined;
      if (!this.model.boxes.length) {
        return;
      }

      for (let index = 0; index < model.boxes.length; index++) {
        const box = model.boxes[index].box;

        if (mailBoxTypeGuard(box)) {
          if (!engravingOptions) {
            const options =
              this.engine.optionValuesByPath(
                model,
                `boxes.${index}.box.engraving`,
                new FlatStructuralTransformer<IMailBoxEngraving>()
              ) ?? [];

            const hasPVC = isAlbo(this.model.wallType);
            if (hasPVC && !this.previousEngraving.name && name) {
              pvc = true;
            }

            engravingOptions = this.findEngravings(apartment, name, color, pvc, options);
          }
          if (!engravingOptions) {
            continue;
          }

          box.engraving = engravingOptions;
        }
      }
    });
    this.previousEngraving = { apartment, name, color, pvc };
  }

  private findEngravings(
    apartment: boolean,
    name: boolean,
    color: MailBoxEngravingMaterial,
    pvc: boolean,
    options: Option<IMailBoxEngraving>[]
  ): IMailBoxEngraving[] {
    if (apartment && name) {
      let compositeEngraving = options.find(
        option =>
          option.model.name === name &&
          option.model.apartment === apartment &&
          option.model.color === color &&
          option.model.pvc === pvc
      );
      if (compositeEngraving) {
        return [compositeEngraving.model];
      }
    }
    // find separate
    let result: IMailBoxEngraving[] = [];
    // Apartment only does not have PVC
    if (apartment) {
      let apartmentEngraving = options.find(
        option => option.model.apartment && !option.model.name && !option.model.pvc && option.model.color === color
      );
      if (apartmentEngraving) {
        result.push(apartmentEngraving.model);
      }
    }
    // PVC engraving does not have color
    if (name) {
      let nameColor = pvc ? MailBoxEngravingMaterial.Aluminum : color;

      const isItAlbo = isAlbo(this.model.wallType);

      let nameEngraving = options.find(
        option =>
          !option.model.apartment &&
          option.model.name &&
          (option.model.pvc === pvc || isItAlbo) &&
          option.model.color === nameColor
      );
      if (nameEngraving) {
        result.push(nameEngraving.model);
      }
    }
    return result;
  }

  changeNamePersonalizationTechnic(technic: PersonalizationTechnic) {
    this.changeEngraving(
      this.hasApartmentEngraving,
      this.hasNameEngraving,
      technic === PersonalizationTechnic.PVC,
      this.mailboxEngravingMaterial
    );
  }

  changeMailboxEngravingMaterial(material: MailBoxEngravingMaterial): void {
    this.changeEngraving(this.hasApartmentEngraving, this.hasNameEngraving, this.hasNamePVCPlate, material);
  }

  changeLock(lockType: MailboxLockType | ParcelLockType) {
    this.simpleMutation(wall => {
      const currentBoxIndex = wall.boxes.findIndex(
        box => box.row === this.selectedBox?.row && box.column === this.selectedBox?.column
      );
      const currentBox = wall.boxes[currentBoxIndex];
      if (!currentBox) {
        return;
      }

      if (mailBoxTypeGuard(currentBox.box)) {
        const { model } = this.findLockTypeBox(wall, currentBox.box, lockType);
        const engraving = [...(currentBox.box as IMailBox).engraving];
        wall.boxes[currentBoxIndex].box = {
          ...model,
          engraving
        } as IMailBox;

        return;
      }

      if (parcelBoxTypeGuard(currentBox.box)) {
        const { model } = this.findLockTypeBox(wall, currentBox.box, lockType);
        wall.boxes[currentBoxIndex].box = {
          ...model
        };

        return;
      }

      throw new Error(`Unable to change lock type (type: ${lockType})`);
    });
  }

  canChangeLockForSelectedBox(): boolean {
    if (this.model.wallType === WallType.Digital) {
      if (!this.selectedMailBox) {
        return false;
      }
    } else {
      if (!this.selectedParcelBox) {
        return false;
      }
    }
    const { type, name } = this.selectedParcelBox ?? this.selectedMailBox ?? {};
    const options =
      this.engine.optionValuesByPath(this.model, `boxes.box`, new FlatStructuralTransformer<WallBox>()) ?? [];
    console.log(options.filter(({ model }) => model.type === type && model.name === name));

    return options.filter(({ model }) => model.type === type && model.name === name).length > 1;
  }

  disabledElectricLockForSelectedBox() {
    if (this.model.wallType === WallType.Digital) {
      return false;
    }

    if (this.selectedParcelBox?.lockType === ParcelLockType.Electric) {
      return false;
    }

    const centralBox = this.model.boxes.find(({ box }) => centralBoxTypeGuard(box));
    if (!centralBox) {
      return true;
    }
    return (
      this.model.boxes.filter(({ box }) => parcelBoxTypeGuard(box) && box.lockType === ParcelLockType.Electric)
        .length >= MAX_ELECTRIC_LOCK_PARCEL_BOXES
    );
  }

  changeFinishType(finishType: FinishType) {
    this.simpleMutation(wall => {
      if (wall.material) {
        wall.material.finish = finishType;
      }
    });
  }

  changeColor(ralColor: number) {
    this.simpleMutation(wall => {
      if (wall.material) {
        wall.material.ralColor = ralColor;
      } else {
        wall.material = {
          ralColor: ralColor,
          finish: DEFAULT_MATERIAL.finish
        };
      }
    });
  }

  changeMaterial(material: IMaterial | null): void {
    this.simpleMutation(wall => {
      wall.material = material ? { ...material } : undefined;
    });
  }

  changeMode(mode: VisualMode, removableMode?: MainMenu): boolean {
    if (mode !== VisualMode.Delete) {
      this.selectionZones = [];
      this.mode = mode;
      return true;
    }

    if (!removeModeTypeGuard(removableMode)) {
      this.mode = mode;
      return false;
    }

    if (removableMode === MainMenu.DeleteRow) {
      rootStore.notificationsStore.clear();
      this.mode = mode;
      this.rowRemovalPossibilityRulesManager.updateLayout(this.layout);
      const error = this.rowRemovalPossibilityRulesManager.firstError();
      this.updateRowsToRemove();
      if (error) {
        rootStore.notificationsStore.info(error);
        return false;
      }

      if (this.selectionZones.length === 0) {
        return false;
      }
    } else if (removableMode === MainMenu.DeleteColumn) {
      rootStore.notificationsStore.clear();
      this.mode = mode;
      this.columnRemovalPossibilityRulesManager.updateLayout(this.layout);
      const error = this.columnRemovalPossibilityRulesManager.firstError();
      this.updateColumnsToRemove();
      if (error) {
        rootStore.notificationsStore.info(error);
        return false;
      }
    }
    this.mode = mode;

    return true;
  }

  changeRemoveGuideStep(step: number | null, menuMode?: MainMenu) {
    switch (step) {
      case ColumnRemoveGuideStep.CanBeDeleted:
        this.selectionZones = this.generateSelectionForColumnsToRemove(true);
        break;
      case ColumnRemoveGuideStep.ReasonNotToDelete:
        this.selectionZones = this.adjustWithSocleHeight(createNonRemovableZones(this.layout));
        break;
      case ColumnRemoveGuideStep.Explanation:
        this.selectionZones = [];
        break;
      case null:
        if (menuMode === MainMenu.DeleteRow) {
          this.updateRowsToRemove();
        } else if (menuMode === MainMenu.DeleteColumn) {
          this.updateColumnsToRemove();
        }
        break;
    }
  }

  updateColumnsToRemove() {
    if (this.mode === VisualMode.Delete) {
      this.selectionZones = this.generateSelectionForColumnsToRemove();
    } else {
      this.selectionZones = [];
    }
  }

  updateRowsToRemove(): void {
    if (this.mode === VisualMode.Delete) {
      this.selectionZones = this.generateSelectionForRowsToRemove();
    } else {
      this.selectionZones = [];
    }
  }

  toggleRuler() {
    this.showRuler = !this.showRuler;
  }

  onDragStart(draggingBox: DraggingBox, centerX: number, centerY: number) {
    rootStore.enterDragging();
    this.dragPoint = { centerX, centerY };
    this.draggingBox = draggingBox;
    this.dropZones.splice(0, this.dropZones.length);
    this.getDropZones().forEach(zone => {
      zone.centerY = zone.centerY + (this.viewModel.socle ? SOCLE_HEIGHT : 0);
      this.dropZones.push(zone);
    });
  }

  onMove(centerX: number, centerY: number) {
    this.dragPoint = { centerX, centerY };
  }

  onDrop() {
    rootStore.leaveDragging();
    this.acceptDroppedBox();
    this.draggingBox = null;
    this.dragPoint = null;
  }

  private acceptDroppedBox() {
    if (this.nearestDropZones.length) {
      const dropZone = this.nearestDropZones[0];
      if (dropZone.status === ZoneStatus.Valid && dropZone.data) {
        if (this.existingDraggingBox) {
          switch (dropZone.data.type) {
            case 'neighbour': {
              const action = shiftNeighbourAction(this.existingDraggingBox, dropZone.data.position);
              this.simpleMutation((wall, layout) => action(layout));
              break;
            }
            case 'swap': {
              const action = swapSingleAction(this.existingDraggingBox, dropZone.data.boxes);
              this.simpleMutation((wall, layout) => action(layout));
              break;
            }
          }
        }
        if (this.newDraggingBox) {
          switch (dropZone.data.type) {
            case 'installation': {
              const action = installBoxAction(
                this.newDraggingBox,
                dropZone.data.boxes,
                this.additionalBoxes,
                this.initialSettings
              );
              this.simpleMutation((wall, layout) => action(layout));
              break;
            }
            case 'newColumn': {
              const action = newColumnAction(
                this.newDraggingBox,
                dropZone.data.column,
                this.additionalBoxes.filter(box => !centralBoxTypeGuard(box))
              );
              this.simpleMutation((wall, layout) => action(layout));
              break;
            }

            case 'newRow': {
              const action = newRowAction(
                this.newDraggingBox,
                dropZone.data.row,
                this.additionalBoxes.filter(box => !centralBoxTypeGuard(box))
              );
              this.simpleMutation((wall, layout) => action(layout));
              break;
            }
          }
        }
      }
    }
  }

  onRotate() {}

  setSelectedBox(concreteBox: IConcreteBox | undefined) {
    this.selectedBoxPosition = concreteBox;
  }

  onZoomIn() {}

  get selectedZones(): SelectionZone[] {
    return this.selectionZones.filter(zone => zone.selected);
  }

  generateSelectionForColumnsToRemove(disabled = false): SelectionZone[] {
    const columnsToRemove: RemoveColumnsCoordinates[] = [];
    for (let column = this.layout.columns - 1; column >= 0; column--) {
      for (let count = MAX_COLUMNS_TO_REMOVE_AT_ONCE; count > 0; count--) {
        if (this.canApplySimpleMutation((wall, layout) => removeColumnAction(column, count)(layout))) {
          columnsToRemove.push({ column, count: count });
        }
      }
    }

    return this.adjustWithSocleHeight(createColumnRemoveZones(this.layout, columnsToRemove, disabled));
  }

  generateSelectionForRowsToRemove(disabled: boolean = false): SelectionZone[] {
    const rowsToRemoveAll = createRowRemoveZones(this.layout, this.model.location, disabled);

    const rowsToRemoveFiltered = rowsToRemoveAll.filter(rowZone => {
      const canApply = this.canApplySimpleMutation((wall, layout) =>
        removeRowAction(rowZone.row, rowZone.count)(layout)
      );
      return canApply;
    });

    return this.adjustWithSocleHeight(rowsToRemoveFiltered);
  }

  adjustWithSocleHeight<T extends Zone>(zones: T[]): T[] {
    return zones.map(zone => ({
      ...zone,
      centerY: zone.centerY + (this.viewModel.socle ? SOCLE_HEIGHT : 0)
    }));
  }

  onToggleSelection(selectionZone: SelectionZone) {
    const zone = this.selectionZones.find(candidate => candidate.id === selectionZone.id);
    if (!zone) {
      return;
    }

    zone.selected = !zone.selected;
  }

  removeSelectedColumns(): void {
    const columnRemoveZones = this.selectedZones.filter(zone => removeColumnZoneTypeGuard(zone)) as RemoveColumnZone[];
    const actions = columnRemoveZones.map(zone => removeColumnAction(zone.column, zone.count));

    try {
      this.simpleMutation((wall, layout) => {
        for (const action of actions) {
          action(layout);
        }
      });

      this.updateColumnsToRemove();
    } catch (error: unknown) {
      if (error instanceof Error) {
        rootStore.notificationsStore.info(error.message);
      }
    }
  }

  removeSelectedRows(): void {
    const rowRemoveZones = this.selectedZones.filter((zone: SelectionZone): zone is RemoveRowZone =>
      removeRowZoneTypeGuard(zone)
    );
    const rowRemoveZonesSorted = groupAndSortSelectedRowsToRemove(rowRemoveZones, this.layout);
    const actions = rowRemoveZonesSorted.map(zone => removeRowAction(zone.row, zone.count));

    try {
      this.simpleMutation((wall, layout) => {
        for (const action of actions) {
          action(layout);
        }
      });

      this.updateRowsToRemove();
    } catch (error: unknown) {
      if (error instanceof Error) {
        rootStore.notificationsStore.info(error.message);
      }
    }
  }

  removeSelected(removeMode: MainMenu): void {
    if (!removeModeTypeGuard(removeMode)) {
      return;
    }

    if (removeMode === MainMenu.DeleteRow) {
      this.removeSelectedRows();
    } else if (removeMode === MainMenu.DeleteColumn) {
      this.removeSelectedColumns();
    }
  }

  toggleRoofText = (roofPlateIndex: number) => {
    this.simpleMutation(wall => {
      wall.roof.top[roofPlateIndex].hasText = !wall.roof.top[roofPlateIndex].hasText;
    });
  };

  changeRoofText = (roofPlateIndex: number, text: string) => {
    this.simpleMutation(wall => {
      wall.roof.top[roofPlateIndex].text = text;
    });
  };

  changeRoofTextAlignment(roofPlateIndex: number, textAlignment: TextAlignment) {
    this.simpleMutation(wall => {
      wall.roof.top[roofPlateIndex].textAlignment = textAlignment;
    });
  }

  changeLightboxText(lightboxPosition: Position, text: string) {
    this.simpleMutation(wall => {
      const concreteBox = wall.boxes.find(c => c.row === lightboxPosition.row && c.column === lightboxPosition.column);
      const box = concreteBox?.box;

      if (!box || !lightBoxTypeGuard(box)) {
        throw new Error('Cannot find lightbox');
      }
      box.text = text || TEXT_PLACEHOLDER;
    });
  }

  changeLightBoxAlignment(lightboxPosition: Position, textAlignment: TextAlignment) {
    this.simpleMutation(wall => {
      const concreteBox = wall.boxes.find(c => c.row === lightboxPosition.row && c.column === lightboxPosition.column);
      const box = concreteBox?.box;

      if (!box || !lightBoxTypeGuard(box)) {
        throw new Error('Cannot find lightbox');
      }
      box.textAlignment = textAlignment;
    });
  }

  zoomOut() {
    this.fireEvent('zoomOut');
  }

  subscribe(eventName: string, handler: Handler): Handler {
    const handlers = this.handlers.get(eventName) ?? [];
    handlers.push(handler);
    this.handlers.set(eventName, handlers);
    return () => {
      const handlers = this.handlers.get(eventName) ?? [];
      const index = handlers.findIndex(h => h === handler);
      if (index >= 0) {
        handlers.splice(index, 1);
      }
    };
  }

  shareLink() {
    const gzipValue = this.serializer.toLink(this.model);
    return LinkService.makeSharableLink(gzipValue);
  }

  integrationLink(): string {
    const gzipValue = this.serializer.toLink(this.model);
    return LinkService.makeIntegrationLink(gzipValue);
  }

  private getDropZones(): DropZone[] {
    return this.getNeighboursDropZones()
      .concat(
        this.getSwapDropZones(),
        this.getInstallationDropZones(),
        this.getNewColumnsDropZones(),
        this.getNewRowsDropZones()
      )
      .map(zone => ({
        ...zone,
        centerY: zone.centerY + this.model.offsetHeight
      }));
  }

  private getInstallationDropZones(): DropZone[] {
    if (!this.newDraggingBox) {
      return [];
    }
    const zones = getInstallationZones(this.layout, this.newDraggingBox);
    for (let zone of zones) {
      const action = installBoxAction(this.newDraggingBox, zone.data.boxes, this.additionalBoxes, this.initialSettings);
      zone.status = this.canApplyLayoutAction((wall, layout) => action(layout)) ? ZoneStatus.Valid : ZoneStatus.Invalid;
    }

    return zones;
  }

  private getNewColumnsDropZones(): DropZone[] {
    if (!this.newDraggingBox) {
      return [];
    }

    const zones = getNewColumnsDropZones(this.newDraggingBox, this.layout);

    for (let zone of zones) {
      const action = newColumnAction(
        this.newDraggingBox,
        zone.data.column,
        this.additionalBoxes.filter(box => !centralBoxTypeGuard(box))
      );
      zone.status = this.canApplyLayoutAction((wall, layout) => action(layout)) ? ZoneStatus.Valid : ZoneStatus.Invalid;
    }

    return zones;
  }

  private getNewRowsDropZones(): DropZone[] {
    if (!this.newDraggingBox) {
      return [];
    }

    const zones = getNewRowsDropZones(this.newDraggingBox, this.layout, this.model.location);

    for (const zone of zones) {
      const action = newRowAction(
        this.newDraggingBox,
        zone.data.row,
        this.additionalBoxes.filter(box => !centralBoxTypeGuard(box))
      );
      zone.status = this.canApplyLayoutAction((wall, layout) => action(layout)) ? ZoneStatus.Valid : ZoneStatus.Invalid;
    }
    return zones;
  }

  private getNeighboursDropZones(): DropZone[] {
    if (this.existingDraggingBox) {
      const zones = getNeighboursDropZones(this.layout, this.existingDraggingBox);

      for (let zone of zones) {
        if (zone.data && zone.data.type === 'neighbour') {
          const action = shiftNeighbourAction(this.existingDraggingBox, zone.data.position);
          zone.status = this.canApplyLayoutAction((wall, layout) => action(layout))
            ? ZoneStatus.Valid
            : ZoneStatus.Invalid;
        }
      }

      return zones;
    }

    return [];
  }

  private getSwapDropZones(): DropZone[] {
    if (this.existingDraggingBox) {
      const zones = getSwapDropZones(this.layout, this.existingDraggingBox);
      for (let zone of zones) {
        if (zone.data && zone.data.type === 'swap') {
          const action = swapSingleAction(this.existingDraggingBox, zone.data.boxes);
          zone.status = this.canApplyLayoutAction((wall, layout) => {
            action(layout);
          })
            ? ZoneStatus.Valid
            : ZoneStatus.Invalid;
        }
      }
      return zones;
    }
    return [];
  }

  private hasEngraving(predicate: (engraving: IMailBoxEngraving) => boolean): boolean {
    for (let index = 0; index < this.model.boxes.length; index++) {
      const box = this.model.boxes[index].box;

      if (mailBoxTypeGuard(box)) {
        return box.engraving.some(predicate);
      }
    }
    return false;
  }

  private findLockTypeBox = (wall: IWall, box: IMailBox | IParcelBox, lockType: MailboxLockType | ParcelLockType) => {
    const options = this.engine.optionValuesByPath(wall, `boxes.box`, new FlatStructuralTransformer<WallBox>()) ?? [];
    const boxes = options.filter(({ model }) => {
      if (model.type !== box.type || model.name !== box.name) {
        return false;
      }
      return (model as IMailBox | IParcelBox).lockType === lockType;
    });
    if (boxes.length !== 1) {
      throw new Error(`Expect to find one box. But did found ${boxes.length} boxes.`);
    }
    return boxes[0];
  };

  private canApplyLayoutAction(action: (wall: IWall, layout: Layout) => void) {
    let validationResult: ValidationResult;
    try {
      const baseMutator = new BaseMutator(
        this.postProcessor,
        this.layoutRulesManager,
        this.domainRulesManager,
        action,
        this.rawModel
      );
      const copy = CloneService.cloneWallLayout(this.rawModel);

      [, validationResult] = baseMutator.mutate(this.engine, 'Wall', copy);
    } catch (e) {
      console.error(e);
      return false;
    }

    console.log('[canApplyLayoutAction] validation result ', validationResult.isValid, validationResult.errorMessage);

    return validationResult.isValid;
  }

  private canApplySimpleMutation(action: (wall: IWall, layout: Layout) => void) {
    let validationResult: ValidationResult;
    try {
      const baseMutator = new BaseMutator(
        this.postProcessor,
        this.layoutRulesManager,
        this.domainRulesManager,
        action,
        this.rawModel
      );
      [, validationResult] = this.engine.mutate(this.rawModel, baseMutator);
    } catch (e) {
      return false;
    }
    return validationResult.isValid;
  }

  private simpleMutation(action: (wall: IWall, layout: Layout) => void) {
    console.time('simpleMutation');
    const baseMutator = new BaseMutator(
      this.postProcessor,
      this.layoutRulesManager,
      this.domainRulesManager,
      action,
      this.rawModel
    );
    const [newModel, validationResult] = this.engine.mutate(this.model, baseMutator);
    if (validationResult.isInvalid) {
      console.timeEnd('simpleMutation');
      throw new Error(validationResult.errorMessage);
    }
    this.layout = baseMutator.layout;
    this.setModel(newModel as IWall);
    console.log('MODEL=', newModel);
    this.dimensionService = new DimensionsService(this.model, this.layout);
    this.weightService = new WeightService(this.entityFinder, this.model);
    this.costSummaryService = new CostSummaryService(this.entityFinder, this.model, this.summaryGroupsOrder);
    console.timeEnd('simpleMutation');
  }

  private fireEvent(eventName: string) {
    const handlers = this.handlers.get(eventName) ?? [];
    handlers.forEach(handler => handler());
  }

  private toScreenZone<T extends Zone>(zone: T, shift = 0): T {
    const { x, y } = this.getScreenPosition(zone.centerX, zone.centerY);
    const leftX = zone.centerX - 0.5 * zone.width;
    const rightX = zone.centerX + 0.5 * zone.width;
    const topY = zone.centerY - 0.5 * zone.height;
    const bottomY = zone.centerY + 0.5 * zone.height;
    const { x: left, y: top } = this.getScreenPosition(leftX, topY);
    const { x: right, y: bottom } = this.getScreenPosition(rightX, bottomY);
    const width = Math.abs(right - left);
    const height = Math.abs(top - bottom);
    return { ...zone, centerX: x + shift, centerY: y, width, height };
  }

  toggleSummaryItem(groupName: string, subgroupName: string) {
    this.simpleMutation(wall => {
      const items = wall.accessories.filter(accessory => accessory.group === groupName);
      for (let item of items) {
        item.included = !item.included;
      }
    });
  }

  private setModel(newModel: IWall): void {
    this.model = newModel;
    this.rawModel = toJS(this.model);
  }

  onMobileInsertStart(box: WallBox) {
    this.mode = VisualMode.Insert;
    this.onDragStart(box, 0.5 * document.body.clientWidth, 0.5 * document.body.clientHeight);
  }

  onMobileMove(clientX: number, clientY: number): void {
    if (this.draggingBox) {
      this.onMove(clientX, clientY);
    }
  }

  cancelBoxInsertion(): void {
    this.draggingBox = null;
    this.dragPoint = null;
    this.mode = VisualMode.View;
  }

  continueBoxInsertion() {
    this.mode = VisualMode.Edit;
  }

  async createConfigurationId(): Promise<string> {
    const erp = new IntegrationService(this, new BomService(this.entityFinder, this.model));
    return await erp.createConfiguration();
  }

  async addToCart() {
    try {
      this.addToCartDisabled = true;
      rootStore.notificationsStore.info(TranslatedMessage.create('message.addToCart.process'), 'spinner');
      const configurationId = await this.createConfigurationId();
      if (!rootStore.standalone) {
        window.parent.postMessage(
          {
            configurationId,
            productId: rootStore.getProductId(),
            variantId: '',
            quantity: 1
          },
          '*'
        );
        rootStore.notificationsStore.info(TranslatedMessage.create('message.addToCart.success'));
      }
    } catch (e: any) {
      console.error(e.toString());
      rootStore.notificationsStore.error(TranslatedMessage.create('message.addToCart.error'), 'exclamation');
    } finally {
      this.addToCartDisabled = false;
    }
  }
}
