import LayoutForm from '../../../../components/LayoutForm/LayoutForm';
import { WallLayout } from '../../../../schema';
import React from 'react';
import { IWizardStepProps } from '../../WizardStepper';
import { observer } from 'mobx-react';
import styles from './WizardLayoutSelector.module.scss';

const WizardLayoutSelector: React.VFC<IWizardStepProps> = observer(({ index, onChange, initialIsTouched, store }) => {
  const [touched, setTouched] = React.useState(initialIsTouched);

  const handleOnCompleted = React.useCallback(() => {
    onChange(index);
  }, [index, onChange]);

  const handleOnChange = React.useCallback(() => {
    setTouched(true);
    handleOnCompleted();
  }, [setTouched, handleOnCompleted]);

  return (
    <div>
      {store.availableLayouts.length &&
        <LayoutForm
          wallLayout={touched ? store.model.layout : undefined}
          availableWallLayouts={store.availableLayouts as WallLayout[]}
          wizardStore={store}
          onSelect={handleOnChange}
          className={styles.layoutSelector__section}
        />
      }
    </div>
  );
});

export default WizardLayoutSelector;
