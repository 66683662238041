import { Mesh, Object3D } from 'three';
import { IMorph, IPosition3D } from '../types';
import { morph } from '../utils';
import { MeshUtils } from '../MeshUtils';

const defaultData: IMorph = {
    name: 'height',
    min: 1.2,
    max: 1.8
}

export class FootObject {
  readonly node: Object3D;
  readonly obj: Object3D;
  readonly mesh: Mesh;
  readonly heightMorph: IMorph;

  constructor(obj: Object3D, morphData: IMorph = defaultData) {
    this.node = new Object3D();
    this.obj = obj.clone();
    this.mesh = this.obj.children[0] as Mesh;
    this.mesh.geometry = this.mesh.geometry.clone();
    this.node.add(this.obj);
    this.heightMorph = morphData;
  }

  setPosition(pos: IPosition3D) {
    this.node.position.set(pos.x, pos.y, pos.z);
  }

  setHeight(height: number) {
    morph(this.mesh, this.heightMorph, height);
    MeshUtils.applyMorphing(this.mesh);
  }

}
