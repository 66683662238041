import { createUrl } from './utils';
import { WallType } from '../schema';

const DEBUG_MAIL = 'test@test.test';

export interface EmailDto {
  configurationId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  street: string;
  nr: string;
  zip: string;
  city: string;
  country: string;
  remarks: string;
  link: string;
}

interface IGeneralParameter {
  name: string;
  value: string;
}

export interface IAdditionalParameter {
  type: string;
  name: string;
  amount: string;
  value: string;
}

export interface IRemark {
  type: string;
  value: string;
}

export type Item = IAdditionalParameter | IRemark;

export interface IComponent {
  title: string;
  items: Item[];
  optional?: boolean;
}

export interface IInfoComponent {
  title: string;
  image: string;
}

export interface IPdfTitle {
  configurationSpec: string;
  viewConfig: string;
  components: string;
  info: string;
  agreement: {name: string, list: string[]};
  wiring: {name: string, list: string[]}
}

export interface IPdfGeneralInfo {
  design: IGeneralParameter[],
  structure: IGeneralParameter[]
}

export interface PdfDto {
  isAuth: boolean;
  isDigital: boolean;
  sceneImg: string;
  date: string;
  link: string;
  title: IPdfTitle;
  totalPrice: string;
  priceAddInfo: string;
  vatText: string,
  generalInfo: IPdfGeneralInfo;
  components: IComponent[];
  boxesInfo: IInfoComponent[];
}

export interface ErrorReport {
  project: string;
  mobile: string;
  desktop: string;
  errors: ErrorDto[]
}

export interface ErrorDto {
  clientTime: string;
  url: string;
  message: string;
  line: number;
  column: number;
}

export class ContactService {
  async sendEmail(emailDto: EmailDto): Promise<void> {
    const url = `${process.env.REACT_APP_CL__CONTACT_US_SERVICE_URL}api/email/`;
    const templateUrl = createUrl('assets/templates/email');

    const body = {
      debug: emailDto.email === DEBUG_MAIL,
      templateUrl: templateUrl,
      clearCache: process.env.NODE_ENV === 'development',
      model: emailDto
    };
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    if (response.status >= 300) {
      throw new Error(response.statusText);
    }
  }

  async reportErrors(errorReport: ErrorReport): Promise<void> {
    const url = `${process.env.REACT_APP_CL__CONTACT_US_SERVICE_URL}api/email/error_report`;
    const body = {
      debug: process.env.REACT_APP_CL__SEND_ERROR === 'YES',
      model: errorReport
    };
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    if (response.status >= 300) {
      console.error(response.statusText)
    }
  }

  async generatePdf(pdfDto: PdfDto, wall: WallType): Promise<any> {
    const url = `${process.env.REACT_APP_CL__CONTACT_US_SERVICE_URL}api/pdf/`;
    console.log(pdfDto);
    let templateUrl = createUrl('assets/templates/pdf');

    const result = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        templateUrl,
        clearCache: process.env.NODE_ENV === 'development',
        model: pdfDto
      })
    });
    if (![200, 201].includes(result.status)) {
      throw new Error(result.statusText);
    }
    return wall === WallType.Digital ? await result.arrayBuffer() : await result.blob();
  }
}

export const contactService = new ContactService();
