import { Index, Layout } from './Layout';
import { ILayoutTraverser, ISize } from '../generator/ILayoutTraverser';
import { Position } from './Position';
import { LayoutBox, PositionedLayoutBox } from './Box';
import { toJS } from 'mobx';
import { ILayoutData } from './LayoutFactory';
import { WallLayout } from '../schema';
import { belongsTo } from '../services/utils';

export interface ZFormLayoutConstraint {
  w1: number;
  w2: number;
  w3: number;
  h1: number;
  h2: number;
  h3: number;
}

const MAX_ROWS = 32;

export class ZFormLayout extends Layout
  implements ILayoutTraverser {

  private validCellIndexes = new Set<Index>();

  public readonly MAX_ROWS = MAX_ROWS;

  getLayoutData(): ILayoutData {
    return {
      layout: WallLayout.Z,
      layoutOptions: this.constraint,
      cellHeight: this.cellHeight,
      cellWidth: this.cellWidth,
      offsetHeight: this.offsetHeight,
      boxes: this.getBoxes()
    }
  }

  get maxFreeCells() {
    return this.validCellIndexes.size;
  }

  get columns(): number {
    return this.constraint.w1 + this.constraint.w2 + this.constraint.w3;
  }

  get rows(): number {
    return MAX_ROWS;
  }

  get height(): number {
    return this.constraint.h1 + this.constraint.h2 + this.constraint.h3;
  }

  constructor(public constraint: ZFormLayoutConstraint, boxes: PositionedLayoutBox[] = [], cellWidth: number, cellHeight: number) {
    super(
      MAX_ROWS,
      constraint.w1 + constraint.w2 + constraint.w3,
      boxes,
      cellWidth,
      cellHeight
    );

    this.recalculateValidIndices();
  }

  recalculateValidIndices() {
    this.validCellIndexes = new Set<Index>();
    for (const position of this.traverseTopToBottom({ rows: 1, columns: 1 })) {
      this.validCellIndexes.add(this.calculateIndex(position.row, position.column));
    }
  }

  addColumns(index: number, count: number) {
    let { w1, w2 } = this.constraint;
    if (index > w1 + w2) {
      this.constraint.w3 += count;
    } else if (index > w1) {
      this.constraint.w2 += count;
    } else {
      this.constraint.w1 += count;
    }

    super.addColumns(index, count);
    this.recalculateValidIndices();
  }

  removeColumn(column: number, count: number) {
    super.removeColumn(column, count);

    let { w1, w2 } = this.constraint;
    if (column >= w1 + w2) {
      this.constraint.w3 -= count;
    } else if (column >= w1) {
      this.constraint.w2 -= count;
    } else {
      this.constraint.w1 -= count;
    }

    this.updateLayout();
    this.recalculateValidIndices();
  }

  canInsertBoxAt(box: LayoutBox, position: Position): boolean {
    const isFree = super.canInsertBoxAt(box, position);
    if (!isFree) {
      return false;
    }
    for (let dx = 0; dx < box.columns; dx++) {
      for (let dy = 0; dy < box.rows; dy++) {
        const cellIndex = this.calculateIndex(position.row + dy, position.column + dx);
        if (!this.validCellIndexes.has(cellIndex)) {
          return false;
        }
      }
    }
    return true;
  }

  getCentralPosition(lightBox: LayoutBox): Position {
    const width = this.constraint.w1;
    const column = Math.ceil((width - lightBox.columns) / 2);
    const row = MAX_ROWS - lightBox.rows;
    return { row, column };
  }

  traverseBottomToTop(size: ISize): Iterable<Position> {
    // TODO: Implement
    return new ZFormTopBottomTraverser(this.constraint);
  }

  traverseTopToBottom(size: ISize): Iterable<Position> {
    return new ZFormTopBottomTraverser(this.constraint);
  }

  clone(): ZFormLayout {
    const boxes: PositionedLayoutBox[] = [];
    for (const box of this.boxes) {
      boxes.push(Object.assign({}, toJS(box)));
    }
    return new ZFormLayout(this.constraint, boxes, this.cellWidth, this.cellHeight);
  }

  * getRows(column: number): Iterable<number> {
    const { w1, w2, w3, h1, h2, h3 } = this.constraint;
    const height = h1 + h2 + h3;

    if (belongsTo(column, 0, w1)) {
      for (let h = 0; h < h1; h++) {
        const row = MAX_ROWS - 1 - h;
        yield row;
      }
    } else if (belongsTo(column, w1, w1 + w2)) {
      for (let h = 0; h < height; h++) {
        const row = MAX_ROWS - 1 - h;
        yield row;
      }

    } else if (belongsTo(column, w1 + w2, w1 + w2 + w3)) {
      for (let h = 0; h < h3; h++) {
        const row = MAX_ROWS - 1 - h1 - h2 - h;
        yield row;
      }
    }
  }
}

export class ZFormTopBottomTraverser {
  constructor(private readonly constraint: ZFormLayoutConstraint) {
  }

  * [Symbol.iterator]() {
    const { w1, w2, w3, h1, h2, h3 } = this.constraint;
    // w1 x h1
    const height = h1 + h2 + h3;
    for (let w = 0; w < w1; w++) {
      for (let h = 0; h < h1; h++) {
        const row = MAX_ROWS - 1 - h;
        const column = w;
        yield { row, column };

      }
    }
    // w2 x (h1 + h2 + h3)
    for (let w = 0; w < w2; w++) {
      for (let h = 0; h < height; h++) {
        const row = MAX_ROWS - 1 - h;
        const column = w1 + w;
        yield { row, column };
      }
    }
    // w3 x h3
    for (let w = 0; w < w3; w++) {
      for (let h = 0; h < h3; h++) {
        const row = MAX_ROWS - 1 - h1 - h2 - h;
        const column = w1 + w2 + w;
        yield { row, column };
      }
    }
  }
}

