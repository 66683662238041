import * as React from "react";
import classNames from 'classnames';
import s from './Dialog.module.scss';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import Typography from '../Typography/Typography';

interface IProps {
  title: JSX.Element | string;
  className?: string;
  onClose: () => void;
  footer?: JSX.Element;
  closeDisabled?: boolean;
  onAnimationEnd?: React.AnimationEventHandler;
}

export const Dialog: React.FC<IProps> = ({ title, className, children, onClose, footer, closeDisabled, onAnimationEnd }) => {
  return (
    <div className={s.wrapper} onAnimationEnd={onAnimationEnd}>
      <section className={classNames(s.root, className)}>
        <header className={s.header}>
          <Typography className={s.title} variant="h4">
            {title}
          </Typography>
          <Button onClick={onClose} variant="secondary" disabled={closeDisabled}>
            <Icon type="close" size="small" />
          </Button>
        </header>
        <main className={s.content}>
          {children}
        </main>
        {footer ?
          <footer className={s.footer}>
            {footer}
          </footer>
         : null
        }
      </section>
    </div>
  );
}