import { Action } from '../../rules/LayoutRulesManager';
import { Layout } from '../../layout/Layout';
import { WallBox } from "../../schema";
import { NewColumnBehaviour, NewColumnType } from "./behaviour";

export function newColumnAction(box: WallBox, columnType: NewColumnType, availableBoxes: WallBox[]): Action {
  return (layout: Layout) => {
    const behaviour = new NewColumnBehaviour(layout);
    behaviour.newColumn(columnType, box, availableBoxes);
  };
}
