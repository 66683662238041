import React from 'react';
import { observer } from 'mobx-react';
import WallLocationForm from '../../../../components/WallLocationForm/WallLocationForm';
import { WallLocation } from '../../../../schema';
import { IWizardStepProps } from '../../WizardStepper';
import styles from './WizardWallLocationSelector.module.scss';

const WizardWallLocationSelector: React.VFC<IWizardStepProps> = observer(({ index, onChange, initialIsTouched, store }) => {
  const [touched, setTouched] = React.useState(initialIsTouched);

  const handleOnCompleted = React.useCallback(() => {
    onChange(index);
  }, [index, onChange]);

  const handleOnChange = React.useCallback(() => {
    setTouched(true);
    handleOnCompleted();
  }, [setTouched, handleOnCompleted]);

  return (
    <div>
      {store.model.wallLocation && (
        <WallLocationForm
          wallLocation={touched ? store.model.wallLocation : undefined}
          availableWallLocations={store.availableWallLocations as WallLocation[]}
          wizardStore={store}
          onSelect={handleOnChange}
          className={styles.wallLocation__section}
        />
      )}
    </div>
  );
});

export default WizardWallLocationSelector;
