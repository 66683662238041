import { FormattedMessage } from 'react-intl';
import Button from '../Button/Button';
import s from './EmptyTemplate.module.scss';
import Icon from '../Icon/Icon';
import * as React from 'react';
import Logo from '../Logo/Logo';

interface IProps {
  backLink: string;
}

export const EmptyTemplate: React.FC<IProps> = (({ backLink }) => {
  return <div className={s.root}>
    <header className={s.header}>
      <Button
        variant="secondary"
        href={backLink}
      >
        <Icon type="ArrowLeft" size="medium" />
      </Button>
      <div className={s.logo}>
        <Logo />
      </div>
    </header>
    <main className={s.main}>
      <div className={s.icon} />
      <div className={s.textWrapper}>
        <p className={s.placeholderText}>
          <FormattedMessage id="template.empty-configuration.placeholder-text" />
        </p>
        <p className={s.placeholderText}>
          <FormattedMessage id="template.empty-configuration.placeholder-suggestion" />
        </p>
      </div>
    </main>
    <div className={s.footer}>
      <Button
        href={backLink}
      >
        <FormattedMessage id="template.back.to.wizard" />
      </Button>
    </div>
  </div>;
});