import { Position } from '../../layout/Position';
import { Action } from '../../rules/LayoutRulesManager';
import { Layout } from '../../layout/Layout';
import { SwapBehaviour } from './behaviour';

export function swapSingleAction(source: Position, destination: Position[]): Action {
  return (layout: Layout) => {
    const behaviour = new SwapBehaviour(layout);
    behaviour.swapSingle(source, destination);
  };
}