import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Typography from '../Typography/Typography';

import styles from './WizardStepper.module.scss';


interface Step {
  path: string;
  title: string;
  hidden?: boolean;
}

interface IProps {
  steps: Step[];
  current?: number;
  passedSteps: boolean[];
  queryString: string;
}

const WizardStepper: React.VFC<IProps> = ({ current = 0, steps, passedSteps, queryString }) => {
  const lastAvailableStep = useMemo(() => {
    const firstUnfinishedStep = passedSteps.findIndex((progress, index) => !progress && !steps[index].hidden);

    let firstAvailableStep = firstUnfinishedStep === -1 ? passedSteps.length : firstUnfinishedStep;
    while (firstAvailableStep < steps.length && steps[firstAvailableStep].hidden) {
      firstAvailableStep++;
    }

    return firstAvailableStep;
  }, [passedSteps, steps]);

  const handleOnClick = (index: number) => (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (index > lastAvailableStep) {
      event.preventDefault();
      return;
    }
  };

  const stepsWithVisibleIndices = useMemo(() => {
    let visibleIndex = 0;
    return steps.map((step, index) => {
      return {
        ...step,
        visibleIndex: step.hidden ? index : visibleIndex++
      };
    });
  }, [steps]);

  return (
    <nav className={styles.root}>
      {stepsWithVisibleIndices.map(({ title, path, hidden, visibleIndex }, index) => (
        <Link
          key={title}
          to={{ pathname: `../${path}`, search: queryString }}
          relative="path"
          onClick={handleOnClick(index)}
          className={classNames(styles.link, {
            [styles.disabled]: index > lastAvailableStep || hidden,
            [styles.hidden]: hidden
          })}
          tabIndex={index > lastAvailableStep || hidden ? -1 : 0}
        >
          <div
            className={classNames(styles.step, {
              [styles.active]: index === current,
              [styles.disabled]: index > lastAvailableStep || hidden,
              [styles.hidden]: hidden
            })}
          >
            <span
              className={classNames(styles.stepNumber, {
                [styles.active]: index === current,
                [styles.passed]: passedSteps[index],
                [styles.disabled]: index > lastAvailableStep
              })}
            >
              <span className={classNames(styles.stepDigit, { [styles.hidden]: visibleIndex !== index })}>
                <Typography variant="body2">{index + 1}</Typography>
              </span>
              <span className={classNames(styles.stepDigit, { [styles.hidden]: visibleIndex === index })}>
                <Typography variant="body2">{visibleIndex + 1}</Typography>
              </span>
            </span>
            <span
              className={classNames(styles.stepName, {
                [styles.active]: index === current,
                [styles.disabled]: index > lastAvailableStep
              })}
            >
              <Typography variant="body1" weight="semi-bold">
                <FormattedMessage id={title} />
              </Typography>
            </span>
          </div>
        </Link>
      ))}
    </nav>
  );
};

export default WizardStepper;
