import { PositionedLayoutBox } from '../../layout/Box';
import { Layout } from '../../layout/Layout';
import { WallBox } from '../../schema';
import { DropZoneBase } from '../types';
import { addPosition, Position, subtractPosition } from '../../layout/Position';

interface BoxSet {
  width: number;
  height: number;
  boxes: PositionedLayoutBox[];
  position: Position;
}

export interface InstallationData {
  type: 'installation';
  boxes: PositionedLayoutBox[];
}

export interface InstallationZone extends DropZoneBase {
  data: InstallationData;
}

export function getInstallationZones(layout: Layout, wallBox: WallBox): InstallationZone[] {
  // nearly the same as in swap' behaviour:
  const expectedHeight = wallBox.rows;
  const expectedWidth = wallBox.columns;

  const zones: InstallationZone[] = [];
  for (let startColumn = 0; startColumn < layout.columns - expectedWidth + 1; startColumn++) {
    for (let startRow = 0; startRow < layout.rows - expectedHeight + 1;) {
      const boxSet = findBoxSet(startRow, startColumn, expectedWidth, expectedHeight, layout);
      if (boxSet) {
        zones.push(createInstallationZone(layout, boxSet));
        startRow += layout.findBoxAt({ row: startRow, column: startColumn }).box.rows;
      } else {
        startRow++;
      }
    }
  }

  // the new part: find boxes greater than the wallBox
  let largeBoxes = layout.boxes
    .filter(({ box }) => (box.columns > expectedWidth && box.rows >= expectedHeight) || (box.columns >= expectedWidth && box.rows > expectedHeight))

  if(wallBox.type !== 'centralbox') {
    // find large boxes in which the wallBox can be added without gaps
    largeBoxes = largeBoxes.filter(({ box }) => box.columns % expectedWidth === 0 && box.rows % expectedHeight === 0);
  }

  // convert the large boxes into drop zones
  largeBoxes.forEach(box => {
    const boxSet = {
      width: box.box.columns,
      height: box.box.rows,
      boxes: [{
        ...box,
      }],
      position: {
        row: box.row,
        column: box.column
      }
    }
    zones.push(createInstallationZone(layout, boxSet));
  })

  return zones;
}

// nearly the same as swap's findBoxSet
function findBoxSet(startRow: number, startColumn: number, expectedWidth: number, expectedHeight: number, layout: Layout): BoxSet | null {
  const destinationLayout = new Layout(expectedHeight, expectedWidth, [], layout.cellWidth, layout.cellHeight);
  for (let col = startColumn; col < startColumn + expectedWidth; col++) {
    for (let row = startRow; row < startRow + expectedHeight; row++) {
      const box = layout.safeFindBoxAt({ row, column: col });
      if (!box) {
        return null;
      }
      const p = subtractPosition(box, { row: startRow, column: startColumn });

      if (destinationLayout.canInsertBoxAt(box.box, p)) {
        destinationLayout.insertBox({
          ...box,
          ...p
        });

        if (destinationLayout.totalFreeCells() === 0) {
          return {
            width: expectedWidth,
            height: expectedHeight,
            boxes: destinationLayout.boxes.map(box => ({
              ...box,
              ...addPosition(box, { row: startRow, column: startColumn })
            })),
            position: {
              row: startRow,
              column: startColumn
            }
          };
        }
      } else {
        if (destinationLayout.totalFreeCells() === 0) {
          return null;
        }
      }
    }
  }
  return null;
}

// nearly the same as swap's createSwapZone
function createInstallationZone(layout: Layout, boxSet: BoxSet): InstallationZone {
  const {
    centerX,
    centerY
  } = layout.toWorldCoordinates(boxSet.position.row, boxSet.position.column, boxSet.width, boxSet.height);
  const width = boxSet.width * layout.cellWidth;
  const height = boxSet.height * layout.cellHeight;
  return {
    width,
    height,
    centerX,
    centerY,
    data: {
      type: 'installation',
      boxes: boxSet.boxes
    }
  };
}
