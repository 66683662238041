import { ShelteringPostProcessor } from './ShelteringPostProcessor';
import { IWall, WallType } from '../schema';
import { IPostProcessor } from './IPostProcessor';
import { SoclePostProcessor } from './SoclePostProcessor';
import { PackagingPostProcessor } from './PackagingPostProcessor';
import { HangingBracketPostProcessor } from './HangingBracketsPostProcessor';
import { BackplatesPostProcessor } from './BackplatesPostProcessor';
import { CentralBoxPostProcessor } from './CentralBoxPostProcessor';
import { CentralBoxAccessoriesPostProcessor } from './CentralBoxAccessoriesPostProcessor';
import { MailBoxPostProcessor } from './MailBoxPostProcessor';
import { MountingFootPostProcessor } from './MountingFootsPostProcessor';
import { RFIDBadgesPostProcessor } from './RFIDBadgesPostProcessor';

export class WallPostProcessor {
  constructor(
    private readonly roofPostProcessor: ShelteringPostProcessor,
    private readonly soclePostProcessor: SoclePostProcessor,
    private readonly packagingPostProcessor: PackagingPostProcessor,
    private readonly hangingBracketPostProcessor: HangingBracketPostProcessor,
    private readonly backplatesPostProcessor: BackplatesPostProcessor,
    private readonly centralBoxPostProcessor: CentralBoxPostProcessor,
    private readonly centralBoxAccessoriesProcessor: CentralBoxAccessoriesPostProcessor,
    private readonly mailBoxAccessoriesPostProcessor: MailBoxPostProcessor,
    private readonly mountingFootPostProcessor: MountingFootPostProcessor,
    private readonly rfidBadgesPostProcessor: RFIDBadgesPostProcessor
  ) {}

  process(wall: IWall): void {
    let processors: IPostProcessor[] = [];

    switch (wall.wallType) {
      case WallType.Digital:
      case WallType.Mechanical:
        processors = [
          this.roofPostProcessor,
          this.soclePostProcessor,
          this.packagingPostProcessor,
          this.hangingBracketPostProcessor,
          this.backplatesPostProcessor,
          this.centralBoxPostProcessor,
          this.centralBoxAccessoriesProcessor,
          this.rfidBadgesPostProcessor
        ];
        break;
      case WallType.Boxis:
      case WallType.Interna:
        processors = [
          this.centralBoxPostProcessor,
          this.centralBoxAccessoriesProcessor,
          this.mailBoxAccessoriesPostProcessor,
          this.mountingFootPostProcessor
        ];
        break;
    }

    processors.forEach(processor => processor.process(wall));
  }
}
