import { makeAutoObservable } from 'mobx';
import { ContactService } from '../services/ContactService';
import { ConfiguratorStore } from './ConfiguratorStore';

export class ContactMeStore {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  country: string;
  city: string;
  street: string;
  no: string;
  postalCode: string;
  remark: string;

  constructor(
    readonly availableCountries: string[],
    country: string,
    private readonly contactService: ContactService,
    private readonly shareableLink: string,
    private readonly configuratorStore: ConfiguratorStore
  ) {
    this.firstName = window.sessionStorage.getItem('contact.firstName') || '';
    this.lastName = window.sessionStorage.getItem('contact.lastName') || '';
    this.email = window.sessionStorage.getItem('contact.email') || '';
    this.phone = window.sessionStorage.getItem('contact.phone') || '';
    this.country = window.sessionStorage.getItem('contact.country') || country;
    this.city = window.sessionStorage.getItem('contact.city') || '';
    this.street = window.sessionStorage.getItem('contact.street') || '';
    this.no = window.sessionStorage.getItem('contact.no') || '';
    this.postalCode = window.sessionStorage.getItem('contact.postalCode') || '';
    this.remark = '';
    makeAutoObservable(this);
  }

  setFirstName(value: string) {
    this.firstName = value;
    window.sessionStorage.setItem('contact.firstName', value);
  }

  setLastName(value: string) {
    this.lastName = value;
    window.sessionStorage.setItem('contact.lastName', value);
  }

  setEmail(value: string) {
    this.email = value;
    window.sessionStorage.setItem('contact.email', value);
  }

  setPhone(value: string) {
    this.phone = value;
    window.sessionStorage.setItem('contact.phone', value);
  }

  setCountry(value: string) {
    this.country = value;
    window.sessionStorage.setItem('contact.country', value);
  }

  setCity(value: string) {
    this.city = value;
    window.sessionStorage.setItem('contact.city', value);
  }

  setStreet(value: string) {
    this.street = value;
    window.sessionStorage.setItem('contact.street', value);
  }

  setNo(value: string) {
    this.no = value;
    window.sessionStorage.setItem('contact.no', value);
  }

  setPostalCode(value: string) {
    this.postalCode = value;
    window.sessionStorage.setItem('contact.postalCode', value);
  }

  setRemark(value: string) {
    this.remark = value;
  }

  get isValid(): boolean | null {
    return this.isValidEmail && this.isValidFirstName && this.isValidLastName && this.isValidPhone;
  }

  get isValidFirstName(): boolean {
    return !!this.firstName;
  }

  get isValidLastName(): boolean {
    return !!this.lastName;
  }

  get isValidEmail() {
    if (this.email) {
      const regularExpression =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regularExpression.test(this.email.toLowerCase());
    }
    return false;
  }

  get isValidPhone(): boolean {
    return !!this.phone;
  }

  async sendEmail(): Promise<void> {
    const configurationId = await this.configuratorStore.createConfigurationId();
    return await this.contactService.sendEmail({
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phone: this.phone,
      street: this.street,
      nr: this.no,
      city: this.city,
      country: this.country,
      zip: this.postalCode,
      remarks: this.remark,
      link: this.shareableLink,
      configurationId
    });
  }
}
