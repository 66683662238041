import { IPostProcessor } from './IPostProcessor';
import { IWall, WallLayout } from '../schema';
import { LayoutFactory } from '../layout/LayoutFactory';

export class SoclePostProcessor implements IPostProcessor {
  process(wall: IWall): void {
    if (wall.layout === WallLayout.Standalone) {
      const columnsCount = LayoutFactory.create(wall).columns;
      const socleRules = wall.socleRules;

      wall.socles = [];

      socleRules.forEach((rule) => {
        if (rule.columns === columnsCount) {
          let collectingArray = new Array(rule.amount).fill(rule.socle);
          wall.socles.push(...collectingArray);
        }
      });

    } else {
      wall.socles = [];
    }
  }
}