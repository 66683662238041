import * as React from 'react';
import classNames from 'classnames';

import { NotificationIcon, NotificationType } from '../../stores/NotificationsStore';
import Typography from '../Typography/Typography';
import './Toast.scss';

interface IProps {
  type: NotificationType;
  icon?: NotificationIcon;
  hasArrow?: boolean;
  className?: string;
}

class Toast extends React.Component<IProps> {
  render() {
    const { className, children, type, hasArrow } = this.props;

    return (
      <div
        className={classNames(
          'toast',
          {
            'toast--info': type === NotificationType.INFO,
            'toast--warning': type === NotificationType.WARNING,
            'toast--error': type === NotificationType.ERROR,
            'toast--with-arrow': hasArrow
          },
          className
        )}
      >
        {/*{icon &&*/}
        {/*  (icon === 'spinner' ? <Spinner className="toast__spinner" /> : <Icon type={icon} className="toast__icon" />)}*/}
        <Typography variant="body2" component="div">
          {children}
        </Typography>
      </div>
    );
  }
}

export default Toast;
