import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { rootStore } from './stores';

import { Navigate, Route, Routes } from 'react-router-dom';
import { RawIntlProvider } from 'react-intl';

import Configurator from './pages/Configurator/Configurator';
import { Generator } from './pages/Generator/Generator';
import { DeviceType, routes } from './stores/RootStore';

import styles from './App.module.scss';
import { WallType } from './schema';
import { Controller } from './Controller';
import WizardStepper from './pages/WizardStepper/WizardStepper';
import NavigateWithQuery from './components/NavigateWithQuery/NavigateWithQuery';
import { Loading } from './pages/Loading/Loading';
import { menu } from './stores/ConfiguratorMenuStore';

function App() {
  React.useEffect(() => {
    rootStore.init();
  }, []);

  const handleMediaChanged = useCallback(({ matches }: { matches: boolean }) => {
    const deviceType = matches ? DeviceType.HANDLET : DeviceType.DESKTOP;
    rootStore.setDeviceType(deviceType);
    menu.setDeviceType(deviceType);
  }, []);

  useEffect(() => {
    let dispose;
    const mediaQueryList = window.matchMedia('only screen and (max-width: 785px)');
    handleMediaChanged(mediaQueryList);

    if (mediaQueryList.addEventListener) {
      mediaQueryList.addEventListener('change', handleMediaChanged);
      dispose = () => mediaQueryList.removeEventListener('change', handleMediaChanged);
    } else {
      // Safari does not treat MediaQueryList as EventTarget yet
      mediaQueryList.addListener(handleMediaChanged);
      dispose = () => mediaQueryList.removeListener(handleMediaChanged);
    }
    return dispose;
  });

  const { isLoading, localization } = rootStore;
  return isLoading ? <Loading /> : (
    <div className={styles.layout}>
      <RawIntlProvider value={localization.intl!}>
        <Routes>
          <Route path="/" element={<Controller />} />
          <Route path={`${routes.digitalWizard}/:stage`}
                 element={<WizardStepper wallType={WallType.Digital} key="digital" />} />
          <Route path={routes.digitalWizard} element={<Navigate to={`${routes.digitalWizard}/country`} replace />} />
          <Route path={`${routes.mechanicalWizard}/:stage`}
                 element={<WizardStepper wallType={WallType.Mechanical} key="mechanical" />} />
          <Route path={routes.mechanicalWizard}
                 element={<Navigate to={`${routes.mechanicalWizard}/country`} replace />} />
          <Route path={`${routes.internaWizard}/:stage`}
                 element={<WizardStepper wallType={WallType.Interna} key="interna" />} />
          <Route path={routes.internaWizard} element={<Navigate to={`${routes.internaWizard}/intercom`} replace />} />
          <Route path={`${routes.boxisWizard}/:stage`}
                 element={<WizardStepper wallType={WallType.Boxis} key="boxis" />} />
          <Route path={routes.boxisWizard} element={<Navigate to={`${routes.boxisWizard}/mountingtype`} replace />} />

          <Route path={`${routes.generalWizard}/:stage`}
                 element={<WizardStepper wallType={WallType.Mechanical} key="general" />} />
          <Route path={routes.generalWizard} element={<NavigateWithQuery to={`./0`} relative="path" replace />} />

          <Route path={routes.generator} element={<Generator />} />
          <Route path={routes.configurator} element={<Configurator />} />
          {/*<Route path="/generator-sandbox" element={<GeneratorSandbox store={store}/>}/>*/}
        </Routes>
      </RawIntlProvider>
    </div>
  );
}

export default observer(App);
