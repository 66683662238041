import {
  CentralBoxExternalFields,
  ICentralBox,
  IMailBox,
  IWall,
  IWizard,
  MailBoxExternalFields,
  WallBox
} from '../schema';
import { deepCopy, Engine, FlatStructuralTransformer, Option, PModel } from '@canvas-logic/engine';
import { centralBoxTypeGuard, mailBoxTypeGuard } from '../guards';
import { WallPostProcessorFactory } from '../postprocessors/WallPostProcessorFactory';
import { RulesManagerFactory } from '../rules/RulesManagerFactory';
import { BaseMutator, emptyAction } from '../mutators/BaseMutator';
import { LayoutFactory } from '../layout/LayoutFactory';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from 'worker-loader!../workers/worker';
import { callWorker } from '../workers/utils';
import { AlboLayoutResult } from './alboLayout';
import { rootStore } from '../stores';



export class InternaGenerator {
  private readonly boxes: Option<WallBox>[];
  public readonly mailBoxes: IMailBox[];
  public readonly centralBoxes: ICentralBox[];
  private readonly mailboxesByRows = new Map<number, IMailBox>();
  private readonly centralboxesByRows = new Map<number, ICentralBox>();
  static readonly MAX_ROWS = 12;

  constructor(
    private readonly engine: Engine,
    private readonly referenceProduct: PModel<IWall>
  ) {
    this.boxes = this.engine.optionValuesByPath(
      this.referenceProduct,
      'boxes.box',
      new FlatStructuralTransformer<WallBox>()
    ) ?? [];

    this.mailBoxes = [];
    this.centralBoxes = [];

    for (const box of this.boxes) {
      const model = box.model;
      if (mailBoxTypeGuard(model)) {
        this.mailBoxes.push(model);
        if (this.mailboxesByRows.has(model.rows)) {
          throw new Error(`Only one mailboxes with rows = ${model.rows} expected`);
        }
        this.mailboxesByRows.set(model.rows, model);
      } else if (centralBoxTypeGuard(model)) {
        this.centralBoxes.push(model);
        if (this.centralboxesByRows.has(model.rows)) {
          throw new Error(`Only one centralbox with rows = ${model.rows} expected`);
        }
        this.centralboxesByRows.set(model.rows, model);
      } else {
        throw new Error('Invalid box type');
      }
    }
  }

  async generate(wizard: IWizard): Promise<IWall[]> {
    let allBoxes: number[] = [];
    let cu: boolean[] = [];

    if (wizard.hasCentralBox) {
      let size = InternaGenerator.getCUSize(wizard.customIntercom?.height);
      if (size === undefined) {
        allBoxes.push(3, 4, 5);
        cu.push(true, true, true);
      } else {
        allBoxes.push(size);
        cu.push(true);
      }
    }

    if (wizard.mailBox.rows === 1) {
      allBoxes.push(1, 2);
    } else {
      allBoxes.push(2, 1);
    }
    cu.push(false, false);

    const worker = new Worker();
    const configurations = await callWorker<AlboLayoutResult>(worker, {
      kind: 'albo',
      mailboxes: wizard.mailBoxesAmount,
      allBoxes,
      cu,
      minRows: 1,
      maxRows: InternaGenerator.MAX_ROWS
    });
    worker.terminate();
    return configurations.map(configuration => this.createModel(wizard, configuration.layout, allBoxes, cu));
  }

  public static getCUSize(intercomHeight: number | undefined): number | undefined {
    if (!intercomHeight) {
      return undefined;
    }
    if (intercomHeight <= 250) {
      return 3;
    }
    if (intercomHeight > 250 && intercomHeight <= 360) {
      return 4;
    }
    if (intercomHeight <= 470) {
      return 5;
    }
    throw new Error('Invalid size of CU');
  }

  private createModel(wizard: IWizard, columnsLayout: number[][], allBoxSizes: number[], cu: boolean[]): IWall {
    const model = deepCopy(this.referenceProduct);
    let totalRows = 0;
    const rowBase = 0;
    // first column (with central box)
    const firstColumn = columnsLayout[0];
    let row = rowBase;
    for (let i = firstColumn.length - 1; i >= 0; i--) {
      const boxSize = allBoxSizes[i];
      const box = cu[i]
        ? this.centralboxesByRows.get(boxSize)
        : this.mailboxesByRows.get(boxSize);

      if (!box) {
        throw new Error(`Cannot find box with rows = ${boxSize}`);
      }
      for (let n = 0; n < firstColumn[i]; n++) {
        totalRows += boxSize;
        model.boxes.push({
          row,
          column: 0,
          box
        });
        row += boxSize;
      }
    }

    // rest columns (no central box)
    const mailBoxSizes = allBoxSizes.filter((v, i) => !cu[i]);
    for (let column = 1; column < columnsLayout.length; column++) {
      const maxIndex = columnsLayout[column].length - 1;
      let row = rowBase;
      for (let i = maxIndex; i >= 0; i--) {
        // const boxSize =
        const boxSize = mailBoxSizes[i];
        const box = this.mailboxesByRows.get(boxSize);
        if (!box) {
          throw new Error(`Cannot find box with rows = ${boxSize}`);
        }
        for (let n = 0; n < columnsLayout[column][i]; n++) {
          model.boxes.push({
            row,
            column,
            box
          });
          row += boxSize;
        }
      }
    }

    this.calculateOffset(model, totalRows, wizard.mailBoxesAmount);

    model.layoutOptions = {
      row: {
        min: rowBase,
        max: rowBase + totalRows - 1
      },
      column: {
        min: 0,
        max: columnsLayout.length - 1
      }
    };
    model.hasOwnKeyPlan = wizard.hasOwnKeyPlan;
    model.customIntercom = wizard.customIntercom;
    const centralBoxExternalFields = new CentralBoxExternalFields(rootStore.datasetService);
    const mailBoxExternalFields = new MailBoxExternalFields(rootStore.datasetService);
    const wallPostProcessor = WallPostProcessorFactory.create(this.engine, this.referenceProduct, centralBoxExternalFields, mailBoxExternalFields);
    const layout = LayoutFactory.create(model);
    const layoutRulesManager = RulesManagerFactory.createAlbaRulesManager(model, layout);
    const domainRulesManager = RulesManagerFactory.createDomainRulesManager();
    const baseMutator = new BaseMutator(wallPostProcessor, layoutRulesManager, domainRulesManager, emptyAction, model);
    const [newModel, validationResult] = this.engine.mutate(model, baseMutator);
    if (validationResult.isInvalid) {
      throw new Error(validationResult.errorMessage);
    }
    return newModel as IWall;
  }

  private calculateOffset(model: IWall, totalRows: number, mailboxesAmount: number): void {
    const MAX_ROW = 1800;
    const MIN_ROW = 400;
    const AVG_ROW = 1500;
    const maxTotalHeight = MAX_ROW - MIN_ROW;
    const totalHeight = model.cellHeight * 1000 * totalRows;
    if (totalHeight > maxTotalHeight) {
      throw new Error('Exceeded maxTotalHeight value');
    }
    // check if avg can be 1500 mm
    const offset = AVG_ROW - 0.5 * totalHeight;
    const top = totalHeight + offset;
    if (mailboxesAmount > 3 && top <= MAX_ROW) {
      // place at average
      model.offsetHeight = offset / 1000;
    } else {
      // place at top
      model.offsetHeight = (MAX_ROW - totalHeight) / 1000;
    }
  }
}
