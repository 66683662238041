import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { ConfiguratorStore } from '../../stores/ConfiguratorStore';
import { ConfiguratorMenuStore } from '../../stores/ConfiguratorMenuStore';
import ZoomBottomMenu from '../ZoomBottomMenu/ZoomBottomMenu';

interface IProps {
  store: ConfiguratorStore;
  menu: ConfiguratorMenuStore;
}

export const ZoomedMenu: React.FC<IProps> = observer(({ store, menu }) => {
  const leaveZoomedMainMenu = useCallback(() => {
    store.zoomOut();
    menu.enterOverviewMainMenu();
  }, [store, menu]);
  return <ZoomBottomMenu
    onGearClick={store.canChangeLockForSelectedBox() ? menu.enterBoxContextMainMenu : undefined}
    onZoomClick={leaveZoomedMainMenu}
  />;
});