import { ICentralBox, IMailBox, WallBox } from '../schema';
import { getOptionId } from '@canvas-logic/engine';
import { centralBoxTypeGuard, mailBoxTypeGuard } from '../guards';

export class BoxModelPathService {
  static getWallBoxPath(box: WallBox) {
    let path = box.model3D;

    if (mailBoxTypeGuard(box)) {
      path = path.replace('%ENGRAVING%', BoxModelPathService.getEngravingPart(box));
    }

    if (centralBoxTypeGuard(box)) {
      path = path.replace('%BELLS_AMOUNT%', BoxModelPathService.getBellsAmount(box));
    }

    return path;
  }

  private static getEngravingPart(box: IMailBox) {
    const ap = box.engraving.some(engraving => engraving.apartment);
    let np = box.engraving.some(engraving => engraving.name);
    const pvc = box.engraving.some(engraving => engraving.pvc);
    if (pvc) {
      np = false;
    }
    let parts = [];
    if (np) {
      parts.push('np');
    }
    if (ap) {
      parts.push('ap');
    }
    if (pvc) {
      parts.push('pvc');
    }

    if (parts.length === 0) {
      return '';
    }

    return '-' + parts.join('-');
  }

  private static getBellsAmount(box: ICentralBox): string {
    return box.bellsAmount > 0
      ? box.bellsAmount.toString()
      : '';
  }
}