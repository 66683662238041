export const dataCyAttribute = {
  countryNameInput: 'countryNameInput',
  selectItemChecked: 'selectItemChecked',
  autocompleteListItem: 'autocompleteListItem',
  countryNameItem: 'countryNameItem',
  mailboxHeight: 'mailboxHeight'
};
export type DataCyAttribute = keyof typeof dataCyAttribute;
export function dataCy(key?: DataCyAttribute) {
  if (!key) {
    return {};
  }
  const value: string = dataCyAttribute[key];
  return { 'data-cy': value };
}
