import { Layout } from '../../layout/Layout';
import { WallBox } from '../../schema';
import { ZFormLayout } from '../../layout/ZFormLayout';
import { RectangleLayout } from '../../layout/RectangleLayout';
import { HorizontalPlatesSplitter } from '../../services/HorizontalPlatesSplitter';
import { ValueSplitService } from '../../layout/ValueSplitService';


export type NewRowType = 'Top' | 'Bottom';


export class NewRowBehaviour {
  public constructor(private readonly layout: Layout) { }

  public newRow(rowType: NewRowType, box: WallBox, availableBoxes: WallBox[]): void {
    const widthInCells: number = this.layout.width;
    const heightInCells: number = box.rows;
    const newRowIndex: number = this.getNewRowIndex(rowType, heightInCells);

    const horizontalPlatesColumnGroupsWidths: number[] = HorizontalPlatesSplitter.findFinishingModules(this.layout.columns, this.layout.boxes);

    if (this.layout instanceof ZFormLayout) {
      console.error('Adding rows is not available currently  for ZFormLayout');
      throw new Error('Adding rows is not available currently  for ZFormLayout');
    }

    if (box.columns > widthInCells) {
      console.error('Unable to put box with width large than current layout-width');
      throw new Error('Unable to put box with width large than current layout-width');
    }


    if (rowType === 'Top') {
      this.layout.appendRowsToTop(box.rows);
    } else if (rowType === 'Bottom') {
      this.layout.insertRowsToBottom(box.rows);
    }

    if (box.columns === 1) {
      this.fillRowWithBox(newRowIndex, box);
      return;
    }


    const boxesWithSameHeight: WallBox[] = availableBoxes
      .filter(b => b.rows === heightInCells)
      .filter(b => b.article !== box.article)
      .sort((box1, box2) => box1.columns - box2.columns).slice(0, 1);
    // Getting all boxes with the same height and put main (selected) box to first position
    // After usage of ValueSplitService when sorting we can get rows with predominance of main box or without it

    if (boxesWithSameHeight.length > 0 && boxesWithSameHeight[0].columns === 1) {
      this.fillRowWithMainBox(newRowIndex, box, boxesWithSameHeight[0]);
      return;
    }

    if (Math.max(...horizontalPlatesColumnGroupsWidths) < box.columns) {
      console.error('Selected box can not be put in new row without changing finishing modules positions');
      throw new Error('Selected box can not be put in new row without changing finishing modules positions');
    }

    boxesWithSameHeight.unshift(box);
    const widthesOfBoxesWithSameHeight: number[] = boxesWithSameHeight.map(box => box.columns);

    let currentColumnNumber = 0;
    let hasInsertedMainBox = false;
    const configuredRow: Array<{ box: WallBox, row: number, col: number }> = [];

    horizontalPlatesColumnGroupsWidths.forEach((columnGroupWidth: number): void => {
      let result: Array<number[]> = [];

      if (hasInsertedMainBox) {
        result = ValueSplitService.split(columnGroupWidth, widthesOfBoxesWithSameHeight);
      } else {
        if (columnGroupWidth >= box.columns) {
          configuredRow.push({ box: box, row: newRowIndex, col: currentColumnNumber });
          currentColumnNumber += box.columns;
          hasInsertedMainBox = true;

          const restColumns: number = columnGroupWidth - box.columns;

          if (restColumns === 0) return;
          result = ValueSplitService.split(restColumns, widthesOfBoxesWithSameHeight).sort((res1, res2) => res1[0] - res2[0]);
        } else {
          result = ValueSplitService.split(columnGroupWidth, widthesOfBoxesWithSameHeight);
        }
      }

      if (result.length === 0) throw Error('Unable to create finishing plate from boxes');

      const otherBoxes = result[0]; // preferable set of boxes

      otherBoxes.forEach((item, index) => {
        if (item === 0) return;

        for (let counter = 0; counter < item; ++counter) {
          configuredRow.push({ box: boxesWithSameHeight[index], row: newRowIndex, col: currentColumnNumber });
          currentColumnNumber += boxesWithSameHeight[index].columns;
        }
      });
    });

    configuredRow.forEach(box => {
      if (this.layout.canInsertBoxAt(box.box, { row: box.row, column: box.col })) {
        this.layout.insertBox({ row: box.row, column: box.col, box: box.box });
      }
    });
  }

  private getNewRowIndex(rowType: NewRowType, rowHeight: number): number {
    switch (rowType) {
      case 'Bottom': {
        return (this.layout as RectangleLayout).constraint.row.min - rowHeight;
      }
      case 'Top':
        return this.layout.rows;
    }
  }

  private fillRowWithBox(row: number, box: WallBox): void {
    for (let column = 0; column < this.layout.columns; column++) {
      if (this.layout.canInsertBoxAt(box, { row, column })) {
        this.layout.insertBox({ box, row, column });
      }
    }
  }

  private fillRowWithMainBox(row: number, mainBox: WallBox, oneColumnBox: WallBox): void {
    const maxMainBoxPosition = this.layout.columns - mainBox.columns;
    for (let position = 0; position <= maxMainBoxPosition; position++) {
      const layout = this.layout.clone();

      this.insertRowWithMainBoxAt(layout, position, row, mainBox, oneColumnBox);
      if (this.canCoverLayoutWithFinishingPlates(layout)) {
        this.insertRowWithMainBoxAt(this.layout, position, row, mainBox, oneColumnBox);
      }
    }
  }

  private insertRowWithMainBoxAt(layout: Layout, position: number, row: number, mainBox: WallBox, oneColumnBox: WallBox): void {
    for (let column = 0; column < this.layout.columns; column++) {
      const box = position === column ? mainBox : oneColumnBox;
      if (layout.canInsertBoxAt(box, { column, row })) {
        layout.insertBox({ box, column, row });
      }
    }
  }

  private canCoverLayoutWithFinishingPlates(layout: Layout) {
    try {
      if (HorizontalPlatesSplitter.findFinishingModules(layout.columns, layout.boxes).length > 0) {
        return true;
      }
    } catch (e) {
      return false;
    }
  }
}
