import { EntityFinder } from './EntityFinder';
import { IWall } from '../schema';
import { BigNumber } from '@canvas-logic/engine';
import { DimensionsService } from './DimensionsService';
import { LayoutFactory } from '../layout/LayoutFactory';

export class WeightService {
  constructor(
    private readonly finder: EntityFinder,
    private readonly model: IWall
  ) {
  }

  get weight(): BigNumber {
    const result = this.finder.byComponents(this.model, ['Weight']);
    let totalWeight = new BigNumber(0);
    for (let record of result) {
      totalWeight = totalWeight.plus(record.entity.weight);
    }

    let dynamicSizeElementsTotalWeight = new BigNumber(0);
    const dimensionService = new DimensionsService(this.model, LayoutFactory.create(this.model));
    const finishingPlates = this.model.finishingPlates;

    // weightPerSize of one bottom finishing plate multiplied with total width
    if (finishingPlates.bottom.length !== 0) {
      dynamicSizeElementsTotalWeight = dynamicSizeElementsTotalWeight.plus(finishingPlates.bottom[0].weightPerSize.mulBy(dimensionService.width));
    }

    // weightPerSize of one top finishing plate multiplied with total width
    if (finishingPlates.top.length !== 0) {
      dynamicSizeElementsTotalWeight = dynamicSizeElementsTotalWeight.plus(finishingPlates.top[0].weightPerSize.mulBy(dimensionService.width));
    }

    // weightPerSize of one left finishing plate multiplied with total height
    if (finishingPlates.left.length !== 0) {
      dynamicSizeElementsTotalWeight = dynamicSizeElementsTotalWeight.plus(finishingPlates.left[0].weightPerSize.mulBy(dimensionService.height));
    }

    // weightPerSize of one right finishing plate multiplied with total height
    if (finishingPlates.right.length !== 0) {
      dynamicSizeElementsTotalWeight = dynamicSizeElementsTotalWeight.plus(finishingPlates.right[0].weightPerSize.mulBy(dimensionService.height));
    }

    totalWeight = totalWeight.plus(dynamicSizeElementsTotalWeight);

    return totalWeight;
  }
}