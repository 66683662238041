import { Layout } from '../../layout/Layout';
import { addPosition, Position, subtractPosition } from '../../layout/Position';
import { PositionedLayoutBox } from '../../layout/Box';

export class SwapBehaviour {
  constructor(private readonly layout: Layout) {
  }

  swapSingle(sourcePosition: Position, destinationPositions: Position[]) {
    const source = this.layout.findBoxAt(sourcePosition);
    const destination = destinationPositions.map(p => this.layout.findBoxAt(p));
    const destPosition = getDestinationPosition(destination);
    const shift = subtractPosition(destPosition, source);

    this.layout.removeBox(source);
    destination.forEach(dest => this.layout.removeBox(dest));
    setBoxPosition(source, addPosition(sourcePosition, shift));
    destination.forEach(dest => setBoxPosition(dest, subtractPosition(dest, shift)));

    if (!destination.includes(source)) {
      this.layout.insertBox(source);
    }
    destination.forEach(dest => this.layout.insertBox(dest));
  }
}

function setBoxPosition(box: PositionedLayoutBox, position: Position) {
  box.row = position.row;
  box.column = position.column;
}

function getDestinationPosition(destination: Position[]): Position {
  let minRow = Infinity;
  let minCol = Infinity;
  for (let boxPosition of destination) {
    minRow = Math.min(boxPosition.row, minRow);
    minCol = Math.min(boxPosition.column, minCol);
  }
  return { row: minRow, column: minCol };
}

