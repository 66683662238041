import { IPostProcessor } from './IPostProcessor';
import { IWall } from '../schema';
import { LayoutFactory } from '../layout/LayoutFactory';

export class PackagingPostProcessor implements IPostProcessor {

  process(wall: IWall): void {
    const columnsCount = LayoutFactory.create(wall).columns;

    wall.packagings = [];
    wall.packagingRules.forEach((rule) => {

      if (rule.columns === columnsCount) {
        wall.packagings.push(
          rule.packaging
        );
      }
    });
  }
}