import {
  IConcreteBox,
  IWall,
  MailBoxEngravingMaterial,
  MountingType,
  TextAlignment,
  WallLayout,
  WallLocation,
  WallType
} from '../schema';
import { IPosition3D } from './types';
import BoxViewModelMaker from './BoxViewModelMaker';
import { IDimensionsService } from '../services/DimensionsService';
import { rootStore } from '../stores';

export type View = string;

export interface ScreenBox {
  x: number;
  y: number;
  z: number;
  view: View;
  positionedBox: IConcreteBox;
}

export interface ScreenLeftRightPlate {
  view: View;
}

export interface ScreenMountingFoots {
  view: View,
  pos: IPosition3D,
  height: number
}

export interface ScreenTopBottomPlate {
  view: View,
  pos: IPosition3D,
  width: number,
}

export interface ScreenLeftRightPlate {
  view: View,
  pos: IPosition3D,
  height: number,
}

export interface ScreenRoofTopPlate {
  view: View;
  pos: IPosition3D;
  text: string;
  textAlignment: TextAlignment;
}

export interface ScreenRoof {
  topPlates: ScreenRoofTopPlate[];
  leftRights: ScreenLeftRightPlate[];
}

export interface ScreenSocle {
  view: View;
  pos: IPosition3D;
  width: number,
}

export interface MaterialViewModel {
  color: string;
}

export interface DimensionsViewModel {
  width: number; //mm
  height: number; //mm
}
export enum HDR {
  Inside = 'Inside.hdr',
  Mobile = 'Mobile.hdr',
  Outside = 'Outside.hdr'
}
export interface BoxViewModel {
  dimensions: DimensionsViewModel;
  boxes: ScreenBox[];
  topBottoms: ScreenTopBottomPlate[];
  leftRights: ScreenLeftRightPlate[];
  mountingFoots: ScreenMountingFoots[];
  roof: ScreenRoof;
  socle?: ScreenSocle,
  width: number;
  height: number;
  cellWidth: number;
  cellHeight: number;
  offsetHeight: number;
  material: MaterialViewModel;
  engravingMaterial: MailBoxEngravingMaterial | undefined;
  mountingType: MountingType;
  layout: WallLayout;
  location: WallLocation;
  wallType: WallType;
  hdr: HDR;
  interior: string;
  boxDepth: number;
}

export function createViewModel(model: IWall, dimensionsService: IDimensionsService, engravingMaterial: MailBoxEngravingMaterial | undefined): BoxViewModel {
  const maker = new BoxViewModelMaker(model, dimensionsService, engravingMaterial);

  return maker.make();
}
export function createPreviewViewModel(model: IWall, dimensionsService: IDimensionsService, engravingMaterial: MailBoxEngravingMaterial | undefined): BoxViewModel {
  const viewModel = createViewModel(model, dimensionsService, engravingMaterial);
  if (rootStore.isDeviceLowPerformant) {
    viewModel.interior = '';
  }
  return viewModel;
}
