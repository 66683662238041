import classNames from 'classnames';
import * as React from 'react';
import { useCallback } from 'react';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import styles from './CustomizeBottomMenu.module.scss';
import './CustomizeBottomMenu.module.scss';
import { VisualMode } from '../../stores/ConfiguratorStore';

export enum SelectedOption {
  None = 'None',
  Color = 'Color',
  Text = 'Text',
  Add = 'Add',
  Delete = 'Delete',
}

interface IProps {
  className?: string;
  selectedMode: VisualMode;
  onAddClick: () => void;
  onColorClick: () => void;
  onDeleteClick: () => void;
  onTextClick: () => void;
  onHintClick: () => void;
  onModeSelect: (mode: VisualMode) => void;
  selectedOption: SelectedOption;
  isAddRemoveHidden: boolean;
}

const CustomizeBottomMenu: React.FC<IProps> = ({
                                                 className,
                                                 selectedMode,
                                                 onAddClick,
                                                 onColorClick,
                                                 onDeleteClick,
                                                 onTextClick,
                                                 onModeSelect,
                                                 onHintClick,
                                                 selectedOption,
                                                 isAddRemoveHidden
                                               }) => {

  const handleModeSelection = useCallback(() => {
    const newMode = selectedMode === VisualMode.Edit ? VisualMode.View : VisualMode.Edit;
    onModeSelect(newMode);
  }, [onModeSelect, selectedMode]);
  return (
    <div className={classNames(styles.root, className)}>
      <div className={classNames(styles.wrapper)}>
        <Button
          variant="secondary"
          className={classNames(styles.singleButton, {
              [styles.selected]: selectedOption === SelectedOption.Color
            }
          )}
          onClick={onColorClick}
        >
          <Icon type="color" />
        </Button>
      </div>
      <div className={classNames(styles.wrapper)}>
        <Button
          variant="secondary"
          className={classNames(styles.singleButton, {
              [styles.selected]: selectedOption === SelectedOption.Text
            }
          )}
          onClick={onTextClick}
        >
          <Icon type="text" />
        </Button>
      </div>
      {!isAddRemoveHidden && (
        <div className={classNames(styles.wrapper)}>
          <Button
            variant="secondary"
            className={classNames(styles.singleButton, {
              [styles.selected]: selectedOption === SelectedOption.Add
            })}
            onClick={onAddClick}
          >
            <Icon type="add" />
          </Button>
        </div>
      )}
      {!isAddRemoveHidden && (
        <div className={classNames(styles.wrapper)}>
          <Button
            variant="secondary"
            className={classNames(styles.singleButton, {
                [styles.selected]: selectedOption === SelectedOption.Delete
              }
            )}
            onClick={onDeleteClick}
          >
            <Icon type="remove" />
          </Button>
        </div>
      )}
      <div className={classNames(styles.wrapper)}>
        <Button
          variant="secondary"
          className={classNames(styles.singleButton, {
              [styles.selected]: selectedMode === VisualMode.Edit
            }
          )}
          onClick={handleModeSelection}
        >
          <Icon type="editMode" />
        </Button>
      </div>
      {(selectedMode === VisualMode.Edit || selectedOption === SelectedOption.Add) && (
        <div className={classNames(styles.hint, { [styles.desktopOnly]: selectedOption === SelectedOption.Add })}>
          <div className={styles.separator}></div>
          <div className={classNames(styles.wrapper)}>
            <Button
              variant="secondary"
              className={classNames(styles.singleButton, styles.singleButtonAccent)}
              onClick={onHintClick}
            >
              <Icon type="hint" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomizeBottomMenu;
