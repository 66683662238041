import * as React from 'react';
import classNames from 'classnames';
import { v4 } from 'uuid';
import Typography from '../Typography/Typography';

import styles from './TextField.module.scss';
import { dataCy, DataCyAttribute } from '../../cy';

interface IClasses {
  inputMultiline?: string;
}

interface IProps {
  id?: string;
  name?: string;
  type?: HTMLInputElement['type'];
  inputMode?: HTMLInputElement['inputMode'];
  required?: boolean;
  maxLength?: number;
  autoFocus?: boolean;
  label?: string | JSX.Element;
  hint?: string;
  error?: boolean;
  errorLabel?: string | JSX.Element;
  disabled?: boolean;
  multiline?: boolean;
  placeholder?: string;
  className?: string;
  classes?: IClasses;
  value?: string;
  onChange?: React.ChangeEventHandler;
  onClick?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  startAdornment?: React.ReactElement | string;
  endAdornment?: React.ReactElement | string;
  min?: number;
  max?: number;
  componentDataCy?: DataCyAttribute;
}

interface IState {
  id: string;
}

class TextField extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { id } = this.props;

    this.state = {
      id: id ?? v4()
    };
  }

  handleOnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { onChange, maxLength, type, min, max } = this.props;
    const value = event.target.value;

    if (maxLength !== undefined && value.length > maxLength) {
      return;
    }

    if (type === 'number') {
      if (min !== undefined && Number.parseFloat(value) < min) {
        return;
      }
      if (max !== undefined && Number.parseFloat(value) > max) {
        return;
      }
    }

    if (onChange) {
      onChange(event);
    }
  };

  render() {
    const {
      name,
      type,
      required,
      className,
      classes,
      autoFocus,
      label,
      hint,
      error,
      errorLabel,
      disabled,
      multiline,
      placeholder,
      value,
      startAdornment,
      endAdornment,
      min,
      max,
      onClick,
      onFocus,
      onBlur,
      componentDataCy
    } = this.props;
    const { id } = this.state;

    const Component: React.ElementType = multiline ? 'textarea' : 'input';

    return (
      <div className={classNames(styles.textField, {
        [styles.textFieldAdornmentStart]: startAdornment,
        [styles.textFieldAdornmentEnd]: endAdornment,
        [styles.textFieldError]: error,
        [styles.textFieldDisabled]: disabled,
      }, className)}>
        {label &&
        <label htmlFor={id} className={styles.label}>
          <Typography variant="body2">
            {label} 
            {hint && <small className={styles.hint}>({hint})</small>}
            {error ? errorLabel && <small className={styles.errorLabel}>{errorLabel}</small> : null}
          </Typography>          
        </label>
        }
        <div className={
          classNames(
            styles.inputWrapper,
            {[styles.inputWrapperMultiline]: multiline})
        }>
          {startAdornment &&
          <div className={classNames(styles.adornment, styles.adornmentStart)}>
            {startAdornment}
          </div>
          }
          <Component
            id={id}
            name={name}
            type={type}
            required={required}
            className={classNames(styles.input, {
              [styles.inputMultiline]: multiline,
              [classes?.inputMultiline ?? '']: multiline,
            })}
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onChange={this.handleOnChange}
            onClick={onClick}
            onFocus={onFocus}
            onBlur={onBlur}
            autoFocus={autoFocus}
            min={min}
            max={max}
            {...dataCy(componentDataCy)}
          />
          {endAdornment &&
          <div className={classNames(styles.adornment, styles.adornmentEnd)}>
            {endAdornment}
          </div>
          }
        </div>
      </div>
    );
  }
}

export default TextField;
