import { Layout } from '../../layout/Layout';


export class RemoveColumnBehaviour {
  constructor(private readonly layout: Layout) {
  }

  removeColumns(start: number, count: number) {
    this.layout.removeColumn(start, count);
  }
}
