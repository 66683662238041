import * as React from 'react';
import { CSSProperties, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import s from './Generator.module.scss';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { rootStore } from '../../stores';
import { GeneratorStore } from '../../stores/GeneratorStore';
import { WallPreview } from '../../components/WallPreview/WallPreview';
import { routes } from '../../stores/RootStore';
import classNames from 'classnames';
import Button from '../../components/Button/Button';
import Typography from '../../components/Typography/Typography';
import { FormattedMessage } from 'react-intl';
import Logo from '../../components/Logo/Logo';
import Icon from '../../components/Icon/Icon';
import { EmptyTemplate } from '../../components/EmptyTemplate/EmptyTemplate';
import Loader from '../../components/Loader/Loader';
import { LinkService } from '../../services/LinkService';

export const Generator = observer(() => {
  const [searchParams] = useSearchParams();
  const link = searchParams.get('link');
  if (!link) {
    throw new Error('No link');
  }

  const [store] = React.useState<GeneratorStore>(() => new GeneratorStore(rootStore, link));
  const refCarousel = React.useRef<HTMLUListElement>(null);

  const [scrollMargin, setScrollMargin] = React.useState(0);

  React.useLayoutEffect(() => {
    if (!refCarousel.current) {
      return;
    }

    const sm = Number.parseInt(getComputedStyle(refCarousel.current).getPropertyValue('--scroll-margin'), 10);
    if (!Number.isNaN(sm) && sm !== scrollMargin) {
      setScrollMargin(sm);
    }
  }, [scrollMargin, store.previews.length]);

  const moveToSlideNumber = useCallback((number: number) => {
    const scrollContainer = refCarousel.current;

    if (!scrollContainer) {
      return;
    }

    const left = (scrollContainer.scrollWidth - scrollMargin * 2) / store.previews.length * number;

    scrollContainer.scroll({
      left,
      behavior: 'smooth'
    });
  }, [refCarousel, scrollMargin, store.previews.length]);

  const onPreviewClick = (ownSlideNumber: number) => {
    moveToSlideNumber(ownSlideNumber);
  };

  const [loadedPreviews, setLoadedPreviews] = useState(0);
  const increaseLoadedPreviews = useCallback(() => {
    setLoadedPreviews(v => v + 1);
  }, []);

  const [scrollProgress, setScrollProgress] = useState(0);

  const handleScroll = (event: React.UIEvent<HTMLUListElement>) => {
    const p = event.currentTarget.scrollLeft / ((event.currentTarget.scrollWidth - scrollMargin * 2) / store.previews.length);
    setScrollProgress(Math.min(p, store.previews.length - 1));
  };

  const backLink = `${routes.generalWizard}?${createSearchParams({ link }).toString()}`;

  if (!store.previews.length) {
    return store.previewLoaded
      ? (<EmptyTemplate backLink={backLink} />)
      : (<section className={classNames(s.root)}>
        <div className={s.loader}>
          <Loader />
        </div>
      </section>);
  }

  return (
    <section className={classNames(s.root, { [s.loaded]: loadedPreviews })}>
      <div className={s.loader}>
        <Loader />
      </div>
      <header className={s.header}>
        <Button
          className={s.arrowButton}
          variant="secondary"
          href={backLink}
        >
          <Icon type="ArrowLeft" size="small" />
        </Button>
        <div className={s.logo}>
          <Logo />
        </div>
      </header>
      <main className={s.main}>
        <div className={s.carouselWrapper}>
          <div
            className={s.info}
            style={{ '--scroll-opacity': `${Math.max(0, 1 - scrollProgress * 8)}` } as CSSProperties}
          >
            <Typography variant="h3" className={s.hint}>
              <FormattedMessage id="template.selections.info.text" />
            </Typography>
          </div>
          <div className={s.slider}>
            <div className={s.frame}>
              <div className={s.prevButtonContainer}>
                <Button
                  variant="secondary"
                  disabled={scrollProgress < 1}
                  onClick={() => moveToSlideNumber(Math.max(0, Math.floor(scrollProgress) - 1))}
                >
                  <Icon type="left" />
                </Button>
              </div>
              <div className={s.nextButtonContainer}>
                <Button
                  variant="secondary"
                  disabled={scrollProgress >= store.previews.length - 1}
                  onClick={() => moveToSlideNumber(Math.min(store.previews.length - 1, Math.floor(scrollProgress) + 1))}
                >
                  <Icon type="right" />
                </Button>
              </div>
            </div>

            <ul
              ref={refCarousel}
              className={s.carouselTrack}
              onScroll={handleScroll}
            >
              {store.previews.map((preview, i) => {
                const scrollScale = Math.max(0.5, Math.min(1, 1 - Math.abs(scrollProgress - i)));
                const isCurrent = Math.abs(scrollProgress - i) < 0.2;

                return (
                  <li
                    style={{ '--scroll-scale': scrollScale } as CSSProperties}
                    className={classNames(s.slide, { [s.currentSlide]: isCurrent })}
                    key={`preview-${i}`}
                    onClick={() => {
                      onPreviewClick(i);
                    }}
                  >
                    <WallPreview viewModel={preview} onLoaded={increaseLoadedPreviews}
                                 render={loadedPreviews === i} />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className={s.carouselNav}>
          {store.previews.map((slide, i) => {
            const isCurrentDot = Math.abs(scrollProgress - i) < 0.2;

            return (
              <div onClick={() => moveToSlideNumber(i)} key={i} className={classNames(s.carouselIndicator)}>
                <span className={classNames(s.carouselIndicatorDot, { [s.currentDot]: isCurrentDot })} />
              </div>
            );
          })}
        </div>
      </main>
      <div className={s.footer}>
        <Button
          href={`${routes.configurator}?${createSearchParams({
            v: LinkService.version,
            link: store.getLinkForModel(Math.round(scrollProgress)),
            wizardLink: link
          }).toString()}`}
        >
          <FormattedMessage id="template.selections.view.configuration.btn.label" />
        </Button>
      </div>
    </section>
  );
});
