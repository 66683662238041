import SimpleColorConverter from 'simple-color-converter';
import {
  ICentralBox,
  ICustomIntercom,
  IntercomSetting,
  IWall,
  MailboxLockType,
  ParcelLockType,
  WallType
} from '../schema';
import Localization from '../stores/Localization';
import { LayoutBox, PositionedLayoutBox } from '../layout/Box';
import { Layout } from '../layout/Layout';
import { centralBoxTypeGuard, mailBoxTypeGuard } from '../guards';

interface IColorError {
  error: string;
}

interface IColorResult {
  color: string;
}

export function colorErrorTypeGuard(color: any): color is IColorError {
  return color && 'error' in color;
}

export function colorResultTypeGuard(color: any): color is IColorResult {
  return color && !colorErrorTypeGuard(color) && 'color' in color;
}

export type ColorConversionResult = IColorError | IColorResult;

export function ralToHex(ralColorCode: number): ColorConversionResult {
  return new SimpleColorConverter({
    ral: { ral: ralColorCode },
    to: 'hex'
  });
}

export function isMobile(): boolean {
  const query = window.matchMedia('only screen and (max-width: 600px)');
  return query && query.matches;
}

export function takeTop<T extends { id: number }>(amount: number, items: T[], weightFunction: (item: T) => number): T[] {
  const weightedItems = items.map(item => ({
    item,
    weight: weightFunction(item) //
  }));
  const result: T[] = [];
  const taken = new Set<number>();
  let resultLength = Math.min(weightedItems.length, amount);
  while (resultLength-- > 0) {
    let candidateWeight = Infinity,
      candidateIndex = -1;
    for (let i = 0; i < weightedItems.length; i++) {
      if (!taken.has(i) && weightedItems[i].weight <= candidateWeight) {
        candidateIndex = i;
        candidateWeight = weightedItems[i].weight;
      }
    }
    taken.add(candidateIndex);
    // operationLog.take(weightedItems[candidateIndex].item.id);
    result.push(weightedItems[candidateIndex].item);
  }

  return result;
}

/**
 * @param hex
 * @param blackAndWhiteOnly
 */
export function invertColor(hex: string, blackAndWhiteOnly?: boolean): string {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  const r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);
  if (blackAndWhiteOnly) {
    // https://stackoverflow.com/a/3943023/112731
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
  }
  // invert color components
  const red = (255 - r).toString(16);
  const green = (255 - g).toString(16);
  const blue = (255 - b).toString(16);
  // pad each with zeros and return
  return '#' + red.padStart(2) + green.padStart(2) + blue.padStart(2);
}

export async function copyTextToClipboard(text: string) {
  return new Promise<void>(resolve => {
    setTimeout(() => {
      const tmpElement = document.createElement('textarea');
      tmpElement.value = text;
      document.body.appendChild(tmpElement);
      tmpElement.select();
      document.execCommand('copy');
      document.body.removeChild(tmpElement);

      resolve();
    }, 1);
  });
}

export const groupBy = <T>(data: T[], key: keyof T | ((value: T) => any)): Record<string, T[]> => {
  return data.reduce((acc, iteratee) => {
    let groupingKey;
    if (typeof key === 'function') {
      groupingKey = key(iteratee);
    } else {
      groupingKey = iteratee[key];
    }

    if (!acc[groupingKey]) {
      acc[groupingKey] = [];
    }

    acc[groupingKey].push(iteratee);

    return acc;
  }, {} as Record<string, T[]>);
};

export const uniq = <T>(data: T[], key: keyof T | ((value: T) => any)): T[] => {
  const set = new Set();
  const result: T[] = [];

  for (const iteratee of data) {
    let uniqueAttr;
    if (typeof key === 'function') {
      uniqueAttr = key(iteratee);
    } else {
      uniqueAttr = iteratee[key];
    }

    if (set.has(uniqueAttr)) {
      continue;
    }

    set.add(uniqueAttr);

    result.push(iteratee);
  }

  return result;
};

export const count = <T>(data: T[], predicate: (item: T) => boolean): number => {
  return data.filter(predicate).length;
};

export function translateLockType(localization: Localization, lockType: ParcelLockType | MailboxLockType): string {
  return localization.formatMessage('boxes.lock.type.' + lockType.toLowerCase());
}

export function normalizeUrl(url: string): string {
  if (url.slice(-1) === '/') {
    return url;
  }
  return url + '/';
}

function normalizePath(path: string): string {
  if (path.slice(0) === '/') {
    return path.slice(1);
  }
  return path;
}

export function createUrl(path: string): string {
  return window.location.origin + normalizeUrl(process.env.PUBLIC_URL) + normalizePath(path);
}

export function isAlbo(wallType: WallType): boolean {
  return [WallType.Boxis, WallType.Interna].includes(wallType);
}

export function isDigital(wallType: WallType): boolean {
  return WallType.Digital === wallType;
}

export function isMechanical(wallType: WallType): boolean {
  return WallType.Mechanical === wallType;
}

export function belongsTo(value: number, min: number, max: number) {
  return value >= min && value < max;
}

const mmsInMeter = 1000;

function toMms(value: number): number {
  return ((value * mmsInMeter) | 0);
}

export function isLessOrEqThan(value1: number, value2: number): boolean {
  return toMms(value1) <= toMms(value2);
}

export function isLessThan(value1: number, value2: number): boolean {
  return toMms(value1) < toMms(value2);
}

export function canBoxBeRemovedFromLayout(box: LayoutBox): boolean {
  return box.type !== 'centralbox' && box.type !== 'mailbox';
}

// is it solid row containing only boxes with same height and row-position
export function isRowSolid(layout: Layout, row: number, boxRows: number): boolean {
  let columnNumber = 0;

  while (columnNumber < layout.columns) {
    const boxAtThisCoordinate: PositionedLayoutBox = layout.findBoxAt({ row: row, column: columnNumber });

    if (boxAtThisCoordinate.box.rows !== boxRows || boxAtThisCoordinate.row !== row) {
      return false;
    }

    columnNumber += boxAtThisCoordinate.box.columns;
  }

  return true;
}

export const getRowUpperEdgeHeight = (rowNumber: number, layout: Layout): number => {
  const firstBoxInCurrentRow: PositionedLayoutBox = layout.findBoxAt({ row: rowNumber, column: 0 });
  const isRow = isRowSolid(layout, rowNumber, firstBoxInCurrentRow.box.rows);
  if (!isRow) {
    throw new Error('Provided row is not solid');
  }
  const height = firstBoxInCurrentRow.box.rows;

  return (rowNumber + height) * layout.cellHeight + layout.offsetHeight;
};
export const getRowLowerEdgeHeight = (rowNumber: number, layout: Layout): number => {
  return rowNumber * layout.cellHeight + layout.offsetHeight;
};

export const doesRowIntersectReferenceHeight = (rowNumber: number, layout: Layout, referenceHeight: number): boolean => {
  return referenceHeight < getRowUpperEdgeHeight(rowNumber, layout) && referenceHeight > getRowLowerEdgeHeight(rowNumber, layout);
};

export interface InitialSettings {
  isDigital: boolean;
  hasIntercom: boolean;
  isCustom: boolean;
  intercomSettings: IntercomSetting;
  bellsAmount: number;
}

export function getInitialSettings(wall: IWall): InitialSettings {
  let centralBox: ICentralBox | undefined;
  for (const { box } of wall.boxes) {
    if (centralBoxTypeGuard(box)) {
      centralBox = box;
    }
  }
  const isDigital = wall.wallType === WallType.Digital;
  if (!centralBox) {
    return {
      isDigital,
      hasIntercom: false,
      isCustom: false,
      intercomSettings: IntercomSetting.NA,
      bellsAmount: 0
    };
  } else {
    return {
      isDigital,
      hasIntercom: true,
      isCustom: !!wall.customIntercom,
      intercomSettings: centralBox.intercomSetting,
      bellsAmount: centralBox.bellsAmount
    };
  }
}
