import { Layout } from './Layout';
import { PositionedLayoutBox } from './Box';
import { IStandaloneConstraint, WallLayout } from '../schema';
import { ILayoutData } from './LayoutFactory';


export class StandaloneLayout extends Layout {
  constructor(
    public readonly constraint: IStandaloneConstraint,
    boxes: PositionedLayoutBox[] = [],
    cellWidth: number,
    cellHeight: number
  ) {
    super(constraint.rows, constraint.columns, boxes, cellWidth, cellHeight);
  }
  getLayoutData(): ILayoutData {
    return {
      layout: WallLayout.Standalone,
      layoutOptions: this.constraint,
      cellHeight: this.cellHeight,
      cellWidth: this.cellWidth,
      offsetHeight: this.offsetHeight,
      boxes: this.getBoxes()
    }
  }

  get rows(): number {
    return this.constraint.rows;
  }

  get columns(): number {
    return this.constraint.columns;
  }

  override get firstRowIndex(): number {
    return 0;
  }


  addColumns(index: number, count: number) {
    this.constraint.columns += count;

    super.addColumns(index, count);
  }

  override appendRowsToTop(count: number): void {
    this.constraint.rows += count;
    super.appendRowsToTop(count);
  }

  override insertRowsToBottom(count: number): void {
    return;
  }

  removeColumn(columnIndex: number, count: number) {
    super.removeColumn(columnIndex, count);

    this.constraint.columns -= count;
  }

  protected override shiftRowsAfterDeletion(rowIndex: number, count: number): void {
    for (let boxIndex = this.boxes.length - 1; boxIndex >= 0; --boxIndex) {
      const box = this.boxes[boxIndex];
      if (box.row > rowIndex) {
        box.row -= count;
      }
    }

    this.constraint.rows -= count;
  }
}
