import * as React from 'react';
import { NotificationsStore } from '../stores/NotificationsStore';
import RemoveGuide from "../components/RemoveGuide/RemoveGuide";
import InsertGuide from "../components/InsertGuide/InsertGuide";
import SwapGuide from "../components/SwapGuide/SwapGuide";

type GuideContextType = {
  showInsertBoxGuide: () => void;
  showSwapBoxGuide: () => void;
  showRemoveGuide: (onChangeStep: (step: number) => void, onFinish: () => void) => void;
  dismissGuide: () => void;
  isGuideVisible: boolean;
};

function noop() {}

const GuideContext = React.createContext<GuideContextType>({
  showInsertBoxGuide: noop,
  showSwapBoxGuide: noop,
  showRemoveGuide: noop,
  dismissGuide: noop,
  isGuideVisible: false,
});

interface IProps {
  notificationsStore: NotificationsStore;
}

export const GuideContextProvider: React.FC<IProps> = ({ notificationsStore, children }) => {
  const guideDismissRef = React.useRef<Function>();
  const [isGuideVisible, setIsGuideVisible] = React.useState(false);

  const value = React.useMemo(() => {
    const dismissGuide = () => {
      if (!guideDismissRef.current) {
        return;
      }

      guideDismissRef.current();
      setIsGuideVisible(false);
    };

    const showGuide = (content: JSX.Element) => {
      dismissGuide();
      notificationsStore.clear();

      guideDismissRef.current = notificationsStore.guide(content);
      setIsGuideVisible(true);
    };

    const showRemoveGuide = (onChangeStep: (step: number) => void, onFinish: () => void) => {
      showGuide(<RemoveGuide onChangeStep={onChangeStep} onFinish={onFinish} />)
    }

    const showInsertBoxGuide = () => {
      showGuide(<InsertGuide onFinish={dismissGuide} />);
    }

    const showSwapBoxGuide = () => {
      showGuide(<SwapGuide onFinish={dismissGuide} />);
    }

    return {
      showInsertBoxGuide,
      showSwapBoxGuide,
      showRemoveGuide,
      dismissGuide,
      isGuideVisible
    };
  }, [notificationsStore, isGuideVisible]);

  return <GuideContext.Provider value={value}>{children}</GuideContext.Provider>;
};

export const useGuideContext = () => {
  return React.useContext(GuideContext);
};
