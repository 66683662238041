import { ICost, ISummary, IWeight } from '../schema';
import { Engine, IEntitySearchResult, Model } from '@canvas-logic/engine';

export type Components = {
  Weight: IWeight;
  Summary: ISummary;
  Cost: ICost;
}
export type SearchEntitiesByComponents =
  <T extends keyof Components>(model: Model, components: T[]) => IEntitySearchResult<UnionToIntersection<Components[T]>>[];

type UnionToIntersection<U> =
  (U extends any ? (k: U) => void : never) extends ((k: infer I) => void) ? I : never


// TODO: Add to engine API
export class EntityFinder {
  private readonly pathsToIgnore = ['socleRules','packagingRules','hangingBracketRules'];

  byComponents: SearchEntitiesByComponents;

  constructor(private readonly engine: Engine) {
    // Hack for types
    const fn: any = (model: any, components: any) => {
      return this.engine.entitiesByComponents(model, components)
        .filter(record => !this.pathsToIgnore.some(path => record.path.startsWith('$.' + path)));
    };
    this.byComponents = fn;
  }
}