import { toJS } from 'mobx';
import { Layout, RowsShiftDirection } from './Layout';
import { ILayoutTraverser, ISize } from '../generator/ILayoutTraverser';
import { LayoutConstraint } from '../generator/RectangleLayoutGenerator';
import { Position } from './Position';
import { LayoutBox, PositionedLayoutBox } from './Box';
import { ILayoutData } from './LayoutFactory';
import { WallLayout } from '../schema';
import { doesRowIntersectReferenceHeight, isLessOrEqThan } from '../services/utils';
import { REFERENCE_HEIGHT } from '../rules/rules';


export class RectangleLayout extends Layout implements ILayoutTraverser {
  constructor(
    public readonly constraint: LayoutConstraint,
    boxes: PositionedLayoutBox[] = [],
    cellWidth: number,
    cellHeight: number
  ) {
    super(constraint.row.max + 1, constraint.column.max + 1, boxes, cellWidth, cellHeight);
  }

  getLayoutData(): ILayoutData {
    return {
      layout: WallLayout.Rectangle,
      layoutOptions: this.constraint,
      cellHeight: this.cellHeight,
      cellWidth: this.cellWidth,
      offsetHeight: this.offsetHeight,
      boxes: this.getBoxes()
    };
  }

  get height(): number {
    return this.constraint.row.max - this.constraint.row.min + 1;
  }

  get width(): number {
    return this.constraint.column.max - this.constraint.column.min + 1;
  }

  get rows(): number {
    return this.constraint.row.max + 1;
  }

  get columns(): number {
    return this.constraint.column.max + 1;
  }

  get maxFreeCells(): number {
    const height = this.constraint.row.max - this.constraint.row.min + 1;
    const width = this.constraint.column.max - this.constraint.column.min + 1;
    return width * height;
  }

  override get firstRowIndex(): number {
    return this.constraint.row.min;
  }

  canInsertBoxAt(box: LayoutBox, position: Position): boolean {
    const isFree = super.canInsertBoxAt(box, position);
    return isFree;
  }

  getCentralPosition(lightBox: LayoutBox): Position {
    const width = (this.constraint.column.max - this.constraint.column.min) + 1;
    const column = this.constraint.column.min + Math.ceil((width - lightBox.columns) / 2);
    const row = this.constraint.row.max - lightBox.rows + 1;
    return { row, column };
  }

  addColumns(index: number, count: number) {
    this.constraint.column.max += count;
    super.addColumns(index, count);
  }

  override appendRowsToTop(count: number): void {
    this.constraint.row.max += count;
    super.appendRowsToTop(count);
  }

  override insertRowsToBottom(count: number): void {
    if (this.constraint.row.min - count < 0) {
      console.error('[RectangleLayout]: Unable to make negative rows ');
      throw new Error('[RectangleLayout]: Unable to make negative rows ');
    }
    this.constraint.row.min -= count;
    super.appendRowsToTop(count);
  }

  removeColumn(columnIndex: number, count: number) {
    super.removeColumn(columnIndex, count);

    this.constraint.column.max -= count;
  }

  private static shiftBoxIfNecessary(box: PositionedLayoutBox, direction: RowsShiftDirection, referenceRowIndex: number, shiftLength: number): void {
    // lower boxes above or / raise boxes below
    if ((direction === 'down' && box.row > referenceRowIndex) || (direction === 'up' && box.row < referenceRowIndex)) {
      Layout.shiftBox(box, direction, shiftLength);
    }
  }

  protected override shiftRowsAfterDeletion(rowIndex: number, count: number): void {
    const doesIntersectReferenceHeight = doesRowIntersectReferenceHeight(rowIndex, this, REFERENCE_HEIGHT);

    if (doesIntersectReferenceHeight) {
      this.shiftRowsToReferenceHeight(rowIndex, count);
    } else {
      this.shiftRowsFromOneSideOnly(rowIndex, count);
    }
  }

  private shiftRowsFromOneSideOnly(rowIndex: number, count: number): void {
    const rowBottomLineHeight = rowIndex * this.cellHeight;

    const shiftDirection: RowsShiftDirection = isLessOrEqThan(REFERENCE_HEIGHT, rowBottomLineHeight) ? 'down' : 'up';
    // if removed-row is higher than 1600, then we just downgrade all rest rows, which are above removed-row
    // if we removed row lower than 1600  ==> we raise all rest-rows which are below removed-row

    for (const box of this.boxes) {
      RectangleLayout.shiftBoxIfNecessary(box, shiftDirection, rowIndex, count);
    }

    if (shiftDirection === 'down') {
      this.constraint.row.max -= count;
    } else if (shiftDirection === 'up') {
      this.constraint.row.min += count;
    }
  }

  private shiftRowsToReferenceHeight(rowIndex: number, count: number): void {
    const boxesHigherThanRemoved = this.boxes.filter(box => box.row > rowIndex);
    const boxesLowerThanRemoved = this.boxes.filter(box => box.row < rowIndex);

    const referenceRow = (REFERENCE_HEIGHT - this.offsetHeight) / this.cellHeight;

    const heightOfRemovedRowUpperPart = rowIndex + count - referenceRow; // part which is higher than ref. height 1600 mm
    const boxesAboveShiftLength = heightOfRemovedRowUpperPart + (this.rows - (rowIndex + count));

    const heightOfRemovedRowLowerPart = referenceRow - rowIndex;
    const boxesBelowShiftLength = (this.rows - (rowIndex + count)) - heightOfRemovedRowLowerPart;


    // putting the boxes (which were above removed one) to be directly under the reference height (1600 mm)
    boxesHigherThanRemoved.forEach(box => {
      Layout.shiftBox(box, 'down', boxesAboveShiftLength);
    });

    // putting the boxes (which were below removed one) to be under boxes shifted in previous step
    const belowShiftDirection: RowsShiftDirection = boxesBelowShiftLength < 0 ? 'up' : 'down';
    boxesLowerThanRemoved.forEach(box => {
      Layout.shiftBox(box, belowShiftDirection, Math.abs(boxesBelowShiftLength));
    });

    this.constraint.row.max -= boxesAboveShiftLength;
    this.constraint.row.min -= boxesBelowShiftLength; // boxesBelowShiftLength negative ==> lower border will rise
  }


  traverseBottomToTop(size: ISize): Iterable<Position> {
    return new RectangleBottomTopTraverser(this.constraint);
  }

  traverseTopToBottom(size: ISize): Iterable<Position> {
    return new RectangleTopBottomTraverser(this.constraint);
  }

  clone(): RectangleLayout {
    const boxes = this.boxes.map(box => {
      return Object.assign({}, toJS(box));
    });
    return new RectangleLayout(this.constraint, boxes, this.cellWidth, this.cellHeight);
  }

  * getRows(column: number): Iterable<number> {
    for (let row = this.constraint.row.max; row >= this.constraint.row.min; row--) {
      yield row;
    }
  }
}

export class RectangleTopBottomTraverser {
  constructor(private readonly constraint: LayoutConstraint) {
  }

  * [Symbol.iterator]() {
    const constraint = this.constraint;
    for (let column = constraint.column.min; column <= constraint.column.max; column++) {
      for (let row = constraint.row.max; row >= constraint.row.min; row--) {
        yield { row, column };
      }
    }
  }
}

export class RectangleBottomTopTraverser {
  constructor(private readonly constraint: LayoutConstraint) {
  }

  * [Symbol.iterator]() {
    const constraint = this.constraint;
    for (let column = constraint.column.min; column <= constraint.column.max; column++) {
      // for (let row = constraint.row.min; row <= constraint.row.max; row += 2) {
      for (let row = constraint.row.min; row <= constraint.row.max; row += 1) {
        yield { row, column };
      }
    }
  }
}
