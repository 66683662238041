import { ConfiguratorMenuStore } from '../../stores/ConfiguratorMenuStore';
import { ConfiguratorStore, VisualMode } from '../../stores/ConfiguratorStore';
import { rootStore } from '../../stores';
import { SummaryBoxPartsMaker, SummaryDimensionsMaker } from './SummaryPartsMaker';
import { DimensionsService } from '../../services/DimensionsService';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { ContactForm } from '../ContactForm/ContactForm';
import { SendingContactsForm } from '../SendingContactsForm/SendingContactsForm';
import { ContactMeStore } from '../../stores/ContactMeStore';
import { contactService } from '../../services/ContactService';
import { PdfDownloader } from '../../services/PdfDownloader';
import { SummarySidePanelComponent } from './SummarySidePanelComponent';
import { isAlbo } from '../../services/utils';

interface IProps {
  store: ConfiguratorStore;
  menu: ConfiguratorMenuStore;
}

export const SummarySidePanel: React.FC<IProps> = observer(({ menu, store }) => {
  const { parts, totalPrice, toggleParts } = React.useMemo(() => {
    const maker = new SummaryBoxPartsMaker(store.costSummaryService, isAlbo(store.model.wallType));
    return maker.createParts();
  }, [store.costSummaryService]);

  const dimensionsParts = React.useMemo(() => {
    const model = store.model;
    const layout = store.layout;
    const dimension = new DimensionsService(model, layout);
    const dimensionMaker = new SummaryDimensionsMaker(dimension, store.weightService);
    return dimensionMaker.createDimensionsParts(model.wallType);
  }, [store.model, store.layout, store.weightService]);

  const downloader = new PdfDownloader(
    contactService,
    store.dimensionService,
    store.weightService,
    store.costSummaryService,
    rootStore.imageService,
    rootStore.localization,
    rootStore.notificationsStore,
    store
  );

  const [pdfDownloadExpected, setPdfDownloadExpected] = React.useState(false);
  const [showContactMeDialog, setShowContactMeDialog] = React.useState(false);
  const [showSendingContactMeDialog, setShowSendingContactMeDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const openContactMeDialog = useCallback(async () => {
    setShowContactMeDialog(true);
  }, [setShowContactMeDialog]);

  const handleCloseContactForm = useCallback(() => {
    setShowContactMeDialog(false);
    setPdfDownloadExpected(false);
  }, []);

  const handleSubmit = async (store: ContactMeStore) => {
    setLoading(true);
    setShowContactMeDialog(false);
    setShowSendingContactMeDialog(true);
    try {
      await store.sendEmail();
      if (pdfDownloadExpected) {
        await downloader.downloadPdf();
      }
    } catch (e) {
      rootStore.notificationsStore.error(<FormattedMessage id="contactMe.error.cannotSend" />);
      setShowContactMeDialog(true);
      setShowSendingContactMeDialog(false);
    } finally {
      setLoading(false);
      setPdfDownloadExpected(false);
    }
  };

  const handleDownload = () => {
    if (!rootStore.authorized) {
      // UnAuthorized users have to fill contact form and get email
      setPdfDownloadExpected(true);
      setShowContactMeDialog(true);
    } else {
      downloader.downloadPdf();
    }
  };
  return (
    <>
      {showContactMeDialog ? (
        <ContactForm
          configuratorStore={store}
          onClose={handleCloseContactForm}
          onSubmit={handleSubmit}
          shareableLink={store.shareLink()}
          submitBtnText={<FormattedMessage id="contactMe.details" />}
          country={rootStore.localization.formatMessage(store.model.country.name)}
          availableCountries={store.availableCountries.map(country => country.model.name)}
        />
      ) : showSendingContactMeDialog ? (
        <SendingContactsForm onClose={() => {
          setPdfDownloadExpected(false);
          setShowSendingContactMeDialog(false);
        }} loading={loading} />
      ) : null}
      <SummarySidePanelComponent
        model={store.model}
        onClose={() => {
          if (store.mode === VisualMode.Insert) {
            menu.enterAdditionalBoxesSidePanel();
          } else {
            menu.enterOverviewMainMenu();
          }
        }}
        onToggle={store.toggleSummaryItem}
        onContactMe={openContactMeDialog}
        addToCartDisabled={store.addToCartDisabled}
        onAddToCart={store.addToCart}
        dimensionsParts={dimensionsParts}
        parts={parts}
        toggleParts={toggleParts}
        totalPrice={totalPrice}
        onDownload={handleDownload}
      />
    </>
  );
});


