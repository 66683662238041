import { IWall } from '../schema';
import { DomainValidationResult } from './DomainValidationResult';

export type DomainRule = (wall: IWall, prev: IWall) => DomainValidationResult;

export class DomainRulesManager {
  private rules: DomainRule[] = [];

  private validateRules(wall: IWall, prev: IWall): DomainValidationResult {
    for (const rule of this.rules) {
      const ruleResult = rule(wall, prev);
      if (ruleResult.isInvalid) {
        return ruleResult;
      }
    }

    return DomainValidationResult.Valid();
  }

  addRule(rule: DomainRule) {
    this.rules.push(rule);
  }

  validate(wall: IWall, prev: IWall): DomainValidationResult {
    return this.validateRules(wall, prev);
  }
}
