import classNames from 'classnames';
import React from 'react';
import { rootStore } from '../../stores';
import Logo from '../Logo/Logo';
import Typography from '../Typography/Typography';

import styles from './TemplateSelections.module.scss';
import { Link } from 'react-router-dom';
import { routes } from '../../stores/RootStore';

const TemplateSelections: React.FC = () => {
  return (
    <div className={classNames(styles.templateSelections)}>
      <Logo className={classNames(styles.templateSelections__logo)}/>
      <div className={classNames(styles.templateSelections__navigation)}>
        <Link
          to={routes.digitalWizard}
          className={classNames(styles.templateSelections__navigationItem)}
        >
          <img
            alt="Digital wall"
            className={classNames(styles.templateSelections__navigationItemImage)}
            src="./assets/images/main_page/eSafe_d.jpg"
          />
          <Typography
            size="12"
            weight="bold"
            className={classNames(styles.templateSelections__navigationItemText)}
          >
            {rootStore.localization.formatMessage('wall.wallDigital')}
          </Typography>
        </Link>
        <Link
          to={routes.mechanicalWizard}
          className={classNames(styles.templateSelections__navigationItem)}
        >
          <img
            alt="Mechanical Wall"
            className={classNames(styles.templateSelections__navigationItemImage)}
            src="./assets/images/main_page/eSafe_m.png"
          />
          <Typography
            size="12"
            weight="bold"
            className={classNames(styles.templateSelections__navigationItemText)}
          >
            {rootStore.localization.formatMessage('wall.wallMechanical')}
          </Typography>
        </Link>
        <Link
          to={routes.internaWizard}
          className={classNames(styles.templateSelections__navigationItem)}
        >
          <img
            alt="Interna Wall"
            className={classNames(styles.templateSelections__navigationItemImage)}
            src="./assets/images/main_page/eSafe_i.jpg"
          />
          <Typography
            size="12"
            weight="bold"
            className={classNames(styles.templateSelections__navigationItemText)}
          >
            {rootStore.localization.formatMessage('wall.wallInterna')}
          </Typography>
        </Link>
        <Link
          to={routes.boxisWizard}
          className={classNames(styles.templateSelections__navigationItem)}
        >
          <img
            alt="Boxis Wall"
            className={classNames(styles.templateSelections__navigationItemImage)}
            src="./assets/images/main_page/eSafe_b.jpg"
          />
          <Typography
            size="12"
            weight="bold"
            className={classNames(styles.templateSelections__navigationItemText)}
          >
            {rootStore.localization.formatMessage('wall.wallBoxis')}
          </Typography>
        </Link>
      </div>
    </div>
  );
};

export default TemplateSelections;
