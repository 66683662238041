import * as React from 'react';

import LogoLoader from './assets/logo-loader.svg';
import styles from './Loader.module.scss';

interface IProps {
  className?: string;
}

const Loader: React.FC<IProps> = ({ className }) => {
  return (
    <div className={styles.wrapper}>
      <img src={LogoLoader} alt="" />
    </div>
  );
};

export default Loader;
