import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { rootStore } from '../../stores';
import { TranslatedMessage } from '../../stores/Localization';
import Toast from "../Toast/Toast";

import './NotificationContainer.scss';

interface IProps {
  className?: string;
}

@observer
class NotificationContainer extends React.Component<IProps> {
  render() {
    const { className } = this.props;
    const Notification = NotificationContainer.getActiveNotification();

    return <div className={classNames('notification-container', className)}>{Notification}</div>;
  }

  private static getActiveNotification() {
    const notification = rootStore.notificationsStore.currentNotification;
    if (!notification) {
      return null;
    }

    let contentToDisplay = notification.content;
    if (Array.isArray(contentToDisplay)) {
      contentToDisplay = (
        <>
          {contentToDisplay.map((message, key) => (
            <p key={key}>{message}</p>
          ))}
        </>
      );
    }
    if (contentToDisplay instanceof TranslatedMessage) {
      contentToDisplay = (
        <FormattedMessage id={contentToDisplay.translationId} values={contentToDisplay.translationContext} />
      );
    }
    return (
      <Toast type={notification.type} icon={notification.icon}>
        {contentToDisplay}
      </Toast>
    );
  }
}

export default NotificationContainer;
