import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { WallLocation, WallType } from '../../schema';
import Logo from '../../components/Logo/Logo';
import styles from './WizardStepper.module.scss';
import { FormattedMessage } from 'react-intl';
import Typography from '../../components/Typography/Typography';
import { WizardStore } from '../../stores/WizardStore';
import { rootStore } from '../../stores';
import { createSearchParams, Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Icon from '../../components/Icon/Icon';
import WizardCountrySelector from './steps/WizardCountrySelector/WizardCountrySelector';
import Button from '../../components/Button/Button';
import WizardStepperComponent from '../../components/WizardStepper/WizardStepper';
import WizardWallLocationSelector from './steps/WizardWallLocationSelector/WizardWallLocationSelector';
import WizardLayoutSelector from './steps/WizardLayoutSelector/WizardLayoutSelector';
import WizardMountingTypeSelector from './steps/WizardMountingTypeSelector/WizardMountingTypeSelector';
import Inert from '../../components/Inert/Inert';
import WizardIntercomSelector from './steps/WizardIntercomSelector/WizardIntercomSelector';
import WizardBoxesSelector from './steps/WizardBoxesSelector/WizardBoxesSelector';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { routes } from '../../stores/RootStore';
import WizardMobileStepper from '../../components/WizardMobileStepper/WizardMobileStepper';
import { WizardStepContainer } from "./WizardStepContainer";
import { LinkService } from '../../services/LinkService';

export interface IWizardStepProps {
  index: number;
  initialIsTouched?: boolean;
  onChange: (index: number, completed?: boolean) => void;
  store: WizardStore;
}

interface WizardStep {
  path: string;
  hidden?: boolean;
  title: string;
  component: JSX.Element;
}

interface IProps {
  wallType: WallType;
}

const WizardStepper: React.FC<IProps> = observer(({ wallType }) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [shouldAutoRedirectToNext, setShouldAutoRedirectToNext] = useState(false);

  const link = searchParams.get('link') ?? '';
  const [wizardStore] = React.useState<WizardStore>(() => new WizardStore(rootStore, wallType, link));
  const isValid = wizardStore.isValidForGenerateConfigurations;

  const handleStepChanged = useCallback((index: number, complete: boolean = true) => {
    setProgress(progress => {
      progress[index] = complete;
      return progress.slice();
    });
  }, []);

  const handleStepCompleted = useCallback(
    (index: number) => {
      handleStepChanged(index);

      setShouldAutoRedirectToNext(true);
    },
    [handleStepChanged]
  );

  const hasNoAvailableMountingTypes = React.useMemo(() => {
    return !wizardStore.availableMountingTypes.length;
  }, [wizardStore.availableMountingTypes.length]);

  const hasNoAvailableLayouts = React.useMemo(() => {
    return (
      wizardStore.wallType === WallType.Digital && wizardStore.model.wallLocation === WallLocation.OutsideUnsheltered
    );
  }, [wizardStore.model.wallLocation, wizardStore.wallType]);

  const steps = useMemo<WizardStep[]>(() => {
    switch (wizardStore.wallType) {
      case WallType.Digital:
        return [
          {
            title: 'menu.options.country',
            path: 'country',
            component: (
              <WizardCountrySelector
                index={0}
                onChange={handleStepChanged}
                store={wizardStore}
                initialIsTouched={link !== ''}
              />
            )
          },
          {
            title: 'menu.options.location',
            path: 'walllocation',
            component: (
              <WizardWallLocationSelector
                index={1}
                onChange={handleStepCompleted}
                store={wizardStore}
                initialIsTouched={link !== ''}
              />
            )
          },
          {
            title: 'menu.options.layout',
            path: 'layout',
            component: (
              <WizardLayoutSelector
                index={2}
                onChange={handleStepCompleted}
                store={wizardStore}
                initialIsTouched={link !== ''}
              />
            ),
            hidden: hasNoAvailableLayouts
          },
          {
            title: 'menu.options.mountingType',
            path: 'mountingtype',
            component: (
              <WizardMountingTypeSelector
                index={3}
                onChange={handleStepCompleted}
                store={wizardStore}
                initialIsTouched={link !== ''}
              />
            ),
            hidden: hasNoAvailableMountingTypes
          },
          {
            title: 'menu.options.intercom.title',
            path: 'intercom',
            component: (
              <WizardIntercomSelector
                index={4}
                onChange={handleStepChanged}
                store={wizardStore}
                initialIsTouched={link !== ''}
              />
            )
          },
          {
            title: 'menu.options.boxes',
            path: 'boxes',
            component: (
              <WizardBoxesSelector
                index={5}
                onChange={handleStepChanged}
                store={wizardStore}
                initialIsTouched={link !== ''}
              />
            )
          }
        ];
      case WallType.Mechanical:
        return [
          {
            title: 'menu.options.country',
            path: 'country',
            component: (
              <WizardCountrySelector
                index={0}
                onChange={handleStepChanged}
                store={wizardStore}
                initialIsTouched={link !== ''}
              />
            )
          },
          {
            title: 'menu.options.layout',
            path: 'layout',
            component: (
              <WizardLayoutSelector
                index={1}
                onChange={handleStepCompleted}
                store={wizardStore}
                initialIsTouched={link !== ''}
              />
            )
          },
          {
            title: 'menu.options.mountingType',
            path: 'mountingtype',
            component: (
              <WizardMountingTypeSelector
                index={2}
                onChange={handleStepCompleted}
                store={wizardStore}
                initialIsTouched={link !== ''}
              />
            ),
            hidden: hasNoAvailableMountingTypes
          },
          {
            title: 'menu.options.intercom.title',
            path: 'intercom',
            component: (
              <WizardIntercomSelector
                index={3}
                onChange={handleStepChanged}
                store={wizardStore}
                initialIsTouched={link !== ''}
              />
            )
          },
          {
            title: 'menu.options.boxes',
            path: 'mailboxes',
            component: (
              <WizardBoxesSelector
                index={4}
                onChange={handleStepChanged}
                store={wizardStore}
                initialIsTouched={link !== ''}
              />
            )
          }
        ];
      case WallType.Boxis:
        return [
          {
            title: 'menu.options.mountingType',
            path: 'mountingtype',
            component: (
              <WizardMountingTypeSelector
                index={0}
                onChange={handleStepChanged}
                store={wizardStore}
                initialIsTouched={link !== ''}
              />
            )
          },
          {
            title: 'menu.options.intercom.title',
            path: 'intercom',
            component: (
              <WizardIntercomSelector
                index={1}
                onChange={handleStepChanged}
                store={wizardStore}
                initialIsTouched={link !== ''}
              />
            )
          },
          {
            title: 'menu.options.mailboxes',
            path: 'mailboxes',
            component: (
              <WizardBoxesSelector
                index={2}
                onChange={handleStepChanged}
                store={wizardStore}
                initialIsTouched={link !== ''}
              />
            )
          }
        ];
      case WallType.Interna:
        return [
          {
            title: 'menu.options.intercom.title',
            path: 'intercom',
            component: (
              <WizardIntercomSelector
                index={0}
                onChange={handleStepChanged}
                store={wizardStore}
                initialIsTouched={link !== ''}
              />
            )
          },
          {
            title: 'menu.options.mailboxes',
            path: 'mailboxes',
            component: (
              <WizardBoxesSelector
                index={1}
                onChange={handleStepChanged}
                store={wizardStore}
                initialIsTouched={link !== ''}
              />
            )
          }
        ];
    }
    return [];
  }, [wizardStore, handleStepChanged, handleStepCompleted, link, hasNoAvailableMountingTypes, hasNoAvailableLayouts]);

  // to track which steps are completed
  const [progress, setProgress] = React.useState<boolean[]>(link !== '' ? new Array(steps.length).fill(true) : []);

  useEffect(() => {
    navigate({ pathname: `../${steps[0].path}`, search: searchParams.toString() }, { relative: 'path', replace: true });
  }, []);

  const generate = React.useCallback(() => {
    console.log(toJS(wizardStore.model));
    navigate({
      pathname: routes.generator,
      search: createSearchParams({
        v: LinkService.version,
        link: wizardStore.toLink()
      }).toString()
    });
  }, [navigate, wizardStore]);

  const currentStepIndex = Math.max(steps.findIndex(step => step.path === params.stage), 0);

  const nextStep = React.useMemo(() => {
    let nextStepPath = steps[0].path;

    if (currentStepIndex !== steps.length - 1) {
      const nextStepIndex = steps.findIndex(({ hidden }, index) => !hidden && index > currentStepIndex);

      nextStepPath = steps[nextStepIndex].path;
    }

    return nextStepPath;
  }, [currentStepIndex, steps]);

  const previousStep = React.useMemo(() => {
    if (currentStepIndex !== 0) {
      let previousStepIndex = currentStepIndex - 1;
      while (previousStepIndex > 0 && steps[previousStepIndex].hidden) {
        previousStepIndex--;
      }

      return steps[previousStepIndex].path;
    }
    return steps[steps.length - 1].path;
  }, [currentStepIndex, steps]);

  useEffect(() => {
    if (shouldAutoRedirectToNext) {
      navigate(
        {
          pathname: `../${nextStep}`,
          search: searchParams.toString()
        },
        { relative: 'path' }
      );
      setShouldAutoRedirectToNext(false);
    }
  }, [navigate, nextStep, searchParams, shouldAutoRedirectToNext]);

  const handlePreviousClick = (event: React.MouseEvent<HTMLElement>) => {
    if (params.stage === steps[0].path) {
      event.preventDefault();
    }
  };

  const handleNextClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!progress[currentStepIndex]) {
      event.preventDefault();
    }
  };

  const showGenerateButton = React.useMemo(() => {
    if (params.stage === steps[steps.length - 1].path && progress[currentStepIndex - 1]) {
      return progress.slice(0, -1).every(stage => stage);
    }
  }, [currentStepIndex, progress, params.stage, steps]);

  return (
    <div className={styles.wizard}>
      <section className={styles.stepsSection}>
        <div className={styles.stepsSection__header}>
          <Logo className={styles.stepsSection__header_logo} />
          <Typography variant="h1" className={styles.stepsSection__header_text}>
            <FormattedMessage id={'setup.configurationSetup'} />
          </Typography>
        </div>
        <div className={styles.divider}></div>
        <div className={styles.stepsSection__desktop}>
          <WizardStepperComponent
            steps={steps}
            current={currentStepIndex}
            passedSteps={progress}
            queryString={searchParams.toString()}
          />
        </div>
        <div className={styles.stepsSection__mobile}>
          <WizardMobileStepper
            steps={steps}
            current={currentStepIndex}
            passedSteps={progress}
            onGenerate={generate}
            queryString={searchParams.toString()}
            nextStep={nextStep}
            previousStep={previousStep}
          />
        </div>
      </section>
      <div className={styles.configurationBlock}>
        <div className={styles.pagesWrapper}>
          <div
            style={
              {
                '--stage': currentStepIndex,
                '--total-steps': steps.length
              } as CSSProperties
            }
            className={styles.configurationBlock__params}
          >
            {steps.map((step, index) => (
              <WizardStepContainer key={step.title} current={index === currentStepIndex && !step.hidden}>
                <Inert className={styles.stepContainer__inert} inert={index !== currentStepIndex || !!step.hidden}>
                  {step.component}
                </Inert>
              </WizardStepContainer>
            ))}
          </div>
        </div>
        <div className={styles.configurationBlock__controls}>
          <Link
            to={{
              pathname: `../${previousStep}`,
              search: searchParams.toString()
            }}
            relative="path"
            onClick={handlePreviousClick}
            style={{ visibility: currentStepIndex === 0 ? 'hidden' : undefined }}
          >
            <p className={styles.navButton}>
              <Typography variant="button" color="default" component="span">
                  <span className={styles.navButtonContent}>
                    <Icon type="ArrowLeft" />
                    <FormattedMessage id="wizardStepper.previous" />
                  </span>
              </Typography>
            </p>
          </Link>
          {!showGenerateButton ? (
            <Link
              to={{
                pathname: `../${nextStep}`,
                search: searchParams.toString()
              }}
              relative="path"
              onClick={handleNextClick}
              style={{ visibility: !progress[currentStepIndex] ? 'hidden' : undefined }}
            >
              <p className={styles.navButton}>
                <Typography variant="button" color="default" component="span">
                  <span className={styles.navButtonContent}>
                    <FormattedMessage id="wizardStepper.next" />
                    <Icon type="ArrowRight" />
                  </span>
                </Typography>
              </p>
            </Link>
          ) : (
            <Button className={styles.generateButton} onClick={generate} disabled={!isValid.value}>
              <FormattedMessage id="generate.label" />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
});

export default WizardStepper;
