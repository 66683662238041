import { Matrix4, Mesh, MeshBasicMaterial, Object3D, Vector3 } from 'three';
import { TextAlignment } from '../schema';
import { Font, FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { CSG } from 'three-csg-ts';

export const LIGHT_COLOR = 'white';
export const TEXT_MESH_NAME = 'glow text';
const TEXT_OFFSET = 0.0025;
const PADDING = 0.02;

export class TextRenderer {
  private font: Font;
  private textZ: number;

  private constructor(
    private readonly fontPath: string
  ) {
  }

  static async create(fontPath: string): Promise<TextRenderer> {
    const self = new TextRenderer(fontPath);
    await self.init();
    return self;
  }

  private async init() {
    const loader = new FontLoader();
    return new Promise((resolve, reject) => {
      loader.load(this.fontPath, (response) => {
        this.font = response;
        resolve(this.font);
      }, () => {
      }, reject);
    });
  }

  renderText(text: string, textAlignment: TextAlignment, model: Object3D): Object3D {
    const copy = model.clone(true);
    const frontMesh = copy.getObjectByName('front');
    if (!(frontMesh instanceof Mesh)) {
      console.warn(`Cannot find front mesh`)
      return copy;
    }

    const textGeometry = this.createTextGeometry(frontMesh, text);

    //TODO: align text
    this.alignText(textGeometry, frontMesh, textAlignment);
    const textCSG = CSG.fromGeometry(textGeometry as any);
    const frontCSG = CSG.fromMesh(frontMesh);
    const engravedCSG = frontCSG.subtract(textCSG);
    frontMesh.geometry.dispose();
    frontMesh.geometry = CSG.toMesh(
      engravedCSG,
      frontMesh.matrix
    ).geometry;
    const mesh = CSG.toMesh(textCSG, new Matrix4());
    mesh.material = new MeshBasicMaterial({ color: LIGHT_COLOR });
    mesh.position.setZ(this.textZ - TEXT_OFFSET);
    mesh.name = TEXT_MESH_NAME;
    copy.add(mesh);
    return copy;
  }

  private createTextGeometry(frontMesh: Mesh, text: string): TextGeometry {
    const geometry = new TextGeometry(text, {
      font: this.font,
      size: 0.065,
      height: 0.1,
      curveSegments: 12
    });
    const textSize = new Vector3();
    const textCenter = new Vector3();
    geometry.computeBoundingBox();
    geometry.boundingBox!.getSize(textSize);
    geometry.translate(0, 0, -0.10);

    const frontSize = new Vector3();
    frontMesh.geometry.boundingBox!.getSize(frontSize);

    const fSize = frontSize.x * frontMesh.scale.x;

    let scaling = 1;
    const centerPadding = 2 * PADDING;
    if (textSize.x + centerPadding > fSize) {
      const percent = 100 * (textSize.x + centerPadding - fSize) / textSize.x + centerPadding;
      scaling = (100 - percent - 1) / 100;
    }
    geometry.scale(scaling, scaling, scaling);
    geometry.boundingBox!.getCenter(textCenter);
    this.textZ = textCenter.z;
    return geometry;
  }

  private alignText(textGeometry: TextGeometry, frontMesh: Mesh, textAlignment: TextAlignment) {
    textGeometry.center();
    switch (textAlignment) {
      case TextAlignment.Central:{
        const center = new Vector3();
        const textSize = new Vector3();
        frontMesh.geometry.boundingBox?.getCenter(center);
        textGeometry.boundingBox?.getSize(textSize);
        textGeometry.translate(center.x, center.y, 0);
        break;
      }
      case TextAlignment.Left: {
        const center = new Vector3();
        const textSize = new Vector3();
        frontMesh.geometry.boundingBox?.getCenter(center);
        textGeometry.boundingBox?.getSize(textSize);
        textGeometry.translate(0.5 * textSize.x + PADDING, center.y, 0);
        break;
      }
      case TextAlignment.Right: {
        const center = new Vector3();
        const textSize = new Vector3();
        const frontSize = new Vector3()
        frontMesh.geometry.boundingBox?.getCenter(center);
        frontMesh.geometry.boundingBox?.getSize(frontSize);
        textGeometry.boundingBox?.getSize(textSize);
        textGeometry.translate(frontSize.x - 0.5 * textSize.x - PADDING, center.y, 0);
        break;
      }
    }
  }
}
