import * as React from 'react';

import Stepper from '../Stepper/Stepper';

import styles from './Guide.module.scss';

const GuideFrame: React.FC = ({ children }) => (
  <div className={styles.frame}>
    {children}
  </div>
);


interface IProps {
  steps: JSX.Element[];
  onChangeStep?: (step: number) => void;
  onFinish: () => void;
}

const Guide: React.VFC<IProps> = ({ steps, onChangeStep, onFinish }) => {
  const [currentStep, setCurrentStep] = React.useState(0);

  React.useEffect(() => {
    onChangeStep?.(0);
  }, [onChangeStep]);

  const handleChangeStep = (step: number) => {
    onChangeStep?.(step);
    setCurrentStep(step);
  }

  const renderStep = React.useCallback((step: number) => {
    const content = steps[step];

    return content ?? null;
  }, [steps]);

  return (
    <section>
      <main className={styles.frames}>
        {renderStep(currentStep)}
      </main>
      <nav className={styles.nav}>
        <Stepper
          steps={steps.length}
          current={currentStep}
          onChange={handleChangeStep}
          onFinish={onFinish}
        />
      </nav>
    </section>
  );
};


export default Guide;
export { GuideFrame };
