import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import QRCode from '../QRCode/QRCode';
import ARLoader from '../ARLoader/ARLoader';

import s from './ARCode.module.scss';
import Typography from '../Typography/Typography';

interface IProps {
  arLink?: string;
  onQrClose: () => void;
  className?: string;
}

const ARCode: React.FC<IProps> = ({ arLink, className, onQrClose }) => {
  return (
    <>
      <div className={s.overlay} onClick={onQrClose}></div>
      <div className={classNames(s.arCode, className)}>
        <div className={s.qrWrapper}>
          {arLink ?
            <QRCode
              className={s.qr}
              data={arLink}
            />
            :
            <ARLoader>
              <Typography variant="body2">Link is being generated...</Typography>
            </ARLoader>
          }
        </div>
        <div className={s.unsupported}>
          <Typography align="center" variant="body2">
            <FormattedMessage
              id="ar.code.supported"
              values={{
                link: <a href={arLink}>
                  <FormattedMessage
                    id="ar.code.link"
                  />
                </a>
              }}
            />
          </Typography>
        </div>
      </div>
    </>
  );
};

export default ARCode;
