import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { HeightFromGround, MountingType, WallLayout } from '../../schema';
import { rootStore } from '../../stores';
import { WizardStore } from '../../stores/WizardStore';
import { IconName } from '../Icon/Icon';
import Selector, { ImageType } from '../Selector/Selector';
import Switch from '../Switch/Switch';
import Typography from '../Typography/Typography';

import styles from './MountingTypeForm.module.scss';

interface IProps {
  className?: string;
  selectedMountingType: MountingType | undefined;
  availableMountingTypes: MountingType[];
  wizardStore: WizardStore;
  onSelect?: () => void;
}

export const MountingTypeForm: React.FC<IProps> = ({
  className,
  selectedMountingType,
  availableMountingTypes,
  wizardStore,
  onSelect
}) => {
  const options = React.useMemo(() => {
    return availableMountingTypes.map(mountingType => {
      const image =
        wizardStore.isBoxis && (mountingType === MountingType.Niche || mountingType === MountingType.Hanging)
          ? ((mountingType + 'Boxis') as IconName)
          : wizardStore.model.layout === WallLayout.Z
          ? ((mountingType + 'Z') as IconName)
          : (mountingType as IconName);
      return {
        value: mountingType,
        title: <FormattedMessage id={`mountingType.${mountingType}`} />,
        image: image,
        imageType: ImageType.Icon
      };
    });
  }, [availableMountingTypes, wizardStore.isBoxis, wizardStore.model.layout]);

  const handleOnSelect = React.useCallback((mountingType: string) => {
    onSelect?.();
    wizardStore.setMountingType(mountingType as MountingType);
  }, [onSelect, wizardStore]);

  const handleOnHeightSelect = React.useCallback((height: string) => {
    wizardStore.setHeightFromGround(Number.parseInt(height, 10) as HeightFromGround)
  }, [wizardStore]);

  const heightFromGround = wizardStore.model.heightFromGround;
  const boxisOrInterna = wizardStore.isBoxis || wizardStore.isInterna;

  return (
    <section className={classNames(className, styles.mountingType__section, {
      [styles.boxisOrInterna]: boxisOrInterna
    })}>
      <Typography variant="h2" align="center">
        <FormattedMessage id="menu.options.mountingType" />
      </Typography>
      <Selector
        name={rootStore.localization.formatMessage('menu.options.mountingType')}
        options={options}
        selected={selectedMountingType}
        onSelect={handleOnSelect}
        className={styles.mountingType__selector}
        classes={{ list: styles.selectorList }}
      />
      {[MountingType.FixOnTheGround, MountingType.FixOnConcrete].includes(wizardStore.model.mountingType) &&
        <div className={classNames(styles.option, styles.optionOneLine)}>
          <Typography component="span" variant="subtitle1">
            <FormattedMessage id="mountingType.heightFromGround" />
          </Typography>
          <div className={styles.optionValues}>
            <Switch
              name="mountingType-heightFromGround"
              className={styles.modeSelector}
              options={wizardStore.availableHeightsFromGround.map(height => ({
                title: `${height}`,
                value: `${height}`
              }))}
              selected={heightFromGround ? heightFromGround.toString() : ''}
              onSelect={handleOnHeightSelect}
            />
          </div>
        </div>
      }
    </section>
  );
};

export default MountingTypeForm;
