import { ICost, IWall } from '../schema';
import { BigNumber } from '@canvas-logic/engine';

export class CostService {
  static itemPrice(item: ICost, model: IWall): BigNumber | null {
    if (item.price.isNegative()) {
      return null;
    }
    const marginItem = model.margins.find(margin => margin.name === item.marginName);
    if (!marginItem) {
      throw new Error(`Cannot find margin ${item.marginName}`);
    }
    if (marginItem.margin.isNegative()) {
      return null;
    }
    return item.price.multiply(marginItem.margin).multiply(new BigNumber(item.amount));
  }
}