import { Boundary } from './types';
import { Box3, Camera, Object3D, Vector3 } from 'three';
import { threeUtils } from '../services/ThreeUtils';
import { Size } from './Size';

export interface IBoundingBoxService {
  getBoundingBox(root: Object3D): Box3;

  getBoundingBoxProjection(boundingBox: Box3, camera: Camera, screenSize: Size): Boundary;
}

export class FakeBoundingBoxService implements IBoundingBoxService {
  constructor(private readonly boundingBox: Box3, private readonly boundary: Boundary) {
  }

  getBoundingBox(root: Object3D): Box3 {
    return this.boundingBox;
  }

  getBoundingBoxProjection(boundingBox: Box3, camera: Camera, screenSize: Size): Boundary {
    return this.boundary;
  }
}

export class BoundingBoxService implements IBoundingBoxService {
  getBoundingBox(root: Object3D): Box3 {
    const bbox = new Box3();
    bbox.setFromObject(root);
    const size = new Vector3();
    bbox.getSize(size);
    return bbox;
  }

  getBoundingBoxProjection(bbox: Box3, camera: Camera, screenSize: Size): Boundary {
    const center = new Vector3();

    bbox.getCenter(center);
    const size = new Vector3();
    bbox.getSize(size);

    const vertexes = [
      new Vector3(-0.5 * size.x + center.x, -0.5 * size.y + center.y, -0.5 * size.z + center.z),
      new Vector3(-0.5 * size.x + center.x, -0.5 * size.y + center.y, 0.5 * size.z + center.z),
      new Vector3(-0.5 * size.x + center.x, 0.5 * size.y + center.y, -0.5 * size.z + center.z),
      new Vector3(-0.5 * size.x + center.x, 0.5 * size.y + center.y, 0.5 * size.z + center.z),

      new Vector3(0.5 * size.x + center.x, -0.5 * size.y + center.y, -0.5 * size.z + center.z),
      new Vector3(0.5 * size.x + center.x, -0.5 * size.y + center.y, 0.5 * size.z + center.z),
      new Vector3(0.5 * size.x + center.x, 0.5 * size.y + center.y, -0.5 * size.z + center.z),
      new Vector3(0.5 * size.x + center.x, 0.5 * size.y + center.y, 0.5 * size.z + center.z)
    ].map(v => threeUtils.get2DPosition(v, camera, screenSize.width, screenSize.height));

    return vertexes.reduce(
      (acc, next) => ({
        minX: Math.min(acc.minX, next.x),
        minY: Math.min(acc.minY, next.y),
        maxX: Math.max(acc.maxX, next.x),
        maxY: Math.max(acc.maxY, next.y)
      }),
      { minX: Infinity, minY: Infinity, maxX: -Infinity, maxY: -Infinity }
    );
  }
}