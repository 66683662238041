import { FormattedMessage } from 'react-intl';
import { Form } from '../Form/Form';
import BottomSheet from '../BottomSheet/BottomSheet';
import Checkbox from '../Checkbox/Checkbox';
import RALColourSelector from '../RALColourSelector/RALColourSelector';
import Typography from '../Typography/Typography';
import { COLOR_UNDEFINED, RECOMMENDED_MATERIALS } from './ColorSidePanel';
import Icon from '../Icon/Icon';
import ColourSelectorItem from '../ColourSelectorItem/ColourSelectorItem';
import { colorResultTypeGuard, invertColor, ralToHex } from '../../services/utils';
import { FinishType, IMaterial } from '../../schema';
import styles from './ColorSidePanel.module.scss';


interface MainColorBarProps {
  selectedRecommendedColor: string | undefined;
  lastSelectedCustomMaterial: IMaterial | undefined;
  selectedMaterial: IMaterial | undefined;
  handleRecommendedMaterialSelected: (value: string) => void;
  handleLastSelectedCustomColorSelect: (code: string, finish: FinishType) => void;
  onKnownColorChange: (value: boolean) => void;
  materialSpecified: boolean;
  openCustomColorPanel: () => void;
}

export const MainColorBar = ({
                               selectedRecommendedColor,
                               lastSelectedCustomMaterial,
                               selectedMaterial,
                               handleRecommendedMaterialSelected,
                               handleLastSelectedCustomColorSelect,
                               onKnownColorChange,
                               materialSpecified,
                               openCustomColorPanel,
                             }: MainColorBarProps): JSX.Element => {
  const tryConvertToHex = lastSelectedCustomMaterial ? ralToHex(lastSelectedCustomMaterial.ralColor) : undefined;

  return (
    <BottomSheet.Content>
      <Form.Container>
        <section>
          <div className={styles.title}>
            <Typography variant="subtitle1">
              <FormattedMessage id="color.standardColors" />
            </Typography>
          </div>
          <Form.Control labelDisplay="block">
            <RALColourSelector
              name="popular-main"
              items={RECOMMENDED_MATERIALS.map(({ ralColor, finish }) => ({ ralCode: ralColor, smallText: <FormattedMessage id={`color.finishType.${finish}`}/> }))}
              selected={selectedRecommendedColor}
              orientation="vertical"
              onSelect={handleRecommendedMaterialSelected}
              autoFocusSelected={selectedRecommendedColor !== COLOR_UNDEFINED}
            />
          </Form.Control>
          <Form.Control labelDisplay="block">
            <div className={styles.paletteDontKnowColor}>
              <Checkbox onChange={onKnownColorChange} checked={!materialSpecified}>
                <Typography variant="body1" className={styles.paletteDontKnowColorLabel}>
                  <FormattedMessage id="color.dontKnowColorYet" />
                </Typography>
              </Checkbox>
            </div>
          </Form.Control>
        </section>
      </Form.Container>
      <button onClick={openCustomColorPanel} className={styles.customColorOpenBtn}>
        <Typography variant="subtitle1" className={styles.customColorNote}>
          <FormattedMessage id="menu.colorSide.customColor" />
        </Typography>
        <Typography variant="body2" className={styles.selectCustomColorNote}>
          <FormattedMessage id="menu.colorSide.select" />
        </Typography>
        <Icon type="arrowRightGoForward" />
      </button>
      {
        lastSelectedCustomMaterial && colorResultTypeGuard(tryConvertToHex)
        &&
        <ColourSelectorItem
          id={`search-main-${lastSelectedCustomMaterial.ralColor}-in-left-part`}
          name="search-main"
          background={`#${tryConvertToHex.color}`}
          textColor={invertColor(tryConvertToHex.color, true)}
          value={lastSelectedCustomMaterial.ralColor.toString()}
          selected={
            selectedMaterial?.ralColor === lastSelectedCustomMaterial.ralColor
            &&
            selectedMaterial?.finish === lastSelectedCustomMaterial.finish
          }
          onSelect={() => handleLastSelectedCustomColorSelect(lastSelectedCustomMaterial.ralColor.toString(), lastSelectedCustomMaterial.finish)}
          label={`RAL ${lastSelectedCustomMaterial.ralColor}`}
          smallText={<FormattedMessage id={`color.finishType.${lastSelectedCustomMaterial.finish.toString()}`}/>}
        />
      }
    </BottomSheet.Content>
  );
};
