const ralPalette = [
  { ralCode: 1001 },
  { ralCode: 1002 },
  { ralCode: 1003 },
  { ralCode: 1004 },
  { ralCode: 1005 },
  { ralCode: 1006 },
  { ralCode: 1007 },
  { ralCode: 1011 },
  { ralCode: 1012 },
  { ralCode: 1013 },
  { ralCode: 1014 },
  { ralCode: 1015 },
  { ralCode: 1016 },
  { ralCode: 1017 },
  { ralCode: 1018 },
  { ralCode: 1019 },
  { ralCode: 1020 },
  { ralCode: 1021 },
  { ralCode: 1023 },
  { ralCode: 1024 },
  { ralCode: 1026 },
  { ralCode: 1027 },
  { ralCode: 1028 },
  { ralCode: 1032 },
  { ralCode: 1033 },
  { ralCode: 1034 },
  { ralCode: 1035 },
  { ralCode: 1036 },
  { ralCode: 1037 },
  { ralCode: 2000 },
  { ralCode: 2001 },
  { ralCode: 2002 },
  { ralCode: 2003 },
  { ralCode: 2004 },
  { ralCode: 2005 },
  { ralCode: 2007 },
  { ralCode: 2008 },
  { ralCode: 2009 },
  { ralCode: 2010 },
  { ralCode: 2011 },
  { ralCode: 2012 },
  { ralCode: 2013 },
  { ralCode: 3000 },
  { ralCode: 3001 },
  { ralCode: 3002 },
  { ralCode: 3003 },
  { ralCode: 3004 },
  { ralCode: 3005 },
  { ralCode: 3007 },
  { ralCode: 3009 },
  { ralCode: 3011 },
  { ralCode: 3012 },
  { ralCode: 3013 },
  { ralCode: 3014 },
  { ralCode: 3015 },
  { ralCode: 3016 },
  { ralCode: 3017 },
  { ralCode: 3018 },
  { ralCode: 3020 },
  { ralCode: 3022 },
  { ralCode: 3024 },
  { ralCode: 3026 },
  { ralCode: 3027 },
  { ralCode: 3028 },
  { ralCode: 3031 },
  { ralCode: 3032 },
  { ralCode: 3033 },
  { ralCode: 4001 },
  { ralCode: 4002 },
  { ralCode: 4003 },
  { ralCode: 4004 },
  { ralCode: 4005 },
  { ralCode: 4006 },
  { ralCode: 4007 },
  { ralCode: 4008 },
  { ralCode: 4009 },
  { ralCode: 4010 },
  { ralCode: 4011 },
  { ralCode: 4012 },
  { ralCode: 5000 },
  { ralCode: 5001 },
  { ralCode: 5002 },
  { ralCode: 5003 },
  { ralCode: 5004 },
  { ralCode: 5005 },
  { ralCode: 5007 },
  { ralCode: 5008 },
  { ralCode: 5009 },
  { ralCode: 5010 },
  { ralCode: 5011 },
  { ralCode: 5012 },
  { ralCode: 5013 },
  { ralCode: 5014 },
  { ralCode: 5015 },
  { ralCode: 5017 },
  { ralCode: 5018 },
  { ralCode: 5019 },
  { ralCode: 5020 },
  { ralCode: 5021 },
  { ralCode: 5022 },
  { ralCode: 5023 },
  { ralCode: 5024 },
  { ralCode: 5025 },
  { ralCode: 5026 },
  { ralCode: 6000 },
  { ralCode: 6001 },
  { ralCode: 6002 },
  { ralCode: 6003 },
  { ralCode: 6004 },
  { ralCode: 6005 },
  { ralCode: 6006 },
  { ralCode: 6007 },
  { ralCode: 6008 },
  { ralCode: 6009 },
  { ralCode: 6010 },
  { ralCode: 6011 },
  { ralCode: 6012 },
  { ralCode: 6013 },
  { ralCode: 6014 },
  { ralCode: 6015 },
  { ralCode: 6016 },
  { ralCode: 6017 },
  { ralCode: 6018 },
  { ralCode: 6019 },
  { ralCode: 6020 },
  { ralCode: 6021 },
  { ralCode: 6022 },
  { ralCode: 6024 },
  { ralCode: 6025 },
  { ralCode: 6026 },
  { ralCode: 6027 },
  { ralCode: 6028 },
  { ralCode: 6029 },
  { ralCode: 6032 },
  { ralCode: 6033 },
  { ralCode: 6034 },
  { ralCode: 6035 },
  { ralCode: 6036 },
  { ralCode: 6037 },
  { ralCode: 6038 },
  { ralCode: 7000 },
  { ralCode: 7001 },
  { ralCode: 7002 },
  { ralCode: 7003 },
  { ralCode: 7004 },
  { ralCode: 7005 },
  { ralCode: 7006 },
  { ralCode: 7008 },
  { ralCode: 7009 },
  { ralCode: 7010 },
  { ralCode: 7011 },
  { ralCode: 7012 },
  { ralCode: 7013 },
  { ralCode: 7015 },
  { ralCode: 7016 },
  { ralCode: 7021 },
  { ralCode: 7022 },
  { ralCode: 7023 },
  { ralCode: 7024 },
  { ralCode: 7026 },
  { ralCode: 7030 },
  { ralCode: 7031 },
  { ralCode: 7032 },
  { ralCode: 7033 },
  { ralCode: 7034 },
  { ralCode: 7035 },
  { ralCode: 7036 },
  { ralCode: 7037 },
  { ralCode: 7038 },
  { ralCode: 7039 },
  { ralCode: 7040 },
  { ralCode: 7042 },
  { ralCode: 7043 },
  { ralCode: 7044 },
  { ralCode: 7045 },
  { ralCode: 7046 },
  { ralCode: 7047 },
  { ralCode: 7048 },
  { ralCode: 8000 },
  { ralCode: 8001 },
  { ralCode: 8002 },
  { ralCode: 8003 },
  { ralCode: 8004 },
  { ralCode: 8007 },
  { ralCode: 8008 },
  { ralCode: 8011 },
  { ralCode: 8012 },
  { ralCode: 8014 },
  { ralCode: 8015 },
  { ralCode: 8016 },
  { ralCode: 8017 },
  { ralCode: 8019 },
  { ralCode: 8022 },
  { ralCode: 8023 },
  { ralCode: 8024 },
  { ralCode: 8025 },
  { ralCode: 8028 },
  { ralCode: 8029 },
  { ralCode: 9001 },
  { ralCode: 9002 },
  { ralCode: 9003 },
  { ralCode: 9004 },
  { ralCode: 9005 },
  { ralCode: 9006 },
  { ralCode: 9007 },
  { ralCode: 9010 },
  { ralCode: 9011 },
  { ralCode: 9016 },
  { ralCode: 9017 },
  { ralCode: 9018 },
  { ralCode: 9022 },
  { ralCode: 9023 }
];

export default ralPalette;
