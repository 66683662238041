import * as React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { ConfiguratorStore } from '../../stores/ConfiguratorStore';
import { rootStore } from '../../stores';
import BottomSheet from '../BottomSheet/BottomSheet';
import { ConfiguratorMenuStore } from '../../stores/ConfiguratorMenuStore';
import { FinishType, IMaterial } from '../../schema';

import { MainColorBar } from './MainColorBar';
import { CustomColorBar } from './CustomColorBar';
import Typography from '../Typography/Typography';
import Icon from '../Icon/Icon';
import Button from '../Button/Button';
import Inert from '../Inert/Inert';

import styles from './ColorSidePanel.module.scss';


interface IProps {
  store: ConfiguratorStore;
  menu: ConfiguratorMenuStore;
}

export const RECOMMENDED_MATERIALS: Readonly<IMaterial[]> = [
  { ralColor: 9005, finish: FinishType.StructureMatt },
  { ralColor: 9016, finish: FinishType.StructureMatt },
  { ralColor: 7016, finish: FinishType.Structure }
];

export const DEFAULT_MATERIAL: Readonly<IMaterial> = RECOMMENDED_MATERIALS[0];

export const COLOR_UNDEFINED = 'undefined';


export const ColorSidePanel: React.FC<IProps> = observer(({ store, menu }) => {
  const [isCustomColorPanelShown, setIsCustomColorPanelShown] = React.useState<boolean>(false);

  const [lastSelectedCustomMaterial, setLastSelectedCustomMaterial] = React.useState<IMaterial | undefined>(() => {
    if (!store.model.material) {
      return undefined;
    }
    const isRecommendedMaterial = RECOMMENDED_MATERIALS.some(material => material.ralColor === store.model.material?.ralColor && material.finish === store.model.material.finish);

    if (!isRecommendedMaterial) {
      return store.model.material;
    }

    return undefined;
  });

  const setLastSelectedCustomMaterialIfNecessary = (material: IMaterial | undefined, clearLastSelected = false) => {
    if (!RECOMMENDED_MATERIALS.some(item => item.finish === material?.finish && item.ralColor === material.ralColor)) {
      setLastSelectedCustomMaterial(material ? { ...material } : undefined);
    } else {
      if (clearLastSelected) {
        setLastSelectedCustomMaterial(undefined);
      }
    }
  };

  React.useEffect((): void => {
    if (store.model.material) {
      setLastSelectedCustomMaterialIfNecessary({ ...store.model.material });
    }
  }, [store.model.material]);


  const handleColorSelected = (ralColorCode: string, currentlySelectedFinish: FinishType) => {
    const parsedRalCode = parseInt(ralColorCode, 10);

    if (!Number.isNaN(parsedRalCode)) {
      store.changeMaterial({ ralColor: parsedRalCode, finish: currentlySelectedFinish });
    }

    setLastSelectedCustomMaterialIfNecessary(store.model.material, true);
  };

  const handleFinishTypeSelected = (value: string) => {
    store.changeFinishType(value as FinishType);
  };

  const onKnownColorChange = (): void => {
    store.toggleMaterialSpecified();
  };


  const handleRecommendedMaterialSelected = (ralColorCode: string) => {
    const parsedCode = Number.parseInt(ralColorCode);

    switch (ralColorCode) {
      case '9005':
      case '9016':
        store.changeMaterial({ finish: FinishType.StructureMatt, ralColor: parsedCode });
        break;
      case '7016':
        store.changeMaterial({ finish: FinishType.Structure, ralColor: parsedCode });
        break;
    }
  };

  const materialSpecified = store.isMaterialSpecified;


  const selectedRecommendedColor = React.useMemo(() => {
    const currentMaterial = store.model.material;
    const recommendedColor = RECOMMENDED_MATERIALS.find(
      material => material.ralColor === currentMaterial?.ralColor && material.finish === currentMaterial.finish
    );
    if (!recommendedColor) {
      return COLOR_UNDEFINED;
    }

    return store.model.material?.ralColor.toString(10);
  }, [store.model.material]);

  const toggleCustomColorPanel = () => setIsCustomColorPanelShown(state => !state);

  const titleElement: JSX.Element =
    <div className={styles.titleWithIcon}>
      {
        isCustomColorPanelShown &&
        <Button variant="secondary" onClick={toggleCustomColorPanel} className={styles.iconGoBack}>
          <Icon type="arrowLeftGoBack" />
        </Button>
      }
      <Typography
        variant="h4"
        className={classnames(styles.smooth, { [styles.titleWhenPanelOpened]: isCustomColorPanelShown })}
      >
        {rootStore.localization.formatMessage(`menu.colorSide.title`)}
      </Typography>
    </div>;


  return (
    <BottomSheet title={titleElement} onClose={menu.closeSidePanels}>
      <div className={styles.root}>
        <Inert inert={isCustomColorPanelShown} className={classnames(styles.colorSidePanelSection, { [styles.opened]: isCustomColorPanelShown })}>
          <MainColorBar
            selectedMaterial={store.model.material}
            lastSelectedCustomMaterial={lastSelectedCustomMaterial}
            selectedRecommendedColor={selectedRecommendedColor}
            handleRecommendedMaterialSelected={handleRecommendedMaterialSelected}
            handleLastSelectedCustomColorSelect={handleColorSelected}
            onKnownColorChange={onKnownColorChange}
            materialSpecified={materialSpecified}
            openCustomColorPanel={toggleCustomColorPanel}
          />
        </Inert>
        <Inert inert={!isCustomColorPanelShown} className={classnames(styles.colorSidePanelSection, { [styles.opened]: isCustomColorPanelShown })}>
          <CustomColorBar
            handleFinishTypeSelected={handleFinishTypeSelected}
            handleColorSelected={handleColorSelected}
            selectedMaterial={store.model.material}
          />
        </Inert>
      </div>
    </BottomSheet>
  );
});
