import { Layout } from '../../layout/Layout';
import { ICentralBox, Intercom, IntercomSetting, WallBox } from '../../schema';
import { PositionedLayoutBox } from '../../layout/Box';
import { Position } from '../../layout/Position';
import { ValueSplitService } from '../../layout/ValueSplitService';
import { parcelBoxTypeGuard } from '../../guards';
import { InitialSettings } from '../../services/utils';

export class InstallationBehaviour {
  constructor(private readonly layout: Layout, private readonly initialSettings: InitialSettings) {
  }

  install(box: WallBox, deleteBoxes: PositionedLayoutBox[], availableBoxes: WallBox[]): void {
    if (deleteBoxes.length === 0) {

      throw new Error('No delete boxes given.');
    }
    switch (box.type) {
      case 'centralbox':
        this.installCU(box as ICentralBox, deleteBoxes, availableBoxes);
        return;
      default:
        this.installBox(box, deleteBoxes);
    }
  }

  private installCU(box: ICentralBox, deleteBoxes: PositionedLayoutBox[], availableBoxes: WallBox[]) {
    this.updateIntercomSettings(box);
    deleteBoxes.forEach(box => this.layout.removeBox(box));
    const [minPos, maxPos] = getMinMaxPositions(deleteBoxes);
    const rows = maxPos.row - minPos.row;

    if (this.layout.canInsertBoxAt(box, { row: maxPos.row - box.rows, column: minPos.column })) {
      this.layout.insertBox({
        box: { ...box },
        row: maxPos.row - box.rows,
        column: minPos.column
      });
    }

    const filteredBoxes = availableBoxes
      .filter(box => parcelBoxTypeGuard(box))
      .sort((a, b) => b.rows - a.rows);

    for (let column = minPos.column; column < maxPos.column; column++) {
      const availableRows = (column < minPos.column + box.columns) ? rows - box.rows : rows;
      const splitResult = ValueSplitService.split(
        availableRows,
        filteredBoxes.map(box => box.rows)
      );
      if (splitResult.length === 0) {
        throw new Error('Not possible to combine items into column');
      }
      let boxesToAdd = splitResult[0].flatMap((count, index) => new Array(count).fill(filteredBoxes[index]));
      for (const box of boxesToAdd) {
        for (let row = minPos.row; row < maxPos.row; row++) {
          if (this.layout.canInsertBoxAt(box, { row, column })) {
            this.layout.insertBox({
              box: { ...box },
              row, column
            });
          }
        }
      }
    }
  }

  private updateIntercomSettings(box: ICentralBox) {
    const mailboxesAmount = countMailboxes(this.layout);
    box.bellsAmount = !this.initialSettings.isCustom
      ? this.initialSettings.bellsAmount
      : this.initialSettings.isDigital ? mailboxesAmount: 1;
    if (box.custom) {
      box.bellsAmount = 0;
    }
    box.intercomSetting = this.initialSettings.intercomSettings;
    switch (box.intercom) {
      case Intercom.Comelit:
        if (![IntercomSetting.IP, IntercomSetting.TwoWire].includes(box.intercomSetting)) {
          box.intercomSetting = IntercomSetting.TwoWire;
        }
        break;
      case Intercom.Fermax:
        if (![IntercomSetting.IP, IntercomSetting.TwoWire].includes(box.intercomSetting)) {
          box.intercomSetting = IntercomSetting.TBD;
        }
        break;
      case Intercom.Bticino:
      case Intercom.Niko:
      case Intercom.No:
        box.intercomSetting = IntercomSetting.NA;
    }
  }

  private installBox(box: WallBox, deleteBoxes: PositionedLayoutBox[]) {
    deleteBoxes.forEach(box => this.layout.removeBox(box));
    const [minPos, maxPos] = getMinMaxPositions(deleteBoxes);

    for (let column = minPos.column; column < maxPos.column; column += box.columns) {
      for (let row = minPos.row; row < maxPos.row; row += box.rows) {
        if (this.layout.canInsertBoxAt(box, { row, column })) {
          this.layout.insertBox({
            box: { ...box },
            row, column
          });
        }
      }
    }
  }
}

function getMinMaxPositions(boxes: PositionedLayoutBox[]): [Position, Position] {
  let minColumn = Infinity, minRow = Infinity, maxColumn = -Infinity, maxRow = -Infinity;
  boxes.forEach(box => {
    minColumn = Math.min(minColumn, box.column);
    maxColumn = Math.max(maxColumn, box.column + box.box.columns);
    minRow = Math.min(minRow, box.row);
    maxRow = Math.max(maxRow, box.row + box.box.rows);
  });

  return [{
    column: minColumn,
    row: minRow
  }, {
    column: maxColumn,
    row: maxRow
  }];
}

function countMailboxes(layout: Layout): number {
  let amount = 0;
  for (const box of layout.boxes) {
    if (box.box.type === 'mailbox') {
      amount++;
    }
  }
  return amount;
}