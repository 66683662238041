import { observer } from 'mobx-react';
import * as React from 'react';
import { useState } from 'react';
import { IWizardStepProps } from '../../WizardStepper';
import { CylinderCutout, MailboxLockType, ParcelLockType, WallLocation } from '../../../../schema';
import { IconName } from '../../../../components/Icon/Icon';
import Selector, { ImageType } from '../../../../components/Selector/Selector';
import classNames from 'classnames';
import styles from './WizardBoxesSelector.module.scss';
import Typography from '../../../../components/Typography/Typography';
import { FormattedMessage } from 'react-intl';
import { TextFieldLabel } from '../../../../components/TextFieldLabel/TextFieldLabel';
import TextField from '../../../../components/TextField/TextField';
import { rootStore } from '../../../../stores';
import Switch from '../../../../components/Switch/Switch';
import Toggle from '../../../../components/Toggle/Toggle';
import { translateLockType } from '../../../../services/utils';
import Lightbox from './images/lightbox.jpg';
import LockMechanical from './images/lock--mechanical.jpg';
import LockParcelElectric from './images/lock--parcel-electric.jpg';
import LockMailElectric from './images/lock--mail-electric.jpg';
import LockCode from './images/lock--code.jpg';

const MAILBOX_LOCK_IMAGES_MAPPING: Record<MailboxLockType, string> = {
  [MailboxLockType.Mechanical]: LockMechanical,
  [MailboxLockType.Electric]: LockMailElectric,
};

const PARCEL_LOCK_IMAGES_MAPPING: Record<ParcelLockType, string> = {
  [ParcelLockType.Electric]: LockParcelElectric,
  [ParcelLockType.Number]: LockCode
};

const WizardBoxesSelector: React.VFC<IWizardStepProps> = observer(({ index, store, onChange, initialIsTouched }) => {
  const [mailboxSelected, setMailboxSelected] = React.useState(initialIsTouched);

  const handleOnChange = React.useCallback((completed?: boolean) => {
    if (store.model.mailBoxesAmount || store.model.parcelBoxesAmount) {
      onChange(index, completed);
    }
  }, [index, onChange, store.model.mailBoxesAmount, store.model.parcelBoxesAmount]);

  const [doorBellsChanged, setDoorBellsChanged] = useState(false);
  const [isPristine, setIsPristine] = React.useState(!initialIsTouched);
  const digital = store.isDigital;

  const handleChangeMailboxesAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!store.model.parcelBoxesAmount) {
      handleOnChange(false);
    }

    const amount = Number.parseInt(event.currentTarget.value, 10);
    if (Number.isNaN(amount)) {
      store.setMailBoxesAmount(0);
      if (!doorBellsChanged && digital) {
        store.setDoorBellsAmount(0);
      }
    } else {
      setIsPristine(false);
      store.setMailBoxesAmount(amount);
      handleOnChange();

      if (!doorBellsChanged && amount <= store.maxDoorbellsAmount && digital) {
        setIsPristine(false);
        if (store.model.hasIntercom && !store.model.hasCustomIntercom) {
          store.setDoorBellsAmount(amount);
        }
        handleOnChange();
      }
    }
  };

  const handleChangeDoorBellsAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDoorBellsChanged(true);
    const amount = Number.parseInt(event.currentTarget.value, 10);
    if (Number.isNaN(amount)) {
      store.setDoorBellsAmount(store.minDoorbellsAmount, true);
    } else {
      store.setDoorBellsAmount(amount, true);
    }
  };

  const handleChangeParcelBoxesAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    !store.model.mailBoxesAmount && handleOnChange(false);
    const amount = Number.parseInt(event.currentTarget.value, 10);
    if (Number.isNaN(amount)) {
      store.setParcelBoxesAmount(0);
    } else {
      store.setParcelBoxesAmount(amount);
      handleOnChange();
    }
  };

  const handleMailboxHeightSelect = (value: string) => {
    store.setMailboxesHeight(Number.parseInt(value));
  };

  const parcelLockOptions = React.useMemo(() => {
    return Object.values(ParcelLockType).map(lock => ({
      value: lock,
      title: translateLockType(rootStore.localization, lock),
      image: PARCEL_LOCK_IMAGES_MAPPING[lock],
      imageType: ImageType.Image,
      disabled: !store.availableParcelboxLockTypes.includes(lock as ParcelLockType)
    }));
  }, [store.availableParcelboxLockTypes]);

  const heights = store.availableMailboxHeight.map(height => ({
    title: height.toString(),
    value: height.toString()
  }));

  const mailLockOptions = React.useMemo(() => {
    return store.availableMailboxLockTypes.map(lock => ({
      value: lock,
      title: translateLockType(rootStore.localization, lock),
      image: MAILBOX_LOCK_IMAGES_MAPPING[lock],
      imageType: ImageType.Image
    }));
  }, [store.availableMailboxLockTypes]);

  const mailboxesOptions = React.useMemo(() => {
    return store.availableMailBoxes.map(mailbox => ({
      value: mailbox.model.name,
      title: rootStore.localization.formatMessage(mailbox.model.name),
      image: mailbox.model.image as IconName,
      imageType: ImageType.Icon
    }));
  }, [store.availableMailBoxes]);

  const cylinderCutouts = store.availableCylinderCutouts.map(cutout => ({
    title: rootStore.localization.formatMessage(`boxes.cylinderCutout.${cutout}`),
    value: cutout
  }));

  const isAlbo = store.isAlbo;
  const showOwnKeyPlanHint = store.model.hasOwnKeyPlan && store.model.cylinderCutout === CylinderCutout.BuildInLock;
  return (
    <section className={classNames(styles.boxesForm, {
      [styles.boxisOrInterna]: isAlbo
    })}>
      <Typography variant="h2">
        {store.isInterna || store.isBoxis
          ? <FormattedMessage id="menu.options.mailboxes" />
          : <FormattedMessage id="menu.options.boxes" />}
      </Typography>
      {isAlbo && (
        <div className={styles.option}>
          <Selector
            name="mailboxes"
            options={mailboxesOptions}
            selected={!isPristine && mailboxSelected ? store.model.mailBox.name : undefined}
            onSelect={value => {
              setMailboxSelected(true);
              store.setMailbox(value);
              setIsPristine(false);
            }}
            classes={{ list: styles.selectorList }}
          />
        </div>
      )}
      <div className={classNames(styles.option, styles.optionOneLine)}>
        <TextFieldLabel
          icon="mail"
          label={<FormattedMessage id="boxes.mailboxes.amount" />}
          description={(store.isFourMailboxesComplying && !isPristine && mailboxSelected)
            ? <FormattedMessage id="boxes.mailboxes.total.boxisAmount"
                                values={{ boxName: rootStore.localization.formatMessage(store.model.mailBox.name) }} />
            : <FormattedMessage id="boxes.mailboxes.total.amount" />}
        />
        <TextField
          className={styles.optionField}
          placeholder={rootStore.localization.formatMessage('boxes.amountTotal')}
          type="number"
          value={store.model.mailBoxesAmount.toString()}
          onChange={handleChangeMailboxesAmount}
          min={store.minDoorbellsAmount}
          max={store.maxMailboxAmount}
          disabled={isAlbo && isPristine}
        />
      </div>
      {!isAlbo && (
        <div className={styles.option}>
          <TextFieldLabel labelVariant="body2" label={<FormattedMessage id="boxes.mailboxes.height" />} />
          <Switch
            name="mailbox-height"
            className={styles.modeSelector}
            options={heights}
            selected={store.model.mailBox.height.toString()}
            onSelect={handleMailboxHeightSelect}
            itemDataCy="mailboxHeight"
          />
        </div>
      )}
      {digital ? (
        <div className={styles.option}>
          <TextFieldLabel labelVariant="body2" label={<FormattedMessage id="boxes.mailboxes.lockType.title" />} />
          <Selector
            name="mailbox-lock-type"
            options={mailLockOptions}
            selected={store.model.mailBox.lockType.toString()}
            onSelect={value => store.setMailboxLockType(value as MailboxLockType)}
            classes={{ root: styles.sideSelector, list: styles.sideSelectorList }}
          />
        </div>
      ) : null}
      <div
        className={classNames(styles.option, styles.optionOneLine, {
          [styles.optionHidden]: !store.hasOwnKeyPlanField
        })}
      >
        <TextFieldLabel labelVariant="body2"
                        label={<FormattedMessage id="boxes.ownKeyPlan" />}
                        description={showOwnKeyPlanHint ? <FormattedMessage id="boxes.ownKeyPlanHint" />: undefined}/>
        <Toggle onChange={has => store.setOwnKeyPlan(has)} checked={store.model.hasOwnKeyPlan} />
      </div>
      <div
        className={classNames(styles.option, {
          [styles.optionHidden]: !cylinderCutouts.length
        })}
      >
        <TextFieldLabel labelVariant="body2" label={<FormattedMessage id="boxes.cylinderCutouts" />} />
        <Switch
          name="cylinderCutouts"
          className={styles.modeSelector}
          options={cylinderCutouts}
          selected={store.model.cylinderCutout}
          onSelect={value => store.setCylinderCutout(value)}
        />
      </div>
      {!isAlbo && (
        <>
          <div className={styles.border} />
          <div className={classNames(styles.option, styles.optionOneLine)}>
            <TextFieldLabel
              icon={'box' as IconName}
              label={<FormattedMessage id="menu.summary.parcelBoxes" />}
              description={<FormattedMessage id="boxes.mailboxes.minimum.amount" />}
            />
            <div className={styles.amountHelpText}>
              <Typography variant="caption">
                <FormattedMessage
                  id="boxes.suggestedAmount"
                  values={{ amount: store.suggestedParcelboxAmount }}
                />
              </Typography>
            </div>
            <TextField
              className={styles.optionField}
              type="number"
              value={store.model.parcelBoxesAmount.toString()}
              onChange={handleChangeParcelBoxesAmount}
              min={0}
              max={store.maxParcelboxAmount}
            />
          </div>
          {store.isMechanical ? (
            <div className={styles.option}>
              <TextFieldLabel
                labelVariant="body2"
                label={<FormattedMessage id="boxes.parcelboxes.lockType.title" />}
                description={
                  <div
                    className={
                      store.model.parcelLockType === ParcelLockType.Electric
                        ? styles.lockTypeHint
                        : styles.lockTypeHintHidden
                    }
                  >
                    <FormattedMessage id="boxes.parcelboxes.lockType.wifi" />
                  </div>
                }
              />
              <Selector
                name="parcelbox-lock-type"
                options={parcelLockOptions}
                selected={store.model.parcelLockType.toString()}
                onSelect={value => store.setParcelLockType(value as ParcelLockType)}
                classes={{ root: styles.sideSelector, list: styles.selectorList }}
              />
            </div>
          ) : null}
          <div className={styles.border} />
          <div className={classNames(styles.option, styles.optionOneLine)}>
            <TextFieldLabel
              icon={'bell' as IconName}
              label={<FormattedMessage id="menu.summary.doorbells" />}
              description={<FormattedMessage id="boxes.mailboxes.total.amount" />}
            />
            <TextField
              className={styles.optionField}
              type="number"
              value={store.model.doorBellsAmount.toString()}
              onChange={handleChangeDoorBellsAmount}
              min={store.minDoorbellsAmount}
              max={store.maxDoorbellsAmount}
              disabled={!store.doorBellsEnabled}
            />
          </div>
        </>
      )}
      {store.isMechanical || (digital && store.model.wallLocation !== WallLocation.OutsideUnsheltered) ? (
        <>
          <div className={styles.border}/>
          <div className={classNames(styles.option, styles.optionOneLine)}>
            <TextFieldLabel
              icon="light"
              iconAlign="start"
              label={<FormattedMessage id="boxes.lightbox.title"/>}
            />
            <Toggle onChange={has => store.setLightBox(has)} checked={store.model.hasLightBox}/>
          </div>

          <div className={classNames(
            styles.option,
            styles.optionOneLine,
            { [styles.disabled]: !store.model.hasLightBox }
          )}>
            <TextFieldLabel
              labelVariant="body2"
              label={<FormattedMessage id="boxes.lightbox.description"/>}
            />
            <img className={classNames(styles.lightboxImage)} src={Lightbox} alt=""/>
          </div>
        </>
      ) : null}
    </section>
  );
});

export default WizardBoxesSelector;
