import {
  ICuttingService,
  IRoofModuleConnection,
  IRoofTopPlate,
  ISidePlate,
  ITopBottomPlate,
  IWall,
  IZFormLayoutConstraint,
  LayoutOptions,
  SidePlateList,
  TextAlignment,
  TopBottomPlateList,
  WallLocation
} from '../schema';
import { IPostProcessor } from './IPostProcessor';
import { HorizontalPlatesSplitter } from '../services/HorizontalPlatesSplitter';
import {
  rectangleLayoutOptionsTypeGuard,
  standaloneLayoutOptionsTypeGuard,
  zFormLayoutOptionsTypeGuard
} from '../guards';
import { TEXT_PLACEHOLDER } from '../stores/ConfiguratorStore';
import { deepCopy } from '@canvas-logic/engine';

export interface AvailablePlates {
  roofTopPlates: IRoofTopPlate[];
  roofLeftPlates: ISidePlate[];
  roofRightPlates: ISidePlate[];
  finishingTopPlates: ITopBottomPlate[];
  finishingBottomPlates: ITopBottomPlate[];
  finishingLeftPlates: ISidePlate[];
  finishingRightPlates: ISidePlate[];
}

interface SidePlates {
  left: SidePlateList;
  right: SidePlateList;
}

export interface IShelteringWall {
  layoutOptions: IWall['layoutOptions'];
  location: IWall['location'];
  boxes: IWall['boxes'];
  roof: IWall['roof'];
  finishingPlates: IWall['finishingPlates'];
  shelteringCuttingServices: IWall['shelteringCuttingServices'];
}

interface ZFormSidePlateSizes {
  topLeftPanelSize: number;
  topRightPanelSize: number;
  bottomLeftPanelSize: number;
  bottomRightPanelSize: number;
}

interface ZFormTopPlateSizes {
  bottomLeftPanelSize: number;
  bottomRightPanelSize: number;
}

interface ZFormBottomPlateSizes {
  topLeftPanelSize: number;
  topRightPanelSize: number;
}

export class ShelteringPostProcessor implements IPostProcessor {
  private columns: number;
  private wall: IShelteringWall;
  private needRoof: boolean;
  private modulesSizes: number[] = [];
  private onRequestTopBottomPlate: ITopBottomPlate;
  private topBottomCuttingAdded = false;

  constructor(
    private readonly availablePlates: AvailablePlates,
    private readonly connections: IRoofModuleConnection[],
    private readonly cuttingService: ICuttingService
  ) {
    this.extractOnRequestTopBottomPlate();
    this.sortSidePlatesBySize();
  }

  process(wall: IShelteringWall): void {
    this.topBottomCuttingAdded = false;
    this.columns = ShelteringPostProcessor.calculateWallColumns(wall.layoutOptions);
    this.wall = wall;
    this.needRoof = wall.location === WallLocation.OutsideUnsheltered;
    this.modulesSizes = this.needRoof
      ? HorizontalPlatesSplitter.calculateRoofModules(this.columns)
      : HorizontalPlatesSplitter.findFinishingModules(this.columns, wall.boxes);

    // this.addFinishingPlates();

    this.clearFinishingPlates();
    if (this.needRoof) {
      this.addRoof();
      this.addConnections();
      this.addBottomFinishingPlates();
    } else {
      this.removeRoof();
      this.addSideFinishingPlates();
      this.addBottomFinishingPlates();
      this.addTopFinishingPlates();
    }
  }

  private removeRoof() {
    this.wall.roof.top = [];
    this.wall.roof.left = [];
    this.wall.roof.right = [];
    this.wall.roof.connections = [];
  }

  private static calculateWallColumns(layoutOptions: LayoutOptions) {
    if (zFormLayoutOptionsTypeGuard(layoutOptions)) {
      return layoutOptions.w1 + layoutOptions.w2 + layoutOptions.w3;
    }
    if (rectangleLayoutOptionsTypeGuard(layoutOptions)) {
      return layoutOptions.column.max - layoutOptions.column.min + 1;
    }
    if (standaloneLayoutOptionsTypeGuard(layoutOptions)) {
      return layoutOptions.columns;
    }
    throw new Error('Invalid layoutOptions');
  }

  private addRoof() {
    this.wall.roof.top = this.modulesSizes.map((size, i) =>
      this.createRoofTopPlates(i, this.wall.roof.top[i]?.hasText ?? true, size)
    );
    this.wall.roof.left = [this.findRoofLeftPlate()];
    this.wall.roof.right = [this.findRoofRightPlate()];
  }

  private createRoofTopPlates(plateIndex: number, hasText: boolean, size: number): IRoofTopPlate {
    const sourcePlate = this.wall.roof.top[plateIndex];
    const topPlate = deepCopy(this.findRoofTopPlate(hasText, size));

    topPlate.hasText = hasText;

    if (hasText) {
      topPlate.text = sourcePlate?.text ? sourcePlate.text : TEXT_PLACEHOLDER;
      topPlate.textAlignment = sourcePlate?.textAlignment ? sourcePlate.textAlignment : TextAlignment.Central;
    }

    return topPlate;
  }

  private findRoofTopPlate(hasText: boolean, size: number): IRoofTopPlate {
    const topPlate = this.availablePlates.roofTopPlates.find(model => {
      const { text, columns } = model;
      if (hasText) {
        return text && columns === size;
      } else {
        return !text && columns === size;
      }
    });
    if (!topPlate) {
      throw new Error(`Cannot find roof top plate`);
    }
    return topPlate;
  }

  private addSideFinishingPlates(): void {
    const layoutOptions = this.wall.layoutOptions;
    let sidePlates: SidePlates = { left: [], right: [] };

    if (zFormLayoutOptionsTypeGuard(layoutOptions)) {
      const sizes = this.getZFormSidePlatesSizes(layoutOptions);
      sidePlates = this.createZFormFinishingSidePlates(sizes);
      this.addZFormSidePlatesCuttingServices(sidePlates, sizes);
    }
    if (rectangleLayoutOptionsTypeGuard(layoutOptions)) {
      const rows = layoutOptions.row.max - layoutOptions.row.min + 1;
      sidePlates = this.create1PairFinishingSidePlates(rows);
      this.addSidePlatesCuttingServices(sidePlates, rows);
    }
    if (standaloneLayoutOptionsTypeGuard(layoutOptions)) {
      const rows = layoutOptions.rows;
      sidePlates = this.create1PairFinishingSidePlates(rows);
      this.addSidePlatesCuttingServices(sidePlates, rows);
    }

    this.wall.finishingPlates.left = sidePlates.left;
    this.wall.finishingPlates.right = sidePlates.right;
  }

  private findRoofLeftPlate(): ISidePlate {
    if (this.availablePlates.roofLeftPlates.length) {
      return this.availablePlates.roofLeftPlates[0];
    }
    throw new Error('Cannot find left roof side plate');
  }

  private findRoofRightPlate(): ISidePlate {
    if (this.availablePlates.roofRightPlates.length) {
      return this.availablePlates.roofRightPlates[0];
    }
    throw new Error('Cannot find right roof side plate');
  }

  private createFinishingTopPlate(size: number): ITopBottomPlate {
    const topPlate = this.availablePlates.finishingTopPlates.find(model => model.columns === size);
    if (!topPlate) {
      throw new Error('Cannot find top finishing plate');
    }
    return topPlate;
  }

  private createFinishingBottomPlate(size: number): ITopBottomPlate {
    const bottomPlate = this.availablePlates.finishingBottomPlates.find(model => model.columns === size);
    if (!bottomPlate) {
      throw new Error(`Cannot find bottom finishing plate for size ${size}`);
    }
    return bottomPlate;
  }

  private getZFormSidePlatesSizes(layoutOptions: IZFormLayoutConstraint): ZFormSidePlateSizes {
    const { h1, h2, h3 } = layoutOptions;
    return {
      topLeftPanelSize: h1,
      topRightPanelSize: h1 + h2,
      bottomLeftPanelSize: h2 + h3,
      bottomRightPanelSize: h3
    };
  }

  private createZFormFinishingSidePlates(sizes: ZFormSidePlateSizes): SidePlates {
    const { topLeftPanelSize, topRightPanelSize, bottomRightPanelSize, bottomLeftPanelSize } = sizes;
    const topLeftPanel = this.availablePlates.finishingLeftPlates.find(panel => panel.rows >= topLeftPanelSize);
    if (!topLeftPanel) {
      throw new Error(`Cannot find top-left side finishing plate for height ${topLeftPanelSize} rows`);
    }
    const topRightPanel = this.availablePlates.finishingRightPlates.find(
      panel => panel.rows >= topRightPanelSize
    );
    if (!topRightPanel) {
      throw new Error(`Cannot find top-right side finishing plate for height ${topRightPanel} rows`);
    }

    const bottomLeftPanel = this.availablePlates.finishingLeftPlates.find(
      panel => panel.rows >= bottomLeftPanelSize
    );
    if (!bottomLeftPanel) {
      throw new Error(`Cannot find bottom-left side finishing plate for height ${bottomLeftPanelSize} rows`);
    }

    const bottomRightPanel = this.availablePlates.finishingRightPlates.find(
      panel => panel.rows >= bottomRightPanelSize
    );
    if (!bottomRightPanel) {
      throw new Error(`Cannot find bottom-right side finishing plate for height ${bottomRightPanelSize} rows`);
    }

    return {
      left: [topLeftPanel, bottomLeftPanel],
      right: [topRightPanel, bottomRightPanel]
    };
  }

  private create1PairFinishingSidePlates(rows: number): SidePlates {
    const leftPlate = this.availablePlates.finishingLeftPlates.find(plate => plate.rows >= rows);
    if (!leftPlate) {
      throw new Error(`Cannot find left finishing plate for height ${rows} rows`);
    }

    const rightPlate = this.availablePlates.finishingRightPlates.find(plate => plate.rows >= rows);
    if (!rightPlate) {
      throw new Error(`Cannot find right finishing plate for height ${rows} rows`);
    }
    return { left: [leftPlate], right: [rightPlate] };
  }

  private sortSidePlatesBySize() {
    this.availablePlates.finishingLeftPlates.sort(sortByRowsAsc);
    this.availablePlates.finishingRightPlates.sort(sortByRowsAsc);
  }

  private addConnections() {
    let connectionsAmount = Math.max(0, this.modulesSizes.length - 1);
    this.wall.roof.connections = [];
    while (connectionsAmount-- > 0) {
      this.wall.roof.connections.push(...this.connections);
    }
  }

  private clearFinishingPlates() {
    this.wall.shelteringCuttingServices = [];
    this.wall.finishingPlates.top = [];
    this.wall.finishingPlates.bottom = [];
    this.wall.finishingPlates.left = [];
    this.wall.finishingPlates.right = [];
  }

  private addBottomFinishingPlates(): void {
    const layoutOptions = this.wall.layoutOptions;
    let bottomPlates: TopBottomPlateList = [];
    if (zFormLayoutOptionsTypeGuard(layoutOptions)) {
      const bottomSizes = this.getZFormBottomPlatesSizes(layoutOptions);
      bottomPlates = this.createZFormFinishingBottomPlates(bottomSizes);
      // this.addZFormBottomPlatesCuttingServices(bottomPlates, bottomSizes);
    }
    if (rectangleLayoutOptionsTypeGuard(layoutOptions)) {
      const cols = layoutOptions.column.max - layoutOptions.column.min + 1;
      bottomPlates = this.createBottomFinishingPlates();
      this.addTopBottomCuttingIfNeeded(cols);
    }
    if (standaloneLayoutOptionsTypeGuard(layoutOptions)) {
      const cols = layoutOptions.columns;
      bottomPlates = this.createBottomFinishingPlates();
      this.addTopBottomCuttingIfNeeded(cols);
    }

    this.wall.finishingPlates.bottom = bottomPlates;
    // this.wall.finishingPlates.bottom = topBottomPlates.bottom;
  }

  private addTopFinishingPlates() {
    const layoutOptions = this.wall.layoutOptions;
    let topPlates: TopBottomPlateList = [];
    if (zFormLayoutOptionsTypeGuard(layoutOptions)) {
      const topSizes = this.getZFormTopPlatesSizes(layoutOptions);
      topPlates = this.createZFormFinishingTopPlates(topSizes);
    }
    if (rectangleLayoutOptionsTypeGuard(layoutOptions)) {
      const cols = layoutOptions.column.max - layoutOptions.column.min + 1;
      topPlates = this.createTopFinishingPlates();
      this.addTopBottomCuttingIfNeeded(cols);
    }
    if (standaloneLayoutOptionsTypeGuard(layoutOptions)) {
      const cols = layoutOptions.columns;
      topPlates = this.createTopFinishingPlates();
      this.addTopBottomCuttingIfNeeded(cols);
    }

    this.wall.finishingPlates.top = topPlates;
  }

  private addZFormSidePlatesCuttingServices(sidePlates: SidePlates, sizes: ZFormSidePlateSizes) {
    const [topLeft, bottomLeft] = sidePlates.left;
    const [topRight, bottomRight] = sidePlates.right;
    if (topLeft.rows !== sizes.topLeftPanelSize ||
      bottomLeft.rows !== sizes.bottomLeftPanelSize ||
      topRight.rows !== sizes.topRightPanelSize ||
      bottomRight.rows !== sizes.bottomRightPanelSize) {
      this.wall.shelteringCuttingServices.push(this.cuttingService);
    }
  }

  private addSidePlatesCuttingServices(sidePlates: SidePlates, rows: number) {
    const allSidePlates = [...sidePlates.left, ...sidePlates.right];
    let cuttingServiceRequired = allSidePlates.some(sidePlate => sidePlate.rows !== rows);

    if (cuttingServiceRequired) {
      this.wall.shelteringCuttingServices.push(this.cuttingService);
    }
  }

  private getZFormBottomPlatesSizes(layoutOptions: IZFormLayoutConstraint): ZFormTopPlateSizes {
    const { w1, w2, w3 } = layoutOptions;
    return {
      bottomLeftPanelSize: w1,
      bottomRightPanelSize: w2 + w3
    };
  }

  private createZFormFinishingBottomPlates(bottomSizes: ZFormTopPlateSizes): TopBottomPlateList {
    const { bottomRightPanelSize, bottomLeftPanelSize } = bottomSizes;
    let bottomLeftPanel = this.availablePlates.finishingBottomPlates.find(
      panel => panel.columns >= bottomLeftPanelSize
    );
    if (!bottomLeftPanel) {
      if (!this.onRequestTopBottomPlate) {
        throw new Error('Cannot find on-request TopBottomPlate (with column < 0) ');
      }
      bottomLeftPanel = deepCopy(this.onRequestTopBottomPlate);
      bottomLeftPanel.columns = bottomLeftPanelSize;
    }

    let bottomRightPanel = this.availablePlates.finishingBottomPlates.find(
      panel => panel.columns >= bottomRightPanelSize
    );
    if (!bottomRightPanel) {
      if (!this.onRequestTopBottomPlate) {
        throw new Error('Cannot find on-request TopBottomPlate (with column < 0) ');
      }
      bottomRightPanel = deepCopy(this.onRequestTopBottomPlate);
      bottomRightPanel.columns = bottomRightPanelSize;
    }
    return [bottomLeftPanel, bottomRightPanel];
  }

  private createBottomFinishingPlates(): TopBottomPlateList {
    const bottom: TopBottomPlateList = this.modulesSizes.map(size => this.createFinishingBottomPlate(size));
    return bottom;
  }

  private getZFormTopPlatesSizes(layoutOptions: IZFormLayoutConstraint): ZFormBottomPlateSizes {
    const { w1, w2, w3 } = layoutOptions;
    return {
      topLeftPanelSize: w1 + w2,
      topRightPanelSize: w3
    };
  }

  private createZFormFinishingTopPlates(topSizes: ZFormBottomPlateSizes): TopBottomPlateList {
    const { topLeftPanelSize, topRightPanelSize } = topSizes;
    let topLeftPanel = this.availablePlates.finishingTopPlates.find(panel => panel.columns >= topLeftPanelSize);
    if (!topLeftPanel) {
      if (!this.onRequestTopBottomPlate) {
        throw new Error('Cannot find on-request TopBottomPlate (with column < 0) ');
      }
      topLeftPanel = deepCopy(this.onRequestTopBottomPlate);
      topLeftPanel.columns = topLeftPanelSize;
    }
    let topRightPanel = this.availablePlates.finishingTopPlates.find(panel => panel.columns >= topRightPanelSize);
    if (!topRightPanel) {
      if (!this.onRequestTopBottomPlate) {
        throw new Error('Cannot find on-request TopBottomPlate (with column < 0) ');
      }
      topRightPanel = deepCopy(this.onRequestTopBottomPlate);
      topRightPanel.columns = topRightPanelSize;
    }

    return [topLeftPanel, topRightPanel];
  }

  private createTopFinishingPlates(): TopBottomPlateList {
    return this.modulesSizes.map(size => this.createFinishingTopPlate(size));
  }

  private addTopBottomCuttingIfNeeded(cols: number) {
    if (!this.topBottomCuttingAdded && cols > 6) {
      this.wall.shelteringCuttingServices.push(this.cuttingService);
      this.topBottomCuttingAdded = true;
    }
  }

  private extractOnRequestTopBottomPlate() {
    const finishingTopPlates = this.availablePlates.finishingTopPlates;
    this.availablePlates.finishingTopPlates = [];
    for (const plate of finishingTopPlates) {
      if (plate.columns < 0) {
        this.onRequestTopBottomPlate = plate;
      } else {
        this.availablePlates.finishingTopPlates.push(plate);
      }
    }
    const finishingBottomPlates = this.availablePlates.finishingBottomPlates;
    this.availablePlates.finishingBottomPlates = [];
    for (const plate of finishingBottomPlates) {
      if (plate.columns < 0) {
        this.onRequestTopBottomPlate = plate;
      } else {
        this.availablePlates.finishingBottomPlates.push(plate);
      }
    }

  }
}

function sortByRowsAsc(a: ISidePlate, b: ISidePlate) {
  return a.rows - b.rows;
}
