import * as React from 'react';
import { createSearchParams, useSearchParams, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { routes } from '../../stores/RootStore';

import { ConfiguratorStore } from '../../stores/ConfiguratorStore';
import { ConfiguratorMenuStore } from '../../stores/ConfiguratorMenuStore';
import { BoxViewer } from '../../viewer/BoxViewer';
import { rootStore } from '../../stores';
import { TranslatedMessage } from '../../stores/Localization';

import { ArService } from '../../services/ArService';
import ARCode from '../ARCode/ARCode';
import { DropDownMenu } from '../DropDownMenu/DropDownMenu';
import { TopMenuButton } from '../TopMenuButton/TopMenuButton';

import { FormattedMessage } from 'react-intl';
import { copyTextToClipboard, isAlbo } from '../../services/utils';
import { WallType } from '../../schema';
import classNames from 'classnames';

import styles from './TopMenu.module.scss';
import { Formatter } from '../../services/Formatter';

interface IProps {
  store: ConfiguratorStore;
  menu: ConfiguratorMenuStore;
  boxViewer: BoxViewer | undefined;
}

const useGoToWizard = (wallType: WallType) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const goToWizard = React.useCallback(() => {
    const wizardLink = searchParams.get('wizardLink');
    if (!wizardLink) {
      switch (wallType) {
        case WallType.Digital: {
          navigate(routes.digitalWizard);
          return;
        }
        case WallType.Mechanical: {
          navigate(routes.mechanicalWizard);
          return;
        }
        case WallType.Interna: {
          navigate(routes.internaWizard);
          return;
        }
        case WallType.Boxis: {
          navigate(routes.boxisWizard);
          return;
        }
      }
    }

    navigate(`${routes.generalWizard}?${createSearchParams({ link: wizardLink }).toString()}`);
  }, [searchParams, wallType, navigate]);

  return goToWizard;
};

export const TopMenu: React.FC<IProps> = observer(({ store, menu, boxViewer }) => {
  const goToWizard = useGoToWizard(store.model.wallType);

  // Ar link:
  const [arLinkRequested, setArLinkRequested] = React.useState(false);
  const [generatingArLink, setGeneratingArLink] = React.useState(false);
  const [arLink, setArLink] = React.useState<string | undefined>();

  const handleArLinkRequested = async () => {
    setDropDownMenuVisible(false);
    menu.closeSidePanels();
    setArLinkRequested(!arLinkRequested);

    if (!arLinkRequested) {
      setGeneratingArLink(true);
      setArLink(undefined);
      if (!boxViewer) {
        console.error('Attempt to get AR link before viewer is ready');
        return;
      }
      const link = await new ArService().generateArLink(
        boxViewer.scene.getRootWithShellNode(),
        boxViewer.getMountingType()
      );
      setArLink(link);
      setGeneratingArLink(false);
    } else {
      setArLink(undefined);
    }
  };

  //Share link:
  const [generatingShareLink, setGeneratingShareLink] = React.useState(false);

  const copyLink = React.useCallback(async () => {
    setDropDownMenuVisible(false);
    setGeneratingShareLink(true);
    const link = store.shareLink();
    await copyTextToClipboard(link);
    rootStore.notificationsStore.info(new TranslatedMessage('share.tooltip'));
    setGeneratingShareLink(false);
  }, [store]);

  // Ruler:
  const toggleRuler = React.useCallback(() => {
    store.toggleRuler();
  }, [store]);

  // Drop down menu:
  const [dropDownMenuVisible, setDropDownMenuVisible] = React.useState(false);
  const dropDownMenuItems = [
    <TopMenuButton
      onClick={toggleRuler}
      icon={store.showRuler ? 'rulerOff' : 'rulerOn'}
      title={<FormattedMessage id={store.showRuler ? 'top.menu.ruler.off' : 'top.menu.ruler.on'} />}
      toggle
      className={styles.dropButton}
    />,
    <TopMenuButton
      onClick={handleArLinkRequested}
      icon="ar"
      title={<FormattedMessage id="top.menu.ar" />}
      className={styles.dropButton}
    />,
    <TopMenuButton
      onClick={copyLink}
      icon="share"
      title={<FormattedMessage id="top.menu.share" />}
      className={styles.dropButton}
    />
  ];

  const handleMoreActions = () => {
    if (!dropDownMenuVisible && !!arLink) {
      handleArLinkRequested();
    } else {
      setDropDownMenuVisible(!dropDownMenuVisible);
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.topMenuWrapper}>
        <div className={styles.btnWrapper}>
          <TopMenuButton onClick={goToWizard} icon="home" />
          <span className={styles.separator} />
        </div>
        <div className={classNames(styles.arWrapper, styles.hidden)}>
          <TopMenuButton
            onClick={handleArLinkRequested}
            icon="ar"
            toggle
            active={!!arLink}
            loading={generatingArLink}
          />
          {arLink && (
            <ARCode
              className={classNames(styles.arCode, { [styles.arCodeVisible]: arLink })}
              arLink={arLink}
              onQrClose={handleArLinkRequested}
            />
          )}
        </div>
        <div className={classNames(styles.shareButton__wrapper, styles.hidden)}>
          <TopMenuButton
            onClick={() => {
              setDropDownMenuVisible(false);
              copyLink();
            }}
            icon="share"
            disabled={generatingShareLink}
            loading={generatingShareLink}
          />
        </div>
        <span className={classNames(styles.separator, styles.hidden)} />
        <TopMenuButton
          onClick={menu.enterSummarySidePanel}
          icon="cart"
          title={
            !rootStore.authorized || store.costSummaryService.totalPrice === null ? (
              <FormattedMessage id="menu.summary.title" />
            ) : (
              Formatter.formatPrice(store.costSummaryService.totalPrice, !isAlbo(store.model.wallType), 2)
            )
          }
        />
        <div className={styles.btnWrapper}>
          <span className={styles.separator} />
          <TopMenuButton
            onClick={toggleRuler}
            icon={store.showRuler ? 'rulerOff' : 'rulerOn'}
            className={styles.hidden}
          />
          <div className={styles.moreActions}>
            <TopMenuButton
              onClick={handleMoreActions}
              icon="moreActions"
              active={dropDownMenuVisible || !!arLink}
              toggle
              loading={generatingShareLink || generatingArLink}
            />
            {arLink && !dropDownMenuVisible && (
              <ARCode
                className={classNames(styles.arCode, { [styles.arCodeVisible]: arLink })}
                arLink={arLink}
                onQrClose={handleArLinkRequested}
              />
            )}
          </div>
        </div>
      </div>
      {dropDownMenuVisible ? <DropDownMenu items={dropDownMenuItems} className={styles.dropDownMenu} /> : null}
    </div>
  );
});
