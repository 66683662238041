import {
  ICountry,
  IMailBoxEngraving,
  IntercomSetting,
  IRoofTopPlate,
  IWall,
  TextAlignment,
  WallBox,
  WallType
} from '../schema';
import {
  DatasetService,
  deepCopy,
  Engine,
  FlatStructuralTransformer,
  getOptionId,
  getProductId,
  PModel
} from '@canvas-logic/engine';
import { OptionId } from '@canvas-logic/engine/dist/option';
import { LinkService } from '../services/LinkService';
import {
  centralBoxTypeGuard,
  lightBoxTypeGuard,
  mailBoxTypeGuard,
  rectangleLayoutOptionsTypeGuard,
  standaloneLayoutOptionsTypeGuard,
  zFormLayoutOptionsTypeGuard
} from '../guards';
import { IRoofPlateState, IWallBoxState, IWallState, IWallWithEngine } from './types';
import { wallMigrationService } from './WallMigrationService';
import { rootStore } from '../stores';
import { Locale } from '../stores/Localization';

export class WallSerializer {
  private engine: Engine;
  private referenceModel: IWall;

  constructor(private readonly datasetService: DatasetService) {}

  serialize(wall: IWall): IWallState {
    return {
      productId: getProductId(wall),
      roof: wall.roof.top.map(plate => this.serializeRoofModule(plate)),
      layout: wall.layout,
      layoutOptions: wall.layoutOptions,
      location: wall.location,
      boxes: wall.boxes.map(box => ({
        row: box.row,
        column: box.column,
        box: this.serializeBox(box.box)
      })),
      mountingType: wall.mountingType,
      heightFromGround: wall.heightFromGround,
      material: wall.material,
      country: {
        id: getOptionId(wall.country),
        name: wall.country.name
      },
      customIntercom: wall.customIntercom,
      hasOwnKeyPlan: wall.hasOwnKeyPlan,
      includedAccessories: this.getIncludedAccessories(wall),
      offsetHeight: wall.offsetHeight,
      boxDepth: wall.boxes[0].box.depth,
      cylinderCutout: wall.cylinderCutout,
      language: rootStore.localization.locale
    };
  }

  getDefault(wallType: WallType): IWallWithEngine {
    this.engine = new Engine();
    const productSchema = this.datasetService.getProductSchema('', {}, '');
    const productId =
      wallType === WallType.Digital
        ? 'default_digital'
        : wallType === WallType.Interna
        ? 'default_interna'
        : wallType === WallType.Boxis
        ? 'default_boxis'
        : wallType === WallType.Mechanical
        ? 'default_mechanical'
        : '';
    if (!productId) {
      throw new Error(`Cannot find product with wall type '${wallType}'`);
    }
    const product = this.datasetService.getProductById(productId);
    const model = this.engine.initByProductWithContext(productSchema, product) as PModel<IWall>;
    return {
      wall: model,
      engine: this.engine
    };
  }

  findById(productId: string): IWallWithEngine {
    this.engine = new Engine();
    const productSchema = this.datasetService.getProductSchema('', {}, '');
    const product = this.datasetService.getProductById(productId);
    const model = this.engine.initByProductWithContext(productSchema, product) as PModel<IWall>;
    return {
      wall: model,
      engine: this.engine
    };
  }

  deserialize(wallState: IWallState): IWallWithEngine {
    wallMigrationService.migrate(wallState);
    this.engine = new Engine();
    this.buildReferenceModel(wallState);
    const allBoxes = this.engine.optionValuesByPath(this.referenceModel, 'boxes.box', new FlatStructuralTransformer<WallBox>()) ?? [];

    const roofPlates =
      this.engine.optionValuesByPath(this.referenceModel, 'roof.top', new FlatStructuralTransformer<IRoofTopPlate>()) ??
      [];

    const model = deepCopy(this.referenceModel);
    model.boxDepth = wallState.boxDepth;
    model.boxes = [];
    model.layout = wallState.layout;
    model.layoutOptions = wallState.layoutOptions;

    model.offsetHeight = wallState.offsetHeight;

    // Restore type hint
    let layoutOptions = model.layoutOptions as any;
    if (standaloneLayoutOptionsTypeGuard(model.layoutOptions)) {
      layoutOptions._type = 'StandaloneConstraint';
    }
    if (rectangleLayoutOptionsTypeGuard(model.layoutOptions)) {
      layoutOptions._type = 'RectangleLayoutConstraint';
    }
    if (zFormLayoutOptionsTypeGuard(model.layoutOptions)) {
      layoutOptions._type = 'ZFormLayoutConstraint';
    }

    model.location = wallState.location;
    wallState.boxes.forEach(boxState => {
      const boxOption = allBoxes.find(option => option._id === boxState.box.optionId);

      if (!boxOption) {
        throw new Error('Invalid wallState');
      }

      const box = deepCopy(boxOption.model);

      if (centralBoxTypeGuard(box) && boxState.box.bellsAmount) {
        box.bellsAmount = boxState.box.bellsAmount;
        box.intercomSetting = boxState.box.intercomSettings ?? IntercomSetting.NA;
      }
      if (mailBoxTypeGuard(box)) {
        const options =
          this.engine.optionValuesByPath(box, 'engraving', new FlatStructuralTransformer<IMailBoxEngraving>()) ?? [];

        if (boxState.box.engravings) {
          const engraving: IMailBoxEngraving[] = [];
          for (const optionId of boxState.box.engravings) {
            const option = options.find(o => o._id === optionId);
            if (!option) {
              throw new Error('Invalid engraving');
            }
            engraving.push(option.model);
          }
          box.engraving = engraving;
        }
      }
      if (lightBoxTypeGuard(box)) {
        box.text = boxState.box.text?.text ?? '';
        box.textAlignment = boxState.box.text?.alignment ?? TextAlignment.Central;
      }

      model.boxes.push({
        box,
        row: boxState.row,
        column: boxState.column
      });
    });

    wallState.roof.forEach(plateState => {
      const plateOption = roofPlates.find(option => option._id === plateState.optionId);
      if (!plateOption) {
        throw new Error('Invalid wallState');
      }

      const plate = deepCopy(plateOption.model);

      if (plateState.text) {
        plate.hasText = true;
        plate.text = plateState.text.text;
        plate.textAlignment = plateState.text.alignment;
      } else {
        plate.hasText = false;
      }

      model.roof.top.push(plate);
    });
    model.mountingType = wallState.mountingType;
    model.heightFromGround = wallState.heightFromGround;
    model.material = wallState.material;


    const allCountries = this.engine.optionValuesByPath(this.referenceModel, 'country', new FlatStructuralTransformer<ICountry>()) ?? [];

    const country = allCountries.find(option => getOptionId(option.model) === wallState.country.id);

    if (!country) {
      throw new Error('Invalid wallState');
    }

    model.country = country.model;
    model.country.name = wallState.country.name;
    model.customIntercom = wallState.customIntercom;
    model.hasOwnKeyPlan = wallState.hasOwnKeyPlan;
    model.cylinderCutout = wallState.cylinderCutout;

    //deserialize accessories
    model.accessories.forEach(accessory => {
      const included = wallState.includedAccessories.includes(getOptionId(accessory));
      accessory.included = included;
    });

    if (Object.values(Locale).includes(wallState.language)) {
      rootStore.localization.changeLanguage(wallState.language);
    }

    return {
      wall: model as IWall,
      engine: this.engine
    };
  }

  private buildReferenceModel(wallState: IWallState) {
    const productSchema = this.datasetService.getProductSchema('', {}, '');
    const product = this.datasetService.getProductById(wallState.productId);
    this.referenceModel = this.engine.initByProductWithContext(productSchema, product) as PModel<IWall>;
  }

  toLink(wall: IWall): string {
    const json = this.serialize(wall);
    return LinkService.jsonToLink(json);
  }

  fromLink(link: string, version: string): IWallWithEngine {
    const v = Number.parseInt(version);
    let json;
    if (!Number.isNaN(v) && v > 1) {
      json = LinkService.linkToJson(link);
    } else {
      json = LinkService.linkToJsonV1(link);
    }
    return this.deserialize(json as IWallState);
  }

  private serializeBox(box: WallBox): IWallBoxState {
    if (centralBoxTypeGuard(box)) {
      return {
        optionId: getOptionId(box),
        bellsAmount: box.bellsAmount,
        intercomSettings: box.intercomSetting
      };
    }
    if (mailBoxTypeGuard(box)) {
      return {
        optionId: getOptionId(box),
        engravings: box.engraving.map(getOptionId)
      };
    }
    if (lightBoxTypeGuard(box)) {
      return {
        optionId: getOptionId(box),
        text: {
          text: box.text,
          alignment: box.textAlignment
        }
      };
    }
    return {
      optionId: getOptionId(box)
    };
  }

  private serializeRoofModule(plate: IRoofTopPlate): IRoofPlateState {
    return {
      optionId: getOptionId(plate),
      text: plate.hasText
        ? {
            text: plate.text,
            alignment: plate.textAlignment
          }
        : undefined
    };
  }

  private getIncludedAccessories(wall: IWall): OptionId[] {
    return wall.accessories.filter(accessory => accessory.included).map(getOptionId);
  }
}
