import { ITraversable } from './ITraversable';
import { ILayoutTraverser } from './ILayoutTraverser';
import { ZFormLayout, ZFormLayoutConstraint } from '../layout/ZFormLayout';

export class ZFormLayoutGenerator implements ITraversable {
  private static MAX_LINE_WIDTH = 6;
  private static LINE_MAX_WIDTH_DIFF = 1;
  private static MAX_LINE_HEIGHT = 7;
  private static MAX_ROWS = 15;

  private constructor(
    public readonly layouts: ZFormLayoutConstraint[]) {
  }

  static fromLayouts(layouts: ZFormLayoutConstraint[]) {
    return new ZFormLayoutGenerator(layouts);
  }

  static fromConstraints() {
    return new ZFormLayoutGenerator(this.generateLayouts());
  }

  public static generateLayouts(): ZFormLayoutConstraint[] {
    let layouts: ZFormLayoutConstraint[] = [];
    const h2 = 0;
    for (let h = 2; h <= this.MAX_LINE_HEIGHT; h++) {
      // for (let h2 = 0; h2 <= this.MAX_ROWS - 2; h2++) {
      if (2 * h + h2 <= this.MAX_ROWS && Math.abs(h - h2) === 4) {
        for (let w1 = 1; w1 <= this.MAX_LINE_WIDTH - 1; w1++) {
          for (let w2 = 1; w1 + w2 <= this.MAX_LINE_WIDTH; w2++) {
            for (let w3 = 1; w2 + w3 <= this.MAX_LINE_WIDTH; w3++) {
              if (Math.abs(w1 - w3) <= this.LINE_MAX_WIDTH_DIFF &&
                Math.abs(w1 - w2) <= 3) {
                layouts.push({
                  w1,
                  w2,
                  w3,
                  h1: h * 2,
                  h2: h2 * 2,
                  h3: h * 2
                });
              }
            }
          }
        }
      }
    }
    return layouts;
  }

  getTraversers(cellWidth:number, cellHeight:number): ILayoutTraverser[] {
    return this.layouts.map(constraint => new ZFormLayout(constraint, [], cellWidth, cellHeight));
  }
}