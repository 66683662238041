import { DimensionsService } from '../../services/DimensionsService';
import { WeightService } from '../../services/WeightService';
import { Formatter } from '../../services/Formatter';
import { CostSummaryService } from '../../services/CostSummaryService';
import { WallType } from '../../schema';

export interface SummaryPartItem {
  title: string,
  count: number | null,
  price: string
}

export interface SummaryTogglePartItem {
  title: string;
  included: boolean;
  price: string;
}

export interface SummaryPart {
  header: string,
  items: SummaryPartItem[]
}

export interface SummaryTogglePart {
  header: string,
  items: SummaryTogglePartItem[]
}

interface DimensionItem {
  title: string,
  value: string
}

export interface Dimension {
  header: string,
  items: DimensionItem[]
}

export class SummaryDimensionsMaker {
  constructor(private dimension: DimensionsService, private weightService: WeightService) {
  }

  createDimensionsParts(wallType: WallType) {
    if (wallType === WallType.Interna || wallType === WallType.Boxis) {
      return {
        header: 'dimensions',
        items: [
          { title: 'width', value: Formatter.formatSize(this.dimension.width) },
          { title: 'height', value: Formatter.formatSize(this.dimension.height) },
          { title: 'depth', value: Formatter.formatSize(this.dimension.depth) }
        ]
      };
    }

    return {
      header: 'dimensions',
      items: [
        { title: 'width', value: Formatter.formatSize(this.dimension.width) },
        { title: 'height', value: Formatter.formatSize(this.dimension.height) },
        { title: 'depth', value: Formatter.formatSize(this.dimension.depth) },
        { title: 'weight', value: Formatter.formatWeight(this.weightService.weight) }
      ]
    };
  }
}

export class SummaryBoxPartsMaker {
  constructor(
    private readonly costSummaryService: CostSummaryService,
    private readonly isAlbo: boolean
  ) {
  }

  createParts(): { parts: SummaryPart[], toggleParts: SummaryTogglePart[], totalPrice: string } {
    const toggleParts: SummaryTogglePart[] = this.costSummaryService.toggleParts.map(part => {
      return {
        header: part.group,
        items: part.subgroups.map(subgroup => {
          return {
            title: subgroup.subgroup,
            price: Formatter.formatPrice(subgroup.price, !this.isAlbo, 2),
            included: subgroup.included
          };
        })
      };
    });

    const parts = this.costSummaryService.parts.map(group => {
      return {
        header: group.group,
        items: group.subgroups.map(subgroup => {
          return {
            title: subgroup.subgroup,
            price: Formatter.formatPrice(subgroup.price, !this.isAlbo, 2),
            count: subgroup.count
          };
        })
      };
    });

    return {
      parts: parts,
      toggleParts: toggleParts,
      totalPrice: Formatter.formatPrice(this.costSummaryService.totalPrice, !this.isAlbo, 2)
    };
  }
}
