import { PositionedLayoutBox } from '../layout/Box';

export const MODULE_SIZE_LIMIT = 7;

export class HorizontalPlatesSplitter {
  /* Predefined values by custom */
  static calculateRoofModules(columns: number): number[] {
    if (columns < MODULE_SIZE_LIMIT) {
      return [columns];
    }
    switch (columns) {
      case 7:
        return [2, 3, 2];
      case 8:
        return [2, 4, 2];
      case 9:
        return [3, 3, 3];
      case 10:
        return [4, 2, 4];
      case 11:
        return [4, 3, 4];
      case 12:
        return [4, 4, 4];
      default:
        throw new Error(`Invalid columns amount`);
    }
  }

  static calculateFinishingModules(columns: number, boxes: PositionedLayoutBox[]): number[] {
    if (columns < MODULE_SIZE_LIMIT) {
      return [columns];
    }
    const lightBox = boxes.find(box => box.box.type === 'lightbox');
    if (!lightBox) {
      const firstColumn = Math.round(columns / 2);
      const secondColumn = columns - firstColumn;
      return [firstColumn, secondColumn];
    }

    const lightBoxSize = lightBox.box.columns;
    let firstColumn = MODULE_SIZE_LIMIT, secondColumn = MODULE_SIZE_LIMIT;
    // lightbox in first column
    const firstColumnSizeWithLightboxIncluded = lightBox.column + lightBoxSize;
    if (firstColumnSizeWithLightboxIncluded < MODULE_SIZE_LIMIT) {
      firstColumn = Math.round(columns / 2);
      firstColumn = Math.max(firstColumn, firstColumnSizeWithLightboxIncluded);
      secondColumn = columns - firstColumn;
    } else {
      // lightbox in second column
      const secondColumnSizeWithLightboxIncluded = columns - lightBox.column;
      if (secondColumnSizeWithLightboxIncluded < MODULE_SIZE_LIMIT) {
        secondColumn = Math.round(columns / 2);
        secondColumn = Math.max(secondColumn, secondColumnSizeWithLightboxIncluded);
        firstColumn = columns - secondColumn;
      } else {
        throw new Error('Cannot calculate finishing modules')
      }
    }

    if (firstColumn >= MODULE_SIZE_LIMIT || secondColumn >= MODULE_SIZE_LIMIT) {
      throw new Error('Cannot calculate finishing modules');
    }
    return [firstColumn, secondColumn];
  }

  static findFinishingModules(columns: number, boxes: PositionedLayoutBox[]): number[] {
    if (columns < MODULE_SIZE_LIMIT) {
      return [columns];
    }

    // It tries to find 2 finishing plates within the limit with min different of size
    let bestModules = [-1, -1];
    let bestDiff = Infinity;
    for (let firstSize = 1; firstSize < MODULE_SIZE_LIMIT; firstSize++) {
      const secondSize = columns - firstSize;
      const modules = [firstSize, secondSize];
      if (secondSize >= MODULE_SIZE_LIMIT) {
        continue;
      }
      if (!HorizontalPlatesSplitter.boxesCrossModules(modules, boxes)) {
        const diff = Math.abs(firstSize - secondSize);
        if (diff < bestDiff) {
          bestDiff = diff;
          bestModules = modules;
        }
      }
    }
    if (bestDiff === Infinity) {
      throw new Error('Cannot find finishing modules');
    }
    return bestModules;
  }

  static boxesCrossModules(modules: number[], boxes: PositionedLayoutBox[]): boolean {
    const columnsInModule = [];
    let column = 0;
    const q = modules.concat();
    while (q.length) {
      const cellsInColumn = q.shift()!;
      let tmp = [];
      for (let cell = 0; cell < cellsInColumn; cell++) {
        tmp.push(column++);
      }
      columnsInModule.push(tmp);
    }

    for (const { box, column } of boxes) {
      if (box.columns > 1) {
        const startColumn = columnsInModule.findIndex(cells => cells.includes(column));
        const endColumn = columnsInModule.findIndex(cells => cells.includes(column + box.columns - 1));
        if (startColumn !== endColumn) {
          return true;
        }
      }
    }
    return false;
  }
}