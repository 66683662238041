import { observer } from 'mobx-react';
import { ConfiguratorStore, MAX_ELECTRIC_LOCK_PARCEL_BOXES } from '../../stores/ConfiguratorStore';
import { ConfiguratorMenuStore } from '../../stores/ConfiguratorMenuStore';
import { useCallback } from 'react';
import LockTypeMenu from '../LockTypeMenu/LockTypeMenu';
import { MailboxLockType, ParcelLockType } from '../../schema';
import Typography from '../Typography/Typography';
import { FormattedMessage } from 'react-intl';

import s from './BoxContextMenu.module.scss';

interface IProps {
  store: ConfiguratorStore;
  menu: ConfiguratorMenuStore;
}

export const BoxContextMenu: React.FC<IProps> = observer(({ store, menu }) => {
  const leaveContextMenu = useCallback(() => {
    store.zoomOut();
    menu.enterOverviewMainMenu();
  }, [store, menu]);
  const disabled = store.disabledElectricLockForSelectedBox();

  return (
    <>
      {store.selectedMailBox &&
        <LockTypeMenu selected={store.selectedMailBox.lockType}
                      values={[
                        { value: MailboxLockType.Mechanical },
                        { value: MailboxLockType.Electric }
                      ]}
                      onSelect={store.changeLock}
                      onClose={leaveContextMenu} />
      }
      {
        store.selectedParcelBox &&
        <LockTypeMenu selected={store.selectedParcelBox.lockType}
                      values={[
                        { value: ParcelLockType.Number },
                        { value: ParcelLockType.Electric, disabled }
                      ]}
                      onSelect={store.changeLock}
                      onClose={leaveContextMenu}>
          {
            disabled &&
            <Typography variant="caption" className={s.maxElectricParcelLocksHint}>
              <FormattedMessage id="max.electrical.parcel.box.num" values={{ num: MAX_ELECTRIC_LOCK_PARCEL_BOXES }} />
            </Typography>
          }
        </LockTypeMenu>
      }
    </>
  );
});