import {
  AxesHelper,
  Color,
  DirectionalLight,
  Group,
  Mesh,
  MeshBasicMaterial,
  MeshStandardMaterial,
  Object3D,
  Scene
} from 'three';
import {
  BoxViewModel,
  ScreenBox,
  ScreenLeftRightPlate,
  ScreenMountingFoots,
  ScreenRoof,
  ScreenSocle,
  ScreenTopBottomPlate,
  View
} from './BoxViewModel';
import ResourceLoader, { ResourceLoadTransaction } from './ResourceLoader';
import * as utils from './utils';
import { LeftRightPlateObject } from './objects/LeftRightPlateObject';
import { TopBottomPlateObject } from './objects/TopBottomPlateObject';
import { LIGHT_COLOR, TextRenderer } from './TextRenderer';
import { lightBoxTypeGuard } from '../guards';
import { MeshUtils } from './MeshUtils';
import { MailBoxEngravingMaterial, MountingType, WallLayout } from '../schema';
import { WallSocleObject } from './objects/WallSocleObject';
import { FootObject } from './objects/FootObject';
import { DimensionsService } from '../services/DimensionsService';
import { CSG } from 'three-csg-ts';

export const DEBUG = false;
export const BOX_NAME = 'box';
export const SELECTION_ZONE = 'selection_zone';
export const LIGHT_MESH_NAME = 'light';
const MAIN_MATERIAL_NAME = 'dark-rough-anthrazid';
const ENGRAVING_MESH_NAME = 'engraving';
const MIN_WIDTH = 0.38;
const NICHE_DEPTH = 0.37;
const MAX_DEPTH = 398;
const MIN_DEPTH = 120;
const FOOT_OFFSET = 35;
const MOUNTING_PLATE_MODEL_NAME = 'plate';

export class BoxScene {
  private scene!: Scene;
  private dimensionsScene!: Scene;
  private sceneNode!: Object3D;
  private rootNode!: Object3D;
  private backgroundNode!: Object3D;
  private rulerNode!: Object3D;
  private models = new Map<View, Object3D>();
  private dropZoneNode!: Object3D;
  private selectionZonesNode!: Object3D;
  private interiorNode: Object3D | null;
  private shellNode!: Object3D;
  private wallMeshes: Mesh[] = [];
  private iconSelected: Group;

  constructor(
    private resourceLoader: ResourceLoader,
    private textRenderer: TextRenderer
  ) {
  }

  init(mountingType: MountingType, offsetHeight: number) {
    this.initScene(mountingType, offsetHeight);
    this.initLights();
  }

  private initScene(mountingType: MountingType, offsetHeight: number) {
    this.scene = new Scene();
    this.dimensionsScene = new Scene();
    this.sceneNode = new Object3D();
    this.sceneNode.name = 'Scene';
    this.scene.add(this.sceneNode);
    this.dropZoneNode = new Object3D();
    this.dropZoneNode.name = 'DropZones';
    this.dropZoneNode.position.setY(offsetHeight);
    this.selectionZonesNode = new Object3D();
    this.selectionZonesNode.name = 'SelectionZones';
    this.selectionZonesNode.position.z = 0.0001;
    this.selectionZonesNode.position.setY(offsetHeight);
    this.sceneNode.add(this.dropZoneNode);
    this.sceneNode.add(this.selectionZonesNode);
    this.rulerNode = new Object3D();
    this.rulerNode.name = 'Ruler';
    this.sceneNode.add(this.rulerNode);

    this.rootNode = new Group();
    this.rootNode.name = 'Root';
    this.rootNode.position.setY(offsetHeight);
    this.sceneNode.add(this.rootNode);
    this.backgroundNode = new Object3D();
    this.backgroundNode.name = 'Background';
    this.sceneNode.add(this.backgroundNode);
    this.shellNode = new Object3D();
    this.shellNode.name = 'Shell';
    this.shellNode.position.setY(offsetHeight);
    this.sceneNode.add(this.shellNode);

    if (mountingType === MountingType.Niche) {
      this.rootNode.position.setZ(-NICHE_DEPTH);
      this.shellNode.position.setZ(-NICHE_DEPTH);
      this.dropZoneNode.position.setZ(-NICHE_DEPTH);
      this.selectionZonesNode.position.setZ(-NICHE_DEPTH);
    }

    if (DEBUG) {
      const axesHelper = new AxesHelper(1);
      this.scene.add(axesHelper);
    }
  }

  private initLights() {
    const light = new DirectionalLight(LIGHT_COLOR);
    light.position.setZ(10);
    this.scene.add(light);
  }

  async buildScene(viewModel: BoxViewModel, isInteriorRequired = true, onProgress?: (progress: number) => void) {
    if (onProgress) {
      onProgress(0);
    }

    if (viewModel.mountingType !== MountingType.Niche && DimensionsService.isBoxisOrInterna(viewModel.wallType)) {
      const footOffset = (viewModel.mountingType === MountingType.FixOnTheGround && viewModel.boxDepth === MIN_DEPTH) ? FOOT_OFFSET : 0;
      const zOffset = DimensionsService.mmToM(footOffset - (MAX_DEPTH - viewModel.boxDepth));
      this.rootNode.position.setZ(zOffset);
      this.shellNode.position.setZ(zOffset);
      this.dropZoneNode.position.setZ(zOffset);
      this.selectionZonesNode.position.setZ(zOffset);
    }

    await this.loadResources(viewModel, isInteriorRequired, onProgress);
    utils.removeNodeChildren(this.backgroundNode);
    utils.removeNodeChildren(this.rootNode);
    utils.removeNodeChildren(this.shellNode);
    viewModel.boxes.forEach(box => this.createBox(box));
    viewModel.leftRights.forEach(scr => this.addLeftRightPlate(scr));
    viewModel.topBottoms.forEach(scr => this.addTopBottomPlate(scr));
    viewModel.mountingFoots.forEach(foot => this.addFoot(foot, viewModel.mountingType));
    this.addSocle(viewModel.socle);
    this.buildRoof(viewModel.roof);

    if (this.interiorNode) {
      this.interiorNode.traverse(obj => {
        if (obj instanceof Mesh && obj.material instanceof MeshStandardMaterial) {
          obj.material.envMapIntensity = 1.5;
        }
      });
      this.backgroundNode.add(this.interiorNode);
      this.backgroundNode.traverse(obj => {
        if (obj instanceof Mesh) {
          if (obj.name.startsWith('shifting')) {
            MeshUtils.shiftXMesh(obj, (viewModel.width - MIN_WIDTH));
          }
          if (['shifting_wall', 'shifting_left', 'static_wall'].includes(obj.name)) {
            this.wallMeshes.push(obj);
          }
        }
      });
    }
    this.cutTheWall(viewModel);
  }

  private cutTheWall(viewModel: BoxViewModel) {
    if (this.wallMeshes.length > 0 && viewModel.mountingType === MountingType.Niche) {
      let boundingCsg = this.getConfigurationBoundingCsg(viewModel);
      this.wallMeshes.forEach(mesh => {
        mesh.updateMatrix();
        mesh.geometry = CSG.fromMesh(mesh).subtract(boundingCsg).toGeometry(mesh.matrix);
      });
    }
  }

  private getConfigurationBoundingCsg(viewModel: BoxViewModel): CSG {
    let boundingCsg = new CSG();
    if (viewModel.layout === WallLayout.Z) {
      return this.getBoundingCsg(boundingCsg);
    }

    const mesh = MeshUtils.buildBoundingBox(this.rootNode);
    mesh.updateMatrix();
    return CSG.fromMesh(mesh);
  }

  private getBoundingCsg(boundingCsg: CSG) {
    for (let node of this.rootNode.children) {
      const mesh = MeshUtils.buildBoundingBox(node);
      mesh.updateMatrix();
      const nodeCsg = CSG.fromMesh(mesh);
      boundingCsg = boundingCsg.union(nodeCsg);
    }
    return boundingCsg;
  }

  private async loadResources(viewModel: BoxViewModel, isInteriorRequired: boolean, onProgress: undefined | ((progress: number) => void)) {
    const views = this.getUsedModels(viewModel);
    const resourcesList = [
      ResourceLoadTransaction.Image('assets/images/icon-selected.svg', 0.005),
      ...views.map(view => ResourceLoadTransaction.Model(view))
    ];
    if (isInteriorRequired && viewModel.interior) {
      resourcesList.unshift(ResourceLoadTransaction.Model('interior/' + viewModel.interior));
    }
    const transaction = new ResourceLoadTransaction(resourcesList);
    const res = await this.resourceLoader.load(transaction, onProgress);
    if (isInteriorRequired && viewModel.interior) {
      const interior = res.shift()!;
      this.interiorNode = interior;
    }
    this.iconSelected = res[0] as Group;
    const models = res.slice(1, views.length + 1);
    for (let i = 0; i < views.length; i++) {
      const model = models[i];
      this.models.set(views[i], model);
    }
  }

  private getUsedModels(viewModel: BoxViewModel): string[] {
    const usedModels = new Set<string>();
    viewModel.boxes.forEach(box => {
      usedModels.add(box.view);
    });
    viewModel.leftRights.forEach(plate => {
      usedModels.add(plate.view);
    });
    viewModel.topBottoms.forEach(plate => {
      usedModels.add(plate.view);
    });
    viewModel.roof.topPlates.forEach(plate => {
      usedModels.add(plate.view);
    });
    viewModel.roof.leftRights.forEach(plate => {
      usedModels.add(plate.view);
    });
    if (viewModel.socle) {
      usedModels.add(viewModel.socle.view);
    }
    if (viewModel.mountingFoots) {
      viewModel.mountingFoots.forEach(foot => {
        usedModels.add(foot.view);
      });
    }
    return Array.from(usedModels);
  }

  getScene(): Scene {
    return this.scene;
  }

  getDimensionsScene(): Scene {
    return this.dimensionsScene;
  }

  getSceneRoot(): Object3D {
    return this.sceneNode;
  }

  getRulerNode(): Object3D {
    return this.rulerNode;
  }

  getRootNode(): Object3D {
    return this.rootNode;
  }

  getRootWithShellNode(): Object3D {
    const rootWithShell = new Object3D();
    rootWithShell.add(this.rootNode.clone());
    rootWithShell.add(this.shellNode.clone());
    return rootWithShell;
  }

  getDropZoneNode(): Object3D {
    return this.dropZoneNode;
  }

  getSelectionZonesNode(): Object3D {
    return this.selectionZonesNode;
  }

  getIconSelected(): Group {
    return this.iconSelected;
  }

  addPermanentObject(node: Object3D) {
    this.sceneNode.add(node);
  }

  private createBox(box: ScreenBox) {
    const boxNode = new Object3D();
    boxNode.name = BOX_NAME;
    boxNode.position.set(box.x, box.y, box.z);
    let model = this.models.get(box.view)!.clone();
    const wallBox = box.positionedBox.box;
    if (lightBoxTypeGuard(wallBox)) {
      model = this.textRenderer.renderText(wallBox.text, wallBox.textAlignment, model);
    }
    boxNode.add(model);
    boxNode.userData = box;
    this.rootNode.add(boxNode);
  }

  private addLeftRightPlate(scr: ScreenLeftRightPlate) {
    const model = this.models.get(scr.view);
    if (!model) {
      console.log(`ERROR: left right model ${scr.view} not found.`);

      return;
    }
    const plate = new LeftRightPlateObject(model);
    plate.setPosition(scr.pos);
    plate.setHeight(scr.height);
    this.shellNode.add(plate.node);
  }

  private addFoot(scr: ScreenMountingFoots, mountingType: MountingType) {
    const model = this.models.get(scr.view);
    if (!model) {
      console.log(`ERROR: left right model ${scr.view} not found.`);

      return;
    }
    const foot = new FootObject(model);
    foot.setPosition(scr.pos);
    foot.setHeight(scr.height);
    if (mountingType === MountingType.FixOnConcrete) {
      foot.node.traverse(obj => {
        if (obj.name === MOUNTING_PLATE_MODEL_NAME) {
          obj.visible = false;
        }
      });
    }
    this.shellNode.add(foot.node);
  }

  private addTopBottomPlate(scr: ScreenTopBottomPlate) {
    const model = this.models.get(scr.view);
    if (!model) {
      console.log(`ERROR: top bottom model ${scr.view} not found.`);
      return;
    }
    const plate = new TopBottomPlateObject(model);
    plate.setPosition(scr.pos);
    plate.setWidth(scr.width);
    this.shellNode.add(plate.node);
  }

  private addSocle(scr?: ScreenSocle) {
    if (!scr) {

      return;
    }
    const model = this.models.get(scr.view);
    if (!model) {
      console.log(`ERROR: socle model ${scr.view} not found.`);

      return;
    }
    const socle = new WallSocleObject(model);
    socle.setPosition(scr.pos);
    socle.setWidth(scr.width);
    this.shellNode.add(socle.node);
  }

  changeMainColor(color: Color) {
    this.rootNode.traverse(obj => {
      if (obj instanceof Mesh && obj.material instanceof MeshStandardMaterial) {
        if (obj.material.name === MAIN_MATERIAL_NAME) {
          obj.material.toneMapped = false;
          obj.material.color.setHex(color.getHex()).convertSRGBToLinear();
        }
      }
    });
  }

  changeShellColor(color: Color) {
    this.shellNode.traverse(obj => {
      if (obj instanceof Mesh && obj.material instanceof MeshStandardMaterial) {
        if (obj.material.name === MAIN_MATERIAL_NAME) {
          obj.material.toneMapped = false;
          obj.material.color.setHex(color.getHex()).convertSRGBToLinear();
        }
      }
    });
  }

  changeEngravingColor(color: MailBoxEngravingMaterial) {
    this.rootNode.traverse(obj => {
      if (obj instanceof Mesh && obj.name === ENGRAVING_MESH_NAME && obj.material instanceof MeshStandardMaterial) {
        switch (color) {
          case MailBoxEngravingMaterial.White:
            obj.material.color.set(new Color());
            obj.material.metalness = 0;
            obj.material.roughness = 0.67;
            break;
          case MailBoxEngravingMaterial.Black:
            obj.material.color.set(new Color('#000000'));
            obj.material.metalness = 0;
            obj.material.roughness = 0.67;
            break;
          case MailBoxEngravingMaterial.Aluminum:
            obj.material.color.set(new Color('#9c9c9c'));
            obj.material.metalness = 1;
            obj.material.roughness = 0.48;
            break;
        }
      }
    });
  }

  private buildRoof(roof: ScreenRoof) {
    roof.topPlates.forEach(plate => {
      const node = new Object3D();
      node.name = 'roofTopPlate';
      const model = this.models.get(plate.view);
      if (model) {
        const modelWithText = this.textRenderer.renderText(plate.text, plate.textAlignment, model);
        const lightMesh = modelWithText.getObjectByName(LIGHT_MESH_NAME) as Mesh;
        lightMesh.material = new MeshBasicMaterial({ color: LIGHT_COLOR });
        node.add(modelWithText);
        node.position.set(plate.pos.x, plate.pos.y, plate.pos.z);
        this.rootNode.add(node);
      }
    });
    roof.leftRights.forEach(plate => {
      const node = new Object3D();
      node.name = 'roofLeftRightPanel';
      const model = this.models.get(plate.view);
      if (model) {
        node.add(model.clone());
        node.position.set(plate.pos.x, plate.pos.y, plate.pos.z);
        this.rootNode.add(node);
      }
    });
  }

  hideBackground() {
    this.backgroundNode.visible = false;
  }
}
