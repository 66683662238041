export interface Split23Result {
  n2: number;
  n3: number;
}

interface SplitResult {
  value: number;
  bases: number[];
}

function arraysEqual(xs: number[], ys: number[]): boolean {
  if (xs.length !== ys.length) {
    return false;
  }

  for (let i = 0; i < xs.length; i++) {
    if (xs[i] !== ys[i]) {
      return false;
    }
  }
  return true;
}

function splitEquals(a: SplitResult, b: SplitResult) {
  return a.value === b.value && arraysEqual(a.bases, b.bases);
}

export class ValueSplitService {
  private static cache = new Map<string, number[][]>();
  static splitWithCache(value: number, bases: number[]): number[][] {
    const key = `${value}_${bases}`;
    let result = this.cache.get(key);
    if (!result) {
      result = this.split(value, bases);
      this.cache.set(key, result);
    }
    return result;
  }
  static split(value: number, bases: number[], max = Infinity): number[][] {
    const splits: SplitResult[] = [];
    const visited: SplitResult[] = [];
    const q: SplitResult[] = [{
      value,
      bases: bases.map(() => 0)
    }];

    while (q.length) {
      const next = q.pop()!;

      if (visited.some(split => splitEquals(split, next))) {
        continue;
      }
      if (next.value === 0) {
        splits.push(next);
        if (splits.length === max) {
          break;
        }
      }
      for (let i = bases.length - 1; i >= 0; i--) {
        const v = bases[i];
        if (next.value >= v) {
          const split = {
            value: next.value - v,
            bases: next.bases
              .map((base, index) => i === index ? base + 1 : base)
          };
          q.push(split);
        }
      }
      visited.push(next);
    }

    // remove duplicates
    const result: number[][] = [];
    for (const split of splits) {
      if (!result.find(s => arraysEqual(split.bases, s))) {
        result.push(split.bases);
      }
    }
    return result;
  }

  static split23(value: number): Split23Result[] {
    return ValueSplitService.split(value, [3, 2]).map(bases => ({
      n3: bases[0],
      n2: bases[1]
    }));
  }
}