import * as React from 'react';
import { WallType } from "../../schema";

interface IProps {
  wallType: WallType;
  acceptedTypes: WallType[];
  when?: boolean;
}

export const WallTypeGuard: React.FC<IProps> = ({ acceptedTypes, wallType, when = true, children }) => {
  if (!acceptedTypes.includes(wallType) || !when) {
    return null;
  }

  return <>{children}</>
};

