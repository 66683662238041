import { WallBox } from '../schema';
import { Point } from '../layout/Point';
import { ConfiguratorStore } from '../stores/ConfiguratorStore';

export class DnDService {
  private pointer: Point;
  private box: WallBox | null = null;
  private store: ConfiguratorStore;
  private moveExpecting: boolean;

  constructor() {
    document.body.addEventListener('pointermove', this.handlePointerMove);
    document.body.addEventListener('pointerup', this.handlePointerUp);
  }

  private handlePointerUp = () => {
    this.pointerUp();
  };

  private handlePointerMove = (event: PointerEvent) => {
    this.pointerMove(event.clientX, event.clientY);
  };

  setStore(store: ConfiguratorStore) {
    this.store = store;
  }

  dispose() {
    document.body.removeEventListener('pointermove', this.handlePointerMove);
    document.body.removeEventListener('pointerup', this.handlePointerUp);
  }

  pointerDown(box: WallBox): void {
    this.box = box;
    this.moveExpecting = true;
  }

  pointerMove(clientX: number, clientY: number): void {
    this.pointer = {
      centerX: clientX,
      centerY: clientY
    };

    if (this.box) {
      if (this.moveExpecting) {
        if (this.store.isDesktop) {
          this.store.onDragStart(this.box, clientX, clientY);
        }
        this.moveExpecting = false;
      } else {
        if (this.store.isDesktop) {
          this.store.onMove(clientX, clientY);
        } else {
          this.store.onMobileMove(clientX, clientY);
        }
      }
    }
  }

  pointerUp(): void {
    if (this.box) {
      this.store.onDrop();
      this.box = null;
    }
  }
}

export const dndService = new DnDService();