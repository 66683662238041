import * as React from 'react';
import clsx from 'clsx';
import SwitchItem from '../SwitchItem/SwitchItem';

import { IconName } from '../Icon/Icon';
import { DataCyAttribute } from '../../cy';
import styles from './Switch.module.scss';


export interface ISwitchOption<T> {
  title?: string | JSX.Element;
  icon?: IconName;
  value: T;
  disabled?: boolean;
}

interface IProps<T> {
  orientation?: 'horizontal' | 'vertical';
  name: string;
  className?: string;
  options: ISwitchOption<T>[];
  selected: T | undefined;
  onSelect?: (value: T) => void;
  noBackground?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  itemDataCy?: DataCyAttribute;
}

function Switch<T extends string>({
                                    name,
                                    className,
                                    options,
                                    selected,
                                    onSelect,
                                    orientation = 'horizontal',
                                    noBackground,
                                    disabled,
                                    autoFocus,
                                    itemDataCy
                                  }: IProps<T>) {

  return (
    <div className={clsx(styles.root, {
      [styles.vertical]: orientation === 'vertical',
      [styles.noBackground]: noBackground
    }, className)}>
      <ul className={styles.list}>
        {options.map(option => {
          return (
            <li key={option.value} className={styles.item}>
              <SwitchItem
                id={`${name}-${option.value}`}
                name={name}
                title={option.title}
                image={option.icon}
                value={option.value}
                selected={option.value === selected}
                onSelect={onSelect}
                disabled={disabled || option.disabled}
                autoFocus={autoFocus}
                itemDataCy={itemDataCy}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default Switch;
