import {
  Box3,
  BoxBufferGeometry,
  BoxGeometry,
  Mesh,
  MeshBasicMaterial,
  MeshPhongMaterial,
  MeshStandardMaterial,
  Object3D,
  Vector3
} from 'three';
import { LIGHT_COLOR, TEXT_MESH_NAME } from '../viewer/TextRenderer';
import { LIGHT_MESH_NAME } from '../viewer/BoxScene';
import { MountingType } from '../schema';
import { MeshUtils } from '../viewer/MeshUtils';

const PLINTH_HEIGHT = 0.15;
const REDUCTION = 1000;

const AR_STORAGE_PROJECT_NAME = 'esafe';


export class ArService {
  async generateArLink(scene: Object3D, mountingType: MountingType): Promise<string> {
    const sceneBox = new Box3();
    const sceneBoxSize = new Vector3();
    const rootScene = scene.getObjectByName('Root');
    if (rootScene && mountingType !== MountingType.OnFloor) {
      sceneBox.setFromObject(rootScene);
      sceneBox.getSize(sceneBoxSize);
      const geometry = new BoxGeometry(sceneBoxSize.x, sceneBox.min.y, sceneBoxSize.z);
      const box = new Mesh(geometry, new MeshBasicMaterial({ color: 0xffff00, transparent: true, opacity: 0 }));
      box.position.y = sceneBox.min.y / 2;
      box.position.x = sceneBox.max.x / 2;
      box.position.z = -sceneBoxSize.z / 2;
      scene.add(box);
    }
    if (mountingType === MountingType.Niche) {
      scene.scale.setZ(0.05);
    }
    MeshUtils.eraseSceneObjectNames(scene);
    const gltf = await exportScene(scene);
    const json = JSON.stringify(gltf);

    const url = 'https://storage-dot-canvas-logic-276507.nw.r.appspot.com/';

    const response = await fetch(`${url}?project=${AR_STORAGE_PROJECT_NAME}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/octet-stream'
      },
      body: json
    });
    const data = await response.json();
    return url + `link/${data.id}?project=${AR_STORAGE_PROJECT_NAME}`;
  }

  alignScene(scene: Object3D, mountingType: MountingType): Object3D {
    let sceneToExport: Object3D = scene.clone();
    let bbox = new Box3();
    let center = new Vector3();
    const size = new Vector3();

    bbox.setFromObject(sceneToExport);
    bbox.getCenter(center);
    bbox.getSize(size);

    sceneToExport.position.x += -center.x;
    sceneToExport.position.z += -size.z / 2;
    sceneToExport.position.y = PLINTH_HEIGHT;

    bbox.setFromObject(sceneToExport);
    bbox.getCenter(center);

    let geometry = new BoxBufferGeometry(size.x / REDUCTION, size.y / REDUCTION, size.z / REDUCTION);
    let material = new MeshPhongMaterial({ opacity: 0, transparent: true, visible: false });
    let mesh = new Mesh(geometry, material);
    sceneToExport.add(mesh);
    mesh.name = 'bounds';
    mesh.position.set(center.x + size.x / 2, center.y - PLINTH_HEIGHT, center.z + size.z / 2);
    return sceneToExport;
  }
}

async function exportScene(scene: Object3D) {
  // workaround for 'not a module' error
  const { GLTFExporter } = await import('three/examples/jsm/exporters/GLTFExporter');
  const exporter = new GLTFExporter();

  scene.traverse(obj => {
    if (obj instanceof Mesh && [TEXT_MESH_NAME, LIGHT_MESH_NAME].includes(obj.name)) {
      obj.material = new MeshStandardMaterial({ color: LIGHT_COLOR });
    }
  });

  return new Promise<object>((resolve) => {
    exporter.parse(scene, (gltf) => {
      resolve(gltf);
    }, { forceIndices: true });
  });
}
