import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import styles from './SwapMenu.module.scss';
import Typography from "../Typography/Typography";
import { useGuideContext } from "../../contexts/GuideContext";

interface IProps {
  onHintClick?: () => void;
  onClose: () => void;
}

export const SwapMenu: React.VFC<IProps> = ({ onHintClick, onClose }) => {
  const { showSwapBoxGuide } = useGuideContext();

  const handleHintClick = () => {
    showSwapBoxGuide();
    onHintClick?.();
  }

  return (
    <div className={styles.root}>
      <Typography variant="h4">
        <FormattedMessage id="boxes.swap.label" />
      </Typography>
      <span className={styles.separator} />
      <Button variant="secondary" onClick={handleHintClick}>
        <Icon type="hint" size="small" />
      </Button>
      <Button variant="secondary" onClick={onClose}>
        <Icon type="close" size="small" />
      </Button>
    </div>
  );
};
