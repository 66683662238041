import {
  CentralBoxExternalFields,
  ICentralBox,
  IConcreteBox,
  ILightBox,
  IMailBox,
  Intercom,
  IParcelBox,
  IWall,
  IWizard, MailBoxExternalFields,
  ParcelLockType,
  WallBox,
  WallLayout,
  WallLocation,
  WallType
} from '../schema';
import { deepCopy, Engine, FlatStructuralTransformer, PModel } from '@canvas-logic/engine';
import { Layout } from '../layout/Layout';
import { centralBoxTypeGuard, lightBoxTypeGuard, mailBoxTypeGuard, parcelBoxTypeGuard } from '../guards';
import { ZFormLayout } from '../layout/ZFormLayout';
import { RectangleLayout } from '../layout/RectangleLayout';
import { BaseMutator, emptyAction } from '../mutators/BaseMutator';
import { WallPostProcessorFactory } from '../postprocessors/WallPostProcessorFactory';
import { RulesManagerFactory } from '../rules/RulesManagerFactory';
import { adjustMechanicalWallParcels, ParcelLockTypeDetector } from './utils';
import { rootStore } from '../stores';

export class WallGenerator {
  constructor(private readonly engine: Engine, private readonly referenceProduct: PModel<IWall>) {
  }

  generate(layout: Layout, settings: IWizard): PModel<IWall> {
    const boxes =
      this.engine.optionValuesByPath(this.referenceProduct, 'boxes.box', new FlatStructuralTransformer<WallBox>()) ??
      [];
    const mailBoxes: IMailBox[] = [];
    const parcelBoxes: IParcelBox[] = [];
    const centralBoxes: ICentralBox[] = [];
    const lightBoxes: ILightBox[] = [];
    for (const box of boxes) {
      const model = box.model;
      if (mailBoxTypeGuard(model)) {
        mailBoxes.push(model);
      }
      if (parcelBoxTypeGuard(model)) {
        parcelBoxes.push(model);
      }
      if (centralBoxTypeGuard(model)) {
        centralBoxes.push(model);
      }
      if (lightBoxTypeGuard(model)) {
        lightBoxes.push(model);
      }
    }
    const model = deepCopy(this.referenceProduct);
    model.boxes = [];
    model.layout = settings.layout;
    model.location = settings.wallLocation ?? WallLocation.Inside;
    if (layout instanceof ZFormLayout) {
      model.layoutOptions = layout.constraint;
    } else if (layout instanceof RectangleLayout) {
      model.layoutOptions = layout.constraint;
    }

    if (settings.layout === WallLayout.Standalone) {
      model.layoutOptions = { columns: layout.columns, rows: layout.rows };
    }
    model.mountingType = settings.mountingType;
    model.country = settings.country;
    if (settings.hasCustomIntercom) {
      model.customIntercom = settings.customIntercom;
    }
    model.hasOwnKeyPlan = settings.hasOwnKeyPlan;

    const maxElectricParcelBoxes = settings.wallType === WallType.Mechanical ? 1 : Infinity;
    let totalElectricParcelBoxes = 0;

    const lockTypeOf: ParcelLockTypeDetector = (settings.wallType === WallType.Mechanical)
      ? adjustMechanicalWallParcels(layout.boxes, settings.parcelLockType)
      : () => settings.parcelLockType;

    layout.boxes.forEach(layoutBox => {
      const type = layoutBox.box.type;
      const rows = layoutBox.box.rows;
      const cols = layoutBox.box.columns;

      let boxToInsert: WallBox | undefined;
      switch (type) {
        case 'mailbox':
          boxToInsert = mailBoxes.find(
            box => box.rows === rows && box.columns === cols && box.lockType === settings.mailBox.lockType
          );
          break;
        case 'parcelbox':
          boxToInsert = parcelBoxes.find(
            box => box.rows === rows && box.columns === cols && box.lockType === lockTypeOf(layoutBox)
          );

          if (!boxToInsert) {
            throw new Error('Cannot find parcelbox');
          }
          if (boxToInsert.lockType === ParcelLockType.Electric) {
            totalElectricParcelBoxes++;
          }
          if (totalElectricParcelBoxes > maxElectricParcelBoxes) {
            throw new Error(`Cannot insert more than ${maxElectricParcelBoxes} boxes`);
          }
          break;
        case 'lightbox':
          boxToInsert = lightBoxes.find(box => box.rows === rows && box.columns === cols);
          break;
        case 'centralbox':
          boxToInsert = settings.centralBox;
          boxToInsert.bellsAmount = boxToInsert.intercom === Intercom.No ? 0 : settings.doorBellsAmount;
          break;
      }

      if (boxToInsert) {
        const concreteBox: IConcreteBox = {
          row: layoutBox.row,
          column: layoutBox.column,
          box: boxToInsert
        };
        model.boxes.push(concreteBox);
      } else {
        console.error(`Cannot find ${type} ${rows}x${cols}`);
      }
    });
    const centralBoxExternalFields = new CentralBoxExternalFields(rootStore.datasetService);
    const mailBoxExternalFields = new MailBoxExternalFields(rootStore.datasetService);
    const wallPostProcessor = WallPostProcessorFactory.create(this.engine, this.referenceProduct, centralBoxExternalFields, mailBoxExternalFields);
    const layoutRulesManager = RulesManagerFactory.createWallRulesManager(model, layout);
    const domainRulesManager = RulesManagerFactory.createDomainRulesManager();
    const baseMutator = new BaseMutator(wallPostProcessor, layoutRulesManager, domainRulesManager, emptyAction, model);
    const [newModel, validationResult] = this.engine.mutate(model, baseMutator);
    if (validationResult.isInvalid) {
      console.error(model, layout);
      throw new Error(validationResult.errorMessage);
    }
    return newModel as IWall;
  }
}
