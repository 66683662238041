import { v4 } from 'uuid';
import { Layout } from '../../layout/Layout';
import { PositionedLayoutBox } from '../../layout/Box';
import { SelectionZone } from '../types';
import {
  canBoxBeRemovedFromLayout,
  doesRowIntersectReferenceHeight,
  getRowLowerEdgeHeight,
  getRowUpperEdgeHeight,
  isLessOrEqThan,
  isLessThan,
  isRowSolid
} from '../../services/utils';
import { ICentralBox, Intercom, WallLocation } from '../../schema';
import { ZFormLayout } from '../../layout/ZFormLayout';
import { StandaloneLayout } from '../../layout/StandaloneLayout';
import { RectangleLayout } from '../../layout/RectangleLayout';
import { REFERENCE_HEIGHT } from '../../rules/rules';


const ZONE_PADDING = 0.02;


export interface RemoveRowCoordinates {
  // if changing fields' names - don't forget to change removeRowZoneTypeGuard below
  row: number; // index of row
  count: number;
}

export type RemoveRowZone = SelectionZone & RemoveRowCoordinates;

export const removeRowZoneTypeGuard = (zone: SelectionZone): zone is RemoveRowZone => {
  return 'row' in zone && 'count' in zone;
};

export const getAllSolidRows = (layout: Layout): RemoveRowCoordinates[] => {
  const response: RemoveRowCoordinates[] = [];
  let currentRow = layout.firstRowIndex;

  while (currentRow < layout.rows) {
    const firstBoxInCurrentRow: PositionedLayoutBox = layout.findBoxAt({ row: currentRow, column: 0 });
    const isRow = isRowSolid(layout, currentRow, firstBoxInCurrentRow.box.rows);

    if (isRow) {
      response.push({ row: currentRow, count: firstBoxInCurrentRow.box.rows });
    }

    currentRow += firstBoxInCurrentRow.box.rows;
  }

  return response;
};

const isRowZoneValid = (layout: Layout, rowCoordinate: RemoveRowCoordinates): boolean => {
  let columnNumber = 0;

  while (columnNumber < layout.columns) {
    const boxAtThisCoordinate: PositionedLayoutBox = layout.findBoxAt({ row: rowCoordinate.row, column: columnNumber });

    if (!canBoxBeRemovedFromLayout(boxAtThisCoordinate.box)) {
      return false;
    }

    columnNumber += boxAtThisCoordinate.box.columns;
  }

  return true;
};

export const createRowRemoveZones = (layout: Layout, location: WallLocation, disabled: boolean = false): RemoveRowZone[] => {
  if (layout instanceof ZFormLayout) {
    return [];
  }

  if (location === WallLocation.OutsideUnsheltered) {
    return [];
  }

  const allRows: RemoveRowCoordinates[] = getAllSolidRows(layout);

  const response: RemoveRowZone[] = allRows
    .filter((row: RemoveRowCoordinates) => isRowZoneValid(layout, row))
    .map((row: RemoveRowCoordinates): RemoveRowZone => {
      const { centerX, centerY } = layout.toWorldCoordinates(row.row, 0, layout.columns, row.count);

      return {
        id: v4(),
        row: row.row,
        count: row.count,
        centerX: centerX,
        centerY: centerY,
        height: row.count * layout.cellHeight - ZONE_PADDING * 2,
        width: layout.columns * layout.cellWidth - ZONE_PADDING * 2,
        selected: false,
        disabled: disabled,
        valid: true
      };
    });

  return response;
};

export const groupAndSortSelectedRowsToRemove = (selectedRows: RemoveRowZone[], layout: Layout): RemoveRowZone[] => {
  // putting zones: first those above 1600 (REFERENCE_HEIGHT), then rows below it. The last one - row which intersects ref. height (if there's such)
  // Sort the first ones from top to bottom, the second - vice versa.
  // That's made for reason not to create conflicts while removing rows

  if (layout instanceof StandaloneLayout) {
    return [...selectedRows].sort((zone1, zone2) => zone2.row - zone1.row);
  } else if (layout instanceof RectangleLayout) {
    return getGroupedAndSortedRemovedRowsForRectangleLayout(selectedRows, layout);
  } else {
    return selectedRows;
  }
};

const getGroupedAndSortedRemovedRowsForRectangleLayout = (selectedRows: RemoveRowZone[], layout: Layout): RemoveRowZone[] => {
  if (!(layout instanceof RectangleLayout)) {
    throw new Error('Layout is not rectangle');
  }

  const higherThanReferenceHeight: RemoveRowZone[] = [];
  const lowerThanReferenceHeight: RemoveRowZone[] = [];
  const intersectingReferenceHeight: RemoveRowZone[] = [];

  for (const selectedRow of selectedRows) {
    if (isLessOrEqThan(REFERENCE_HEIGHT, getRowLowerEdgeHeight(selectedRow.row, layout))) {
      higherThanReferenceHeight.push(selectedRow);
    } else if (isLessOrEqThan(getRowUpperEdgeHeight(selectedRow.row, layout), REFERENCE_HEIGHT)) {
      lowerThanReferenceHeight.push(selectedRow);
    } else if (doesRowIntersectReferenceHeight(selectedRow.row, layout, REFERENCE_HEIGHT)) {
      intersectingReferenceHeight.push(selectedRow);
    }
  }

  higherThanReferenceHeight.sort((zone1, zone2) => zone2.row - zone1.row);
  lowerThanReferenceHeight.sort((zone1, zone2) => zone1.row - zone2.row);

  return [...higherThanReferenceHeight, ...lowerThanReferenceHeight, ...intersectingReferenceHeight];
};

