import * as React from 'react';
import clsx from 'clsx';
import Typography from '../Typography/Typography';

import styles from './ElementItem.module.scss';
import classNames from 'classnames';

interface IProps {
  className?: string;
  id: string;
  name: string;
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
  image?: string;
  value: string;
  disabled?: boolean;
  selected?: boolean;
  onSelect?: (value: string) => void;
  onPointerDown?: React.PointerEventHandler;
}

const ElementItem: React.FC<IProps> = ({
                                         className,
                                         id,
                                         title,
                                         name,
                                         subtitle,
                                         image,
                                         value,
                                         disabled,
                                         selected,
                                         onSelect,
                                         onPointerDown
                                       }) => {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onSelect) {
      onSelect(event.currentTarget.value);
    }
  };

  return (
    <div
      className={clsx(styles.root, {
          [styles.disabled]: disabled
        },
        className
      )}
    >
      <label className={styles.label} htmlFor={id}>
        <input
          className={styles.input}
          type="radio"
          id={id}
          value={value}
          name={name}
          checked={selected}
          onChange={handleOnChange}
          disabled={disabled}
        />
        <div className={styles.content}>
          {image && <div className={classNames(styles.imageWrapper, styles.desktopOnly)}>
            <img onPointerDown={onPointerDown}
                 draggable={false}
                 src={image}
                 className={styles.icon} alt="" />
          </div>
          }

          {image && <div className={styles.mobileOnly}>
            <img draggable={false}
                 src={image}
                 className={styles.icon} alt="" />
          </div>
          }

          <div className={styles.title_wrapper}>
            <Typography
              variant="subtitle1"
              className={styles.title}
            >
              {title}
            </Typography>
            {subtitle && <Typography variant="caption" className={styles.subtitle}>{subtitle}</Typography>}
          </div>
        </div>
      </label>
    </div>
  );
};

export default ElementItem;
