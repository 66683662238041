import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import Guide, { GuideFrame } from "../Guide/Guide";

import Illustration from './assets/Esafe-wizard-add-animation.svg';
import IllustrationMobile from './assets/Esafe-wizard-add-mobile-animation.svg';
import styles from './InsertGuide.module.scss';

interface IProps {
  onChangeStep?: (step: number) => void;
  onFinish: () => void;
}

const FirstStep: React.VFC = () => (
  <div>
    <FormattedMessage id="boxes.insert.guide.1" />
  </div>
);

const SecondStep: React.VFC = () => (
  <GuideFrame>
    <div className={styles.image}>
      <img className={styles.desktopOnly} src={Illustration} alt="" />
      <img className={styles.mobileOnly} src={IllustrationMobile} alt="" />
    </div>
    <span className={styles.desktopOnly}><FormattedMessage id="boxes.insert.guide.2" /></span>
    <span className={styles.mobileOnly}><FormattedMessage id="boxes.insert.guide.2_mobile" /></span>
  </GuideFrame>
);

const InsertGuide: React.VFC<IProps> = ({ onChangeStep, onFinish }) => {
  const steps = React.useMemo(() => [<FirstStep />, <SecondStep />], []);

  return <Guide steps={steps} onChangeStep={onChangeStep} onFinish={onFinish} />;
};

export default InsertGuide;
