import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form } from '../Form/Form';
import ralPalette from '../../constants/ral-palette';
import { FinishType, IMaterial } from '../../schema';
import { rootStore } from '../../stores';
import Autocomplete from '../Autocomplete/Autocomplete';
import BottomSheet from '../BottomSheet/BottomSheet';
import Icon from '../Icon/Icon';
import RALColourSelector, { IColorItem } from '../RALColourSelector/RALColourSelector';
import TextField from '../TextField/TextField';
import Typography from '../Typography/Typography';
import { DEFAULT_MATERIAL, RECOMMENDED_MATERIALS } from './ColorSidePanel';
import styles from './ColorSidePanel.module.scss';



interface CustomColorBarProps {
  handleFinishTypeSelected: (value: string) => void;
  handleColorSelected: (value: string, currentFinish: FinishType) => void;
  selectedMaterial: IMaterial | undefined;
}

export const CustomColorBar = ({
  handleFinishTypeSelected,
  handleColorSelected,
  selectedMaterial
}: CustomColorBarProps): JSX.Element => {
  const [ralSearchText, setRalSearchText] = React.useState('');
  const [currentlyFinishSelected, setCurrentlyFinishSelected] = React.useState<FinishType>(selectedMaterial?.finish ?? DEFAULT_MATERIAL.finish);

  React.useEffect((): void => {
    if (selectedMaterial) {
      setCurrentlyFinishSelected(selectedMaterial.finish);
    }
  }, [selectedMaterial]);

  const onFinishTypeChange = (event: React.ChangeEvent<HTMLInputElement> | null, value: string): void => {
    setCurrentlyFinishSelected(value as FinishType);
    handleFinishTypeSelected(value as FinishType);
  };

  const onCustomColorChange = (colorCode: string): void => {
    handleColorSelected(colorCode, currentlyFinishSelected);
  };

  const handleMainRalSearchTextReset = () => {
    setRalSearchText('');
  };

  const handleMainRalSearchTextChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRalSearchText(event.target.value);
  };

  const renderedColorItems: IColorItem[] = ralPalette
  .filter(color => color.ralCode.toString().startsWith(ralSearchText))
  .map(item => {
    const isRecommendedMaterial = RECOMMENDED_MATERIALS.some(material => material.ralColor === item.ralCode && material.finish === currentlyFinishSelected);
    return {ralCode: item.ralCode, smallText: isRecommendedMaterial ? <FormattedMessage id="color.standardColor"/> : undefined};
  });

  return (
    <BottomSheet.Content height="*" className={styles.color}>
      <Form.Control labelDisplay="block">
        <div className={styles.paletteSearch}>
          <TextField
            className={styles.searchField}
            startAdornment={
              <span className={styles.searchAdornment}>
                <Icon type="loop" size="small" />
                <Typography className={styles.searchAdornmentText}>RAL</Typography>
              </span>
            }
            endAdornment={
              <button
                className={styles.clearButton}
                onClick={handleMainRalSearchTextReset}
                hidden={ralSearchText === ''}
                disabled={ralSearchText === ''}
              >
                <Icon type="clear" size="small" />
              </button>
            }
            placeholder={rootStore.localization.formatMessage('color.searchPlaceholder')}
            value={ralSearchText}
            onChange={handleMainRalSearchTextChanged}
          />
        </div>
        <Autocomplete
          suggestions={Object.values(FinishType).map(finishType => ({
            value: finishType,
            label: rootStore.localization.formatMessage(`color.finishType.${finishType}`)
          }))}
          value={currentlyFinishSelected.toString()}
          onChange={onFinishTypeChange}
          select
        />
      </Form.Control>
      <RALColourSelector
        className={styles.colorsList}
        name="search-main"
        items={renderedColorItems}
        selected={selectedMaterial?.ralColor.toString()}
        onSelect={onCustomColorChange}
        orientation="vertical"
      />
    </BottomSheet.Content>
  )
};
