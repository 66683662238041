import * as React from 'react';
import { action, makeObservable, observable } from 'mobx';
import {
  createIntl,
  createIntlCache,
  defineMessages,
  FormattedMessage,
  IntlShape,
} from 'react-intl';

export enum Locale {
  en = 'en',
  de = 'de',
  nl = 'nl',
  fr = 'fr'
}

type RawLocaleMessages = Array<[string, string | RawLocaleMessages]>;
type LocaleMessages = Record<string, string>;

type LocaleSwitchListener = (locale: Locale) => void;

export class TranslatedMessage {
  constructor(public readonly translationId: string, public readonly translationContext?: any) {}

  static create(translationId: string, translationContext?: any) {
    return new TranslatedMessage(translationId, translationContext);
  }
}

export class LocalizedError extends Error {}

class Localization {
  locale: Locale;
  intl?: IntlShape;

  private localeSwitchListener?: LocaleSwitchListener;

  constructor(initialLocale: Locale) {
    this.locale = initialLocale;

    makeObservable<Localization, 'fetchTranslation'>(this, {
      locale: observable,
      intl: observable,

      init: action,
      fetchTranslation: action,
      setLocaleSwitchListener: action.bound,
      removeLocaleSwitchListener: action.bound,
      changeLanguage: action.bound,
    });
  }

  async init() {
    const messages = await this.fetchTranslation();
    const flattenMessages = Object.fromEntries(this.flattenTranslation(Object.entries(messages)));
    this.initIntl(flattenMessages);
  }

  private async fetchTranslation() {
    const url = `assets/i18n/${this.locale}.json`;
    try {
      const response = await fetch(url);
      return (await response.json()) as RawLocaleMessages;
    } catch (error) {
      console.error(error);
      return {};
    }
  }

  private flattenTranslation(
    messageEntries: Array<[string, string | object]>
  ): Array<[string, string]> {
    return messageEntries.flatMap(([key, entry]) => {
      if (typeof entry === 'string') {
        return [[key, entry]];
      } else {
        return this.flattenTranslation(Object.entries(entry)).map(([innerKey, innerEntry]) => {
          return [`${key}.${innerKey}`, innerEntry];
        });
      }
    }) as Array<[string, string]>;
  }

  setLocaleSwitchListener(listener: LocaleSwitchListener) {
    this.localeSwitchListener = listener;
  }

  removeLocaleSwitchListener() {
    this.localeSwitchListener = undefined;
  }

  async changeLanguage(locale: Locale) {
    this.locale = locale;
    await this.init();

    if (this.localeSwitchListener) {
      this.localeSwitchListener(locale);
    }
  }

  formatMessage(key: string, values?: Record<string, any>) {
    if (!this.intl) {
      console.warn(`Localization didn't init`);
      return key;
    }

    const messages = defineMessages({
      [key]: {
        id: key,
      },
    });

    return this.intl.formatMessage(messages[key], values);
  }

  formatedMessage(key: string, values?: Record<string, any>) {
    return <FormattedMessage id={key} values={values} />;
  }

  private initIntl(messages: LocaleMessages) {
    // This is optional but highly recommended
    // since it prevents memory leak
    const cache = createIntlCache();

    this.intl = createIntl(
      {
        locale: this.locale,
        messages: messages,
        textComponent: 'span',
      },
      cache
    );
  }
}

export default Localization;
