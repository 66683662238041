import { action, computed, makeObservable, observable } from "mobx";
import { v4 } from 'uuid';

import { IconName } from '../components/Icon/Icon';
import { TranslatedMessage } from "./Localization";

export enum NotificationType {
  INFO,
  WARNING,
  ERROR
}

export enum NotificationDuration {
  SHORT = 3000,
  MEDIUM = 5000,
  LONG = 5000,
  PERSISTENT = Infinity
}

type MessageConfiguration = {
  type: NotificationType;
  duration: NotificationDuration;
  icon?: IconName | 'spinner';
  content: JSX.Element | TranslatedMessage | string | string[];
};

export type NotificationIcon = MessageConfiguration['icon'];

type NotificationDismissAction = () => void;

type Notification = MessageConfiguration & {
  id: string;
};

export class NotificationsStore {
  constructor() {
    makeObservable<NotificationsStore,
      'notificationStack' |
      'timeout' |
      'queueNotification' |
      'cancelNotification' |
      'displayNotification' |
      'dismissNotification'
      >(this, {
      notificationStack: observable,
      timeout: observable,
      currentNotification: computed,

      info: action.bound,
      warning: action.bound,
      error: action.bound,
      persistent: action.bound,
      queueNotification: action.bound,
      cancelNotification: action.bound,
      displayNotification: action.bound,
      dismissNotification: action.bound,
      fromPromise: action.bound,
    });
  }

  private notificationStack: Notification[] = [];

  private timeout?: ReturnType<typeof setTimeout> | null;

  info(content: MessageConfiguration['content'], icon?: NotificationIcon) {
    return this.queueNotification({
      type: NotificationType.INFO,
      icon,
      duration: NotificationDuration.MEDIUM,
      content: content
    });
  }

  warning(content: MessageConfiguration['content'], icon?: NotificationIcon) {
    return this.queueNotification({
      type: NotificationType.WARNING,
      icon,
      duration: NotificationDuration.LONG,
      content: content
    });
  }

  error(content: MessageConfiguration['content'], icon?: NotificationIcon) {
    return this.queueNotification({
      type: NotificationType.ERROR,
      icon,
      duration: NotificationDuration.SHORT,
      content: content
    });
  }

  persistent(content: MessageConfiguration['content'], icon?: NotificationIcon) {
    return this.queueNotification({
      type: NotificationType.WARNING,
      icon,
      duration: NotificationDuration.PERSISTENT,
      content: content
    });
  }

  guide(content: MessageConfiguration['content'], icon?: NotificationIcon) {
    return this.queueNotification({
      type: NotificationType.INFO,
      icon,
      duration: NotificationDuration.PERSISTENT,
      content: content
    });
  }

  clear(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }
    this.notificationStack.length = 0;
  }

  private queueNotification(config: MessageConfiguration): NotificationDismissAction {
    const id = v4();
    this.notificationStack.push({
      id,
      ...config
    });

    if (this.timeout === undefined) {
      this.displayNotification();
    }

    return () => this.cancelNotification(id);
  }

  private cancelNotification(id: string) {
    if (this.currentNotification?.id === id) {
      this.dismissNotification();
      if (this.notificationStack.length > 0) {
        this.displayNotification();
      }
    } else {
      const notificationIndex = this.notificationStack.findIndex(notification => notification.id === id);
      if (notificationIndex !== -1) {
        this.notificationStack.splice(notificationIndex, 1);
      }
    }
  }

  get currentNotification() {
    return this.notificationStack.length > 0 && this.timeout !== undefined ? this.notificationStack[0] : undefined;
  }

  private displayNotification() {
    const notificationConfig = this.notificationStack[0];

    if (!notificationConfig) {
      return;
    }

    if (notificationConfig.duration === Infinity) {
      this.timeout = null;
    } else {
      this.timeout = setTimeout(() => {
        this.dismissNotification();
        if (this.notificationStack.length > 0) {
          this.displayNotification();
        }
      }, notificationConfig.duration);
    }
  }

  private dismissNotification() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = undefined;
    this.notificationStack.shift();
  }

  async fromPromise(
    promise: Promise<any>,
    pendingMessage: TranslatedMessage,
    doneMessage: TranslatedMessage,
    errorMessage?: TranslatedMessage
  ) {
    const dismissNotification = this.persistent(pendingMessage, 'spinner');
    try {
      await promise;
      dismissNotification();
      this.info(doneMessage, 'check');
    } catch (e: unknown) {
      dismissNotification();
      if (errorMessage) {
        this.error(errorMessage);
      } else if (e instanceof Error) {
        this.error(e.message);
      }
    }
  }
}
