export enum ZoneStatus {
  Unknown,
  Valid,
  Invalid
}

export interface Zone {
  centerX: number;
  centerY: number;
  width: number;
  height: number;
}

export interface DropZoneBase extends Zone {
  status?: ZoneStatus;
}

export interface SelectionZone extends Zone {
  id: string;
  selected: boolean;
  valid: boolean;
  disabled: boolean;
}

export interface ZoneDistance {
  inner: number;
  outer: number;
}