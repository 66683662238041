import { Layout } from '../../layout/Layout';
import { RectangleLayout } from '../../layout/RectangleLayout';
import { ZFormLayout } from '../../layout/ZFormLayout';
import { WallBox, WallLocation } from '../../schema';
import { DropZoneBase } from '../types';
import { NewRowType } from './behaviour';

export interface NewRowData {
    type: 'newRow';
    row: NewRowType;
}

export interface NewRowZone extends DropZoneBase {
    data: NewRowData;
}

export const getNewRowsDropZones = (box: WallBox, layout: Layout, location: WallLocation): NewRowZone[] => {
    if (location === WallLocation.OutsideUnsheltered) {
        return [];
    }

    const height: number = box.rows * layout.cellHeight;

    // TODO: add implementation for ZFormLayout
    if (!(layout instanceof ZFormLayout)) {
        const { centerX: leftX, centerY: firstRowY } = layout.toWorldCoordinates(layout.rows - layout.height - box.rows, 0, layout.width, box.rows);
        const { centerY: lastRowY } = layout.toWorldCoordinates(layout.rows, 0, layout.width, box.rows);

        const width: number = layout.width * layout.cellWidth;

        const response: NewRowZone[] = [{
            width: width,
            height: height,
            centerX: leftX,
            centerY: lastRowY,
            data: { type: 'newRow', row: 'Top' }
        }];

        if (layout instanceof RectangleLayout) {
            response.unshift({
                width: width,
                height: height,
                centerX: leftX,
                centerY: firstRowY,
                data: { row: 'Bottom', type: 'newRow' }
            });
        }

        return response;
    }

    return [];
}
