import { Position } from '../../layout/Position';
import { Action } from '../../rules/LayoutRulesManager';
import { Layout } from '../../layout/Layout';
import { NeighbourBehaviour } from './behaviour';
import { NeighbourBoxPosition } from './zones';

export function shiftNeighbourAction(position: Position, direction: NeighbourBoxPosition): Action {
  return (layout: Layout) => {
    const behaviour = new NeighbourBehaviour(layout);
    behaviour.shift(position, direction);
  };
}