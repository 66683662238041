import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import Button from '../Button/Button';
import Icon from '../Icon/Icon';

import styles from './WizardMobileStepper.module.scss';
import { useMemo } from "react";

interface Step {
  title: string;
  path: string;
  hidden?: boolean;
}

interface IProps {
  steps: Step[];
  current: number;
  passedSteps: boolean[];
  onGenerate: () => void;
  queryString: string;
  nextStep: string;
  previousStep: string;
}

const WizardMobileStepper: React.VFC<IProps> = ({ steps, current, passedSteps, onGenerate, queryString, previousStep, nextStep }) => {
  const navigate = useNavigate();

  const lastStep = React.useMemo(() => steps.length - 1, [steps]);
  const lastAvailableStep = useMemo(() => {
    const firstUnfinishedStep = passedSteps.findIndex((progress, index) => !progress && !steps[index].hidden);

    let firstAvailableStep = firstUnfinishedStep === -1 ? passedSteps.length : firstUnfinishedStep;
    while (firstAvailableStep < steps.length && steps[firstAvailableStep].hidden) {
      firstAvailableStep++;
    }

    return firstAvailableStep;
  }, [passedSteps, steps]);

  const handlePreviousClick = () => {
    navigate(
      {
        pathname: `../${previousStep}`,
        search: queryString
      },
      {
        relative: 'path'
      }
    );
  };

  const handleNextClick = () => {
    if (current === lastStep) {
      onGenerate();
    } else {
      navigate(
        {
          pathname: `../${nextStep}`,
          search: queryString
        },
        {
          relative: 'path'
        }
      );
    }
  };

  const handleStepClick = (step: string) => {
    navigate(
      { pathname: `../${step}`, search: queryString },
      {
        relative: 'path'
      }
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={classNames(styles.prevButtonWrapper, { [styles.prevButtonHidden]: current === 0 })}>
        <Button variant="secondary" onClick={handlePreviousClick} disabled={current === 0}>
          <Icon type="ArrowLeft" />
        </Button>
      </div>

      {steps.length > 1 && (
        <div>
          {steps.map(({ title, path, hidden }, index) => {
            if (hidden) {
              return null;
            }

            return (
              <button
                key={title}
                className={classNames(styles.step, {
                  [styles.current]: index === current,
                  [styles.passed]: passedSteps[index] && index <= lastAvailableStep,
                  [styles.disabled]: index > lastAvailableStep,
                })}
                disabled={index > lastAvailableStep}
                onClick={() => handleStepClick(path)}
              />
            );
          })}
        </div>
      )}

      <div hidden={!passedSteps[current]}>
        <Button
          variant="secondary"
          onClick={handleNextClick}
          disabled={current === lastAvailableStep}
        >
          {current === lastStep ? <Icon type="ok" /> : <Icon type="ArrowRight" />}
        </Button>
      </div>
    </div>
  );
};

export default WizardMobileStepper;
