import * as React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import Icon from '../Icon/Icon';
import Typography from '../Typography/Typography';
import styles from './ColourSelectorItem.module.scss';


interface IProps {
  className?: string;
  id: string;
  name: string;
  background: string;
  textColor: string;
  borderColor?: string;
  value: string;
  selected?: boolean;
  onSelect?: (value: string) => void;
  label?: string;
  smallText?: string | JSX.Element;
  minimal?: boolean
  autoFocus?: boolean;
}

const ColourSelectorItem: React.FC<IProps> = ({
  className,
  id,
  name,
  background,
  textColor,
  borderColor,
  value,
  selected,
  onSelect,
  minimal,
  label,
  smallText,
  autoFocus
}) => {

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onSelect) {
      onSelect(event.currentTarget.value);
    }
  };

  return (
    <div className={classNames(styles.root, {
      [styles.minimal]: minimal,
      [styles.selected]: selected,
    }, className)}>
      <input
        className={styles.input}
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={selected}
        onChange={handleOnChange}
        autoFocus={autoFocus}
      />
      <label className={styles.label} htmlFor={id}>
        <div className={styles.color} style={{ background, color: textColor, border: borderColor ? `1px solid ${borderColor}` : undefined }}>
          <div className={styles.colorText}>
            {label && (
              <Typography variant="body2">
                {label}
              </Typography>
            )}
            {smallText && (
              <Typography variant="caption">
                {smallText}
              </Typography>
            )}
          </div>
          <span className={styles.check}>
            <Icon type="check" size="xsmall" />
          </span>
        </div>
      </label>
    </div>
  );
};

export default ColourSelectorItem;
