import { IWallState } from './types';

export class WallMigrationService {
  migrate(wallState: IWallState): void {
    const namePvc = this.namePvc(wallState);
    const removedEngravings: Record<string, string[]> = {
      'name_engraving_albo': [namePvc],
      'name_engraving_black': [namePvc],
      'name_engraving_white': [namePvc],

      'apartment_name_engraving': ['apartment_engraving_albo', namePvc],
      'apartment_name_engraving_black': ['apartment_engraving_black', namePvc],
      'apartment_name_engraving_white': ['apartment_engraving_white', namePvc]
    };

    wallState.boxes.forEach(wbox => {
      if (wbox.box.engravings) {
        wbox.box.engravings = wbox.box.engravings.flatMap(engraving => {
          if (engraving in removedEngravings) {
            return removedEngravings[engraving];
          }
          return [engraving];
        });
      }
    });
  }

  private namePvc(wallState: IWallState): string {
    const isInterna = wallState.productId === 'default_interna';
    const isBoxis = wallState.productId === 'default_boxis';
    if (isInterna) {
      return 'name_pvc_interna'
    }
    if (isBoxis) {
      return 'name_pvc_boxis';
    }
    return '';
  }
}

export const wallMigrationService = new WallMigrationService();