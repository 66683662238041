import * as React from 'react';
import { Figure } from '../Icon/Icon';
import styles from './LockTypeMenu.module.scss';
import BottomSheet from '../BottomSheet/BottomSheet';
import Switch from '../Switch/Switch';
import { MailboxLockType, ParcelLockType } from '../../schema';
import { observer } from 'mobx-react';
import { rootStore } from '../../stores';
import { translateLockType } from '../../services/utils';

interface Value {
  value: MailboxLockType | ParcelLockType,
  disabled?: boolean,
}

interface Props {
  onClose: () => void,
  values: Value[];
  selected: Value['value'];
  onSelect: (lockType: Value['value']) => void;
}

const LockTypeMenu: React.FC<Props> = ({ values, selected, children, onSelect, onClose }) => {
  return (
    <BottomSheet title={rootStore.localization.formatMessage('menu.summary.lockType')} onClose={onClose}>
      <BottomSheet.Content>
        <div className={styles.menuContent}>
          <figure className={styles.image}>
            {[MailboxLockType.Electric, ParcelLockType.Electric].includes(selected) && <Figure type={'lockElectrical'} />}
            {MailboxLockType.Mechanical === selected && <Figure type={'lockMechanical'} />}
            {ParcelLockType.Number === selected && <Figure type={'lockNumber'} />}
          </figure>
          <Switch
            name="lock-type"
            options={values.map(({ value, disabled }) => ({
              title: translateLockType(rootStore.localization, value),
              value,
              disabled: !!disabled
            }))}
            selected={selected}
            onSelect={onSelect}
          />
          <div className={styles.note}>
            {children}
          </div>
        </div>
      </BottomSheet.Content>
    </BottomSheet>
  );
};

export default observer(LockTypeMenu);
