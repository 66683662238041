import * as React from 'react';
import ColourSelector from '../ColourSelector/ColourSelector';
import { colorErrorTypeGuard, invertColor, ralToHex } from '../../services/utils';
import { RECOMMENDED_MATERIALS } from '../ColorSidePanel/ColorSidePanel';


export interface IColorItem {
  ralCode: number;
  smallText?: string | JSX.Element;
}

interface IProps {
  name: string;
  className?: string;
  items: IColorItem[];
  selected: string | undefined;
  onSelect: (value: string) => void;
  orientation?: 'horizontal' | 'vertical';
  autoFocusSelected?: boolean;
}

const RALColourSelector: React.FC<IProps> = ({
  name,
  className,
  items,
  selected,
  onSelect,
  orientation = 'horizontal',
  autoFocusSelected
}) => {
  const convertedItems = React.useMemo(() => {
    return items.flatMap(item => {
      const hexColor = ralToHex(item.ralCode);

      if (colorErrorTypeGuard(hexColor)) {
        console.error(`Unable to convert RAL ${item.ralCode} to HEX`);
        return [];
      }

      const color = `#${hexColor.color}`;

      return {
        label: orientation === 'vertical' ? `RAL ${item.ralCode}` : item.ralCode.toString(10),
        value: item.ralCode.toString(10),
        smallText: item.smallText,
        background: color,
        textColor: invertColor(color, true)
      };
    });
  }, [items, orientation]);

  return (
    <ColourSelector
      name={name}
      className={className}
      items={convertedItems}
      selected={selected}
      onSelect={onSelect}
      orientation={orientation}
      autoFocusSelected={autoFocusSelected}
    />
  );
};

export default RALColourSelector;
