import { FormattedMessage } from "react-intl";
import { Dialog } from "../Dialog/Dialog";
import Icon, { Figure } from '../Icon/Icon';
import Typography from "../Typography/Typography";
import s from "./SendingContactsForm.module.scss";

interface IProps {
  onClose: () => void;
  loading: boolean;
}

export const SendingContactsForm: React.FC<IProps> = ({ onClose, loading }) => {
  return (
    <Dialog
      onClose={onClose}
      title={<FormattedMessage id="contactMe.title" />}
      closeDisabled={loading}
    >
      <div
        className={s.wrapper}
      >
        {
          loading ? <>
            <Icon type="loader24" className={s.loadingIcon} />
            <Typography variant="body2">
              <FormattedMessage id="contactMe.sending.information" />
            </Typography>
          </> : <>
            <Figure type="sendMail" className={s.mailingIcon}/>
            <Typography variant="body2">
              <FormattedMessage id="contactMe.sending.finish" />
            </Typography>
          </>
        }
      </div>
    </Dialog>
  );
}