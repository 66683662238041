import * as THREE from 'three';
import { ModelLoader, ProgressEventHandler } from './ModelLoader';
import { ImagesLoader } from './ImagesLoader';

enum ResourceType {
  MODEL,
  MATERIAL,
  IMAGE
}

type ModelResource = {
  type: ResourceType.MODEL;
  path: string;
};

type MaterialResource = {
  type: ResourceType.MATERIAL;
  path: string;
};

type ImageResource = {
  type: ResourceType.IMAGE;
  scale: number;
  path: string;
};

type Resource = ModelResource | MaterialResource | ImageResource;

type ResourceReturnType<T extends Resource> = T['type'] extends ResourceType.MATERIAL ? THREE.Material : THREE.Object3D;

type ResourceTransactionToReturn<T extends readonly Resource[]> = {
  [K in keyof T]: T[K] extends Resource ? ResourceReturnType<T[K]> : never;
};

type LoadableResource = Resource & {
  total: number;
  loaded: number;
};

export class ResourceLoadTransaction<T extends readonly Resource[]> {
  public resources: LoadableResource[];

  constructor(resources: T) {
    this.resources = resources.map(resource => ({
      ...resource,
      total: 0,
      loaded: 0
    }));
  }

  static Model(path: string): Resource {
    return {
      type: ResourceType.MODEL,
      path
    };
  }

  static Material(path: string): MaterialResource {
    return {
      type: ResourceType.MATERIAL,
      path
    };
  }

  static Image(path: string, scale: number): ImageResource {
    return {
      type: ResourceType.IMAGE,
      path,
      scale
    };
  }

  get progress() {
    const [total, loaded] = this.resources.reduce(
      ([total, loaded], resource) => {
        return [total + resource.total, loaded + resource.loaded];
      },
      [0, 0]
    );

    const progress = Math.floor((loaded * 100) / total);

    return Number.isNaN(progress) ? 100 : progress;
  }

  updateProgress(path: string, event: ProgressEvent) {
    const resource = this.resources.find(resource => resource.path === path);

    if (resource !== undefined && event.lengthComputable) {
      resource.total = event.total;
      resource.loaded = event.loaded;
    }
  }
}

export default class ResourceLoader {
  private static MATERIALS_PATH = 'materials';
  private materialsCache = new Map<string, THREE.Material>();

  constructor(private readonly modelLoader: ModelLoader, private readonly imagesLoader?: ImagesLoader) {
  }

  private async loadMaterial(materialName: string, onProgress?: ProgressEventHandler) {
    try {
      const node = await this.modelLoader.load(`${ResourceLoader.MATERIALS_PATH}/${materialName}`, 'glb', onProgress);
      node.traverse(obj => {
        if (obj instanceof THREE.Mesh) {
          const material: THREE.Material = Array.isArray(obj.material) ? obj.material[0] : obj.material;
          this.materialsCache.set(materialName, material);
        }
      });
    } catch (e: any) {
      console.error(e.message);
    }
  }

  async getMaterial(name: string, onProgress?: ProgressEventHandler) {
    if (!this.materialsCache.get(name)) {
      await this.loadMaterial(name, onProgress);
    }
    return this.materialsCache.get(name)!;
  }

  async getModel(path: string, onProgress: ProgressEventHandler) {
    return this.modelLoader.load(path, 'glb', onProgress);
  }

  async getImage(path: string, scale: number, onProgress: ProgressEventHandler) {
    if (this.imagesLoader) {
      return this.imagesLoader.loadSVG(path, scale, onProgress);
    }
  }

  private async getResource(resource: Resource, onProgress: ProgressEventHandler, scale: number = 0) {
    const { type, path } = resource;

    switch (type) {
      case ResourceType.MATERIAL:
        return this.getMaterial(path, onProgress);
      case ResourceType.MODEL:
        return this.getModel(path, onProgress);
      case ResourceType.IMAGE:
        return this.getImage(path, scale, onProgress);
      default:
        throw new Error(`Unsupported resource type: ${type}`);
    }
  }

  load<T extends readonly Resource[]>(
    transaction: ResourceLoadTransaction<T>,
    progressListener?: (progress: number) => void
  ): Promise<ResourceTransactionToReturn<T>> {
    return Promise.all(
      transaction.resources.map(resource =>
        this.getResource(resource, event => {
          transaction.updateProgress(resource.path, event);

          if (progressListener) {
            progressListener(transaction.progress);
          }
        }, (resource as ImageResource).scale)
      )
    ) as any;
  }
}
