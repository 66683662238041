import * as React from 'react';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { Dialog } from '../Dialog/Dialog';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import Typography from '../Typography/Typography';
import classNames from 'classnames';
import TextField from '../TextField/TextField';

import s from './ContactForm.module.scss';
import { ContactMeStore } from '../../stores/ContactMeStore';
import { observer } from 'mobx-react';
import { MAX_LENGTH_COUNTRY, OTHER_COUNTRY } from '../../stores/WizardStore';
import Autocomplete from '../Autocomplete/Autocomplete';
import { contactService } from '../../services/ContactService';
import { ConfiguratorStore } from '../../stores/ConfiguratorStore';
import { rootStore } from '../../stores';

const STEPS_AMOUNT = 3;
interface IProps {
  configuratorStore: ConfiguratorStore;
  onClose: () => void;
  onSubmit: (store: ContactMeStore) => void;
  shareableLink: string;
  submitBtnText: string | JSX.Element;
  country: string;
  availableCountries: string[];
}

export const ContactForm: React.FC<IProps> = observer(({
                                                         configuratorStore,
                                                         onClose,
                                                         onSubmit,
                                                         submitBtnText,
                                                         country,
                                                         availableCountries,
                                                         shareableLink
                                                       }) => {
  const [contactMeStore] = React.useState<ContactMeStore>(() =>
    new ContactMeStore(availableCountries, country, contactService, shareableLink, configuratorStore));
  const [step, setStep] = React.useState(1);
  const [warningInProcess, setWarningInProcess] = React.useState(false);
  const mandatoryFieldsRef = React.useRef<{ touch: () => void }>(null);

  const handleSubmit = useCallback(() => {
    onSubmit(contactMeStore);
  }, [contactMeStore, onSubmit]);

  const triggerShake = () => {
    setWarningInProcess(true);
  };

  const handleNext = () => {
    if (mandatoryFieldsRef.current) {
      mandatoryFieldsRef.current.touch();
    }

    if (contactMeStore.isValid) {
      if (step < STEPS_AMOUNT) {
        setStep(step + 1);
      }
    } else {
      triggerShake();
    }
  };

  const handlePrev = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const submitForm = step === 3;

  return (
    <Dialog
      className={classNames({ [s.shake]: warningInProcess })}
      onClose={onClose}
      title={<FormattedMessage id="contactMe.title" />}
      footer={
        <Footer
          onPrev={step !== 1 ? handlePrev : undefined}
          onNext={submitForm ? handleSubmit : handleNext}
          finish={submitForm}
          submitBtnText={submitBtnText}
          submitDisabled={!contactMeStore.isValid}
        />
      }
      onAnimationEnd={() => setWarningInProcess(false)}
    >
      <div
        className={classNames(s.wrapper, {
          [s.wrapper__step1]: step === 1,
          [s.wrapper__step2]: step === 2,
          [s.wrapper__step3]: step === 3
        })}
      >
        <Step1 contactMeStore={contactMeStore} ref={mandatoryFieldsRef} />
        <Step2 contactMeStore={contactMeStore} />
        <Step3 contactMeStore={contactMeStore} />
      </div>
    </Dialog>
  );
});

const StepForm: React.FC<{
  step: 1 | 2 | 3,
  title: string | JSX.Element,
  className?: string
}> = ({
        step,
        title,
        children,
        className
      }) => {
  return (
    <div className={classNames(s.stepForm, className)}>
      <div className={s.stepFormTitle}>
        <Typography variant="subtitle1">
          <FormattedMessage id="contactMe.step" values={{ step, total: 3 }} />
        </Typography>
        <Typography variant="body2" className={s.stepFormTitle_subtitle}>
          {title}
        </Typography>
      </div>
      {children}
    </div>
  );
};

const Step1 = observer(React.forwardRef<{ touch: () => void }, { contactMeStore: ContactMeStore }>(({ contactMeStore }, ref) => {
  const [touchedEmail, setTouchedEmail] = React.useState(false);
  const [touchedFirstName, setTouchedFirstName] = React.useState(false);
  const [touchedLastName, setTouchedLastName] = React.useState(false);
  const [touchedPhone, setTouchedPhone] = React.useState(false);

  React.useImperativeHandle(ref, () => ({
    touch: () => {
      setTouchedEmail(true);
      setTouchedFirstName(true);
      setTouchedLastName(true);
      setTouchedPhone(true);
    }
  }), []);

  const handleChangeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    contactMeStore.setFirstName(value);
    setTouchedFirstName(true);
  };

  const handleChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    contactMeStore.setLastName(value);
    setTouchedLastName(true);
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    contactMeStore.setEmail(value);
    setTouchedEmail(true);
  };

  const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    contactMeStore.setPhone(value);
    setTouchedPhone(true);
  };

  return (
    <StepForm
      step={1}
      title={<FormattedMessage id="contactMe.mandatory.fields" />}
      className={s.stepForm}
    >
      <TextField
        error={touchedFirstName && !contactMeStore.isValidFirstName}
        errorLabel={<FormattedMessage id="contactMe.error.not.empty" />}
        label={<FormattedMessage id="contactMe.first.name" />}
        className={s.textField}
        value={contactMeStore.firstName}
        onChange={handleChangeFirstName}
        maxLength={255}
      />
      <TextField
        error={touchedLastName && !contactMeStore.isValidLastName}
        errorLabel={<FormattedMessage id="contactMe.error.not.empty" />}
        label={<FormattedMessage id="contactMe.last.name" />}
        className={s.textField}
        value={contactMeStore.lastName}
        onChange={handleChangeLastName}
        maxLength={255}
      />
      <TextField
        error={touchedEmail && !contactMeStore.isValidEmail}
        errorLabel={<FormattedMessage id="contactMe.error.invalid.email" />}
        label={<FormattedMessage id="contactMe.email" />}
        className={s.textField}
        onChange={handleChangeEmail}
        value={contactMeStore.email}
        maxLength={255}
        type="email"
      />
      <TextField
        error={touchedPhone && !contactMeStore.isValidPhone}
        errorLabel={<FormattedMessage id="contactMe.error.not.empty" />}
        label={<FormattedMessage id="contactMe.phone" />}
        className={s.textField}
        value={contactMeStore.phone}
        onChange={handleChangePhone}
        maxLength={255}
        type="phone"
      />
    </StepForm>
  );
}));

const Step2: React.VFC<{ contactMeStore: ContactMeStore }> = observer(({ contactMeStore }) => {
  const handleChangeCountry = (event: React.ChangeEvent<HTMLInputElement> | null, value: string) => {
    contactMeStore.setCountry(value);
  };

  const handleChangeCity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    contactMeStore.setCity(value);
  };

  const handleChangeStreet = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    contactMeStore.setStreet(value);
  };

  const handleChangeNo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    contactMeStore.setNo(value);
  };

  const handleChangePostalCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    contactMeStore.setPostalCode(value);
  };

  return (
    <StepForm
      step={2}
      title={<FormattedMessage id="contactMe.optional.fields" />}
      className={s.stepForm}
    >
      <Autocomplete
        label={<FormattedMessage id="contactMe.country" />}
        className={s.countries}
        value={contactMeStore.country ?? OTHER_COUNTRY}
        onChange={handleChangeCountry}
        suggestions={contactMeStore.availableCountries.map(country => ({ value: rootStore.localization.formatMessage(country) }))}
        maxLength={MAX_LENGTH_COUNTRY}
        textFieldDataCy="countryNameInput"
      />
      <TextField
        label={<FormattedMessage id="contactMe.city" />}
        className={s.textField}
        value={contactMeStore.city}
        onChange={handleChangeCity}
        maxLength={255}
      />
      <TextField
        label={<FormattedMessage id="contactMe.street" />}
        className={s.textField}
        value={contactMeStore.street}
        onChange={handleChangeStreet}
        maxLength={255}
      />
      <div className={s.postal}>
        <TextField
          label={<FormattedMessage id="contactMe.building" />}
          className={classNames(s.textField, s.postal_No)}
          value={contactMeStore.no}
          onChange={handleChangeNo}
          maxLength={255}
        />
        <TextField
          label={<FormattedMessage id="contactMe.postalCode" />}
          className={s.textField}
          value={contactMeStore.postalCode}
          onChange={handleChangePostalCode}
          maxLength={255}
        />
      </div>
    </StepForm>
  );
});

const Step3: React.VFC<{ contactMeStore: ContactMeStore }> = observer(({ contactMeStore }) => {
  const [link, setLink] = React.useState<string>('');

  const handleChangeRemark = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    contactMeStore.setRemark(value);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      const locale = rootStore.localization.locale;
      const url = 'assets/links/links.json';
      const response = await fetch(url);
      const policyLinks = await response.json();
      const link = policyLinks?.privacyPolicy?.[locale] || '';

      if (!link) {
        console.error('Privacy policy link not found for the specified locale.');
      }

      setLink(link);
    };

    fetchData();
  }, []);

  return (
    <StepForm
      step={3}
      title={<FormattedMessage id="contactMe.any.remark" />}
      className={s.stepForm}
    >
      <TextField
        multiline
        className={classNames(s.textField, s.multiTextFieldWrapper)}
        classes={{ inputMultiline: s.multiTextField }}
        value={contactMeStore.remark}
        onChange={handleChangeRemark}
      />
      <div className={s.submittingHint}>
        <Typography variant="caption">
          <FormattedMessage id="contactMe.submitting.hint" />
        </Typography>
        <a href={link ? link : undefined} className={s.link} target="_blank" rel="noreferrer">
          <Typography variant="caption">
            <FormattedMessage id="contactMe.submitting.link" />
          </Typography>
        </a>
      </div>
    </StepForm>
  );
});

const Footer: React.FC<{
  onNext?: () => void;
  onPrev?: () => void;
  submitBtnText: string | JSX.Element;
  finish?: boolean;
  submitDisabled?: boolean;
}> = ({
        onPrev,
        onNext,
        submitBtnText,
        finish = false,
        submitDisabled
      }) => {
  return (<>
    {onPrev ? (
      <Button onClick={onPrev} variant="secondary">
        <span className={s.footerBtn}>
          <Icon type="ArrowLeft" size="small"/>
          <FormattedMessage id="dialog.back" />
        </span>
      </Button>
    ) : (
      <div className={s.buttonPlaceholder} />
    )}
    {onNext ? finish ? (
      <Button onClick={onNext} disabled={submitDisabled}>
        {submitBtnText}
      </Button>
    ) : (
      <Button onClick={onNext} variant="secondary">
        <span className={s.footerBtn}>
          <FormattedMessage id="dialog.next" />
          <Icon type="ArrowRight" size="small"/>
        </span>
      </Button>
    ) : (
      <div className={s.buttonPlaceholder} />
    )}
  </>);
};
