import { PositionedLayoutBox } from '../../layout/Box';
import { Layout } from '../../layout/Layout';
import { addPosition, Position, subtractPosition } from '../../layout/Position';
import { DropZoneBase } from '../types';

interface BoxSet {
  width: number;
  height: number;
  boxes: PositionedLayoutBox[];
  position: Position;
}

export interface SwapData {
  type: 'swap';
  boxes: PositionedLayoutBox[];
}

export interface SwapZone extends DropZoneBase {
  data: SwapData;
}
function createSwapZone(layout: Layout, boxSet: BoxSet): SwapZone {
  const {
    centerX,
    centerY
  } = layout.toWorldCoordinates(boxSet.position.row, boxSet.position.column, boxSet.width, boxSet.height);
  const width = boxSet.width * layout.cellWidth;
  const height = boxSet.height * layout.cellHeight;
  return {
    width,
    height,
    centerX,
    centerY,
    data: {
      type: 'swap',
      boxes: boxSet.boxes
    }
  };
}

export function getSwapDropZones(layout: Layout, positionedBox: PositionedLayoutBox): SwapZone[] {
  const box = positionedBox.box;
  const expectedHeight = box.rows;
  const expectedWidth = box.columns;
  const zones: SwapZone[] = [];
  for (let startColumn = 0; startColumn < layout.columns - expectedWidth + 1; startColumn++) {
    for (let startRow = 0; startRow < layout.rows - expectedHeight + 1;) {
      const boxSet = findBoxSet(startRow, startColumn, expectedWidth, expectedHeight, layout);
      if (boxSet) {
        zones.push(createSwapZone(layout, boxSet));
        startRow += layout.findBoxAt({ row: startRow, column: startColumn }).box.rows;
      } else {
        startRow++;
      }
    }
  }
  return zones;
}

function findBoxSet(startRow: number, startColumn: number, expectedWidth: number, expectedHeight: number, layout: Layout): BoxSet | null {
  const destinationLayout = new Layout(expectedHeight, expectedWidth, [], layout.cellWidth, layout.cellHeight);
  for (let col = startColumn; col < startColumn + expectedWidth; col++) {
    for (let row = startRow; row < startRow + expectedHeight; row++) {
      const box = layout.safeFindBoxAt({ row, column: col });
      if (!box) {
        return null;
      }
      const p = subtractPosition(box, { row: startRow, column: startColumn });

      if (destinationLayout.canInsertBoxAt(box.box, p)) {
        destinationLayout.insertBox({
          ...box,
          ...p
        });

        if (destinationLayout.totalFreeCells() === 0) {
          return {
            width: expectedWidth,
            height: expectedHeight,
            boxes: destinationLayout.boxes.map(box => ({
              ...box,
              ...addPosition(box, { row: startRow, column: startColumn })
            })),
            position: {
              row: startRow,
              column: startColumn
            }
          };
        }
      } else {
        if (destinationLayout.totalFreeCells() === 0) {
          return null;
        }
      }
    }
  }
  return null;
}
