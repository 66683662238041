import { Action } from '../../rules/LayoutRulesManager';
import { Layout } from '../../layout/Layout';
import { WallBox } from '../../schema';
import { PositionedLayoutBox } from '../../layout/Box';
import { InstallationBehaviour } from './behaviour';
import { InitialSettings } from '../../services/utils';

export function installBoxAction(box: WallBox, boxes: PositionedLayoutBox[], availableBoxes: WallBox[], initialSettings: InitialSettings): Action {
  return (layout: Layout) => {
    const behaviour = new InstallationBehaviour(layout, initialSettings);
    behaviour.install(box, boxes, availableBoxes);
  }
}
