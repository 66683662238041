import { LayoutBox, PositionedLayoutBox } from './Box';
import { IConcreteBox } from '../schema';

export class LayoutBoxRegistry {
  static mailXS: LayoutBox = {
    token: '@xs',
    columns: 1,
    rows: 2,
    type: 'mailbox'
  }

  static mail150: LayoutBox = {
    token: '@150',
    columns: 1,
    rows: 3,
    type: 'mailbox'
  }

  static mailS: LayoutBox = {
    token: '@s',
    columns: 1,
    rows: 4,
    type: 'mailbox'
  }

  static parcelXS: LayoutBox = {
    token: 'xs',
    columns: 1,
    rows: 2,
    type: 'parcelbox'
  }

  static parcel150: LayoutBox = {
    token: '150',
    columns: 1,
    rows: 3,
    type: 'parcelbox'
  }

  static parcelS: LayoutBox = {
    token: 's',
    columns: 1,
    rows: 4,
    type: 'parcelbox'
  }

  static parcelM: LayoutBox = {
    token: 'm',
    columns: 1,
    rows: 8,
    type: 'parcelbox'
  }

  static parcelLH: LayoutBox = {
    token: 'lh',
    columns: 2,
    rows: 8,
    type: 'parcelbox'
  }

  static parcelLV: LayoutBox = {
    token: 'lv',
    columns: 1,
    rows: 16,
    type: 'parcelbox'
  }

  static parcelXL: LayoutBox = {
    token: 'xl',
    columns: 2,
    rows: 16,
    type: 'parcelbox'
  }

  static lightXS2K: LayoutBox = {
    token: 'xs2k',
    columns: 2,
    rows: 2,
    type: 'lightbox'
  }

  static lightXS4K: LayoutBox = {
    token: 'xs4k',
    columns: 4,
    rows: 2,
    type: 'lightbox'
  }

  static lightS2K: LayoutBox = {
    token: 's2k',
    columns: 2,
    rows: 4,
    type: 'lightbox'
  }
  static lightS4K: LayoutBox = {
    token: 's4k',
    columns: 4,
    rows: 4,
    type: 'lightbox'
  }
  static central400: LayoutBox = {
    token: 'c400',
    columns: 1,
    rows: 8,
    type: 'centralbox'
  }
  static central600: LayoutBox = {
    token: 'c600',
    columns: 1,
    rows: 12,
    type: 'centralbox'
  }

  static mails = [
    LayoutBoxRegistry.mailXS, LayoutBoxRegistry.mail150, LayoutBoxRegistry.mailS
  ]
  static parcels = [
    LayoutBoxRegistry.parcelXS, LayoutBoxRegistry.parcelS, LayoutBoxRegistry.parcel150, LayoutBoxRegistry.parcelM,
    LayoutBoxRegistry.parcelLH, LayoutBoxRegistry.parcelLV, LayoutBoxRegistry.parcelXL
  ]

  static all = [

    ...LayoutBoxRegistry.mails,
    ...LayoutBoxRegistry.parcels,

    LayoutBoxRegistry.lightXS2K, LayoutBoxRegistry.lightXS4K, LayoutBoxRegistry.lightS2K, LayoutBoxRegistry.lightS4K,

    LayoutBoxRegistry.central400, LayoutBoxRegistry.central600
  ]

  static createFromConcreteBox = (concreteBox: IConcreteBox): PositionedLayoutBox => {
    return concreteBox;
  }
}
