import React from 'react';
import { Form } from '../Form/Form';
import { rootStore } from '../../stores';
import classNames from 'classnames';
import s from './SummarySidePanel.module.scss';
import Toggle from '../Toggle/Toggle';
import { SummaryTogglePart } from './SummaryPartsMaker';
import { Price } from './Price';

export type OnToggleHandler = (groupName: string, subgroupName: string) => void;
export interface TogglePartProps {
  onChange: OnToggleHandler;
  part: SummaryTogglePart;
}

export const TogglePart: React.FC<TogglePartProps> = ({ part, onChange }) => {
  return (
    <Form.Section>
      <Form.Section title={rootStore.localization.formatMessage('menu.summary.' + part.header)}>
        {part.items.map((item, i: number) => {
          return (
            <div key={i} className={classNames(s.line, s.checkBoxLine)}>
              <span>{rootStore.localization.formatMessage('summary.subgroup.' + item.title)}</span>
              <Toggle onChange={() => onChange(part.header, item.title)} checked={item.included} />
              {item.included && <Price price={item.price} />}
            </div>
          );
        })}
      </Form.Section>
    </Form.Section>
  );
};



