import { Box3, BoxBufferGeometry, BufferGeometry, Mesh, Object3D, Vector3 } from 'three';
import { CSG } from 'three-csg-ts';

export class MeshUtils {

  static morphMesh(mesh: Mesh, target: string, morphFactor: number) {
    const idx = mesh.morphTargetDictionary![target];
    mesh.morphTargetInfluences![idx] = morphFactor;
    mesh.updateMatrix();
  }

  static applyMorphing(mesh?: Mesh) {
    if (!(mesh?.geometry instanceof BufferGeometry)) {
      throw new Error('BufferGeometry expected');
    }

    const morphCount = mesh.morphTargetInfluences ? mesh.morphTargetInfluences.length : 0;
    const positions = mesh.geometry.getAttribute('position');

    for (let i = 0; i < positions.count; i++) {
      let x = positions.getX(i);
      for (let j = 0; j < morphCount; j++) {
        x += mesh.morphTargetInfluences![j] * mesh.geometry.morphAttributes.position[j].getX(i);
      }
      positions.setX(i, x);

      let y = positions.getY(i);
      for (let j = 0; j < morphCount; j++) {
        y += mesh.morphTargetInfluences![j] * mesh.geometry.morphAttributes.position[j].getY(i);
      }
      positions.setY(i, y);

      let z = positions.getZ(i);
      for (let j = 0; j < morphCount; j++) {
        z += mesh.morphTargetInfluences![j] * mesh.geometry.morphAttributes.position[j].getZ(i);
      }
      positions.setZ(i, z);

    }
    for (let j = 0; j < morphCount; j++) {
      mesh.morphTargetInfluences![j] = 0;
    }
  }

  static shiftXMesh(mesh: Mesh, distance: number) {
    mesh.position.setX(mesh.position.x + distance);
  }

  static subtract(mesh: Mesh, subtract: Mesh): Mesh {
    mesh.updateMatrix();
    subtract.updateMatrix();
    const bspResult = CSG.fromMesh(mesh).subtract(CSG.fromMesh(subtract));
    const meshResult = CSG.toMesh(bspResult, mesh.matrix);
    return meshResult;
  }

  static buildBoundingBox(object: Object3D): Mesh {
    const boundingBox = new Box3();
    const boundingBoxSize = new Vector3();
    const boundingBoxCenter = new Vector3();
    boundingBox.setFromObject(object);
    boundingBox.getSize(boundingBoxSize);
    boundingBox.getCenter(boundingBoxCenter);
    const boundingBoxGeometry = new BoxBufferGeometry(boundingBoxSize.x, boundingBoxSize.y, boundingBoxSize.z);
    const boundingBoxMesh = new Mesh(boundingBoxGeometry);
    boundingBoxMesh.position.set(boundingBoxCenter.x, boundingBoxCenter.y, boundingBoxCenter.z);
    boundingBoxMesh.scale.set(1.0015, 1.0005, 1.005);
    return boundingBoxMesh;
  }

  // Android Scene Viewer bug
  static eraseSceneObjectNames(scene: Object3D) {
    scene.traverse(obj => {
      obj.name = '';
    });
  }

}
