import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import Typography from '../Typography/Typography';
import TextField from '../TextField/TextField';
import { rootStore } from '../../stores';
import { MAX_CUT_OUT_HEIGHT, MAX_CUT_OUT_WIDTH, MAX_LENGTH_INTERCOM_NAME, WizardStore } from '../../stores/WizardStore';
import { Intercom, IntercomCutoutManufacturer, IntercomSetting } from '../../schema';
import Toggle from '../Toggle/Toggle';
import Checkbox from '../Checkbox/Checkbox';
import Selector, { ImageType } from '../Selector/Selector';
import Switch from '../Switch/Switch';
import styles from './IntercomForm.module.scss';
import Autocomplete from '../Autocomplete/Autocomplete';


interface IProps {
  className?: string;
  wizardStore: WizardStore;
  onSelect?: (complete?: boolean) => void;
  touched?: boolean;
}

const IntercomForm: React.FC<IProps> = ({ className, wizardStore, onSelect, touched }) => {
  const hasIntercom = wizardStore.model.hasIntercom;
  const intercomDimensionsUnknown = wizardStore.customIntercomDimensionsUnknown;

  const [customIntercomName, setCustomIntercomName] = React.useState(wizardStore.model.customIntercom?.name ?? '');
  const [customIntercomWidth, setCustomIntercomWidth] = React.useState(wizardStore.model.customIntercom?.width ?? '');
  const [customIntercomHeight, setCustomIntercomHeight] = React.useState(wizardStore.model.customIntercom?.height ?? '');

  const [hasCustomIntercom, setHasCustomIntercom] = React.useState(wizardStore.model.hasCustomIntercom);
  const [intercomSelected, setIntercomSelected] = React.useState(touched && !wizardStore.model.hasCustomIntercom);

  const options = React.useMemo(() => {
    return wizardStore.availableIntercoms.map(intercom => ({
      value: intercom.name,
      title: intercom.name,
      image: intercom.image,
      imageType: ImageType.Image
    }));
  }, [wizardStore.availableIntercoms]);
  
  const additionalSettings = React.useMemo(() => {
    return wizardStore.availableIntercomSettings.map(intercom => ({
      label: rootStore.localization.formatMessage(`intercom.setting.${intercom}`),
      value: intercom
    }));
  }, [wizardStore.availableIntercomSettings]);

  const getAdditionalSettingsValue = React.useCallback((sourcedValue: IntercomSetting): string => {
    const matchedValue = additionalSettings.find(option => option.value === sourcedValue);
    return matchedValue ? matchedValue.label : '';
  }, [additionalSettings]);

  const handleChangeIntercom = React.useCallback((hasIntercom: boolean) => {
    wizardStore.setHasIntercom(hasIntercom);

    if (!hasIntercom) {
      setIntercomSelected(false);
      wizardStore.setHasCustomIntercom(false);
    }

    if (hasIntercom) {
      if ((wizardStore.isBoxis || wizardStore.isInterna) && wizardStore.model.centralBox.intercomCutoutManufacturer === IntercomCutoutManufacturer.client) {
        onSelect?.(true);
        wizardStore.setCustomIntercomCutoutManufacturer(IntercomCutoutManufacturer.client);
      } else if ((wizardStore.isBoxis || wizardStore.isInterna || wizardStore.isMechanical) && hasCustomIntercom && customIntercomHeight && customIntercomWidth) {
        onSelect?.(true);
        wizardStore.setCustomIntercomDimensions({ width: Number(customIntercomWidth), height: Number(customIntercomHeight) });
      } else if ((wizardStore.isMechanical || wizardStore.isInterna) && hasCustomIntercom && intercomDimensionsUnknown) {
        onSelect?.(true);
        wizardStore.setCustomIntercomDimensionsUnknown(true)
      } else if (wizardStore.isDigital && hasCustomIntercom && customIntercomName) {
        onSelect?.(true);
        wizardStore.setCustomIntercom(customIntercomName);
      } else {
        onSelect?.(false);
      }
    } else {
      onSelect?.(true);
    }
  }, [customIntercomHeight, customIntercomName, customIntercomWidth, hasCustomIntercom, intercomDimensionsUnknown, onSelect, wizardStore]);

  const handleAdditionalSettingsChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement> | null, value: string) => {
    wizardStore.setIntercomSetting(value as IntercomSetting);
    onSelect?.(true);
  }, [onSelect, wizardStore])
  
  const handleChangeCustomIntercom = React.useCallback((toggle: boolean) => {
    wizardStore.setCustomIntercomDimensionsUnknown(false);
    wizardStore.setHasCustomIntercom(toggle);
    setHasCustomIntercom(toggle);
    if (toggle) {
      setIntercomSelected(false);

      if (customIntercomHeight || customIntercomWidth) {
        wizardStore.setCustomIntercomDimensions({
          height: customIntercomHeight !== '' ? Number(customIntercomHeight) : undefined,
          width: customIntercomWidth !== '' ? Number(customIntercomWidth) : undefined,
        });
      }

      if (customIntercomWidth && customIntercomHeight) {
        onSelect?.(true);
        return;
      }

      if (customIntercomName) {
        wizardStore.setCustomIntercom(customIntercomName);
        onSelect?.(true);
        return;
      }
    }
    onSelect?.(false);
  }, [customIntercomHeight, customIntercomName, customIntercomWidth, onSelect, wizardStore]);

  const handleChangeCustomIntercomName = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onSelect?.();
      const intercomBrand = event.currentTarget.value;
      if (!intercomBrand.trim()) {
        onSelect?.(false);
      }
      setCustomIntercomName(intercomBrand);
      wizardStore.setCustomIntercom(intercomBrand);
    },
    [onSelect, wizardStore]
  );

  const handleChangeCustomIntercomWidth = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const width = Number.parseInt(event.currentTarget.value);
      if (width > MAX_CUT_OUT_WIDTH(wizardStore.wallType)) return;
      wizardStore.setCustomIntercomDimensions({ width });
      setCustomIntercomWidth(width);
      if (customIntercomHeight) {
        onSelect?.();
      }
      if (!width) {
        onSelect?.(false);
      }
    },
    [customIntercomHeight, onSelect, wizardStore]
  );

  const handleChangeCustomIntercomHeight = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const height = Number.parseInt(event.currentTarget.value);
      if (height > MAX_CUT_OUT_HEIGHT(wizardStore.wallType)) return;
      wizardStore.setCustomIntercomDimensions({ height });
      setCustomIntercomHeight(height);
      if (customIntercomWidth) onSelect?.();
      if (!height) onSelect?.(false);
    },
    [customIntercomWidth, onSelect, wizardStore]
  );

  const handleSelectIntercom = React.useCallback((intercom: string) => {
    onSelect?.();
    setIntercomSelected(true);
    wizardStore.setCustomIntercomDimensionsUnknown(false);
    wizardStore.setHasCustomIntercom(false);
    setHasCustomIntercom(false);
    wizardStore.setIntercom(intercom);

    if (
      wizardStore.model.centralBox.intercomSetting === IntercomSetting.NA
      && (intercom === Intercom.Comelit || intercom === Intercom.Fermax)
    ) {
      onSelect?.(false);
    }
  },
    [onSelect, wizardStore]
  );

  const handleToggleDimensionsUnknown = React.useCallback((dimensionsUnknown: boolean) => {
    wizardStore.setCustomIntercomDimensionsUnknown(!intercomDimensionsUnknown);
    setCustomIntercomHeight('');
    setCustomIntercomWidth('');
    wizardStore.model.intercomDimensionsUnknown ? onSelect?.() : onSelect?.(false);
  }, [intercomDimensionsUnknown, onSelect, wizardStore]);

  const handleToggleCutoutManufacturer = React.useCallback((manufacturer: IntercomCutoutManufacturer) => {
    wizardStore.setIntercomCutOutManufacturer(manufacturer);
    if (manufacturer === IntercomCutoutManufacturer.client) {
      setCustomIntercomHeight('');
      setCustomIntercomWidth('');
      wizardStore.setCustomIntercomDimensionsUnknown(false);
      onSelect?.();
    } else {
      onSelect?.(false);
    }
  }, [onSelect, wizardStore]);

  const mechanicalOrDigital = wizardStore.isDigital || wizardStore.isMechanical;

  return (
    <section className={classNames(styles.locationForm, className)}>
      <div className={styles.intercomForm__title}>
        <Typography variant="h2">
          <FormattedMessage id="menu.options.intercom.title" />
        </Typography>
        <Toggle onChange={handleChangeIntercom} checked={hasIntercom} />
      </div>
      <div className={classNames(styles.intercomForm__option, { [styles.intercomForm__optionHidden]: !hasIntercom })}>
        {mechanicalOrDigital && (
          <Selector
            name="intercom"
            options={options}
            selected={intercomSelected ? wizardStore.model.centralBox.intercom : ''}
            onSelect={handleSelectIntercom}
            classes={{ list: styles.selectorList }}
          />
        )}
        {mechanicalOrDigital && intercomSelected && <div
		      className={classNames(styles.intercomForm__settingToggle, styles.intercomForm__option, {
            [styles.intercomForm__optionHidden]: !wizardStore.hasAdditionalIntercomSetting || !touched
          })}
	      >
		      <div className={styles.settingToggleTitle}>
			      <Typography component="span" variant="subtitle1">
				      <FormattedMessage id="intercom.additionalSettings"/>
			      </Typography>
		      </div>
		      <Autocomplete
            className={classNames(styles.modeSelector, {
              [styles.placeholderMode]: wizardStore.model.centralBox.intercomSetting === IntercomSetting.NA
            })}
            onChange={handleAdditionalSettingsChange}
            suggestions={additionalSettings}
            value={getAdditionalSettingsValue(wizardStore.model.centralBox.intercomSetting)}
            placeholder={rootStore.localization.formatMessage('intercom.setting.NA')}
            select
          />
	      </div>}
        {wizardStore.isDigital ? (
          <div className={styles.intercomForm__ownIntercom}>
            <div className={styles.intercomForm__ownIntercomControl}>
              <Typography component="span" variant="subtitle1">
                <FormattedMessage id="intercom.customIntercom" />
              </Typography>
              <Toggle onChange={handleChangeCustomIntercom} checked={hasCustomIntercom} />
            </div>
            <TextField
              placeholder={rootStore.localization.formatMessage('intercom.customIntercomBrand')}
              className={classNames(styles.intercomForm__customIntercomName, {
                [styles.intercomForm__optionHidden]: !hasCustomIntercom
              })}
              value={customIntercomName}
              onChange={handleChangeCustomIntercomName}
              maxLength={MAX_LENGTH_INTERCOM_NAME}
            />
          </div>
        ) : (
          <>
            <div className={classNames(styles.intercomForm__ownIntercom)}>
              {mechanicalOrDigital && (
                <div className={classNames(styles.intercomForm__ownIntercom_toggle)}>
                  <div className={styles.description}>
                    <Typography component="span" variant="subtitle1">
                      <FormattedMessage id="intercom.intercomCutOut.needed" />
                    </Typography>
                    <div className={styles.hint}>
                      <Typography variant="caption">
                        <FormattedMessage id="intercom.intercomCutOut.hint" />
                      </Typography>
                    </div>
                  </div>
                  <Toggle onChange={handleChangeCustomIntercom} checked={hasCustomIntercom} />
                </div>
              )}
              {!mechanicalOrDigital && (
                <div
                  className={classNames(styles.intercomForm__cutoutManufacturerToggle)}
                >
                  <div className={styles.settingToggleTitle}>
                    <Typography component="span" variant="subtitle1">
                      <FormattedMessage id="intercom.intercomCutOut.madeBy" />
                    </Typography>
                  </div>
                  <Switch
                    name="intercom-cutout"
                    className={styles.modeSelector}
                    options={wizardStore.availableIntercomCutoutManufacturers.map(manufacturer => ({
                      title: rootStore.localization.formatMessage(`intercom.cutoutManufacturer.${manufacturer}`),
                      value: manufacturer
                    }))}
                    selected={wizardStore.model.centralBox.intercomCutoutManufacturer}
                    onSelect={value => handleToggleCutoutManufacturer(value as IntercomCutoutManufacturer)}
                  />
                </div>
              )}
              <div className={classNames(styles.intercomForm__ownIntercom_dimensions)}>
                <div
                  className={classNames(styles.intercomForm__customIntercomDimensions, {
                    [styles.intercomForm__customIntercomFieldHidden]: !hasCustomIntercom || wizardStore.model.centralBox.intercomCutoutManufacturer === IntercomCutoutManufacturer.client,
                  })}
                >
                  <TextField
                    type="number"
                    placeholder={rootStore.localization.formatMessage('dimensions.width')}
                    value={customIntercomWidth && !intercomDimensionsUnknown ? customIntercomWidth.toString() : ''}
                    onChange={handleChangeCustomIntercomWidth}
                    disabled={intercomDimensionsUnknown}
                    endAdornment={
                      <span className={styles.dimensionsAdornment}>
                        <FormattedMessage id="units.mm" />
                      </span>
                    }
                  />
                  <TextField
                    type="number"
                    placeholder={rootStore.localization.formatMessage('dimensions.height')}
                    value={customIntercomHeight && !intercomDimensionsUnknown ? customIntercomHeight.toString() : ''}
                    onChange={handleChangeCustomIntercomHeight}
                    disabled={intercomDimensionsUnknown}
                    endAdornment={
                      <span className={styles.dimensionsAdornment}>
                        <FormattedMessage id="units.mm" />
                      </span>
                    }
                  />
                </div>
                <div
                  className={classNames(styles.intercomForm__customIntercomNoDimensions, {
                    [styles.intercomForm__optionHidden]: !hasCustomIntercom || wizardStore.model.centralBox.intercomCutoutManufacturer === IntercomCutoutManufacturer.client,
                  })}
                >
                  <Checkbox
                    checked={intercomDimensionsUnknown}
                    onChange={handleToggleDimensionsUnknown}
                  >
                    <Typography variant="body2" className={styles.intercomForm__customIntercomNoDimensionsText}>
                      <FormattedMessage id="intercom.intercomCutOutDimensions" />
                    </Typography>
                  </Checkbox>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default observer(IntercomForm);
