import { IPostProcessor } from './IPostProcessor';
import {
  AccessoryType,
  IAccessory,
  ICentralBox,
  Intercom,
  IWall,
  MailboxLockType,
  ParcelLockType,
  WallBox,
  WallType
} from '../schema';
import { centralBoxTypeGuard, mailBoxTypeGuard, parcelBoxTypeGuard } from '../guards';

export interface CentralBoxAccessoriesWall {
  customIntercom?: IWall['customIntercom'];
  wallType: IWall['wallType'];
  accessories: IWall['accessories'];
  boxes: IWall['boxes'];
}

export class CentralBoxAccessoriesPostProcessor implements IPostProcessor {
  private byType: Map<AccessoryType, IAccessory>;
  private amount: Map<AccessoryType, number>;
  private wall: CentralBoxAccessoriesWall;
  private static boxisCentralBoxAccessories: AccessoryType[] = [
    AccessoryType.eSafeCutout
  ]
  private static digitalWallCentralBoxAccessories: AccessoryType[] = [
    AccessoryType.G3104729,
    AccessoryType.G8010108,
    AccessoryType.S0000597,
    AccessoryType.G3100010,
    AccessoryType.G3104896,
    AccessoryType.S0000559,
    AccessoryType.G3104897,
    AccessoryType.G3104226,
    AccessoryType.G3104715,
    AccessoryType.G3104717,
    AccessoryType.G3104725
  ];
  private centralBox: ICentralBox | undefined;
  private electricBoxes: WallBox[];

  constructor(
    private readonly accessories: IAccessory[]
  ) {
  }

  get requiredCentralBox(): ICentralBox {
    if (!this.centralBox) {
      throw new Error('Central box is required');
    }
    return this.centralBox;
  }
  process(wall: CentralBoxAccessoriesWall): void {
    this.wall = wall;
    this.byType = new Map<AccessoryType, IAccessory>();
    this.amount = new Map<AccessoryType, number>();
    this.centralBox = this.getCentralBox();

    switch (this.wall.wallType) {
      case WallType.Digital:
        this.processDigitalWall();
        break;
      case WallType.Mechanical:
        break;
      case WallType.Boxis:
        this.processBoxisWall();
        break;
      case WallType.Interna:
        break;
    }

    this.updateAccessories();
  }
  private processDigitalWall() {
    // central box is required
    if (!this.centralBox) {
      throw new Error('Cannot find centralbox');
    }
    // ignore custom central box
    if (this.centralBox.custom) {
      return;
    }
    this.parseAccessories(CentralBoxAccessoriesPostProcessor.digitalWallCentralBoxAccessories);
    this.electricBoxes = this.getElectricBoxes();
    this.clearOldAccessories(CentralBoxAccessoriesPostProcessor.digitalWallCentralBoxAccessories);
    this.processG3104729();
    this.processG8010108();
    this.processS0000597();
    this.processG3104896();
    this.processS0000559();
    this.processG3104897();
    this.processG3100010();
    this.processG3104226();
    this.processG3104715();
    this.processG3104717();
    this.processG3104725();
  }

  private clearOldAccessories(accessories: AccessoryType[]) {
    this.wall.accessories = this.wall.accessories
      .filter(accessory => !accessories.includes(accessory.type));
  }

  private parseAccessories(accessories: AccessoryType[]) {

    accessories.forEach(accessoryType => {
      const accessory = this.accessories.find(item => item.type === accessoryType);
      if (!accessory) {
        throw new Error(`Cannot find accessory ${accessoryType}`);
      }
      this.byType.set(accessory.type, accessory);
      this.amount.set(accessory.type, 0);
    });
  }

  private setAccessoryAmount(type: AccessoryType, number: number): void {
    this.amount.set(type, number);
  }

  private getAccessoryAmount(type: AccessoryType): number {
    const amount = this.amount.get(type);
    if (amount === undefined) {
      throw new Error(`Cannot find ${type}`);
    }
    return amount;
  }

  private getCentralBox(): ICentralBox | undefined {
    for (let box of this.wall.boxes) {
      if (centralBoxTypeGuard(box.box)) {
        return box.box;
      }
    }
  }

  private getElectricBoxes(): WallBox[] {
    const electricBoxes: WallBox[] = [];
    for (let wallBox of this.wall.boxes) {
      const box = wallBox.box;
      if ((mailBoxTypeGuard(box) && box.lockType === MailboxLockType.Electric) ||
        (parcelBoxTypeGuard(box) && box.lockType === ParcelLockType.Electric)) {
        electricBoxes.push(box);
      }
    }
    return electricBoxes;
  }

  private processG3104729() {
    // const element = this.getAccessory(AccessoryType.G3104729);
    if (this.electricBoxes.length > 69) {
      this.setAccessoryAmount(AccessoryType.G3104729, 1);
    }
  }

  private processG8010108() {
    this.setAccessoryAmount(AccessoryType.G8010108, this.electricBoxes.length);
  }

  private processS0000597() {
    if (this.requiredCentralBox.intercom === Intercom.Comelit) {
      this.setAccessoryAmount(AccessoryType.S0000597, 0)
      return;
    }

    const DOOR_BELLS = this.requiredCentralBox.intercom === Intercom.Niko ? 4 : 8;
    this.setAccessoryAmount(AccessoryType.S0000597, this.onePerExtraDoorBells(DOOR_BELLS));
  }

  private processG3104896() {
    const referenceAmount = this.getAccessoryAmount(AccessoryType.S0000597);
    const amount = Math.ceil(referenceAmount / 4) + 1;
    this.setAccessoryAmount(AccessoryType.G3104896, amount);
  }

  private processS0000559() {
    if (this.requiredCentralBox.physicalBells) {
      return;
    }

    const sum = this.electricBoxes.length + this.getAccessoryAmount(AccessoryType.S0000597) - 15;
    const s = Math.ceil(sum / 15);
    const p = Math.max(0, s);
    const amount = Math.ceil(p / 15) + p;
    this.setAccessoryAmount(AccessoryType.S0000559, amount);
  }

  private processG3104897() {
    const amount = 4 * this.getAccessoryAmount(AccessoryType.S0000559) + 4 * this.getAccessoryAmount(AccessoryType.S0000597);
    this.setAccessoryAmount(AccessoryType.G3104897, amount);
  }

  private processG3100010() {
    const amount = this.getAccessoryAmount(AccessoryType.S0000559);
    this.setAccessoryAmount(AccessoryType.G3100010, amount);
  }

  private processG3104226() {
    if (this.requiredCentralBox.intercom === Intercom.Bticino) {
      const amount = this.onePerExtraDoorBells(8);
      this.setAccessoryAmount(AccessoryType.G3104226, amount);
    }
  }

  private processG3104715() {
    if (this.requiredCentralBox.intercom === Intercom.Comelit) {
      const amount = this.onePerExtraDoorBells(8);
      this.setAccessoryAmount(AccessoryType.G3104715, amount);
    }

  }

  private processG3104717() {
    if (this.requiredCentralBox.intercom === Intercom.Fermax) {
      const amount = this.onePerExtraDoorBells(8);
      this.setAccessoryAmount(AccessoryType.G3104717, amount);
    }
  }

  private processG3104725() {
    if (this.requiredCentralBox.intercom === Intercom.Niko) {
      const amount = this.onePerExtraDoorBells(16);
      this.setAccessoryAmount(AccessoryType.G3104725, amount);
    }
  }

  private updateAccessories() {
    if (this.centralBox && this.centralBox.custom) {
      this.cleanAccessories();
      return;
    }

    for (let [type, amount] of this.amount.entries()) {
      while (amount-- > 0) {
        const accessory = this.getAccessory(type);
        this.wall.accessories.push(accessory);
      }
    }
  }

  private cleanAccessories() {
    this.wall.accessories = [];
  }

  private getAccessory(type: AccessoryType) {
    const candidate = this.byType.get(type);
    if (!candidate) {
      throw new Error(`Cannot find ${type}`);
    }
    return candidate;
  }

  private onePerExtraDoorBells(doorBells: number): number {
    if (this.requiredCentralBox.bellsAmount > doorBells) {
      return Math.ceil((this.requiredCentralBox.bellsAmount - doorBells) / doorBells);
    }
    return 0;
  }

  private chargeEsafeCutout() {
    if (this.wall.customIntercom?.cutoutManufacturer === 'esafe') {
      this.amount.set(AccessoryType.eSafeCutout, 1);
    }
  }

  private processBoxisWall() {
    if (!this.centralBox) {
      return;
    }
    this.parseAccessories(CentralBoxAccessoriesPostProcessor.boxisCentralBoxAccessories);
    this.clearOldAccessories(CentralBoxAccessoriesPostProcessor.boxisCentralBoxAccessories);
    this.chargeEsafeCutout();
  }
}
