import { Layout } from '../layout/Layout';
import { LayoutRule } from './LayoutRulesManager';
import { PositionedLayoutBox } from '../layout/Box';
import { HorizontalPlatesSplitter } from '../services/HorizontalPlatesSplitter';
import { RectangleLayout } from '../layout/RectangleLayout';
import { isLessOrEqThan, isLessThan } from '../services/utils';
import { StandaloneLayout } from '../layout/StandaloneLayout';

export const REFERENCE_HEIGHT = 1.6;

export const centralBoxAt1600Height: LayoutRule = (layout: Layout) => {
  const topDistanceToFloor = REFERENCE_HEIGHT;
  const centralBoxes = layout.boxes.filter(isCentralBox);
  const allValid = centralBoxes.every(box => layout.boxInstalledAt(box, topDistanceToFloor));
  return !allValid ? `central box should be placed at ${topDistanceToFloor} height` : null;
};

export const maximumHeightFromFloorRule: LayoutRule = (layout: Layout) => {
  const maxHeightFromFloor = 1.8;
  const heightFromFloor = layout.offsetHeight + layout.rows * layout.cellHeight;
  return isLessOrEqThan(heightFromFloor, maxHeightFromFloor) ? null : `Maximum height of installation from floor exceeded: new height ${heightFromFloor}. Maximum allowed is ${maxHeightFromFloor}`;
};

export const minimumHeightFromFloorRule: LayoutRule = (layout: Layout) => {
  const minHeightFromFloor = 0.1;

  if (layout instanceof RectangleLayout) {
    const heightFromFloor = layout.offsetHeight + layout.constraint.row.min * layout.cellHeight;
    return isLessThan(heightFromFloor, minHeightFromFloor)  ? `Unable to put installation lower than ${minHeightFromFloor}` : null;
  }

  return null;
};

export const minimumHeightOfStandaloneRule: LayoutRule = (layout: Layout) => {
  if (layout instanceof StandaloneLayout) {
    const totalHeight = layout.offsetHeight + layout.rows * layout.cellHeight;
    return isLessThan(totalHeight, REFERENCE_HEIGHT)  ? `Standalone configurations must have at least ${REFERENCE_HEIGHT} total height` : null;
  }

  return null;
};


export const letterBoxesHeight: LayoutRule = (layout: Layout) => {
  const minBottomDistanceToFloor = 0.4;
  const maxTopDistanceToFloor = 1.7;
  const letterboxes = layout.boxes.filter(isLetterBox);
  const allValid = letterboxes.every(box => layout.boxInstalledInRange(box, 0.4, 1.7));
  return !allValid ? `Letterbox height should be => ${minBottomDistanceToFloor} height and <= ${maxTopDistanceToFloor}` : null;
};


export const finishingPlatesRule = (layout: Layout) => {
  try {
    HorizontalPlatesSplitter.findFinishingModules(layout.columns, layout.boxes);
  } catch (e) {
    return 'boxes cross finishing plates'
  }
  return null;
  // TODO: optimization
  // const platesColumn = HorizontalPlatesSplitter.calculateFinishingModules(layout.columns, layout.boxes);
  // if (platesColumn.length === 1) {
  //   return null;
  // }
  // if (HorizontalPlatesSplitter.boxesCrossModules(platesColumn, layout.boxes)) {
  //   return 'boxes cross finishing plates';
  // }
  // return null;
};

export const roofRule: LayoutRule = (layout: Layout) => {
  const roofModules = HorizontalPlatesSplitter.calculateRoofModules(layout.columns);
  if (roofModules.length === 1) {
    return null;
  }
  if (HorizontalPlatesSplitter.boxesCrossModules(roofModules, layout.boxes)) {
    return 'Box intersects modules';
  }

  return null;
};

export function isCentralBox({ box }: PositionedLayoutBox) {
  return box.type === 'centralbox';
}

function isLetterBox({ box }: PositionedLayoutBox) {
  return box.type === 'mailbox';
}
