import { Action } from '../../rules/LayoutRulesManager';
import { Layout } from '../../layout/Layout';
import { RemoveColumnBehaviour } from "./behaviour";

export function removeColumnAction(column: number, count: number): Action {
  return (layout: Layout) => {
    const behaviour = new RemoveColumnBehaviour(layout);
    behaviour.removeColumns(column, count);
  };
}
