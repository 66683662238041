import {Mesh, Object3D} from "three";
import {SHELL_THICKNESS} from "../../layout/Layout";
import {IMorph, IPosition3D} from "../types";
import {morph} from "../utils";
import { MeshUtils } from '../MeshUtils';

interface ILeftRightPlateData {
    heightMorph: IMorph,
    thickness: number,
}

const defaultData: ILeftRightPlateData = {
    heightMorph: {
        name: 'height',
        min: 0.1,
        max: 1.8
    },
    thickness: SHELL_THICKNESS
}

export class LeftRightPlateObject {
    readonly node: Object3D;
    readonly obj: Object3D;
    readonly heightMorph: IMorph;
    readonly thickness: number;
    readonly mesh: Mesh;

    constructor(obj: Object3D, data: ILeftRightPlateData = defaultData) {
        this.node  = new Object3D();
        this.obj = obj.clone();
        this.mesh = this.obj.children[0] as Mesh;
        this.mesh.geometry = this.mesh.geometry.clone();
        this.node.add(this.obj);
        this.heightMorph = data.heightMorph;
        this.thickness = data.thickness;
    }

    setPosition(pos: IPosition3D) {
        this.node.position.set(pos.x, pos.y, pos.z);
    }

    setHeight(height: number) {
        morph(this.mesh, this.heightMorph, height);
        MeshUtils.applyMorphing(this.mesh);
    }
}
