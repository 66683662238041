import * as React from 'react';
import clsx from 'clsx';

import Typography from '../Typography/Typography';
import { Figure, IconName } from '../Icon/Icon';
import styles from './SelectorItem.module.scss';
import { ImageType } from '../Selector/Selector';

interface IProps {
  className?: string;
  id: string;
  name: string;
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
  image?: IconName | string;
  imageType?: ImageType;
  value: string;
  disabled?: boolean;
  selected?: boolean;
  onSelect?: (value: string) => void;
  autoFocus?: boolean;
}

const SelectorItem: React.FC<IProps> = ({
  className,
  id,
  title,
  name,
  subtitle,
  image,
  imageType,
  value,
  disabled,
  selected,
  onSelect,
  autoFocus
}) => {

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onSelect) {
      onSelect(event.currentTarget.value);
    }
  };

  const component = image && (imageType === ImageType.Image ? <img className={styles.icon} src={image} alt={image}/> :
    <Figure type={image as IconName} className={styles.icon} />);

  return (
    <div
      className={clsx(styles.root, {
        [styles.selected]: selected,
        [styles.disabled]: disabled
      },
        className
      )}
    >
      <input
        className={styles.input}
        type="radio"
        id={id}
        value={value}
        name={name}
        checked={selected}
        onChange={handleOnChange}
        autoFocus={autoFocus && selected}
        disabled={disabled}
      />
      <label className={styles.label} htmlFor={id}>
        <div className={styles.content}>
          {component}
          <div className={styles.title_wrapper}>
            <Typography
              variant="subtitle1"
              className={styles.title}
            >
              {title}
            </Typography>
            {subtitle && <Typography variant="caption" className={styles.subtitle}>{subtitle}</Typography>}
          </div>
        </div>
      </label>
    </div>
  );
};

export default SelectorItem;
