import { ICentralBox, ICountry, IMailBox, IntercomSetting, IWizard, WallType } from '../schema';
import {
  DatasetService,
  deepCopy,
  Engine,
  FlatStructuralTransformer,
  getOptionId,
  getProductId,
  Option,
  PModel,
  PropertyPath
} from '@canvas-logic/engine';
import { LinkService } from '../services/LinkService';
import { CUSTOM_COUNTRY } from './consts';
import { EngineWithWizard, IWizardState } from './types';

export class WizardSerializer {
  private referenceModel: IWizard;
  private engine: Engine;

  constructor(private readonly datasetService: DatasetService) {
  }

  serialize(wizard: IWizard): IWizardState {
    return {
      productId: getProductId(wizard),
      layout: wizard.layout,
      wallLocation: wizard.wallLocation,
      centralBoxId: getOptionId(wizard.centralBox),
      mailBoxesAmount: wizard.mailBoxesAmount,
      doorBellsAmount: wizard.doorBellsAmount,
      parcelBoxesAmount: wizard.parcelBoxesAmount,
      parcelLockType: wizard.parcelLockType,
      hasIntercom: wizard.hasIntercom,
      hasCustomIntercom: wizard.hasCustomIntercom,
      hasCentralBox: wizard.hasCentralBox,
      hasLightBox: wizard.hasLightBox,
      country: wizard.country,
      customIntercom: wizard.customIntercom,
      intercomSetting: wizard.hasCentralBox ? wizard.centralBox.intercomSetting : IntercomSetting.NA,
      intercomDimensionsUnknown: wizard.intercomDimensionsUnknown,
      mailBoxId: getOptionId(wizard.mailBox),
      mountingType: wizard.mountingType,
      heightFromGround: wizard.heightFromGround,
      wallType: wizard.wallType,
      hasOwnKeyPlan: wizard.hasOwnKeyPlan,
      cylinderCutout: wizard.cylinderCutout,
      cellWidth: wizard.cellWidth,
      cellHeight: wizard.cellHeight
    };
  }

  getDefault(wallType: WallType): EngineWithWizard {
    this.engine = new Engine();
    let productId;
    if (wallType === WallType.Digital) {
      productId = 'digital_wizard_default';
    } else if (wallType === WallType.Interna) {
      productId = 'interna_wizard_default';
    } else if (wallType === WallType.Boxis) {
      productId = 'boxis_wizard_default';
    } else if (wallType === WallType.Mechanical) {
      productId = 'mechanical_wizard_default';
    }
    if (!productId) {
      throw new Error(`Cannot find product with wall type '${wallType}'`);
    }
    const product = this.datasetService.getProductById(productId);
    const productSchema = this.datasetService.getProductSchema('', {}, '');
    const model = this.engine.initByProductWithContext(productSchema, product) as PModel<IWizard>;
    return {
      wizard: model,
      engine: this.engine
    };
  }

  deserialize(state: IWizardState): EngineWithWizard {
    this.engine = new Engine();
    const productSchema = this.datasetService.getProductSchema('', {}, '');
    const product = this.datasetService.getProductById(state.productId);
    this.referenceModel = this.engine.initByProductWithContext(productSchema, product) as PModel<IWizard>;
    const model = deepCopy(this.referenceModel);
    model.layout = state.layout;
    model.wallLocation = state.wallLocation;
    model.centralBox = this.findOptionModelById<ICentralBox>('centralBox', state.centralBoxId);
    model.centralBox.intercomSetting = state.intercomSetting;
    model.mailBox = this.findOptionModelById<IMailBox>('mailBox', state.mailBoxId);
    model.hasLightBox = state.hasLightBox;
    model.mailBoxesAmount = state.mailBoxesAmount;
    model.mountingType = state.mountingType;
    model.parcelLockType = state.parcelLockType;
    model.hasOwnKeyPlan = state.hasOwnKeyPlan;
    model.cylinderCutout = state.cylinderCutout;
    model.cellWidth = state.cellWidth;
    model.cellHeight = state.cellHeight;
    model.heightFromGround = state.heightFromGround;
    const countries = this.findAllOptionsModels<ICountry>('country', option => {
      return option.model.name === state.country.name && option.model.minMailboxHeight === state.country.minMailboxHeight;
    });
    let country = countries[0];
    if (!country) {
      const custom = this.findOptionModelById<ICountry>('country', CUSTOM_COUNTRY);
      custom.name = state.country.name;
      custom.minMailboxHeight = state.country.minMailboxHeight;
      country = custom;
    }
    model.country = country;
    model.doorBellsAmount = state.doorBellsAmount;
    model.parcelBoxesAmount = state.parcelBoxesAmount;
    model.customIntercom = state.customIntercom;
    model.intercomDimensionsUnknown = state.intercomDimensionsUnknown;
    model.hasIntercom = state.hasIntercom;
    model.hasCustomIntercom = state.hasCustomIntercom;
    model.hasCentralBox = state.hasCentralBox;
    model.wallType = state.wallType;
    return {
      wizard: model,
      engine: this.engine
    };
  }

  toLink(wizard: IWizard): string {
    const state = this.serialize(wizard);
    return LinkService.jsonToLink(state);
  }

  private findOptionModelById<T>(path: PropertyPath, id: string): T {
    const transformer = new FlatStructuralTransformer<T>();
    const candidates = this.engine.optionValuesByPath(this.referenceModel, path, transformer) ?? [];
    const option = candidates.find(o => o._id === id);
    if (!option) {
      throw new Error(`Cannot find option with id ${id}`);
    }
    return option.model;
  }

  private findOptionModel<T>(path: PropertyPath, selector: (option: Option<T>) => boolean): T {
    const transformer = new FlatStructuralTransformer<T>();
    const candidates = this.engine.optionValuesByPath(this.referenceModel, path, transformer) as Option<T>[] ?? [];
    const option = candidates.find(selector);
    if (!option) {
      throw new Error(`Cannot find option`);
    }
    return option.model;
  }

  private findAllOptionsModels<T>(path: PropertyPath, selector: (option: Option<T>) => boolean): T[] {
    const transformer = new FlatStructuralTransformer<T>();
    const candidates = this.engine.optionValuesByPath(this.referenceModel, path, transformer) as Option<T>[] ?? [];
    return candidates.filter(selector)
      .map(o => o.model);
  }

  fromLink(link: string): EngineWithWizard {
    let json= LinkService.linkToJson(link);
    return this.deserialize(json as IWizardState);
  }
}
