import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import Guide, { GuideFrame } from "../Guide/Guide";

import Illustration from './assets/Esafe-wizard-swap-animation.svg';
import styles from './SwapGuide.module.scss';

interface IProps {
  onChangeStep?: (step: number) => void;
  onFinish: () => void;
}

const FirstStep: React.VFC = () => (
  <GuideFrame>
    <div className={styles.image}>
      <img src={Illustration} alt="" />
    </div>
    <FormattedMessage id="boxes.swap.guide.1" />
  </GuideFrame>
);

const SwapGuide: React.VFC<IProps> = ({ onChangeStep, onFinish }) => {
  const steps = React.useMemo(() => [<FirstStep />], []);

  return <Guide steps={steps} onChangeStep={onChangeStep} onFinish={onFinish} />;
};

export default SwapGuide;
