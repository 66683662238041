import { BigNumber } from '@canvas-logic/engine';
import { rootStore } from '../stores';

export class Formatter {
  static readonly SEPARATOR = ',';
  static readonly GROUP_SEPARATOR = '.';

  private static groupString(value: string) {
    let result = [];
    let taken = 0;
    for (let i = value.length - 1; i >= 0; i--) {
      if (taken === 3) {
        result.unshift(Formatter.GROUP_SEPARATOR);
        taken = 0;
      }
      result.unshift(value[i]);
      taken++;
    }
    return result.join('');
  }

  static formatBigNumber(value: BigNumber, digitsAfterComa: number): string {
    const [integral, frac] = value.toNumberParts();
    let result = this.groupString(integral);
    if (frac && digitsAfterComa > 0) {
      result += this.SEPARATOR + frac.slice(0, digitsAfterComa);
    }
    return result;
  }

  static formatIntegral(value: number): string {
    return this.groupString(value.toFixed(0));
  }

  static formatWeight(value: BigNumber): string {
    return Formatter.formatBigNumber(value, 3) + ` ${rootStore.localization.formatMessage('units.kg')}`;
  }

  static formatSize(value: number): string {
    return Formatter.formatIntegral(value) + ` ${rootStore.localization.formatMessage('units.mm')}`;
  }

  static formatPrice(value: BigNumber | null, round: boolean = true, digitsAfterComa: number = 0): string {
    if (!value) {
      return '';
    }
    const price = round ? Formatter.formatBigNumber(value.round(), 0) : Formatter.formatBigNumber(value, digitsAfterComa);
  
    return '€' + price;
  }

  static priceToNumber(value: BigNumber | null): number {
    return parseFloat((value ?? -1).toString());
  }

  static formatDate(date: Date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }
}
