import * as React from 'react';
import classNames from 'classnames';

import Button from '../Button/Button';
import Icon from '../Icon/Icon';

import styles from './Stepper.module.scss';

interface IProps {
  steps: number;
  current: number;
  onChange: (step: number) => void;
  onFinish: () => void;
}

const Stepper: React.VFC<IProps> = ({ steps, current, onChange, onFinish }) => {
  const lastStep = React.useMemo(() => steps - 1, [steps]);

  const handlePreviousClick = () => {
    onChange(Math.max(0, current - 1));
  };

  const handleNextClick = () => {
    if (current === lastStep) {
      onFinish();
    } else {
      onChange(Math.min(lastStep, current + 1));
    }
  };

  const handleStepClick = (step: number) => {
    onChange(step);
  };

  return (
    <div className={styles.wrapper}>
      <div className={classNames(styles.prevButtonWrapper, { [styles.prevButtonHidden]: current === 0 })}>
        <Button variant="secondary" inverted onClick={handlePreviousClick} disabled={current === 0}>
          <Icon type="left" />
        </Button>
      </div>

      {steps > 1 && (
        <div>
          {new Array(steps).fill(0).map((_, index) => (
            <button
              key={index}
              className={classNames(styles.step, { [styles.current]: index === current })}
              onClick={() => handleStepClick(index)}
            />
          ))}
        </div>
      )}

      <Button variant="secondary" inverted onClick={handleNextClick}>
        {current === lastStep ? <Icon type="ok" /> : <Icon type="right" />}
      </Button>
    </div>
  );
};

export default Stepper;
