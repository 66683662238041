import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { ConfiguratorStore, VisualMode } from '../../stores/ConfiguratorStore';
import { ConfiguratorMenuStore, MainMenu, RemoveMode, SidePanelId } from '../../stores/ConfiguratorMenuStore';
import CustomizeBottomMenu, { SelectedOption } from '../CustomizeBottomMenu/CustomizeBottomMenu';
import { TextSidePanel } from '../TextSidePanel/TextSidePanel';
import { ColorSidePanel } from '../ColorSidePanel/ColorSidePanel';
import { ZoomedMenu } from '../ZoomedMenu/ZoomedMenu';
import { BoxContextMenu } from '../BoxContextMenu/BoxContextMenu';
import { TopMenu } from '../TopMenu/TopMenu';
import { SummarySidePanel } from '../SummarySidePanel/SummarySidePanel';
import { AddBoxSidePanelSidePanel } from '../AdditionalBoxesSidePanel/AdditionalBoxesSidePanel';
import { DeleteRowOrColumnMenu } from '../DeleteRowOrColumnMenu/DeleteRowOrColumnMenu';
import { BoxViewer } from '../../viewer/BoxViewer';
import NotificationContainer from '../NotificationContainer/NotificationContainer';
import { ScreenBox2D } from '../../camera/types';
import { rootStore } from '../../stores';
import persistenceService from '../../services/PersistenceService';
import { SwapMenu } from '../SwapMenu/SwapMenu';
import { useGuideContext } from '../../contexts/GuideContext';
import { WallType } from '../../schema';
import styles from './ConfiguratorMenu.module.scss';


interface IProps {
  store: ConfiguratorStore;
  menu: ConfiguratorMenuStore;
  boxViewer: BoxViewer | undefined;
}

export const ConfiguratorMenu: React.FC<IProps> = observer(({ menu, store, boxViewer }) => {
  const { showRemoveGuide, showSwapBoxGuide, showInsertBoxGuide, dismissGuide, isGuideVisible } = useGuideContext();
  const sideMenuRef = React.createRef<HTMLDivElement>();
  const mainMenuRef = React.createRef<HTMLDivElement>();
  const topMenuRef = React.createRef<HTMLDivElement>();


  React.useEffect(() => {
    if (!rootStore.isMobile) {
      menu.enterSummarySidePanel();
    } else {
      menu.closeSidePanels();
    }
  }, [menu, store.isMobile]);

  React.useEffect(() => {
    let boxes: { main?: ScreenBox2D; sidePanel?: ScreenBox2D; top?: ScreenBox2D } = {};
    if (sideMenuRef.current) {
      boxes.sidePanel = sideMenuRef.current.getBoundingClientRect();
    }
    if (mainMenuRef.current) {
      boxes.main = mainMenuRef.current.getBoundingClientRect();
    }
    if (topMenuRef.current) {
      boxes.top = topMenuRef.current.getBoundingClientRect();
    }
    menu.setMenuScreenBoxes(boxes.main, boxes.sidePanel, boxes.top);
  }, [sideMenuRef, mainMenuRef, topMenuRef, menu]);

  React.useEffect(() => () => dismissGuide(), []);

  const handleDismiss = () => {
    dismissGuide();

    store.changeRemoveGuideStep(null, menu.mainMenu)
  };

  const showRemoveRowColsGuide = () => {
    handleDismiss();

    if (menu.mainMenu === MainMenu.DeleteRow || menu.mainMenu === MainMenu.DeleteColumn) {
      showRemoveGuide(store.changeRemoveGuideStep, handleDismiss);
    }
  };

  const handleDeleteClick = () => {
    menu.enterDeleteColumnMainMenu();
    const changedMode = store.changeMode(VisualMode.Delete, menu.mainMenu);
    if (!changedMode) {
      return;
    }

    if (persistenceService.shouldShowRemoveGuide) {
      showRemoveRowColsGuide();
      persistenceService.setShouldShowRemoveGuide(false);
    }
  };

  const handleDeleteCloseClick = () => {
    menu.enterOverviewMainMenu();
    store.changeMode(VisualMode.View);
  };

  const handleRemoveHintClick = () => {
    showRemoveRowColsGuide();
  };

  const handleHintClick = () => {
    if (menu.selectedOverviewOption === SelectedOption.Add) {
      showInsertBoxGuide();
      return;
    }

    if (store.mode === VisualMode.Edit) {
      showSwapBoxGuide();
    }
  };

  const handleSwapCloseClick = () => {
    menu.enterOverviewMainMenu();
    store.changeMode(VisualMode.View);
  };

  const withSidebar = menu.sidePanels.length > 0;

  const handleAddClick = useCallback(() => {
    if (store.mode === VisualMode.FrontalView) {
      store.changeMode(VisualMode.View);
    } else {
      if (persistenceService.shouldShowBoxInsertGuide) {
        showInsertBoxGuide();
        persistenceService.setShouldShowBoxInsertGuide(false);
      }
      store.changeMode(VisualMode.FrontalView);
    }
    menu.toggleAdditionalBoxesSidePanel();
  }, [store, menu, showInsertBoxGuide]);

  const handleTextClick = useCallback(() => {
    store.changeMode(VisualMode.View);
    menu.toggleTextSidePanel();
  }, [store, menu]);

  const handleColorClick = useCallback(() => {
    store.changeMode(VisualMode.View);
    menu.toggleColorSidePanel();
  }, [store, menu]);

  // Handle case when after device rotation current mode is invalid
  useEffect(() => {
    if (store.mode === VisualMode.Insert && !store.isMobile) {
      store.cancelBoxInsertion();
    }
  }, [store, store.isMobile, store.mode]);

  const changeRemoveMode = useCallback((removeMode: RemoveMode): void => {
    if (removeMode === MainMenu.DeleteColumn) {
      menu.enterDeleteColumnMainMenu();
    } else {
      menu.enterDeleteRowMainMenu();
    }

    store.changeMode(VisualMode.Delete, menu.mainMenu);
  }, [menu, store]);

  return (
    <div className={styles.menuOverlay}>
      <div
        className={classNames(styles.topMenu, {
          [styles.withSidebar]: withSidebar
        })}
        ref={topMenuRef}
      >
        <TopMenu store={store} menu={menu} boxViewer={boxViewer} />
      </div>
      <div className={classNames(styles.mainMenu, { [styles.hidden]: isGuideVisible })} ref={mainMenuRef}>
        {(menu.mainMenu === MainMenu.Overview || (menu.mainMenu === MainMenu.SwapElements && !store.isMobile)) && store.mode !== VisualMode.Insert && (
          <CustomizeBottomMenu
            selectedMode={store.mode}
            onModeSelect={mode => {
              if (mode === VisualMode.Edit) {
                if (persistenceService.shouldShowBoxSwapGuide) {
                  showSwapBoxGuide();
                  persistenceService.setShouldShowBoxSwapGuide(false);
                }
                menu.enterSwapMainMenu();
              }

              store.changeMode(mode);
              menu.closeSidePanels();
            }}
            onHintClick={handleHintClick}
            onDeleteClick={handleDeleteClick}
            onTextClick={handleTextClick}
            onAddClick={handleAddClick}
            onColorClick={handleColorClick}
            selectedOption={menu.selectedOverviewOption}
            isAddRemoveHidden={store.model.wallType === WallType.Interna || store.model.wallType === WallType.Boxis}
          />
        )}
        {menu.mainMenu === MainMenu.Zoomed && <ZoomedMenu store={store} menu={menu} />}
        {menu.mainMenu === MainMenu.BoxContextMenu && <BoxContextMenu store={store} menu={menu} />}
        {menu.mainMenu === MainMenu.SwapElements && store.isMobile && <SwapMenu onClose={handleSwapCloseClick} />}
        {(menu.mainMenu === MainMenu.DeleteRow || menu.mainMenu === MainMenu.DeleteColumn) && (
          <DeleteRowOrColumnMenu
            disabled={store.selectedZones.length === 0}
            onClose={handleDeleteCloseClick}
            onHintClick={handleRemoveHintClick}
            onDeleteClick={() => store.removeSelected(menu.mainMenu)}
            activeRemovalMode={menu.mainMenu}
            onChangeActiveRemovalMode={changeRemoveMode}
          />
        )}
      </div>

      <div className={classNames(styles.notifications, { [styles.menuHidden]: isGuideVisible })}>
        <NotificationContainer />
      </div>
      <section
        className={classNames(styles.sidebar, {
          [styles.sidebarOpened]: withSidebar,
          [styles.sidebarCollapsed]: store.isMobile && store.mode === VisualMode.Insert,
          [styles.sidebarHidden]: isGuideVisible
        })}
        ref={sideMenuRef}
      >
        {menu.sidePanels.map(sidePanelId => {
          switch (sidePanelId) {
            case SidePanelId.Color:
              return <ColorSidePanel key={sidePanelId} store={store} menu={menu} />;
            case SidePanelId.Text:
              return <TextSidePanel key={sidePanelId} store={store} menu={menu} />;
            case SidePanelId.Summary:
              return <SummarySidePanel key={sidePanelId} store={store} menu={menu} />;
            case SidePanelId.AdditionalBoxes:
              return <AddBoxSidePanelSidePanel key={sidePanelId} store={store} menu={menu} />;
            default: {
              return <div></div>;
            }
          }
        })}
      </section>
    </div>
  );
});
