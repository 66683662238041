import * as React from 'react';
import classNames from 'classnames';

import logoUrl from './assets/logo.svg';
import styles from './Logo.module.scss';

interface IProps {
  className?: string;
}

const Logo: React.FC<IProps> = ({ className }) => {
  return (
    <div className={classNames(styles.root, className)}>
      <img src={logoUrl} className={styles.img} alt="eSafe corporate logo."/>
    </div>
  );
};

export default Logo;