import { MediaPathResolver } from './MediaPathResolver';

export interface Data {
  schema: string;
  data: string[]
}

export class DatasetLoader {
  public schema: any;
  public data: any[] = [];

  static async load(mediaResolver: MediaPathResolver): Promise<Data> {
    const [schema, data] = await Promise.all([
      fetchYml('assets/datasets/schema.yml'),

      Promise.all([
        fetchYml('assets/datasets/options.yml'),
        fetchYml('assets/datasets/optionGroups.yml'),
        fetchYml('assets/datasets/products.yml'),
      ])
    ]);

    function fetchYml(path: string) {
      return fetch(mediaResolver.getPath(path))
        .then(r => r.text())
        // .then(r => JSON.parse(r));
    }

    return { schema: schema, data };
  }
}
