import { Token } from './parser';
import { Position } from './Position';

type Cell = Position & {
  token: Token;
};

export interface LayoutBox {
  columns: number;
  rows: number;
  token: string;
  type: 'mailbox' | 'parcelbox' | 'lightbox' | 'centralbox';
}

export type PositionedLayoutBox = Position & {
  box: LayoutBox;
};

export function getBoxCells(positionedBox: PositionedLayoutBox): Cell[] {
  const box = positionedBox.box;
  const cells: Cell[] = [];
  for (let i = 0; i < box.columns; i++) {
    for (let j = 0; j < box.rows; j++) {
      cells.push({
        row: positionedBox.row + j,
        column: positionedBox.column + i,
        token: box.token
      })
    }
  }
  return cells;
}