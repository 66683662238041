import { makeAutoObservable } from 'mobx';
import { Engine } from '@canvas-logic/engine';
import { IWall, IWizard, WallType } from '../schema';
import { WallGenerator } from '../generator/WallGenerator';
import { RootStore } from './RootStore';
import { BoxViewModel, createPreviewViewModel } from '../viewer/BoxViewModel';
import { WallSerializer, WizardSerializer } from '../serialization';
import { FakeDimensionsService } from '../services/DimensionsService';
import { InternaGenerator } from '../generator/InternaGenerator';
import { BoxisGenerator } from '../generator/BoxisGenerator';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from 'worker-loader!../workers/worker';
import { callWorker } from '../workers/utils';
import { LayoutFactory } from '../layout/LayoutFactory';
import { WallWorkerResult } from '../workers/worker';

export class GeneratorStore {
  private readonly wizard: IWizard;
  private readonly wall: IWall;
  private readonly engine: Engine;

  get models(): IWall[] {
    return this._models;
  }

  previewLoaded = false;
  previews: BoxViewModel[] = [];
  private _models: IWall[] = [];
  private serializer: WallSerializer;

  constructor(private readonly rootStore: RootStore, link: string) {
    const serializer = new WizardSerializer(rootStore.datasetService);
    const { wizard } = serializer.fromLink(link);
    this.wizard = wizard;
    this.serializer = new WallSerializer(this.rootStore.datasetService);
    const result = this.serializer.getDefault(wizard.wallType);
    this.engine = result.engine;
    this.wall = result.wall;
    this.updateCylinderCutouts();

    switch (wizard.wallType) {
      case WallType.Interna:
        this.generateInterna();
        break;
      case WallType.Boxis:
        this.generateBoxis();
        break;
      case WallType.Digital:
      case WallType.Mechanical:
        this.generateWall();
        break;

    }

    makeAutoObservable(this);
  }

  getLinkForModel(modelIndex: number): string {
    return this.serializer.toLink(this._models[modelIndex]);
  }

  private async generateInterna() {
    const generator = new InternaGenerator(this.engine, this.wall);
    const models = await generator.generate(this.wizard);
    this.updatePreviews(models);
  }

  private async generateBoxis() {
    const generator = new BoxisGenerator(this.engine, this.wall);
    const models = await generator.generate(this.wizard);
    this.updatePreviews(models);
  }

  private async generateWall() {
    this.previewLoaded = false;
    const wizard = this.wizard;
    const worker = new Worker();
    const topLayouts: WallWorkerResult = await callWorker(worker,
      {
        kind: 'wall',
        wizard
      });
    worker.terminate();
    const wallGenerator = new WallGenerator(this.engine, this.wall);
    const models = topLayouts.map(({ layoutData }) => {
      const layout = LayoutFactory.create(layoutData);
      return wallGenerator.generate(layout, wizard);
    });
    this.updatePreviews(models);
  }

  private updatePreviews(models: IWall[]): void {
    this._models = [];
    this.previews = [];
    models.forEach(model => {
      this._models.push(model);
      this.previews.push(createPreviewViewModel(model, new FakeDimensionsService(), undefined));
    });
    this.previewLoaded = true;
  }

  private updateCylinderCutouts(): void {
    this.wall.cylinderCutout = this.wizard.cylinderCutout;
  }
}
