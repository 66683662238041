import { Layout } from '../layout/Layout';

export type Action = (layout: Layout) => void;

export type LayoutRule = (layout: Layout) => string | null;

export class LayoutRulesManager {
  private rules: LayoutRule[] = [];

  constructor(private layout: Layout) {
  }

  canApplyAction(action: Action): boolean {
    const layoutCopy = this.layout.clone();
    action(layoutCopy);
    return this.validateRules(layoutCopy);
  }

  private validateRules(layout: Layout): boolean {
    return this.rules.length ? this.rules.every(rule => rule(layout) === null) : true;
  }

  addRule(rule: LayoutRule) {
    this.rules.push(rule);
  }

  updateLayout(layout: Layout) {
    this.layout = layout;
  }

  isValid(): boolean {
   return this.validateRules(this.layout);
  }

  firstError(): string | null {
    for (const rule of this.rules) {
      const ruleResult = rule(this.layout);
      if (ruleResult !== null) {
        return ruleResult;
      }
    }
    return null;
  }
}
