import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import styles from "./WizardStepper.module.scss";
import classNames from "classnames";
import Toast from "../../components/Toast/Toast";
import { NotificationType } from "../../stores/NotificationsStore";
import { FormattedMessage } from "react-intl";

const isElementScrollable = (element: HTMLElement) => {
  return element.scrollHeight > element.clientHeight;
}

interface IProps {
  current?: boolean;
}

const WizardStepContainer: React.FC<IProps> = ({ current, children }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isHintAnimated, setIsHintAnimated] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);

  const [userScrolled, setUserScrolled] = useState(false);

  useLayoutEffect(() => {
    setIsScrollable((containerRef.current && isElementScrollable(containerRef.current)) ?? false);
  },[]);

  useLayoutEffect(() => {
    if (!current) {
      return;
    }

    if (isScrollable) {
      setIsHintAnimated(true);
    }
  },[current, isScrollable]);

  const handleOnScroll = useCallback(() => {
    setUserScrolled(true);
  }, []);

  return (
    <div
      className={classNames(styles.stepContainer, {
        [styles.stepContainerScrollableCurrent]: isHintAnimated
      })}
      ref={containerRef}
      onScroll={handleOnScroll}
    >
      {children}
      <div className={classNames(styles.stepContainerHint, {
        [styles.stepContainerHintVisible]: isHintAnimated && !userScrolled
      })}>
        <Toast type={NotificationType.INFO} hasArrow>
          <FormattedMessage id="message.scrollForMore" />
        </Toast>
      </div>
    </div>
  );
};

export { WizardStepContainer };
