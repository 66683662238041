import { Shape } from "three";

export class RoundedShape extends Shape {
  constructor(width: number, height: number, radius = 0.02) {
    super();

    const x = -width / 2;
    const y = -height / 2;

    this.moveTo( x, y + radius );
    this.lineTo( x, y + height - radius );
    this.quadraticCurveTo( x, y + height, x + radius, y + height );
    this.lineTo( x + width - radius, y + height );
    this.quadraticCurveTo( x + width, y + height, x + width, y + height - radius );
    this.lineTo( x + width, y + radius );
    this.quadraticCurveTo( x + width, y, x + width - radius, y );
    this.lineTo( x + radius, y );
    this.quadraticCurveTo( x, y, x, y + radius );
  }
}
