import { IPostProcessor } from './IPostProcessor';
import { AccessoryType, CylinderCutout, IAccessory, IMailBox, IWall, MailBoxExternalFields } from '../schema';
import { mailBoxTypeGuard } from '../guards';

interface MailBoxAccessoriesWall {
  wallType: IWall['wallType'];
  accessories: IWall['accessories'];
  boxes: IWall['boxes'];
  hasOwnKeyPlan: IWall['hasOwnKeyPlan'];
  cylinderCutout: IWall['cylinderCutout'];
}
export type IMailBoxExternalFields = Pick<MailBoxExternalFields, keyof MailBoxExternalFields>;

export class MailBoxPostProcessor implements IPostProcessor {
  private byType: Map<AccessoryType, IAccessory>;
  private amount: Map<AccessoryType, number>;
  private static mailBoxAccessories: AccessoryType[] = [
    AccessoryType.LockCylinder,
    AccessoryType.CylinderCutout,
    AccessoryType.Mount
  ];
  private wall: MailBoxAccessoriesWall;
  private mailBoxes: IMailBox[];

  constructor(private readonly accessories: IAccessory[],
              private readonly fields: IMailBoxExternalFields
  ) {
  }

  process(wall: MailBoxAccessoriesWall): void {
    this.wall = wall;

    this.parseAccessories();
    this.mailBoxes = this.getMailBoxes();
    this.clearOldAccessories();

    this.processLockCylinder();
    this.processMounts();

    this.updatePVC();
    this.addAccessories();
  }

  private clearOldAccessories() {
    this.wall.accessories = this.wall.accessories
      .filter(accessory => !MailBoxPostProcessor.mailBoxAccessories.includes(accessory.type));
  }

  private parseAccessories() {
    this.byType = new Map<AccessoryType, IAccessory>();
    this.amount = new Map<AccessoryType, number>();

    MailBoxPostProcessor.mailBoxAccessories.forEach(accessoryType => {
      const accessory = this.accessories.find(item => item.type === accessoryType);
      if (!accessory) {
        throw new Error(`Cannot find accessory ${accessoryType}`);
      }
      this.byType.set(accessory.type, accessory);
      this.amount.set(accessory.type, 0);
    });
  }

  private setAccessoryAmount(type: AccessoryType, number: number): void {
    this.amount.set(type, number);
  }

  private getMailBoxes(): IMailBox[] {
    const mailBoxes: IMailBox[] = [];
    for (let wallBox of this.wall.boxes) {
      const box = wallBox.box;
      if (mailBoxTypeGuard(box)) {
        mailBoxes.push(box);
      }
    }
    return mailBoxes;
  }

  private processLockCylinder() {
    if (!this.wall.hasOwnKeyPlan) {
      return;
    }
    if (this.wall.cylinderCutout === CylinderCutout.BuildInLock) {
      this.setAccessoryAmount(AccessoryType.LockCylinder, this.mailBoxes.filter(box => box.requiresLockCylinder).length);
    }
    if (this.wall.cylinderCutout === CylinderCutout.CylinderOnly) {
      this.setAccessoryAmount(AccessoryType.CylinderCutout, this.mailBoxes.filter(box => box.requiresLockCylinder).length);
    }
  }

  private processMounts() {
    if (this.mailBoxes.length > 1) {
      let amount = this.mailBoxes.length;
      if (this.hasCu) {
        amount += 1;
      }

      this.setAccessoryAmount(AccessoryType.Mount, amount);
    }
  }

  private addAccessories() {
    for (let [type, amount] of this.amount.entries()) {
      while (amount-- > 0) {
        const accessory = this.getAccessory(type);
        this.wall.accessories.push(accessory);
      }
    }
  }

  private getAccessory(type: AccessoryType) {
    const candidate = this.byType.get(type);
    if (!candidate) {
      throw new Error(`Cannot find ${type}`);
    }
    return candidate;
  }

  private get hasCu(): boolean {
    return this.wall.boxes.some(box => box.box.type === 'centralbox');
  }

  private updatePVC() {
    this.mailBoxes.forEach(mailbox => {
      mailbox.hasPVC = mailbox.engraving.some(engraving => engraving.pvc);
      const overrideArticles = this.fields.overrideArticle(mailbox);
      if (overrideArticles) {
        mailbox.article = overrideArticles;
      }
    });
  }
}
