import * as React from "react";
import { FormattedMessage } from "react-intl";
import { WallLocation } from "../../schema";
import { WizardStore } from "../../stores/WizardStore";
import { IconName } from "../Icon/Icon";
import Typography from "../Typography/Typography";
import Selector, { ImageType } from "../Selector/Selector";
import { useCallback } from "react";
import styles from './WallLocationForm.module.scss';

interface IProps {
  className?: string;
  wallLocation?: WallLocation;
  wizardStore: WizardStore;
  availableWallLocations: WallLocation[];
  onSelect?: () => void;
}

const WallLocationForm: React.FC<IProps> = ({ className, wallLocation, availableWallLocations, wizardStore, onSelect }) => {
  const options = React.useMemo(() => {
    return availableWallLocations.map(location => ({
      value: location,
      title: <FormattedMessage id={`location.${location}`} />,
      image: location as IconName,
      imageType: ImageType.Icon
    }));
  }, [availableWallLocations]);

  const handleOnSelect = useCallback((location: string) => {
    onSelect?.();
    wizardStore.setWallLocation(location as WallLocation)
  }, [onSelect, wizardStore]);

  return (
    <section className={className}>
      <Typography variant="h2" align="center">
        <FormattedMessage id="menu.options.location" />
      </Typography>
      <Selector
        name="Wall location"
        options={options}
        selected={wallLocation}
        onSelect={handleOnSelect}
        className={styles.wallLocation__selector}
        classes={{ list: styles.selectorList }}
      />
    </section>
  );
};

export default WallLocationForm;
