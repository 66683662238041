import gzip from 'gzip-js';
import pako from 'pako';
import { routes } from '../stores/RootStore';


export const PDF_LINK_PARAMETER = 'autodownloadpdf';
export const SOURCE_PARAMETER = 'source';

export class LinkService {
  public static version = '2';

  static configuratorLink(link: string): string {
    const location = window.location;
    return location.origin + location.pathname + `#` + routes.configurator + `?v=${LinkService.version}&link=${link}`;
  }

  private static configuratorIntegrationLink(link: string): string {
    return this.configuratorLink(link) + `&${PDF_LINK_PARAMETER}`;
  }

  static jsonToLink(json: Record<string, any>): string {
    const str = JSON.stringify(json);
    const deflated = pako.deflateRaw(str, { level: 9 });
    let deflatedString = '';
    for (let i = 0; i < deflated.length; i++) {
      deflatedString += String.fromCharCode(deflated[i]);
    }
    return btoa(deflatedString);
  }

  static linkToJsonV1(link: string): Record<string, any> {
    const data = Array.from(atob(link)).map(u => u.charCodeAt(0));
    const unzipped = gzip.unzip(data);
    const unzippedString = unzipped.map(d => String.fromCharCode(d)).join('');
    return JSON.parse(unzippedString);
  }

  static linkToJson(link: string): Record<string, any> {
    const binaryString = atob(link);
    const byteArray = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      byteArray[i] = binaryString.charCodeAt(i);
    }
    const inflated = pako.inflateRaw(byteArray, { to: 'string' });
    return JSON.parse(inflated);
  }

  static makeSharableLink(link: string): string {
    const encodedLink = encodeURIComponent(link);
    const sharableLink = this.configuratorLink(encodedLink);
    return sharableLink;
  }

  static makeIntegrationLink(link: string): string {
    const encodedLink = encodeURIComponent(link);
    const sharableLink = this.configuratorIntegrationLink(encodedLink);
    return sharableLink;
  }
}