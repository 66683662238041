import * as React from 'react';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { ConfiguratorStore } from '../../stores/ConfiguratorStore';
import { Viewer } from '../../components/Viewer/Viewer';

import { BoxViewer } from '../../viewer/BoxViewer';
import { rootStore } from '../../stores';
import { menu } from '../../stores/ConfiguratorMenuStore';
import { ConfiguratorMenu } from '../../components/ConfiguratorMenu/ConfiguratorMenu';
import { dndService } from '../../services/DnDService';
import { GuideContextProvider } from '../../contexts/GuideContext';
import { PDF_LINK_PARAMETER, SOURCE_PARAMETER } from '../../services/LinkService';
import styles from './Configurator.module.scss';

interface IProps {
  className?: string;
}

const Configurator: React.FC<IProps> = ({ className }) => {
    const [searchParams] = useSearchParams();

    const [store] = useState<ConfiguratorStore>(() => {
        const idParameter = searchParams.get('id') ?? '';
        const versionParameter = searchParams.get('v') ?? '1';
        const linkParameter = searchParams.get('link') ?? '';
        const pdfAutoDownloadParameter = searchParams.get(PDF_LINK_PARAMETER) !== null;

        const configuratorStore = new ConfiguratorStore(rootStore, idParameter, linkParameter, versionParameter, pdfAutoDownloadParameter);
        dndService.setStore(configuratorStore);
        
        return configuratorStore;
      }
    );

    useEffect(() => {
      rootStore.setPdfSource(searchParams.get(SOURCE_PARAMETER) === 'pdf');
      return () => menu.enterOverviewMainMenu();
    }, []);

    const [boxViewer, setBoxViewer] = React.useState<BoxViewer | undefined>(undefined);

    return (
      <main className={classNames(styles.root, className)}>
        <div className={styles.viewer}>
          <Viewer store={store} onBoxViewerCreated={setBoxViewer} onBoxViewerRendered={store.startAutoDownloadPdf}/>
          <GuideContextProvider notificationsStore={rootStore.notificationsStore}>
            <ConfiguratorMenu store={store} menu={menu} boxViewer={boxViewer} />
          </GuideContextProvider>
        </div>
      </main>
    );
  }
;


export default observer(Configurator);
