import { ConfiguratorStore } from '../stores/ConfiguratorStore';
import { rootStore } from '../stores';
import { Formatter } from './Formatter';
import { BomService, IProductionBOMLine } from './BomService';
import { FinishType, WallType } from '../schema';


const proxyUrl = process.env.REACT_APP_CL__PROXY_URL;

export interface IConfiguration {
  description: string;
  unitPrice: number;
  configurationURL: string;
  notOrderdYet: boolean;
  priceOnRequest: boolean;
  itemTemplateCode: string;
  productionBOMHeaders: [
    {
      description: string;
      unitOfMeasureCode: 'STUK';
      productionBOMLines: IProductionBOMLine[]
    }
  ]
}

export class IntegrationService {
  private configurationId: string;
  private configuration: IConfiguration;

  constructor(
    private readonly store: ConfiguratorStore,
    private readonly bomService: BomService
  ) {
  }
  async createConfiguration(): Promise<string> {
    this.createDto();
    await this.create();
    return this.configurationId;
  }

  private createDto() {
    const description = this.getDescription();
    this.configuration = {
      itemTemplateCode: this.getItemTemplateCode(this.store.model.wallType),
      description,
      configurationURL: this.store.integrationLink(),
      notOrderdYet: !rootStore.authorized,
      unitPrice: Formatter.priceToNumber(this.bomService.totalPrice),
      priceOnRequest: this.bomService.totalPrice === null,
      productionBOMHeaders: [{
        description,
        unitOfMeasureCode: 'STUK',
        productionBOMLines: this.bomService.lines
      }]
    };
  }

  private async create() {
    const reqUrl = `${proxyUrl}api/configuration`
    const response = await fetch(reqUrl, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(this.configuration)
    });
    if (response.status !== 200) {
      throw new Error('Cannot create configuration')
    }
    const data = await response.json();
    this.configurationId = data.no;
  }

  private getItemTemplateCode(wallType: WallType): string {
    switch (wallType) {
      case WallType.Digital:
        return 'WALL DIGITAL';
      case WallType.Mechanical:
        return 'WALL MECHANICAL'
      case WallType.Boxis:
        return 'ALBO BOXIS';
      case WallType.Interna:
        return 'ALBO INTERNA'
      default:
        throw new Error(`Invalid wallType: ${wallType}`);
    }
  }

  private getDescription() {
    const keyplan = this.store.model.hasOwnKeyPlan ? 'keyplan': '';
    const configurationName = this.getConfigurationName(this.store.model.wallType);
    if (this.store.model.material) {
      const ralColor = this.store.model.material.ralColor;
      const finishing = this.store.model.material.finish === FinishType.StructureMatt ? 'TXT MATT': 'TXT';
      return `${configurationName} ${ralColor} ${finishing} ${keyplan}`.trim();
    } else {
      return `${configurationName} ${rootStore.localization.formatMessage('color.colorToBeConfirmed')} ${keyplan}`.trim();
    }
  }

  private getConfigurationName(wallType: WallType): string {
    switch (wallType) {
      case WallType.Digital:
        return 'Wall Digital';
      case WallType.Mechanical:
        return 'Wall Mechanical';
      case WallType.Boxis:
        return 'Albo Boxis';
      case WallType.Interna:
        return 'Albo Interna';
      default:
        throw new Error('Invalid wall type');
    }
  }
}

export async function loadConfiguration(configurationId: string) {
  const reqUrl = `${proxyUrl}api/configuration/${configurationId}`;
  const response = await fetch(reqUrl)
  const data = await response.json();
  return data.configurationURL;
}