import { Layout } from '../../layout/Layout';
import { Position } from '../../layout/Position';
import { PositionedLayoutBox } from '../../layout/Box';
import { NeighbourBoxPosition } from './zones';

export class NeighbourBehaviour {
  constructor(private readonly layout: Layout) {
  }

  shift(position: Position, direction: NeighbourBoxPosition) {
    switch (direction) {
      case 'left':
        this.shiftLeft(position);
        break;
      case 'right':
        this.shiftRight(position)
        break;
      case 'bottom':
        this.shiftBottom(position);
        break;
      case 'top':
        this.shiftTop(position);
        break;
    }
  }

  shiftRight(position: Position) {
    const box = this.layout.findBoxAt(position);
    const rightNeighbour = this.layout.rightNeighbour(box);
    if (rightNeighbour) {
      this.exchangeHorizontally(box, rightNeighbour);
    }
  }

  shiftLeft(position: Position) {
    const box = this.layout.findBoxAt(position);
    const leftNeighbour = this.layout.leftNeighbour(box);
    if (leftNeighbour) {
      this.exchangeHorizontally(leftNeighbour, box);
    }
  }

  private exchangeHorizontally(left: PositionedLayoutBox, right: PositionedLayoutBox) {
    const leftBox = left.box;
    const rightBox = right.box;
    if (leftBox.rows === rightBox.rows && left.row === right.row) {
      this.layout.removeBox(right);
      this.layout.removeBox(left);
      right.column = left.column;
      left.column = right.column + rightBox.columns;
      this.layout.insertBox(left);
      this.layout.insertBox(right);
    }
  }

  shiftBottom(position: Position) {
    const box = this.layout.findBoxAt(position);
    const bottomNeighbour = this.layout.bottomNeighbour(box);
    if (bottomNeighbour) {
      this.exchangeVertically(box, bottomNeighbour);
    }
  }

  shiftTop(position: Position) {
    const box = this.layout.findBoxAt(position);
    const topNeighbour = this.layout.topNeighbour(box);
    if (topNeighbour) {
      this.exchangeVertically(topNeighbour, box);
    }
  }

  private exchangeVertically(top: PositionedLayoutBox, bottom: PositionedLayoutBox) {
    const topBox = top.box;
    const bottomBox = bottom.box;
    if (topBox.columns === bottomBox.columns && top.column === bottom.column) {
      this.layout.removeBox(bottom);
      this.layout.removeBox(top);
      top.row = bottom.row;
      bottom.row = top.row + topBox.rows;
      this.layout.insertBox(top);
      this.layout.insertBox(bottom);
    }
  }
}
