import { LayoutBox, PositionedLayoutBox } from './Box';
import { LayoutBoxRegistry } from './LayoutBoxRegistry';
export type Token = string;

export type RowSchema = string[];

export function getNextToken(row: string): [Token, string] {
  let position = -1;
  let candidates = LayoutBoxRegistry.all.concat();
  let input = row.trimStart();
  do {
    position++;
    let newCandidates = [];

    for (const box of candidates) {
      if (box.token[position] === input[position]) {
        // full match
        if (box.token.length === position + 1) {
          newCandidates = [box];
          break;
        } else {
          newCandidates.push(box);
        }
      }
    }
    candidates = newCandidates;
  } while (candidates.length > 1);
  if (!candidates.length) {
    throw new Error('Invalid schema');
  }
  const box = candidates[0];
  const token = box.token;
  const rest = input.slice(token.length).trim();
  return [token, rest];
}

export function tokenizeRow(input: string): Token[] {
  const rowTokens: Token[] = [];
  while (input.length) {
    let [token, rest] = getNextToken(input);
    input = rest;
    rowTokens.push(token);
  }
  return rowTokens;
}

export function tokenize(rows: number, cols: number, schema: RowSchema): Token[][] {
  return schema.map(tokenizeRow);
}

export function getLayoutBox(token: Token): LayoutBox {
  const box = LayoutBoxRegistry.all.find(box => box.token === token);
  if (!box) {
    throw new Error(`Invalid token ${token}`);
  }
  return box;
}

function isCellTaken(visited: [number, number][], row: number, col: number) {
  return visited.some(point => point[0] === row && point[1] === col);
}

function takeCells(takenCells: [number, number][], box: LayoutBox, row: number, col: number) {
  for (let i = 0; i < box.columns; i++) {
    for (let j = 0; j < box.rows; j++) {
      takenCells.push([row + j, col + i]);
    }
  }
}

export function parse(rows: number, cols: number, schema: RowSchema): PositionedLayoutBox[] {
  const parsedBoxes: PositionedLayoutBox[] = [];
  const takenCells: [number, number][] = [];
  const tokens = tokenize(rows, cols, schema);
  if (tokens.length !== rows) {
    throw new Error(`Invalid schema. ${rows} rows expected but got ${tokens.length}`);
  }
  for (let column = 0; column < cols; column++) {
    for (let row = 0; row < rows; row++) {
      const rowTokens = tokens[row];
      if (column >= rowTokens.length) {
        throw new Error(`Invalid schema. got ${column} tokens at row ${row} but ${cols} is required`);
      }
      if (isCellTaken(takenCells, row, column)) continue;
      const token = rowTokens[column];
      const box = getLayoutBox(token);
      takeCells(takenCells, box, row, column);
      parsedBoxes.push({
        box,
        row,
        column
      })
    }
  }
  return parsedBoxes.map(box => changeRowDirection(box, rows));
}

export function changeRowDirection(positionedBox: PositionedLayoutBox, rows: number): PositionedLayoutBox {
  const box = positionedBox.box;
  return {
    ...positionedBox,
    row: rows - positionedBox.row - box.rows
  }
}
