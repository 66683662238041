import { PositionedLayoutBox } from '../layout/Box';
import { ParcelLockType } from '../schema';
import { LayoutBoxRegistry } from '../layout/LayoutBoxRegistry';

export type ParcelLockTypeDetector = (box: PositionedLayoutBox) => ParcelLockType;

export function adjustMechanicalWallParcels(boxes: PositionedLayoutBox[], lockType: ParcelLockType): ParcelLockTypeDetector {
  const map = new Map<PositionedLayoutBox, ParcelLockType>();
  const parcels = boxes.filter(lbox => lbox.box.type === 'parcelbox');
  const electricParcels = parcels.filter(mustBeElectric);
  if (electricParcels.length > 1) {
    throw new Error('More than 1 electric parcelbox is not allowed');
  }
  let electricParcel = electricParcels[0];
  // 1 electric Parcel is required if lockType is Electric
  if (electricParcels.length === 0 && lockType === ParcelLockType.Electric) {
    electricParcel = parcels[0];
  }
  for (let parcel of parcels) {
    if (parcel === electricParcel) {
      map.set(parcel, ParcelLockType.Electric);
    } else {
      map.set(parcel, ParcelLockType.Number);
    }
  }
  return (parcel) => {
    const lockType = map.get(parcel);
    if (!lockType) {
      throw new Error('Cannot identify parcel lock type');
    }
    return lockType;
  };
}

function mustBeElectric(layoutBox: PositionedLayoutBox): boolean {
  const { rows, columns } = layoutBox.box;
  const isXL = (rows === LayoutBoxRegistry.parcelXL.rows && columns === LayoutBoxRegistry.parcelXL.columns);
  return [LayoutBoxRegistry.parcelXS.rows,
    LayoutBoxRegistry.parcel150.rows
  ].includes(rows) || isXL;
}