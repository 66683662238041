import * as React from "react";
import classNames from "classnames";
import { v4 } from "uuid";
import "./Checkbox.scss";


interface IProps {
  id?: string;
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  children?: JSX.Element | JSX.Element[];
  onChange?: (checked: boolean) => void;
}

const Checkbox: React.FC<IProps> = ({className, checked, disabled, children, onChange}) => {
  const id = React.useMemo(() => v4(), []);

  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.currentTarget.checked);
    }
  };

  return (
    <label htmlFor={id} className={classNames("checkbox", className, {"checkbox--disabled": disabled })}>
      <input
        id={id}
        type="checkbox"
        className="checkbox__input"
        checked={checked}
        onChange={handleChecked}
        disabled={disabled}
      />
      <span className="checkbox__label" />
      { children }
    </label>
  );
};

export default Checkbox;
