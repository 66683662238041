import * as React from 'react';
import classNames from 'classnames';

import ColourSelectorItem from '../ColourSelectorItem/ColourSelectorItem';

import { FinishType } from '../../schema';
import styles from './ColourSelector.module.scss';


interface IColorItem {
  value: string;
  label?: string;
  textColor: string;
  background: string;
  border?: string;
  smallText?: string | JSX.Element;
}

interface IProps {
  name: string;
  className?: string;
  items: IColorItem[];
  selected: string | undefined;
  onSelect: (value: string) => void;
  orientation?: 'horizontal' | 'vertical';
  autoFocusSelected?: boolean;
}

const ColourSelector: React.FC<IProps> = ({
                                      name,
                                      className,
                                      items,
                                      selected,
                                      onSelect,
                                      orientation = 'horizontal',
                                      autoFocusSelected
                                    }) => {
  return (
    <div className={classNames(styles.root, {
      [styles.vertical]: orientation === 'vertical'
    }, className)}>
      <ul className={styles.list}>
        {items.map(({ label, value, textColor, background, border, smallText }) => {
          const isSelected = selected === value;

          return (
            <li key={value}>
              <ColourSelectorItem
                id={`${name}-${value}`}
                name={name}
                background={background}
                textColor={textColor}
                borderColor={border}
                value={value}
                selected={isSelected}
                onSelect={onSelect}
                minimal={orientation === 'horizontal'}
                label={label ?? value}
                smallText={smallText}
                autoFocus={autoFocusSelected && isSelected}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ColourSelector;
