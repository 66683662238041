import { LayoutRule } from './LayoutRulesManager';
import { Layout } from '../layout/Layout';
import { WallBox } from '../schema';
import { centralBoxTypeGuard } from '../guards';
import { isCentralBox, REFERENCE_HEIGHT } from './rules';
import { ZFormLayout } from '../layout/ZFormLayout';
import { StandaloneLayout } from "../layout/StandaloneLayout";
import { isLessOrEqThan, isLessThan } from '../services/utils';

export const centralBoxesHeight: LayoutRule = (layout: Layout) => {
  const centralBoxes = layout.boxes.filter(isCentralBox);
  const allValid = centralBoxes.every(concreteBox => {
    // TODO: Rework
    const wBox = concreteBox.box as WallBox;
    if (centralBoxTypeGuard(wBox)) {
      return wBox.display ? layout.boxInstalledInRange(concreteBox, 0.4, 1.6) : layout.boxInstalledAt(concreteBox, 1.6);
    }
    throw new Error('Invalid box. Central box expected');
  }); //; Math.abs(layout.topDistanceToFloor(box) - topDistanceToFloor) <= eps);
  return !allValid ? 'Invalid central box placement' : null;
};

export const zLayoutConstraintValidation: LayoutRule = (layout: Layout) => {
  if (layout instanceof ZFormLayout) {
    if (layout.constraint.w3 === 0) {
      return 'Configuration should retain Z shape';
    }

    if (layout.constraint.w2 === 0) {
      return 'Configuration should retain Z shape';
    }

    if (layout.constraint.w1 === 0) {
      return 'Configuration should retain Z shape';
    }
  }

  return null;
};

export const zLayoutMinShapeValidation: LayoutRule = (layout: Layout) => {
  if (layout instanceof ZFormLayout) {
    if (layout.constraint.w3 === 1 && layout.constraint.w2 === 1 && layout.constraint.w1 === 1) {
      return 'No columns to remove. Configuration should retain Z shape';
    }
  }

  return null;
};

export const standaloneMinSizeViolation: LayoutRule = (layout: Layout) => {
  if (layout instanceof StandaloneLayout) {
    if (layout.constraint.columns < 2) {
      return 'Standalone configurations should have at least 2 columns';
    }
  }

  return null;
};

export const standaloneMinSizeValidation: LayoutRule = (layout: Layout) => {
  if (layout instanceof StandaloneLayout) {
    if (layout.constraint.columns === 2) {
      return 'No columns to remove. Standalone configurations should have at least 2 columns';
    }
  }

  return null;
};

export const standaloneMinHeight: LayoutRule = (layout: Layout) => {
  if (layout instanceof StandaloneLayout) {
    const totalHeight = layout.offsetHeight + layout.rows * layout.cellHeight;

    return isLessOrEqThan(totalHeight, REFERENCE_HEIGHT)  ? `No rows to remove. Standalone configurations must have at least ${REFERENCE_HEIGHT} total height` : null;
  }

  return null;
};
