import * as React from 'react';
import { BoxViewer } from '../../viewer/BoxViewer';
import s from './WallPreview.module.scss';
import { BoxViewModel } from '../../viewer/BoxViewModel';
import { observer } from 'mobx-react';
import { rootStore } from '../../stores';

interface IProps {
  render: boolean;
  viewModel: BoxViewModel;
  onLoaded?: () => void;
  onError?: () => void;
}

export const WallPreview: React.FC<IProps> = observer(({ viewModel, render, onLoaded, onError }) => {
  const canvasRef = React.createRef<HTMLCanvasElement>();

  React.useEffect(() => {
    let viewer: BoxViewer | undefined;
    (async () => {
      if (canvasRef.current && render) {
        viewer = await init(canvasRef.current, viewModel, onLoaded, onError);
      }
    })();

    return () => {
      viewer?.dispose();
    };

    // eslint-disable-next-line
  }, [render]);

  return (
    <div className={s.viewer}>
      <canvas className={s.canvas} ref={canvasRef} />
    </div>
  );
});

async function init(canvas: HTMLCanvasElement, viewModel: BoxViewModel, onLoaded?: () => void, onError?: () => void) {
  let viewer: BoxViewer | undefined;
  if (canvas) {
    viewer = new BoxViewer(canvas,
      rootStore.resourceLoader,
      rootStore.textRenderer,
      false,
      true
    );

    try {
      await viewer.init(viewModel);
      await viewer.renderPreview(viewModel);
      //viewer.dispose();
      onLoaded?.();
    } catch (e) {
      onError?.();
      throw e;
    }
  }

  return viewer;
}