import Loader from '../../components/Loader/Loader';
import React from 'react';
import styles from './Loading.module.scss';

export const Loading = () => {
  return (<div className={styles.loader}>
    <div className={styles.loaderWrapper}>
      <Loader />
    </div>
  </div>);
}