import * as React from 'react';
import clsx from 'clsx';

import Typography from '../Typography/Typography';
import Icon, { IconName } from '../Icon/Icon';

import styles from './SwitchItem.module.scss';
import { dataCy, DataCyAttribute } from '../../cy';

interface IProps<T> {
  className?: string;
  id: string;
  name: string;
  title?: string | JSX.Element;
  image?: IconName;
  value: T;
  disabled?: boolean;
  selected?: boolean;
  onSelect?: (value: T) => void;
  autoFocus?: boolean;
  itemDataCy?: DataCyAttribute;
}

function SwitchItem<T extends string>({
                                        className,
                                        id,
                                        title,
                                        name,
                                        image,
                                        value,
                                        disabled,
                                        selected,
                                        onSelect,
                                        autoFocus,
                                        itemDataCy
                                      }: IProps<T>) {

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onSelect) {
      onSelect(event.currentTarget.value as T);
    }
  };

  return (
    <div
      className={clsx(styles.root, {
          [styles.selected]: selected,
          [styles.disabled]: disabled,
          [styles.icon]: image !== undefined && title === undefined
        },
        className
      )}
      {...dataCy(itemDataCy)}
    >
      <label className={styles.label} htmlFor={id}>
        <input
          className={styles.input}
          type="radio"
          id={id}
          value={value}
          name={name}
          onChange={handleOnChange}
          autoFocus={autoFocus && selected}
          disabled={disabled}
          checked={selected}
        />
        <div className={styles.content}>
          {image && <Icon type={image} className={styles.icon} />}
          {title && (
            <div className={styles.title_wrapper}>
              <Typography
                variant="button"
                className={styles.title}
                align="center"
              >
                {title}
              </Typography>
            </div>
          )}
        </div>
      </label>
    </div>
  );
}

export default SwitchItem;
