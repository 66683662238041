import { IPostProcessor } from './IPostProcessor';
import {
  IMailBox,
  IParcelBox,
  IRFIDBadge,
  IWall,
  MailboxLockType,
  ParcelLockType,
  WallBox
} from '../schema';
import { mailBoxTypeGuard, parcelBoxTypeGuard } from '../guards';

interface BoxWall {
  boxes: IWall['boxes'];
}

export class RFIDBadgesPostProcessor implements IPostProcessor {
  private wall: BoxWall;

  constructor(private readonly rfidBadges: IRFIDBadge[]) {
  }

  process(wall: BoxWall): void {
    this.wall = wall;
    if (this.rfidBadges.length === 0) {
      return;
    }

    const badge = this.rfidBadges[0];

    this.addRfidBadges(badge);
  }

  private addRfidBadges(badge: IRFIDBadge) {
    for (let { box } of this.wall.boxes) {
      if (this.canHaveRfidBadges(box)) {
        box.rfidBadges.length = 0;

        if (this.hasElectronicMailbox) {
          if (mailBoxTypeGuard(box) && box.lockType === MailboxLockType.Electric) {
            box.rfidBadges.push(badge, badge);
          }
        } else {
          if (parcelBoxTypeGuard(box) && box.lockType === ParcelLockType.Electric) {
            box.rfidBadges.push(badge, badge);
          }
        }
      }
    }
  }

  private get hasElectronicMailbox(): boolean {
    return this.wall.boxes.some(
      concreteBox => mailBoxTypeGuard(concreteBox.box)
        && concreteBox.box.lockType === MailboxLockType.Electric
    );
  }

  private canHaveRfidBadges(box: WallBox): box is IMailBox | IParcelBox {
    return mailBoxTypeGuard(box) || parcelBoxTypeGuard(box);
  }
}
