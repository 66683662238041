import * as React from "react";
import { useDebounce } from "../../hooks/debounce";
import TextField from "./TextField";

interface IDebouncedTextFieldProps {
  initialValue: string;
  onChange: (value: string) => void;
  debounceTimeout?: number;
  placeholder?: string;
  disabled?: boolean;
}

export const DebouncedTextField: React.VFC<IDebouncedTextFieldProps> = ({ initialValue, onChange, debounceTimeout, placeholder, disabled}) => {
  const handleChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeText(event.currentTarget.value);
  };

  const {
          displayValue: displayText,
          setter: changeText
        } = useDebounce(value => onChange(value), initialValue, debounceTimeout);

  return (
    <TextField
      type="text"
      placeholder={placeholder}
      value={displayText}
      onChange={handleChangeText}
      disabled={disabled}
    />
  );
};
